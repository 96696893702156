import useOnScreen from '@/hooks/useOnScreen/useOnScreen';
import clsx from 'clsx';
import { useRef } from 'react';
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo';
import Button from '../Button/Button';
import { ButtonAlign } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import Headline from '../Headline/Headline';
import { HeadlineColor } from '../Headline/consts';
import Stack from '../Stack/Stack';
import SvgFlagDe from './Flags/FlagDe';
import SvgFlagGb from './Flags/FlagGb';
import SvgFlagIt from './Flags/FlagIt';
import SvgFlagRu from './Flags/FlagRu';
import SvgFlagTr from './Flags/FlagTr';
import styles from './SolarVideoChatTeaser.module.scss';
import { ACTIVE_OPERATOR_PROPS, DEFAULT_PROPS } from './consts';
import { SolarVideoChatTeaserProps } from './types';

const SolarVideoChatTeaser = ({
  isOperatorActive,
}: SolarVideoChatTeaserProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useOnScreen(ref);
  const { buttonLabel, text, title, bubbleMessage } = isOperatorActive
    ? ACTIVE_OPERATOR_PROPS
    : DEFAULT_PROPS;

  return (
    <div className={styles.base}>
      <div ref={ref} className={styles.screenWrapper}>
        <div className={styles.badge}>
          In nur <span className={styles.highlight}>5 Min.</span> zur
          Kosten-einschätzung
        </div>

        <div className={styles.screenBox}>
          <strong>Jan</strong>
          <span>Kundenberatung</span>
        </div>

        <div
          className={clsx(styles.messageBubble, {
            [styles.hide]: !isInView,
            [styles.animate]: isInView,
          })}
        >
          {bubbleMessage}
        </div>
      </div>

      <Headline
        tag="h3"
        level={2}
        headlineColor={HeadlineColor.Gradient}
        className={styles.headline}
        subText={text}
      >
        {title}
      </Headline>

      <ul className={styles.horizontalCheckList}>
        <li>
          <strong>5 Min.</strong> bis zur Kostenschätzung
        </li>
        <li>
          <strong>Individuell</strong> auf Ihr Haus zugeschnitten
        </li>
        <li>
          <strong>Kostenlos</strong> und unverbindlich
        </li>
      </ul>

      <Stack gap="1.5rem" alignItems="center" className={styles.footer}>
        {!isOperatorActive && (
          <Button
            actionType={ClickableAction.EpilotJourney}
            journeyId="cd571ed0-2b9e-11ee-8080-0b81f2713351"
            align={ButtonAlign.Center}
          >
            Zum Online Angebot
          </Button>
        )}

        <Button
          actionType={ClickableAction.Linkextern}
          linkextern={
            isOperatorActive
              ? 'https://videoberatung-solar.enercity.de/customer'
              : '#'
          }
          align={ButtonAlign.Center}
          className={clsx({ [styles.disableLink]: !isOperatorActive })}
          inactive={!isOperatorActive}
        >
          {buttonLabel}
        </Button>

        <div className={styles.customerSupport}>
          <AdditionalInfo>Kundenberatung verfügbar auf:</AdditionalInfo>

          <div className={styles.flags}>
            <SvgFlagDe />
            <SvgFlagGb />
            <SvgFlagTr />
            <SvgFlagRu />
            <SvgFlagIt />
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default SolarVideoChatTeaser;
