import delmenhorstImage from './images/delmenhorst.jpg';
import garbsenImage from './images/garbsen.jpg';
import hannoverImage from './images/hannover.jpg';
import laatzenImage from './images/laatzen.jpg';
import langenhagenImage from './images/langenhagen.jpg';
import rintelnImage from './images/rinteln.jpg';
import ronnenbergImage from './images/ronnenberg.jpg';
import seelzeImage from './images/seelze.jpg';
import type { CommunityHeatPlannerPlace } from './types';

export const communityHeatPlannerPlaces: CommunityHeatPlannerPlace[] = [
  {
    title: 'Kommunale Wärmeplanung der Stadt Hannover',
    lat: 52.375893,
    lng: 9.73201,
    image: hannoverImage,
    dateLabel: '2021-2023',
    link: 'http://www.hannover.de/waermeplanung-lhh',
  },
  {
    title: 'Kommunale Wärmeplanung der Stadt Rinteln',
    lat: 52.187832,
    lng: 9.08019,
    image: rintelnImage,
    dateLabel: 'seit 09/2023',
  },
  {
    title: 'Kommunale Wärmeplanung der Stadt Seelze',
    lat: 52.39706,
    lng: 9.58961,
    image: seelzeImage,
    dateLabel: 'seit 02/2024',
  },
  {
    title: 'Kommunale Wärmeplanung der Stadt Garbsen',
    lat: 52.41385,
    lng: 9.58813,
    image: garbsenImage,
    dateLabel: 'seit 03/2024',
  },
  {
    title: 'Kommunale Wärmeplanung der Stadt Langenhagen',
    lat: 52.5165809,
    lng: 9.7005809,
    image: langenhagenImage,
    dateLabel: 'seit 03/2024',
  },
  {
    title:
      'Vier integrierte Quartierskonzepte und kommunale Wärmeplanung der Stadt Laatzen',
    lat: 52.2937646,
    lng: 9.756308,
    image: laatzenImage,
    dateLabel: 'seit 03/2024',
  },
  {
    title: 'Kommunale Wärmeplanung der Stadt Delmenhorst',
    lat: 53.0522,
    lng: 8.6356,
    image: delmenhorstImage,
    dateLabel: 'seit 06/2024',
  },
  {
    title: 'Kommunale Wärmeplanung der Stadt Ronnenberg',
    lat: 52.308528,
    lng: 9.5757881,
    image: ronnenbergImage,
    dateLabel: 'seit 07/2024',
  },
];
