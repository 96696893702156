import React from 'react';
import { lastDayOfMonth } from '../../helpers/date';
import { createInitialDatePartsState } from './helpers';
import type {
  UseDatePartsConfig,
  UseDatePartsListItem,
  UseDatePartsOption,
  UseDatePartsState,
  DatePart,
} from './interfaces';

const createOptions = (
  length: number,
  start: number,
  labelPostProcess?: (value: number) => string
) =>
  Array.from({ length }, (_, idx) => {
    const value = (+start + idx).toString();
    const label = labelPostProcess ? labelPostProcess(start + idx) : value;
    return { label, value };
  });

const createNewState =
  (datePart: DatePart, option: UseDatePartsOption | null) =>
  (oldState: UseDatePartsState) => {
    switch (datePart) {
      case 'day': {
        return {
          ...oldState,
          day: option,
        };
      }
      case 'month': {
        return {
          ...oldState,
          day: null,
          month: option,
        };
      }
      case 'year': {
        return {
          day: null,
          month: null,
          year: option,
        };
      }
      default: {
        return {
          ...oldState,
          [datePart]: option,
        };
      }
    }
  };

// eslint-disable-next-line
const useDateParts = ({
  minDate,
  maxDate,
  onChangeDate,
  dateParts = ['year', 'month', 'day'],
}: UseDatePartsConfig) => {
  const onChangeRef = React.useRef<(value: Date | null) => void>();
  const datePartsRef = React.useRef<DatePart[]>();
  onChangeRef.current = onChangeDate;
  datePartsRef.current = dateParts;

  const [datePartsState, setDatePartsState] = React.useState<UseDatePartsState>(
    createInitialDatePartsState(dateParts)
  );

  const [touchedDateParts, setTouchedDateParts] = React.useState<DatePart[]>(
    []
  );

  const partStateToNumber = React.useCallback(
    (part: DatePart) => {
      return +(datePartsState[part]?.value ?? '');
    },
    [datePartsState]
  );

  const currentYear = partStateToNumber('year') || minDate.getFullYear();
  const currentMonth = partStateToNumber('month') || minDate.getMonth();

  const startMonth: number = new Date(
    currentYear,
    currentYear === minDate.getFullYear() ? minDate.getMonth() : 0,
    1
  ).getMonth();

  const endMonth: number =
    currentYear === maxDate.getFullYear() ? maxDate.getMonth() : 11;

  const startDay: number =
    currentYear === minDate.getFullYear() && currentMonth === minDate.getMonth()
      ? minDate.getDate()
      : 1;

  const endDay: number =
    currentYear === maxDate.getFullYear() && currentMonth === maxDate.getMonth()
      ? maxDate?.getDate()
      : lastDayOfMonth(currentYear, currentMonth);

  const getMonthName = (monthNumber: number): string =>
    new Date(currentYear, monthNumber, 1).toLocaleString('de-de', {
      month: 'long',
    });

  const createDateByState = React.useCallback(
    (state: UseDatePartsState) => {
      const { current: dateParts } = datePartsRef;
      return dateParts &&
        dateParts.length &&
        dateParts.every((part) => state[part])
        ? new Date(
            partStateToNumber('year'),
            state['month'] ? +(datePartsState['month']?.value ?? '') : 0,
            state['day'] ? +(datePartsState['day']?.value ?? '') : 1
          )
        : null;
    },
    [datePartsState, partStateToNumber]
  );

  const handleOnDataPartChange =
    (datePart: DatePart) => (option: UseDatePartsOption | null) => {
      if (!touchedDateParts.includes(datePart)) {
        setTouchedDateParts((oldState) => [...oldState, datePart]);
      }

      setDatePartsState(createNewState(datePart, option));
    };

  const dataPartsListConfigs = {
    day: {
      label: 'Tag',
      options: createOptions(endDay - startDay + 1, startDay),
    },
    month: {
      label: 'Monat',
      options: createOptions(
        endMonth - startMonth + 1,
        startMonth,
        getMonthName
      ),
    },
    year: {
      label: 'Jahr',
      options: createOptions(
        maxDate.getFullYear() - minDate.getFullYear() + 1,
        minDate.getFullYear()
      ),
    },
  } as const;

  const datePartsList: UseDatePartsListItem[] = dateParts.map((datePart) => ({
    datePart,
    ...dataPartsListConfigs[datePart],
  }));

  React.useEffect(() => {
    const { current: onChange } = onChangeRef;
    const { current: dateParts } = datePartsRef;
    if (
      onChange &&
      dateParts &&
      dateParts?.every((part) => touchedDateParts.includes(part))
    ) {
      onChange(createDateByState(datePartsState));
    }
  }, [
    onChangeRef,
    datePartsState,
    datePartsRef,
    touchedDateParts,
    createDateByState,
  ]);

  return {
    datePartsState,
    datePartsList,
    handleOnDataPartChange,
  };
};

export default useDateParts;
