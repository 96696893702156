import useResizeObserver from '@/hooks/useResizeObserver/useResizeObserver';
import { animated, useTransition } from '@react-spring/web';
import { UIEvent, useCallback, useRef, useState } from 'react';
import Button from '../Button/Button';
import { ButtonSize } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import IconButton from '../IconButton/IconButton';
import { IconButtonSize } from '../IconButton/consts';
import styles from './MapConsentLayer.module.scss';

const MapConsentLayer = ({ onAction }: { onAction: () => void }) => {
  const [showScrollButton, setShowScrollButton] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  const scrollButtonTransition = useTransition(showScrollButton, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });

  const resizeObserverCallback = useCallback(() => {
    const el = contentRef.current;
    if (!el) return;
    if (el.clientHeight >= el.scrollHeight - 32) {
      setShowScrollButton(false);
      return;
    }
    setShowScrollButton(true);
  }, []);
  useResizeObserver(contentRef, resizeObserverCallback);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const scrolledToBottom =
      Math.ceil(scrollTop) + clientHeight >= scrollHeight - 32;

    setShowScrollButton(!scrolledToBottom);
  };

  const handleClickScroll = () => {
    contentRef.current?.scrollTo({
      top: contentRef.current.scrollHeight - contentRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.requestConsentContainer}>
      <section
        className={styles.requestConsent}
        onScroll={handleScroll}
        ref={contentRef}
      >
        <div className={styles.top}>
          <p className={styles.title}>Empfohlener externer Inhalt</p>
          <p>
            An dieser Stelle finden Sie einen externen Inhalt von Google Maps,
            der von uns empfohlen wird oder ggf. für erweiterte Funktionen
            erforderlich ist. Sie können Ihre Zustimmung jederzeit wieder
            zurücknehmen.
          </p>
        </div>
        <div className={styles.bottom}>
          <p>
            Ich bin damit einverstanden, dass mir externe Inhalte angezeigt
            werden. Damit können personenbezogene Daten an Drittplattformen
            übermittelt werden.{' '}
            <a href={'https://www.enercity.de/datenschutz'} target="_blank">
              Mehr dazu in unserer Datenschutzerklärung.
            </a>
          </p>
          <Button
            actionType={ClickableAction.Custom}
            onClick={onAction}
            size={ButtonSize.Medium}
            className={styles.actionButton}
          >
            Karte anzeigen
          </Button>
        </div>
        {scrollButtonTransition(
          (style, showButton) =>
            showButton && (
              <animated.div
                className={styles.scrollButtonWrapper}
                style={style}
              >
                <IconButton
                  size={IconButtonSize.Small}
                  inverse
                  onClick={handleClickScroll}
                  className={styles.scrollButton}
                  variant="action/chevron-down"
                  aria-label="Nach unten scrollen"
                />
              </animated.div>
            )
        )}
      </section>
    </div>
  );
};

export default MapConsentLayer;
