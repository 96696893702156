import clsx from 'clsx';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import styles from './ImageInfoModalContent.module.scss';
import type { ImageInfoModalContentProps } from './interfaces';

const ImageInfoModalContent = ({
  title,
  description,
  imageNumber,
  publisher,
  rights,
  image,
}: ImageInfoModalContentProps) => {
  const shouldShowImageInfo =
    imageNumber || description || publisher || rights || title;

  return (
    <div className={styles.base}>
      <div className={styles.imageStage}>
        <LegacyPicture
          className={styles.backgroundImage}
          asset={image}
          fitCover
        />
        <LegacyPicture className={styles.image} asset={image} />
      </div>

      <a href={image.path} download className={styles.buttonDesktopWrapper}>
        <IconButton
          variant={'action/download'}
          className={clsx(styles.buttonDesktop, {
            [styles.buttonDesktopInImage]: !shouldShowImageInfo,
          })}
          aria-label="Herunterladen"
        />
      </a>

      {shouldShowImageInfo && (
        <div className={styles.imageInfo}>
          <span className={styles.title}>{title}</span>

          {description && <div className={styles.text}>{description}</div>}

          <div className={styles.legal}>
            {imageNumber && <div>Bild-Nr: {imageNumber}</div>}
            {publisher && <div>Copyright: {publisher}</div>}
            {rights && <div>Rechte: {rights}</div>}
          </div>
        </div>
      )}

      <a className={styles.buttonMobile} href={image.path} download>
        <Icon
          className={styles.buttonIcon}
          variant={'action/download'}
          size="iconSize24"
        />
        Bild herunterladen
      </a>
    </div>
  );
};

export default ImageInfoModalContent;
