import React from 'react';
import {
  Carousel,
  CarouselButtonLeft,
  CarouselButtonRight,
  CarouselIndicators,
  CarouselItem,
  CarouselItems,
} from '../Carousel/Carousel';
import Quote from '../Quote/Quote';
import type { QuoteCarouselProps } from './QuoteCarousel.interfaces';
import styles from './QuoteCarousel.module.scss';

const QuoteCarousel = ({ quotes }: QuoteCarouselProps) => {
  if (quotes.length === 0) {
    return <div />;
  }

  const carouselItems = (
    <CarouselItems className={styles.items}>
      {quotes.map((quote, index) => (
        <CarouselItem
          key={index}
          index={index}
          aria-roledescription="Zitat"
          className={styles.item}
        >
          <Quote
            className={styles.quote}
            hasAvatarBorder={false}
            hasAvatarShadow={false}
            classNameAvatar={styles.avatar}
            classNameAvatarWrapper={styles.avatarWrapper}
            classNameCite={styles.cite}
            classNameQuote={styles.text}
            image={quote.image}
            title={quote.title}
            author={quote.author}
            quote={quote.quote}
          />
        </CarouselItem>
      ))}
    </CarouselItems>
  );

  return (
    <Carousel aria-label="Zitate">
      <div className={styles.base}>
        <CarouselButtonLeft
          accessible={false}
          className={styles.carouselButtonLeft}
        />
        {carouselItems}
        <CarouselButtonRight
          accessible={false}
          className={styles.carouselButtonRight}
        />
      </div>
      <div className={styles.carouselIndicators}>
        <CarouselIndicators />
      </div>
    </Carousel>
  );
};

export default QuoteCarousel;
