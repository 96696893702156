import clsx from 'clsx';
import { useId } from 'react';
import styles from './ToggleIcon.module.scss';
import type { ToggleIconProps } from './interfaces';

const ToggleIcon = ({
  checked,
  defaultChecked,
  disabled,
  onChange,
  onClick,
  id,
  className,
  name,
}: ToggleIconProps) => {
  const defaultID = useId();
  const htmlID = id ?? defaultID;

  return (
    <div className={clsx(styles.base, className)}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        tabIndex={0}
        className={styles.input}
        id={htmlID}
      />
      <label htmlFor={htmlID} className={styles.slider} />
    </div>
  );
};

export default ToggleIcon;
