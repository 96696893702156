import React, { useContext } from 'react';
import FormField from '@uikit/components/FormField/FormField';
import FormRow from '@uikit/components/FormRow/FormRow';
import type { AddressProps } from './interfaces';
import { FormBuilderContext } from '../../FormBuilder';
import { FormFieldElement } from '@uikit/components/FormField/interfaces';

const Address = ({
  isOptional,
  fieldName,
  useForMailTemplate,
}: AddressProps) => {
  const {
    handleChange,
    handleBlur,
    formState,
    getErrorByFieldName,
    setFieldValue,
  } = useContext(FormBuilderContext);

  const handleChangeWrapper = (event: React.ChangeEvent<FormFieldElement>) => {
    handleChange(event);

    if (useForMailTemplate) {
      if (event.target.name.endsWith('Straße')) {
        setFieldValue('street', event.target.value);
      }

      if (event.target.name.endsWith('Hausnummer')) {
        setFieldValue('streetNumber', event.target.value);
      }

      if (event.target.name.endsWith('PLZ')) {
        setFieldValue('zipcode', event.target.value);
      }

      if (event.target.name.endsWith('Ort')) {
        setFieldValue('city', event.target.value);
      }
    }
  };

  return (
    <>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChangeWrapper}
          name={`${fieldName} - Straße`}
          value={formState[`${fieldName} - Straße`]}
          errorMessage={getErrorByFieldName(`${fieldName} - Straße`)}
          label={isOptional ? 'Straße (optional)' : 'Straße'}
          autoComplete="address-line1"
          id={`${fieldName}-street`}
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChangeWrapper}
          name={`${fieldName} - Hausnummer`}
          value={formState[`${fieldName} - Hausnummer`]}
          errorMessage={getErrorByFieldName(`${fieldName} - Hausnummer`)}
          label={isOptional ? 'Hausnummer (optional)' : 'Hausnummer'}
          autoComplete="address-line2"
          halfWidth
          id={`${fieldName}-street-number`}
        />
      </FormRow>
      <FormRow>
        <FormField
          maxLength={5}
          onBlur={handleBlur}
          onChange={handleChangeWrapper}
          name={`${fieldName} - PLZ`}
          value={formState[`${fieldName} - PLZ`]}
          errorMessage={getErrorByFieldName(`${fieldName} - PLZ`)}
          label={isOptional ? 'Postleitzahl (optional)' : 'Postleitzahl'}
          autoComplete="postal-code"
          halfWidth
          id={`${fieldName}-zipcode`}
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChangeWrapper}
          name={`${fieldName} - Ort`}
          value={formState[`${fieldName} - Ort`]}
          errorMessage={getErrorByFieldName(`${fieldName} - Ort`)}
          label={isOptional ? 'Ort (optional)' : 'Ort'}
          autoComplete="address-level2"
          id={`${fieldName}-city`}
        />
      </FormRow>
    </>
  );
};

export default Address;
