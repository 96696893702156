/* eslint-disable jsx-a11y/anchor-is-valid */
import useCountdownTimer from '@/hooks/useCountdownTimer/useCountdownTimer';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineAlign } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import RichText from '@uikit/components/RichText/RichText';
import React from 'react';
import useForm from '../../../../hooks/useForm/useForm';
import {
  AppointmentReminderSMSStep2State,
  AppointmentReminderSMSStep,
} from '../interfaces';
import schema from './AppointmentReminderSMSStep2.schema';

export const initialState: AppointmentReminderSMSStep2State = {
  pin: '',
};

const AppointmentReminderSMSFormStep2 = ({
  onSubmit,
  submissionStatePIN = ButtonSubmitState.Initial,
  requestNewPin,
}: AppointmentReminderSMSStep) => {
  const {
    getErrorByFieldName,
    handleChange,
    handleSubmit,
    isFormValid,
    formState,
    handleBlur,
  } = useForm(onSubmit, schema, initialState);

  const [timerTime, { restart, isRunning }] = useCountdownTimer(120, {
    autostart: true,
  });

  const handleNewPin = () => {
    if (!isRunning && requestNewPin) {
      requestNewPin();
      restart();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <Headline
          subText="Nach Absenden der PIN aus Ihrer SMS sind Sie für den Handyservice der Gasumstellung angemeldet."
          tag="h1"
          level={3}
          align={HeadlineAlign.Left}
        >
          Mit dem Handy sicher erreichbar!
        </Headline>
      </FormRow>

      <FormRow>
        <FormField
          name={'pin'}
          value={formState['pin']}
          label={'4-stelliger-PIN'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('pin')}
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
        />
      </FormRow>
      <FormRow>
        <RichText size="small" type="typeFootnote">
          {isRunning ? (
            <>
              Bitte haben Sie etwas Geduld. Es kann bis zu 2 Minuten dauern, bis
              Sie die SMS mit dem PIN erhalten. Sollten Sie die SMS dennoch
              nicht erhalten, können Sie in {timerTime} Minuten eine neue PIN
              per SMS anfordern.
            </>
          ) : (
            <a href="#" onClick={handleNewPin}>
              PIN erneut anfordern
            </a>
          )}
        </RichText>
      </FormRow>
      <FormRow fullWidthChildren={false} bottomMarginSize={{ default: 1.125 }}>
        <ButtonSubmit
          state={submissionStatePIN}
          inactive={!isFormValid}
          stateMessages={{
            pending: 'Überprüfe PIN',
            success: 'PIN erfolgreich geprüft!',
            fail: 'Es ist ein Fehler aufgetreten',
          }}
        >
          Absenden
        </ButtonSubmit>
      </FormRow>
    </form>
  );
};

export default AppointmentReminderSMSFormStep2;
