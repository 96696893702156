import React from 'react';
import {
  InfoBoxProps,
  InfoBoxColor,
  InfoBoxButtonStyle,
  InfoBoxStyle,
} from './interfaces';
import styles from './InfoBox.module.scss';
import clsx from 'clsx';
import RichText from '@uikit/components/RichText/RichText';
import ButtonLink from '@uikit/components/ButtonLink/ButtonLink';
import Button from '@uikit/components/Button/Button';
import { ButtonColor, ShowIcon } from '../Button/consts';

const InfoBox = ({
  color = InfoBoxColor.Simple,
  headline,
  text,
  buttonLabel,
  buttonStyle = InfoBoxButtonStyle.Button,
  boxStyle = InfoBoxStyle.Centered,
  ...props
}: InfoBoxProps) => {
  const hasButtonLinkStyle =
    buttonLabel && buttonStyle === InfoBoxButtonStyle.Link;
  const hasButtonButtonStyle =
    buttonLabel && buttonStyle !== InfoBoxButtonStyle.Link;

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.base, {
          [styles.simple]: color === InfoBoxColor.Simple,
          [styles.highlighted]: color === InfoBoxColor.Highlighted,
          [styles.sideBySide]: boxStyle === InfoBoxStyle.SideBySide,
        })}
      >
        {headline && <h3 className={styles.headline}>{headline}</h3>}
        <div className={styles.group}>
          <RichText className={styles.text}>{text}</RichText>
          {hasButtonLinkStyle && (
            <ButtonLink className={styles.buttonLink} {...props}>
              {buttonLabel}
            </ButtonLink>
          )}
          {hasButtonButtonStyle && (
            <Button
              {...props}
              className={styles.button}
              color={
                color === InfoBoxColor.Simple
                  ? ButtonColor.Primary
                  : ButtonColor.Secondary
              }
              icon={'action/arrow-right'}
              showIcon={ShowIcon.ShowIcon}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
