import * as Yup from 'yup';

const schema = Yup.object({
  publicStation: Yup.string().required(
    'Bitte geben Sie an, wo die Ladesäule platziert wird.'
  ),
  buildingType: Yup.string().required('Bitte geben Sie einen Gebäudetyp ein.'),
  location: Yup.string().required('Bitte geben Sie den Ladeort an.'),
  usageType: Yup.string().required('Bitte wählen Sie eine Nutzungsvariante.'),
});

export default schema;
