import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Clickable from '../Clickable/Clickable';
import { ClickableAction, ClickableTarget } from '../Clickable/consts';
import GoogleMap from '../GoogleMap/GoogleMap';
import Icon from '../Icon/Icon';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import MapMarker from '../MapMarker/MapMarker';
import styles from './CommunityHeatPlannerMap.module.scss';
import { communityHeatPlannerPlaces } from './config';
import { calculateCenter } from './helpers';
import type {
  CommunityHeatPlannerMapProps,
  CommunityHeatPlannerPlace,
} from './types';

const defaultMapCenter = calculateCenter(communityHeatPlannerPlaces);

const CommunityHeatPlannerMap = ({}: CommunityHeatPlannerMapProps) => {
  const mapRef = useRef(null);
  const [activePlace, setActivePlace] =
    useState<CommunityHeatPlannerPlace | null>(null);

  const handleOnClickMapMarker =
    (place: CommunityHeatPlannerPlace) =>
    ($event: React.MouseEvent<HTMLElement>) => {
      $event.preventDefault();
      $event.stopPropagation();

      setActivePlace((currentPlace) => {
        if (!currentPlace || currentPlace.title !== place.title) {
          return place;
        }

        return null;
      });
    };

  const handleOnCloseMapMarker = () => {
    setActivePlace(null);
  };

  return (
    <div className={styles.base}>
      <div className={styles.map} ref={mapRef} onClick={handleOnCloseMapMarker}>
        <GoogleMap
          fullHeight
          bootstrapURLKeys={{
            language: 'ge',
            libraries: ['places', 'geometry'],
          }}
          defaultCenter={defaultMapCenter}
          defaultZoom={8}
          draggable
          options={{
            scrollwheel: false,
            fullscreenControl: true,
            gestureHandling: 'greedy',
            styles: [
              {
                featureType: 'poi',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                elementType: 'labels.icon',
                featureType: 'transit',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ],
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          {communityHeatPlannerPlaces.map((place) => {
            const isCurrentMarker =
              !!activePlace && place.title === activePlace.title;
            return (
              <MapMarker
                key={place.lat + place.lng}
                lat={place.lat}
                lng={place.lng}
                color="gradient"
                onClick={handleOnClickMapMarker(place)}
                isCurrentMarker={isCurrentMarker}
                icon={isCurrentMarker ? 'user/heat' : undefined}
              />
            );
          })}
        </GoogleMap>

        {activePlace && (
          <div
            className={clsx(styles.detailsBoxWrapper, {
              [styles.active]: !!activePlace,
            })}
          >
            <div className={styles.detailsBox} key={activePlace.title}>
              {activePlace.image && (
                <LegacyPicture
                  breakpointAspectRatioConfigs={[
                    {
                      breakpoint: BreakPoint.XS,
                      width: 360,
                    },
                  ]}
                  defaultAspectRatio="360:200"
                  className={styles.image}
                  src={activePlace.image}
                  alt={activePlace.title}
                />
              )}
              <div className={styles.textWrapper}>
                <h4 className={styles.title}>{activePlace.title}</h4>
                <p>{activePlace.dateLabel}</p>
              </div>
              {activePlace.link && (
                <Clickable
                  actionType={ClickableAction.Linkextern}
                  linkextern={activePlace.link}
                  className={styles.link}
                  target={ClickableTarget.Blank}
                  nofollow
                >
                  <Icon variant="action/external-link" size="iconSize24" /> Zur
                  Wärmeplanung
                </Clickable>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityHeatPlannerMap;
