import clsx from 'clsx';
import React from 'react';
import JobToggleFavorite from '../JobToggleFavorite/JobToggleFavorite';
import Link from '../Link/Link';
import styles from './JobListItem.module.scss';

const JobListItem = ({
  isFavorite,
  title,
  mainCategory,
  city,
  link,
  toggleFavorite,
  hasPadding,
}: {
  readonly title: string;
  readonly mainCategory: string;
  readonly city: string;
  readonly isFavorite: boolean;
  readonly toggleFavorite: () => void;
  readonly link: string;
  readonly hasPadding: boolean;
}) => {
  return (
    <li
      data-favorite={isFavorite}
      className={clsx(styles.item, { [styles.hasPadding]: hasPadding })}
    >
      <div className={styles.left}>
        <h3 className={styles.itemHeading}>
          <Link to={link}>{title}</Link>
        </h3>
        <span className={styles.categories}>
          <span className={styles.category}>{mainCategory}</span>
          <span className={styles.category}>{city}</span>
        </span>
      </div>
      <div className={styles.right}>
        <JobToggleFavorite
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
        />
      </div>
    </li>
  );
};

export default JobListItem;
