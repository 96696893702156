import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useIsMounted from '@/hooks/useIsMounted/useIsMounted';
import clsx from 'clsx';

import React from 'react';
import Icon from '../Icon/Icon';
import NotificationIcon from '../NotificationIcon/NotificationIcon';
import { NavItemLinkOptions } from './consts';
import {
  NavItemAnchorProps,
  NavItemExternProps,
  NavItemInternProps,
  NavItemProps,
} from './interfaces';
import styles from './NavItem.module.scss';

const isNavItemExtern = (
  props: Partial<NavItemProps>
): props is NavItemExternProps => {
  return props.pagelink === NavItemLinkOptions.PageExtern;
};

const isNavItemIntern = (
  props: Partial<NavItemProps>
): props is NavItemInternProps => {
  return props.pagelink === NavItemLinkOptions.PageIntern;
};

const isNavItemAnchor = (
  props: Partial<NavItemProps>
): props is NavItemAnchorProps => {
  return props.pagelink === NavItemLinkOptions.PageAnchor;
};

const NavItem = ({
  title,
  asButton,
  isHighlighted,
  className,
  onClick,
  icon,
  notificationCount,
  isMainAction,
  inverted,
  ...props
}: NavItemProps) => {
  const href =
    (isNavItemExtern(props) && props.url) ||
    (isNavItemIntern(props) && props.page) ||
    (isNavItemAnchor(props) && props.anchor) ||
    '';

  const isMobile = useIsMobile(BreakPoint.MD);

  const isRenderOnlyOnClient = useIsMounted();

  return (
    <li
      className={clsx(styles.item, className, {
        [styles.current]: isHighlighted,
        [styles.asButton]: asButton,
        [styles.mainAction]: isMainAction,
        [styles.inverted]: inverted,
      })}
    >
      <a
        className="cta_"
        href={
          isNavItemAnchor(props) && props.anchor ? `#${props.anchor}` : href
        }
        onClick={onClick}
      >
        {icon &&
          isRenderOnlyOnClient &&
          (!notificationCount ? (
            <Icon
              variant={icon}
              size={isMobile ? 'iconSize24' : 'iconSize20'}
              isOutlined
            />
          ) : (
            <NotificationIcon
              notificationCount={notificationCount}
              variant={icon}
              size="iconSize24"
              isOutlined
            />
          ))}

        {
          <div className={clsx({ [styles.mainActionText]: isMainAction })}>
            {/* set replace for &amp; to & sign */}
            {(title ?? '').replace(/&amp;/g, '&')}
          </div>
        }
      </a>
    </li>
  );
};

export default NavItem;
