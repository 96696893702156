import { TableData } from './types';

const checkForAdditionalHeadline = (tableData: TableData): boolean => {
  return (
    tableData.body.some((row) =>
      typeof row[0] === 'string'
        ? row[0].includes('#titel')
        : row[0].content.includes('#titel')
    ) ||
    (!!tableData.head && tableData.head[0]?.includes('#titel'))
  );
};

export default checkForAdditionalHeadline;
