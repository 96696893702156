import { logger } from '@/helpers/logger';
import submitContactForm from '@/helpers/submitContactForm';
import { UseFormState } from '@/hooks/useForm/interfaces';
import useFrcCaptcha from '@/hooks/useFrcCaptcha/useFrcCaptcha';
import useScrollToRef from '@/hooks/useScrollToRef/useScrollToRef';
import { animated, useTransition } from '@react-spring/web';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import React from 'react';
import RegisterChargingStationStep1 from './Steps/RegisterChargingStationStep1';
import RegisterChargingStationStep2 from './Steps/RegisterChargingStationStep2';
import RegisterChargingStationStep3 from './Steps/RegisterChargingStationStep3';
import RegisterChargingStationStep4 from './Steps/RegisterChargingStationStep4';
import RegisterChargingStationStep5 from './Steps/RegisterChargingStationStep5';
import RegisterChargingStationStep6 from './Steps/RegisterChargingStationStep6';
import RegisterChargingStationStep7 from './Steps/RegisterChargingStationStep7';
import {
  createRegisterChargingKeysOrder,
  createRegisterChargingStationSubmitFormState,
} from './helpers';
import { RegisterChargingStationProps } from './interfaces';

export const RegisterChargingStationSteps = [
  RegisterChargingStationStep1,
  RegisterChargingStationStep2,
  RegisterChargingStationStep3,
  RegisterChargingStationStep4,
  RegisterChargingStationStep5,
  RegisterChargingStationStep6,
  RegisterChargingStationStep7,
];

const RegisterChargingStationForm = ({
  onSuccess,
  buttonLabel = 'Bestellung abschicken',
  usageVariantsFile,
  ...options
}: RegisterChargingStationProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [formState, setFormState] = React.useState<UseFormState>({});
  const [submissionState, setSubmissionState] = React.useState(
    ButtonSubmitState.Initial
  );
  const scrollTo = useScrollToRef(ref);
  const { getSolution, FrcCaptchaComponent } = useFrcCaptcha();

  const callbackOnSubmit = React.useCallback(
    async (formState: UseFormState) => {
      const state = createRegisterChargingStationSubmitFormState(formState);
      setSubmissionState(ButtonSubmitState.Pending);

      try {
        const frcCaptchaSolution = await getSolution();
        const response = await submitContactForm(state, {
          ...options,
          keysOrder: createRegisterChargingKeysOrder(state),
          frcCaptchaSolution,
        });
        const json = await response.json();

        if (json.message === 'success') {
          setSubmissionState(ButtonSubmitState.Success);
          onSuccess && setTimeout(onSuccess, 1500);
        } else {
          throw Error(json);
        }
      } catch (error) {
        setSubmissionState(ButtonSubmitState.Fail);
        logger.error(error);
        setTimeout(() => {
          setSubmissionState(ButtonSubmitState.Initial);
        }, 2000);
      }
    },
    [getSolution, options, onSuccess]
  );

  const isLastStep = React.useMemo(
    () => RegisterChargingStationSteps.length - 1 === currentStepIndex,
    [currentStepIndex]
  );

  const handleOnSubmit = async (stepState: UseFormState) => {
    const newFormState = { ...formState, ...stepState };
    if (isLastStep) {
      await callbackOnSubmit(newFormState);
    } else {
      setCurrentStepIndex((prevState) => prevState + 1);
      setFormState(() => newFormState);
      scrollTo();
    }
  };

  const step = React.useMemo(
    () => RegisterChargingStationSteps[currentStepIndex],
    [currentStepIndex]
  );

  const transitions = useTransition(step, {
    from: {
      position: 'absolute',
      opacity: 0,
    },
    enter: { position: 'relative', opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });
  return (
    <div ref={ref}>
      {transitions(({ opacity, position }, Step) => (
        <animated.div
          style={{
            position: position as React.CSSProperties['position'] | any,
            opacity,
          }}
        >
          <Step
            onSubmit={handleOnSubmit}
            submissionState={submissionState}
            buttonLabel={buttonLabel}
            usageVariantsFile={usageVariantsFile}
          />
        </animated.div>
      ))}
      <FrcCaptchaComponent />
    </div>
  );
};

export default RegisterChargingStationForm;
