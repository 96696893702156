import React from 'react';
import useDateParts from '../../../hooks/useDateParts/useDateParts';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import FormFieldDropdown from '../FormFieldDropdown/FormFieldDropdown';
import styles from './FormFieldDateSelect.module.scss';
import type { FormFieldDateSelectProps } from './interfaces';

const FormFieldDateSelect = ({
  dateParts = ['year', 'month', 'day'],
  name,
  minDate = new Date(new Date().getFullYear() - 5, 0, 1),
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1),
  onChange,
  errorMessage,
  title,
}: FormFieldDateSelectProps) => {
  const { datePartsState, datePartsList, handleOnDataPartChange } =
    useDateParts({
      minDate,
      maxDate,
      onChangeDate: onChange,
      dateParts,
    });

  return (
    <div className={styles.base}>
      {title && <p className={styles.title}>{title}</p>}
      <div className={styles.fieldsWrapper}>
        {datePartsList.map(({ datePart, label, options }) => {
          const fieldName = `${name}-${label}`;

          return (
            <FormFieldDropdown
              key={datePart}
              label={label}
              name={fieldName}
              value={datePartsState[datePart]?.value}
              options={options}
              nativeOptions={options}
              onChange={handleOnDataPartChange(datePart)}
              errorMessage={errorMessage ? ' ' : ''}
            />
          );
        })}
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default FormFieldDateSelect;
