import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import { FormFieldType } from '@uikit/components/FormField/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import { FormFieldDropdownOption } from '@uikit/components/FormFieldDropdown/interfaces';
import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import React from 'react';
import { customerSalutationOptions, customerTitleOptions } from '../config';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep4,
} from '../interfaces';
import schema from './RegisterChargingStationStep4.schema';

const registerChargingStationInitalStateStep4: RegisterChargingStationStateStep4 =
  {
    customerCity: '',
    customerCompany: '',
    customerEmail: '',
    customerFirstName: '',
    customerMobile: '',
    customerPhone: '',
    customerSalutation: '',
    customerStreet: '',
    customerStreetNumber: '',
    customerSurname: '',
    customerTitle: '',
    customerZipCode: '',
  };

const RegisterChargingStationStep4 = ({
  onSubmit,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleChangeValue,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep4);

  const handleChangeOption =
    (fieldName: keyof typeof formState & string) =>
    (option: FormFieldDropdownOption | null) =>
      handleChangeValue(fieldName)(option?.value);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>
      <StepHeadline>Persönliche Daten</StepHeadline>
      <FormRow>
        <FormFieldRadioGroup
          label="Anrede"
          items={customerSalutationOptions}
          value={formState['customerSalutation'] || ''}
          name="customerSalutation"
          onChange={handleChange}
          errorMessage={getErrorByFieldName('customerSalutation')}
        />
      </FormRow>
      <FormRow>
        <FormFieldDropdown
          label={'Titel'}
          name={'customerTitle'}
          value={formState['customerTitle']}
          options={customerTitleOptions}
          nativeOptions={customerTitleOptions}
          onBlur={handleBlur}
          onChange={handleChangeOption('customerTitle')}
          errorMessage={getErrorByFieldName('customerTitle')}
          errorMessageHasRelativePosition
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Name'}
          name={'customerSurname'}
          type={FormFieldType.Text}
          value={formState['customerSurname']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerSurname')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Vorname'}
          name={'customerFirstName'}
          type={FormFieldType.Text}
          value={formState['customerFirstName']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerFirstName')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'PLZ'}
          name={'customerZipCode'}
          value={formState['customerZipCode']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerZipCode')}
          twoFifthsWidth
        />
        <FormField
          label={'Ort'}
          name={'customerCity'}
          value={formState['customerCity']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerCity')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Straße'}
          name={'customerStreet'}
          value={formState['customerStreet']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerStreet')}
        />
        <FormField
          label={'Hausnummer'}
          name={'customerStreetNumber'}
          value={formState['customerStreetNumber']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerStreetNumber')}
          twoFifthsWidth
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Telefonnummer'}
          name={'customerPhone'}
          type={FormFieldType.Text}
          value={formState['customerPhone']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerPhone')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Telefon mobil'}
          name={'customerMobile'}
          type={FormFieldType.Text}
          value={formState['customerMobile']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerMobile')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'E-Mail'}
          name={'customerEmail'}
          type={FormFieldType.Text}
          value={formState['customerEmail']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerEmail')}
        />
      </FormRow>
      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default RegisterChargingStationStep4;
