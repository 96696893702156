import { MutableRefObject } from 'react';

export default function scrollToSlide(
  ref: MutableRefObject<HTMLUListElement | null>,
  index: number
) {
  if (!ref.current) return;

  Array.from(ref.current.children)[index]?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'start',
  });
}
