import { ClickableAction } from '../Clickable/consts';
import TeaserImageCard from '../TeaserImageCard/TeaserImageCard';
import UmbrellaCampaignStoriesSection from '../UmbrellaCampaignStoriesSection/UmbrellaCampaignStoriesSection';
import { CARD_DATA, HEADLINE, SUBTITLE } from './consts';
import { UmbrellaCampaignStoriesSectionWrapperProps } from './interfaces';

const UmbrellaCampaignStoriesSectionWrapper = ({
  items,
}: UmbrellaCampaignStoriesSectionWrapperProps) => {
  const content = items || CARD_DATA;

  return (
    <UmbrellaCampaignStoriesSection headline={HEADLINE} subtitle={SUBTITLE}>
      {content.map((card) => (
        <TeaserImageCard
          actionType={ClickableAction.Linkintern}
          disableTransition
          image={card.image}
          imageAspectRatio={{
            initial: '11 / 10',
            sm: '3 / 2',
          }}
          key={card.text}
          linkintern={card.link}
          maxResponsiveSize="s"
        >
          <TeaserImageCard.Headline tag="p">
            {card.text}
          </TeaserImageCard.Headline>
        </TeaserImageCard>
      ))}
    </UmbrellaCampaignStoriesSection>
  );
};

export default UmbrellaCampaignStoriesSectionWrapper;
