export enum ContentAlign {
  Top = 'top',
  Bottom = 'bottom',
  Center = 'center',
}

export enum ContentJustified {
  Left = 'left',
  Right = 'right',
  None = 'none',
}

export enum MobileColumnOrder {
  LinkColumnFirst = 'linkColumnFirst',
  RightColumnFirst = 'rightColumnFirst',
}
