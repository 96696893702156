import clsx from 'clsx';
import React from 'react';
import type { IconVariants } from '@uikit/components/Icon/interfaces';
import Icon from '../Icon/Icon';
import styles from './IconButton.module.scss';
import type { IconButtonProps } from './interfaces';
import { IconButtonSize } from './consts';
import { ButtonType } from '../Button/consts';

const IconButton = ({
  variant,
  inverse,
  className,
  size = IconButtonSize.Medium,
  inactive = false,
  disabled = false,
  isOutlined,
  type = ButtonType.Button,
  ...additionalProps
}: IconButtonProps) => {
  return (
    <button
      className={clsx(
        styles.base,
        styles[size],
        { [styles.inverse]: inverse, [styles.inactive]: inactive },
        className
      )}
      disabled={disabled}
      {...additionalProps}
      type={type}
    >
      <Icon
        variant={variant as IconVariants}
        size="iconSize32"
        isOutlined={isOutlined}
      />
    </button>
  );
};

export default IconButton;
