import React from 'react';

const useAnimatedIcon = <Element extends HTMLElement>({
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
}: {
  onBlur?: React.FormEventHandler<Element>;
  onFocus?: React.FormEventHandler<Element>;
  onMouseEnter?: React.MouseEventHandler<Element>;
  onMouseLeave?: React.MouseEventHandler<Element>;
} = {}) => {
  const [isIconPlaying, setIsIconPlaying] = React.useState(false);

  const handlerProps = {
    onBlur: ($event: React.FocusEvent<Element>) => {
      onBlur?.($event);
      setIsIconPlaying(false);
    },
    onFocus: ($event: React.FocusEvent<Element>) => {
      onFocus?.($event);
      setIsIconPlaying(true);
    },
    onMouseLeave: ($event: React.MouseEvent<Element, MouseEvent>) => {
      onMouseLeave?.($event);
      setIsIconPlaying(false);
    },
    onMouseEnter: ($event: React.MouseEvent<Element, MouseEvent>) => {
      onMouseEnter?.($event);
      setIsIconPlaying(true);
    },
  };

  return {
    handlerProps,
    isIconPlaying,
    setIsIconPlaying,
  };
};

export default useAnimatedIcon;
