import listToOptions from '../../../helpers/listToOptions';
import { GvhFormRequestLabels } from './interfaces';

export const gvhFormKeysOrder = [
  'Anrede',
  'Titel',
  'firstName',
  'lastName',
  'Geburtsdatum',
  'email',
  'Telefonnummer',
  'enercity Kundennummer',
  'MobilCard-Nutzer',
  'MobilCard-Nutzer-Anrede',
  'MobilCard-Nutzer-Vorname',
  'MobilCard-Nutzer-Nachname',
  'MobilCard-Nutzer-Geburstdatum',
  'GVH Abonummer',
  'Beginn der Laufzeit',
  'Von Zone',
  'Bis Zone',
  'Klasse',
  'IBAN',
  'Kontoinhaber',
  'SEPA Lastschrift Zustimmung',
  'Einverständniserklärung',
  'Zustimmung zur Datenverarbeitung',
];

export const gvhFormRequestLabels: GvhFormRequestLabels = {
  designation: 'Anrede',
  title: 'Titel',
  firstName: 'firstName',
  lastName: 'lastName',
  birthDate: 'Geburtsdatum',
  email: 'email',
  phone: 'Telefonnummer',
  enercityUserId: 'enercity Kundennummer',
  mobilCardUserType: 'MobilCard-Nutzer',
  mobilCardUserDesignation: 'MobilCard-Nutzer-Anrede',
  mobilCardUserFirstName: 'MobilCard-Nutzer-Vorname',
  mobilCardUserLastName: 'MobilCard-Nutzer-Nachname',
  mobilCardUserBirthDate: 'MobilCard-Nutzer-Geburstdatum',
  mobilCardUserNr: 'GVH Abonummer',
  startDate: 'Beginn der Laufzeit',
  fromZone: 'Von Zone',
  toZone: 'Bis Zone',
  class: 'Klasse',
  iban: 'IBAN',
  accountOwner: 'Kontoinhaber',
  SEPALastschrift: 'SEPA Lastschrift Zustimmung',
  confirmation: 'Einverständniserklärung',
  agreement: 'Zustimmung zur Datenverarbeitung',
  cancellationPolicy: 'Widerrufsbelehrung',
  phoneConsulting: 'Beratung per Telefon',
  emailConsulting: 'Beratung per E-Mail',
};

export const zoneOptions = listToOptions(['A', 'B', 'C', 'D', 'E', 'F']);
export const titleOptions = listToOptions(['Kein Titel', 'Dr', 'Prof.']);
export const designationOptions = listToOptions(['Herr', 'Frau', 'Divers']);
