import Stack from '@uikit/components/Stack/Stack';
import ProductComparison from '@uikit/composites/ProductComparison/ProductComparison';
import type { ProductComparisonWrapperProps } from './types';

const ProductComparisonWrapper = (props: ProductComparisonWrapperProps) => {
  return (
    <Stack gap={2} flexDirection={'column'}>
      <ProductComparison {...props} />
    </Stack>
  );
};

export default ProductComparisonWrapper;
