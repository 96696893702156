import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import { useQueryParams } from '@/hooks/useQueryParams/useQueryParams';
import { useSolarActiveOperators } from '@/hooks/useSolarActiveOperators/useSolarActiveOperators';
import Container from '@uikit/components/Container/Container';
import Section from '@uikit/components/Section/Section';
import { SectionBackgroundColor } from '@uikit/components/Section/consts';
import SolarVideoChatTeaser from '@uikit/components/SolarVideoChatTeaser/SolarVideoChatTeaser';
import type { SolarVideoChatTeaserSectionProps } from './types';

const SolarVideoChatTeaserSection = ({
  bgColor,
  forceVisible,
  ...props
}: SolarVideoChatTeaserSectionProps) => {
  const isMobile = useIsMobile(1290);
  const { params } = useQueryParams();
  const { data: activeOperators } = useSolarActiveOperators();

  if (!forceVisible && (!isMobile || params.solarvideochat === 'false')) {
    return null;
  }

  return (
    <Section {...props} bgColor={SectionBackgroundColor.White}>
      <Container>
        <SolarVideoChatTeaser isOperatorActive={!!activeOperators} />
      </Container>
    </Section>
  );
};

export default SolarVideoChatTeaserSection;
