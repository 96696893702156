import { useCallback, useEffect } from 'react';
import { useQueryParams } from '../useQueryParams/useQueryParams';

const MIN_PAGE = 1;

export const usePaginationSearchParamsState = (
  initialPage: number,
  maxPage: number
) => {
  const { params, set: setParams } = useQueryParams();
  const paramPage = Number(params.page);
  const currentPage = paramPage || initialPage;

  const calculateNewPage = useCallback(
    (page: number) => Math.min(Math.max(page, MIN_PAGE), maxPage),
    [maxPage]
  );

  const setCurrentPage = useCallback(
    (page: number) => {
      const newValue = calculateNewPage(page);

      if (newValue !== MIN_PAGE && currentPage === newValue) {
        return;
      }

      setParams({
        page: newValue > MIN_PAGE ? newValue.toString() : undefined,
      });
    },
    [calculateNewPage, currentPage, setParams]
  );

  useEffect(() => {
    if (typeof params.page === 'undefined') {
      return;
    }

    setCurrentPage(Number(params.page));
  }, [setCurrentPage, params.page, initialPage, maxPage]);

  return [currentPage, setCurrentPage] as const;
};
