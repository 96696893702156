import clsx from 'clsx';
import React from 'react';
import BadgeRectangular from '../BadgeRectangular/BadgeRectangular';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { FormBoxSize } from '../FormBox/consts';
import styles from './FormBox.module.scss';
import type { FormBoxProps } from './interfaces';

const FormBox = ({
  children,
  label,
  errorMessage,
  hasErrorStyles,
  className,
  badgeLabel,
  checked,
  size = FormBoxSize.Default,
  style,
}: FormBoxProps) => {
  return (
    <div
      className={clsx(className, styles.wrapper, {
        [styles.checked]: !!checked,
        [styles.error]: !!errorMessage || hasErrorStyles,
      })}
      style={style}
    >
      {badgeLabel && <span className={styles.badgeMobile}>{badgeLabel}</span>}
      <label className={clsx(styles.base, { [styles.hasBadge]: !!badgeLabel })}>
        {children}

        <span
          className={clsx(styles.label, {
            [styles.small]: size === FormBoxSize.Small,
          })}
        >
          {label}
        </span>
        {badgeLabel && (
          <BadgeRectangular
            text={badgeLabel}
            additionalClassName={styles.badge}
            tag="span"
          />
        )}
      </label>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default FormBox;
