import React from 'react';
import type { ProductTeaserImageProps } from './interfaces';
import styles from './ProductTeaserImage.module.scss';
import clsx from 'clsx';

const ProductTeaserImage = ({
  description,
  path,
  isMobileCover,
}: ProductTeaserImageProps) => {
  return (
    <img
      className={clsx(styles.base, { [styles.isCover]: isMobileCover })}
      src={path}
      alt={description}
    />
  );
};

export default ProductTeaserImage;
