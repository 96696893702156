import nextId from '@uikit/helpers/nextId';
import { useState, useRef, useEffect } from 'react';

const useCarousel = ({ itemsPerPage }: { itemsPerPage: number }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [canGoNext, setCanGoNext] = useState(true);
  const [numberOfItems, setNumberOfItems] = useState(0);
  const canGoPrev = activeIndex > 0;
  const carouselItemsRef = useRef<HTMLDivElement>(null);
  const id = useRef(nextId()).current;

  useEffect(() => {
    const element = carouselItemsRef.current;
    if (!element) {
      return;
    }
    let timeout: number | undefined;
    const updateIndex = () => {
      const page = Math.ceil(
        element.scrollLeft / (element.clientWidth / itemsPerPage)
      );
      setActiveIndex(page);
      timeout = undefined;
    };
    const handleScroll = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      // @ts-ignore
      timeout = setTimeout(updateIndex, 115);
    };
    element.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [itemsPerPage]);

  useEffect(() => {
    if (!carouselItemsRef.current) {
      return;
    }
    setNumberOfItems(carouselItemsRef.current.children.length);
  }, [carouselItemsRef]);

  useEffect(() => {
    const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);
    setCanGoNext(activeIndex < numberOfPages - 1);
  }, [activeIndex, numberOfItems, itemsPerPage]);

  const triggerScroll = (index: number) => {
    if (!carouselItemsRef.current) {
      return;
    }
    carouselItemsRef.current.scrollTo({
      left: index * carouselItemsRef.current.clientWidth,
      behavior: 'smooth',
    });
  };

  const next = () => {
    if (!canGoNext) {
      setActiveIndex(0);
      triggerScroll(0);
      return;
    }
    setActiveIndex(activeIndex + 1);
    triggerScroll(activeIndex + 1);
  };

  const prev = () => {
    if (!canGoPrev) {
      return;
    }
    setActiveIndex(activeIndex - 1);
    triggerScroll(activeIndex - 1);
  };

  const goTo = (index: number) => {
    setActiveIndex(index);
    triggerScroll(index);
  };
  return {
    canGoNext,
    canGoPrev,
    activeIndex,
    prev,
    next,
    carouselItemsRef,
    numberOfItems,
    id,
    goTo,
  };
};

export default useCarousel;
