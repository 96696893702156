import uncapitalize from '@/helpers/uncapitalized';
import useFiberOpticData from '@/hooks/useFiberOpticData/useFiberOpticData';
import FiberOpticMapLegend from '@uikit/components/FiberOpticMap/FiberOpticMapLegend/FiberOpticMapLegend';
import FiberOpticMapLegendItem from '@uikit/components/FiberOpticMap/FiberOpticMapLegend/FiberOpticMapLegendItem/FiberOpticMapLegendItem';
import FiberOpticMapDetails from '@uikit/components/FiberOpticMap/FilterOpticMapDetails/FilterOpticMapDetails';
import GoogleMap from '@uikit/components/GoogleMap/GoogleMap';
import SearchBox from '@uikit/composites/SearchBox/SearchBox';
import clsx from 'clsx';
import { Point } from 'points-cluster';
import React, { useEffect, useState } from 'react';
import MapMarker from '../MapMarker/MapMarker';
import styles from './FiberOpticMap.module.scss';
import {
  BOOTSTRAP_URL_KEYS,
  BOUNDS_GERMANY,
  CENTER_DEFAULT,
  INIT_MAP_STATE,
  LOCATION_LINKS,
  LOCATION_LINK_TITLES,
  LOCATION_TYPES,
  OPTIONS,
  POLYGON_STYLES,
  ZOOM_DEFAULT,
  statusToColor,
} from './consts';
import {
  FiberOpticMapProps,
  GeneralMapProps,
  GoogleMapState,
  GooglePolygonClickEvent,
  Location,
} from './types';

const FiberOpticMap = ({
  opticFiberMapData,
  fullScreen = false,
}: FiberOpticMapProps) => {
  const { data } = useFiberOpticData(opticFiberMapData.path);
  const [mapProps, setMapProps] = useState<GeneralMapProps | undefined>();
  const [googleMapState, setGoogleMapState] =
    useState<GoogleMapState>(INIT_MAP_STATE);
  const [selectedDetail, setSelectedDetail] = useState<null | Location>(null);
  const [point, setPoint] = useState<Point | null>(null);
  const { mapApiLoaded, mapInstance, mapApi } = googleMapState;
  const detailsRef = React.useRef<HTMLDivElement>(null);

  const apiHasLoaded = (mapData: any): void => {
    if (mapData && 'map' in mapData) {
      setGoogleMapState({
        mapApiLoaded: true,
        mapInstance: mapData.map,
        mapApi: mapData.maps,
      });
    }
  };

  const stylePolygons = React.useCallback(() => {
    mapInstance.data.setStyle((feature: any) => {
      const status = LOCATION_TYPES.find(
        (value) => value === feature.getProperty('Status')
      );
      const isActive = feature.getProperty('isActive');

      return {
        strokeWeight: '3',
        ...(isActive ? POLYGON_STYLES.Aktiv : status && POLYGON_STYLES[status]),
      };
    });
  }, [mapInstance]);

  const handleAddPlace = (place: GoogleMapPlace | null): void => {
    if (!place) {
      setPoint(null);
      setSelectedDetail(null);
      return;
    }

    if ('address_components' in place) {
      if (place.geometry) {
        if (place.geometry.viewport) {
          googleMapState.mapInstance.fitBounds(place.geometry.viewport);
        } else {
          googleMapState.mapInstance.setCenter(place.geometry.location);
          googleMapState.mapInstance.setZoom(15);
        }
      }
    }

    const { lat, lng } = place.geometry.location;
    setPoint({ lat: lat(), lng: lng() });
  };

  const handlePolygonClick = React.useCallback(
    ($event: GooglePolygonClickEvent) => {
      const feature = $event.feature;

      // Reset active polygon each time a child is clicked
      mapInstance.data.forEach((feature: google.maps.Data.Feature) => {
        feature.setProperty('isActive', false);
      });

      const detailFromProperties = (): Location | undefined => {
        const status = LOCATION_TYPES.find(
          (value) => value === feature.getProperty('Status')
        );

        const [city, zipCode, street, streetNumber] = [
          feature.getProperty('Ort'),
          feature.getProperty('Postleitzahl'),
          feature.getProperty('Straße'),
          feature.getProperty('Hausnummer'),
        ];

        if (
          !status ||
          typeof city !== 'string' ||
          typeof zipCode !== 'string' ||
          typeof street !== 'string' ||
          typeof streetNumber !== 'string'
        ) {
          return;
        }

        return {
          status,
          city,
          zipCode,
          street,
          streetNumber,
        };
      };

      const detail = detailFromProperties();

      if (detail === undefined) {
        return;
      }

      setSelectedDetail(detail);

      // Set event target to active
      $event.feature.setProperty('isActive', true);
    },
    [mapInstance]
  );

  useEffect(() => {
    if (!mapApiLoaded || !data) {
      return;
    }

    // Clear existing data layer
    mapInstance.data.forEach(function (feature: any) {
      mapInstance.data.remove(feature);
    });

    const parsedData = JSON.parse(JSON.stringify(data));
    const features = parsedData.features.filter(
      (feature: any) => Object.keys(feature.properties).length !== 0
    );
    parsedData.features = features;

    mapInstance.data.addGeoJson(parsedData);
    stylePolygons();
    mapInstance.data.addListener('click', ($event: GooglePolygonClickEvent) => {
      handlePolygonClick($event);
    });
  }, [mapApiLoaded, mapInstance, stylePolygons, handlePolygonClick, data]);

  useEffect(() => {
    if (!mapApiLoaded) {
      return;
    }
    stylePolygons();
  }, [mapApiLoaded, selectedDetail, stylePolygons]);

  return (
    <div className={styles.base}>
      {mapApiLoaded && (
        <div className={styles.searchBox}>
          <SearchBox
            map={mapInstance}
            mapApi={mapApi}
            bounds={BOUNDS_GERMANY}
            addPlace={handleAddPlace}
            placeholder="Nach Adresse suchen..."
          />
        </div>
      )}
      <div
        className={clsx(styles.mapContainer, {
          [styles.fullScreen]: fullScreen,
        })}
      >
        <GoogleMap
          defaultZoom={ZOOM_DEFAULT}
          zoom={mapProps?.zoom ?? ZOOM_DEFAULT}
          defaultCenter={CENTER_DEFAULT}
          center={mapProps?.center ?? CENTER_DEFAULT}
          bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
          onChange={setMapProps}
          onGoogleApiLoaded={apiHasLoaded}
          onChildClick={handlePolygonClick}
          onDrag={() => setSelectedDetail(null)}
          options={OPTIONS}
          fullHeight
          yesIWantToUseGoogleMapApiInternals
        >
          {point && (
            <MapMarker lat={point.lat} lng={point.lng} color="gradient" />
          )}
        </GoogleMap>
        <div className={styles.fiberOpticMapLegend}>
          <FiberOpticMapLegend>
            <FiberOpticMapLegendItem color="green">
              verfügbar
            </FiberOpticMapLegendItem>
            <FiberOpticMapLegendItem color="orange">
              vorbereitet
            </FiberOpticMapLegendItem>
            <FiberOpticMapLegendItem color="turquoise">
              geplant
            </FiberOpticMapLegendItem>
          </FiberOpticMapLegend>
        </div>
        {selectedDetail && (
          <div className={styles.fiberOpticMapDetails} ref={detailsRef}>
            <FiberOpticMapDetails
              statusText={`Glasfaser ${uncapitalize(selectedDetail.status)}`}
              showLink={
                selectedDetail.status === 'Vorbereitet' ||
                selectedDetail.status === 'Verfügbar' ||
                selectedDetail.status === 'Geplant'
                  ? true
                  : false
              }
              address={
                <>
                  <div>
                    {selectedDetail.street} {selectedDetail.streetNumber},
                  </div>
                  <div>
                    {selectedDetail.zipCode} {selectedDetail.city}
                  </div>
                </>
              }
              color={statusToColor[selectedDetail.status]}
              link={LOCATION_LINKS[selectedDetail.status]}
              linkText={LOCATION_LINK_TITLES[selectedDetail.status]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FiberOpticMap;
