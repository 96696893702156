import { ProductTeaserConfigs } from './interfaces';

export const productTeaserConfig: ProductTeaserConfigs = {
  power: {
    imagePaths: {
      simple: 'enercity-stecker_verlauf.svg',
      highlighted: 'enercity-stecker_weiss.svg',
    },
    imagePathsMobile: {
      simple: 'enercity-stecker_verlauf_mobile.svg',
      highlighted: 'enercity-stecker_weiss_mobile.svg',
    },
    imageDescription: 'Produktbild Strom',
    headline: 'Flexible Strom Tarife für Deutschland',
    highlightColor: 'gradient',
    hasTariffBox: true,
  },
  solar: {
    imagePaths: {
      simple: 'enercity-solar_panel.svg',
    },
    imageDescription: 'Produktbild Solaranlage',
    headline: 'Jetzt konfigurieren und Zuschuss sichern!',
    productText: `
    <ul>
      <li>unabhängiger Strom</li> <li>klimaneutrale Stromerzeugung</li>
      <li>kompatibel mit Ladestationen</li>
    </ul>`,
  },
  charging: {
    imagePaths: {
      simple: 'enercity-wallbe_stand_front.svg',
    },
    imageDescription: 'Produktbild Ladestation',
    headline: 'Jetzt konfigurieren und Zuschuss sichern!',
    productText: `
    <ul>
      <li>11kW oder 22 kW Ladeleistung</li>
      <li>100 % Ökostrom von enercity</li>
      <li>kompatibel mit PV-Anlagen</li>
    </ul>`,
  },
};
