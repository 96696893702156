import { AccentProps } from '../Accent/types';
import { LocationType } from '../WedemarkMap/types';

export const FILTER_CRITERIA: {
  label: string;
  value: LocationType;
  color: AccentProps['color'];
}[] = [
  {
    label: 'Bestehende Windparks',
    value: 'existing',
    color: 'turquoise',
  },
  {
    label: 'Geplante Projektgebiete',
    value: 'planned',
    color: 'violet',
  },
  {
    label: 'Wasserschutzzone 2',
    value: 'waterprotection',
    color: 'blue',
  },
  {
    label: 'Historischer Waldstandort',
    value: 'forest',
    color: 'green',
  },
  {
    label: 'Landschaftsschutzgebiet',
    value: 'landscapeprotection',
    color: 'green',
  },
  {
    label: 'Naturschutzgebiet',
    value: 'naturereserve',
    color: 'yellow',
  },
  {
    label: 'Abstand zur Wohnbebauung',
    value: 'residential',
    color: 'orange',
  },
];
