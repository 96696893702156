import React, { useContext } from 'react';
import FormField from '@uikit/components/FormField/FormField';
import FormRow from '@uikit/components/FormRow/FormRow';
import type { ContactProps } from './interfaces';
import { FormBuilderContext } from '../../FormBuilder';
import { ContactFieldVisibility } from './consts';
import { FormFieldElement } from '@uikit/components/FormField/interfaces';

const Contact = ({
  isMailOptional,
  isPhoneOptional,
  fieldVisibility = ContactFieldVisibility.Both,
  fieldName,
  shouldSendMailToThisAddress,
}: ContactProps) => {
  const {
    handleChange,
    handleBlur,
    formState,
    getErrorByFieldName,
    setFieldValue,
  } = useContext(FormBuilderContext);

  const handleMailChange = (event: React.ChangeEvent<FormFieldElement>) => {
    if (shouldSendMailToThisAddress) {
      setFieldValue('mail', event.target.value);
    }

    handleChange(event);
  };

  return (
    <FormRow>
      {fieldVisibility !== ContactFieldVisibility.PhoneOnly && (
        <FormField
          label={
            isMailOptional ? 'E-Mail-Adresse (optional)' : 'E-Mail-Adresse'
          }
          name={`${fieldName} - E-Mail`}
          onChange={handleMailChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName(`${fieldName} - E-Mail`)}
          value={formState[`${fieldName} - E-Mail`]}
          autoComplete="email"
        />
      )}
      {fieldVisibility !== ContactFieldVisibility.EmailOnly && (
        <FormField
          label={isPhoneOptional ? 'Telefonnummer (optional)' : 'Telefonnummer'}
          name={`${fieldName} - Telefon`}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName(`${fieldName} - Telefon`)}
          value={formState[`${fieldName} - Telefon`]}
          autoComplete="tel"
        />
      )}
    </FormRow>
  );
};

export default Contact;
