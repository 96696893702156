import styles from './DisturbanceMapIframe.module.scss';

const DisturbanceMapIframe = () => {
  const mapkitUrl = process.env.MAPKIT_URL || 'https://karten.enercity.de';

  return (
    <iframe
      className={styles.base}
      src={`${mapkitUrl}/stoerung/embed`}
      title="Störungskarte"
      allow="accelerometer; geolocation; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

export default DisturbanceMapIframe;
