import EnercityGwkFooter from '../EnercityGwkFooter/EnercityGwkFooter';
import EnercityFooter from './EnercityFooter';
import { EnercityFooterWrapperProps } from './types';

const EnercityFooterWrapper = ({
  variant,
  ...props
}: EnercityFooterWrapperProps) => {
  if (variant === 'gwk') {
    return <EnercityGwkFooter {...props} />;
  }

  return <EnercityFooter {...props} />;
};

export default EnercityFooterWrapper;
