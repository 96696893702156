import { useCallback, useEffect, useRef, useState } from 'react';
import { formatCountdownTime } from './formatCountdownTime';
import { UseCountdownTimerOptions } from './types';

const SECOND_IN_MILISECONDS = 1000;

/**
import React from 'react';
import CountdownTimer from '@/hooks/useCountdownTimer/useCountdownTimer';

export const Component = props => {
  const [
    counter,
    { toggle, run, stop, restart, reset, isRunning },
  ] = useCountdownTimer(60 * 60, { withNegativeValue: false });
  return (
    <div>
      <div>{counter}</div>
      <div>isRunning: {isRunning ? 'true' : 'false'}</div>
      <button onClick={() => toggle()}>toggle</button>{' '}
      <button onClick={() => run()}>run</button>{' '}
      <button onClick={() => stop()}>stop</button>{' '}
      <button onClick={() => restart()}>restart</button>{' '}
      <button onClick={() => reset()}>reset</button>{' '}
    </div>
  );
};
 */

const useCountdownTimer = (
  initialValue: number,
  {
    autostart = false,
    withNegativeValue = false,
  }: UseCountdownTimerOptions = {}
) => {
  const [counter, setCounter] = useState(initialValue);
  const [isRunning, setIsRunning] = useState(autostart);
  const intervalRef = useRef<number | undefined>();

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = window.setInterval(() => {
        setCounter((counter) => {
          return counter - 1;
        });
      }, SECOND_IN_MILISECONDS);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [isRunning, withNegativeValue]);

  useEffect(() => {
    if (!withNegativeValue && counter <= 0) {
      window.clearInterval(intervalRef.current);
      setIsRunning(false);
    }
  }, [counter, withNegativeValue]);

  const controls = {
    run: () => setIsRunning(true),
    stop: () => setIsRunning(false),
    toggle: () => setIsRunning((value) => !value),
    reset: () => setCounter(initialValue),
    restart: () => {
      setCounter(initialValue);
      setIsRunning(true);
    },
    isRunning,
  };

  const formatter = useCallback(
    (value: number) => formatCountdownTime(initialValue)(value),
    [initialValue]
  );

  return [formatter(counter), controls] as const;
};

export default useCountdownTimer;
