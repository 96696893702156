import type { IconVariants } from '@uikit/components/Icon/interfaces';

export interface UnorderedListProps {
  children: any;
  className?: string;
  padding: boolean;
}

export interface UnorderedListItemProps {
  icon: IconVariants;
  children: any;
  inactive?: boolean;
  fontSize?: FontSizeOptions;
  className?: string;
}

export enum FontSizeOptions {
  Regular = 'regular',
}
