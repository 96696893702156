import config from '@/config';
import { logger } from '@/helpers/logger';
import useCmpConsent from '@/hooks/useCmpConsent/useCmpConsent';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';
import MapConsentLayer from '../MapConsentLayer/MapConsentLayer';
import Skeleton from '../Skeleton/Skeleton';
import styles from './GoogleMap.module.scss';
import type { GoogleMapProps } from './interfaces';

const googleKey = config.googleKey;

const GoogleMap = ({
  children,
  bootstrapURLKeys,
  ...props
}: GoogleMapProps) => {
  const [consent, setConsent] = useCmpConsent('s1104');

  if (!googleKey) {
    logger.error('Missing GOOGLE_API_KEY !!!');
    return null;
  }

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.map, {
          [styles.fullheight]: props.fullHeight,
          [styles.withBorderRadius]: props.withBorderRadius,
          [styles.relativePosition]: props.relativePosition,
        })}
      >
        {consent === true && (
          <GoogleMapReact
            {...props}
            bootstrapURLKeys={{ ...bootstrapURLKeys, key: googleKey }}
          >
            {children}
          </GoogleMapReact>
        )}
        {consent === false && (
          <MapConsentLayer onAction={() => setConsent(true)} />
        )}
        {consent === undefined && <Skeleton width="100%" height="100%" />}
      </div>
    </div>
  );
};

export default GoogleMap;
