import React from 'react';
import styles from './DistrictHeatMapDetails.module.scss';
import type { DistrictHeatMapDetailsProps } from './interfaces';

const DistrictHeatMapDetails = ({
  address,
}: DistrictHeatMapDetailsProps) => {
  const { available, zipCode, city, street, streetNumber } = address;
  return (
    <div className={styles.base}>
      <div className={styles.status}>
        Gebäude {!available && 'nicht'} im Satzungsgebiet
      </div>
      <address className={styles.address}>
        <span>
          {street} {streetNumber}
        </span>
        <span>
          {zipCode} {city}
        </span>
      </address>
    </div>
  );
};

export default DistrictHeatMapDetails;
