import React from 'react';
import Icon from '../Icon/Icon';
import type { MapExpandToggleProps } from './interfaces';
import styles from './MapExpandToggle.module.scss';
import clsx from 'clsx';

const MapExpandToggle = ({
  expanded,
  handleClick,
  className,
  small = false,
}: MapExpandToggleProps) => {
  return (
    <div
      className={clsx(styles.base, className, { [styles.small]: small })}
      onClick={handleClick}
    >
      <Icon
        variant={expanded ? 'action/chevron-down' : 'action/chevron-up'}
        size="iconSize24"
      />
    </div>
  );
};

export default MapExpandToggle;
