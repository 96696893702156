import React from 'react';
import type { FiberOpticMapLegendProps } from './interfaces';
import styles from './FiberOpticMapLegend.module.scss';

const FiberOpticMapLegend = ({
  children,
}: FiberOpticMapLegendProps) => {
  return <div className={styles.base}>{children}</div>;
};

export default FiberOpticMapLegend;
