import { HoverProvider } from '@/contexts/Hover/Hover.context';
import clsx from 'clsx';
import AdaptiveCard from '../AdaptiveCard/AdaptiveCard';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import gasHeizung from '../HeatProductPageContent/animations/gas-heizung.json';
import hybridHeizung from '../HeatProductPageContent/animations/hybrid-heizung.json';
import HeatProductPageHeatPumpImage from '../HeatProductPageHeatPumpImage/HeatProductPageHeatPumpImage';
import ProductPageLottie from '../ProductPageLottie/ProductPageLottie';
import styles from './HeatProductTeasers.module.scss';
import type { HeatProductTeasersProps } from './types';

const HeatProductTeasers = ({ variant }: HeatProductTeasersProps) => {
  const blueCard = (
    <HoverProvider className={styles.hoverProvider}>
      <Clickable
        actionType={ClickableAction.Linkintern}
        linkintern="/fernwaerme"
      >
        <article className={clsx(styles.card, styles.isBlue)}>
          <div className={styles.content}>
            <h4 className={styles.subtitle}>Sauber. Sicher. Regional.</h4>
            <h3 className={styles.title}>Fernwärme</h3>

            <div className={styles.backgroundImage} style={{ width: '100%' }}>
              <div className={styles.districtBackground} />
            </div>
          </div>

          <AdaptiveCard.Arrow className={styles.arrow} />
        </article>
      </Clickable>
    </HoverProvider>
  );

  const orangeCard = (
    <HoverProvider className={styles.hoverProvider}>
      <Clickable
        actionType={ClickableAction.Linkintern}
        linkintern="/privatkunden/produkte/waerme/gasheizung"
      >
        <article className={clsx(styles.card, styles.isOrange)}>
          <h4 className={styles.subtitle}>
            Die besten, zukunftsorientierten Wärmelösungen
          </h4>
          <h3 className={styles.title}>Gasheizung Alternativen</h3>

          <ProductPageLottie
            animationData={gasHeizung}
            className={styles.icon}
            id="hybridHeizung"
            loopEnd={88}
            loopStart={0}
          />

          <AdaptiveCard.Arrow className={styles.arrow} />
        </article>
      </Clickable>
    </HoverProvider>
  );

  const solidBlueCard = (
    <HoverProvider className={styles.hoverProvider}>
      <Clickable
        actionType={ClickableAction.Linkintern}
        linkintern="/fernwaerme/fernwaerme-satzungsgebiet#karte"
      >
        <article className={clsx(styles.card, styles.isSolidBlue)}>
          <h4 className={styles.subtitle}>Finden Sie Anschluss</h4>
          <h3 className={styles.title}>Fernwärmenetz</h3>

          <AdaptiveCard.Arrow
            className={styles.arrow}
            color="#14459c"
            inverted
          />
        </article>
      </Clickable>
    </HoverProvider>
  );

  const turquoiseCard = (
    <HoverProvider className={styles.hoverProvider}>
      <Clickable
        actionType={ClickableAction.Linkintern}
        linkintern="/privatkunden/produkte/waerme/hybridheizung"
      >
        <article className={clsx(styles.card, styles.isTurquoise)}>
          <h4 className={styles.subtitle}>Der beste Wärme-Kompromiss</h4>
          <h3 className={styles.title}>Hybridheizung</h3>

          <ProductPageLottie
            animationData={hybridHeizung}
            className={styles.icon}
            id="hybridHeizung"
            loopEnd={88}
            loopStart={0}
          />

          <AdaptiveCard.Arrow className={styles.arrow} />
        </article>
      </Clickable>
    </HoverProvider>
  );

  const violetCard = (
    <HoverProvider className={styles.hoverProvider}>
      <Clickable
        actionType={ClickableAction.Linkintern}
        linkintern="/privatkunden/produkte/waerme/waermepumpe"
      >
        <article className={clsx(styles.card, styles.isViolet)}>
          <div className={styles.content}>
            <h4 className={styles.subtitle}>
              Zukunftssicher. Nachhaltig. Unabhängig.
            </h4>
            <h3 className={styles.title}>Wärmepumpe</h3>

            <div className={styles.backgroundImage}>
              <HeatProductPageHeatPumpImage />
            </div>
          </div>

          <AdaptiveCard.Arrow className={styles.arrow} />
        </article>
      </Clickable>
    </HoverProvider>
  );

  if (variant === 'decentral') {
    return (
      <div className={clsx(styles.base, styles.isDecentral)}>
        {violetCard}
        {turquoiseCard}
        {orangeCard}
      </div>
    );
  }

  return (
    <div className={clsx(styles.base, styles.isDistrict)}>
      {blueCard}
      {solidBlueCard}
    </div>
  );
};

export default HeatProductTeasers;
