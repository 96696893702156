import CheckBoxBlock from '@uikit/components/CheckBoxBlock/CheckBoxBlock';
import FormRow from '@uikit/components/FormRow/FormRow';
import React, { useContext, useEffect } from 'react';
import { FormBuilderContext } from '../../FormBuilder';
import type { CustomCheckBoxProps } from './interfaces';

const CustomCheckBox = ({
  isOptional,
  label,
  defaultValue,
}: CustomCheckBoxProps) => {
  const { handleChange, formState, getErrorByFieldName, setFieldValue } =
    useContext(FormBuilderContext);

  useEffect(() => {
    setFieldValue(label, defaultValue ?? false, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, label]);

  return (
    <FormRow>
      <CheckBoxBlock
        onChange={handleChange}
        errorMessage={getErrorByFieldName(label)}
        checked={formState[label]}
        name={label}
      >
        {`${label}${isOptional ? ` (optional)` : ''}`}
      </CheckBoxBlock>
    </FormRow>
  );
};

export default CustomCheckBox;
