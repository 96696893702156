import clsx from 'clsx';
import React from 'react';
import StrictBox from '../StrictBox/StrictBox';
import styles from './ImageComposition.module.scss';

const ImageComposition = ({
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  showInfo = false,
}: {
  item1: React.ReactNode;
  item2: React.ReactNode;
  item3: React.ReactNode;
  item4: React.ReactNode;
  item5: React.ReactNode;
  item6: React.ReactNode;
  showInfo?: boolean;
}) => {
  return (
    <StrictBox className={clsx(styles.base, { [styles.magnolia]: showInfo })}>
      <StrictBox className={clsx(styles.item, styles.item1)}>{item1}</StrictBox>
      <StrictBox className={clsx(styles.item, styles.item2)}>{item2}</StrictBox>
      <StrictBox className={clsx(styles.item, styles.item3)}>{item3}</StrictBox>
      <StrictBox className={clsx(styles.item, styles.item4)}>{item4}</StrictBox>
      <StrictBox className={clsx(styles.item, styles.item5)}>{item5}</StrictBox>
      <StrictBox className={clsx(styles.item, styles.item6)}>{item6}</StrictBox>
    </StrictBox>
  );
};

export default ImageComposition;
