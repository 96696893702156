import React from 'react';
import type { UnorderedListProps } from './interfaces';
import styles from './UnorderedList.module.scss';
import clsx from 'clsx';

const UnorderedList = ({
  children,
  className,
  padding,
}: UnorderedListProps) => {
  return (
    <ul
      className={clsx(styles.list, className, {
        [styles.padding]: padding,
      })}
    >
      {children}
    </ul>
  );
};

export default UnorderedList;
