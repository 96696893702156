import useSearchItems from '@/hooks/useSearchItems/useSearchItems';
import React, { useState } from 'react';
import GuideBox from '../GuideBox/GuideBox';
import NotAvailableInfo from '../NotAvailableInfo/NotAvailableInfo';
import GuideBoxSearchBox from './GuideBoxSearchBox';
import { GuideBoxListProps } from './interfaces';

const GuideBoxList = ({
  guides = [],
  showSearchBox = false,
  searchBoxLabel = 'In Ratgebern suchen…',
}: GuideBoxListProps) => {
  const [term, setTerm] = useState('');
  const searchGuides = useSearchItems(guides, ['title', 'fullText'], {
    initialState: 'full',
  });
  const filteredGuides = searchGuides(term);

  return (
    <div
      data-test-component="guide-box-list"
      data-test-show-search-box={showSearchBox.toString()}
    >
      {showSearchBox && (
        <GuideBoxSearchBox
          resultsAmount={term ? filteredGuides.length : 0}
          onChange={setTerm}
          label={searchBoxLabel}
        />
      )}

      {filteredGuides.map((guide, idx) => (
        <GuideBox guide={guide} key={idx} coloured={idx % 2 === 0} />
      ))}
      {!filteredGuides.length && (
        <NotAvailableInfo
          icon={'user/search'}
          title="Dazu wurde leider kein passender Ratgeber gefunden."
          text="Versuchen Sie es mit einem anderen Suchbegriff."
        />
      )}
    </div>
  );
};

export default GuideBoxList;
