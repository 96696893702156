import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import clsx from 'clsx';
import Button from '../../components/Button/Button';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Badge from '../Badge/Badge';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
  ShowAddon,
} from '../Button/consts';
import StageBox from '../StageBox/StageBox';
import type { StageBoxTextProps } from './interfaces';
import styles from './StageBoxText.module.scss';

const StageBoxText = ({
  title,
  text,
  buttonLabel,
  showIcon,
  showIconHighlighted,
  icon,
  displayBadge,
  badgetext,
  badgeimage,
  badgeColor,
  badgeTextType,
  isSpecialArticleStyle,
  StageBoxComponent = StageBox,
  ButtonComponent = Button,
  backgroundType = 'gradient',
  ...props
}: StageBoxTextProps) => {
  const showBadge = displayBadge && (badgetext || badgeimage);

  const clickableProps = extractClickableProps(props);

  return (
    <StageBoxComponent
      backgroundType={backgroundType}
      badgeSlot={
        showBadge && (
          <div className={styles.stageBadge}>
            <Badge
              image={badgeimage}
              color={badgeColor}
              badgeTextType={badgeTextType}
            >
              {badgetext}
            </Badge>
          </div>
        )
      }
    >
      <div
        className={clsx(styles.stageBox, styles[backgroundType], {
          [styles.withBadge]: showBadge,
          [styles.specialArticleStyles]: isSpecialArticleStyle,
        })}
      >
        {title && <h1 className={styles.title}>{title}</h1>}
        {text && (
          <div className={styles.text} {...getSafeInnerHTMLProp(text)} />
        )}
        {buttonLabel && (
          <div className={styles.buttonWrapper}>
            <ButtonComponent
              color={
                backgroundType === 'gradient'
                  ? ButtonColor.Secondary
                  : ButtonColor.Primary
              }
              size={ButtonSize.Large}
              align={ButtonAlign.Left}
              width={ButtonWidth.Auto}
              showIcon={showIcon}
              clickContext={title}
              icon={icon}
              showAddon={
                showIconHighlighted ? ShowAddon.Gradient : ShowAddon.None
              }
              {...clickableProps}
            >
              {buttonLabel}
            </ButtonComponent>
          </div>
        )}
      </div>
    </StageBoxComponent>
  );
};

export default StageBoxText;
