import * as Yup from 'yup';
import { isDateFormatValid } from '../../../../helpers/date';
import {
  designation,
  email,
  firstName,
  iban,
  lastName,
  phone,
} from '../../../../schemas';

const zone = Yup.string().required('Wählen Sie eine Zone aus.');
const birthDate = Yup.string()
  .required('Bitte geben Sie das korrekte Geburtsdatum an.')
  .test(
    'formatDate',
    'Das Datum muss im Format TT.MM.JJJJ angegeben werden.',
    // @ts-ignore
    isDateFormatValid
  );

const schema = Yup.object({
  designation,
  title: Yup.string(),
  firstName,
  lastName,
  birthDate,
  email,
  phone,
  enercityUserId: Yup.string().required(
    'Wir benötigen eine enercity Kundennummer.'
  ),

  mobilCardUserType: Yup.string().required('Bitte wählen Sie eine Option.'),
  mobilCardUserDesignation: Yup.mixed().when(['mobilCardUserType'], {
    is: 'Selber Haushalt',
    then: () => designation,
  }),
  mobilCardUserFirstName: Yup.mixed().when(['mobilCardUserType'], {
    is: 'Selber Haushalt',
    then: () => firstName,
  }),
  mobilCardUserLastName: Yup.mixed().when(['mobilCardUserType'], {
    is: 'Selber Haushalt',
    then: () => lastName,
  }),
  mobilCardUserBirthDate: Yup.mixed().when(['mobilCardUserType'], {
    is: 'Selber Haushalt',
    then: () => birthDate,
  }),
  mobilCardUserNr: Yup.string(),
  startDate: Yup.date()
    .typeError('Bitte vervollständigen Sie Ihre Angaben.')
    .required('Bitte vervollständigen Sie Ihre Angaben.'),
  fromZone: zone,
  toZone: zone,
  class: Yup.string().required('Bitte wählen Sie eine Option.'),
  iban,
  accountOwner: Yup.string()
    .min(2, 'Der Kontoinhaber name ist zu kurz.')
    .required('Wir benötigen einen Kontoinhaber.'),
  SEPALastschrift: Yup.mixed().test(
    'is-true',
    'Wir benötigen Ihre Zustimmung.',
    // @ts-ignore
    (value: boolean | string) => value === true
  ),
});

export default schema;
