function partSecondFormat(value: number): string {
  return value < 10 ? `${value}`.replace(/(-)?(\d+)/, '$10$2') : `${value}`;
}

function secondsToTimeParts(value: number) {
  const hours = ~~(value / 3600);
  const minutes = ~~((value % 3600) / 60);
  const seconds = ~~value % 60;

  return [
    partSecondFormat(hours),
    partSecondFormat(minutes),
    partSecondFormat(seconds),
  ] as const;
}

export const formatCountdownTime = (
  initialValueInSeconds: number | undefined
) => {
  const initialTimeParts = initialValueInSeconds
    ? secondsToTimeParts(initialValueInSeconds)
    : [];

  return (valueInSeconds: number): string => {
    const formatedTime = secondsToTimeParts(valueInSeconds);

    if (initialValueInSeconds) {
      const [, minutes, seconds] = formatedTime;
      const [initHours, initMinutes] = initialTimeParts;

      if (initHours === '00' && initMinutes === '00') {
        return seconds;
      }
      if (initHours === '00') {
        return [minutes, seconds].join(':');
      }
    }

    return formatedTime.join(':');
  };
};
