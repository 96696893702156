import { ASSET_BASE_URL } from '@/config';
import { JobShiftPersons, JobShiftPillType } from './interfaces';

export const JOB_SHIFTS: Record<
  JobShiftPillType,
  { icon: string; name: string }
> = {
  backShift: {
    icon: '⏰',
    name: 'Spätschicht',
  },
  nightShift: {
    icon: '🌙',
    name: 'Nachtschicht',
  },
  earlyShift: {
    icon: '🌅',
    name: 'Frühschicht',
  },
  disposalShift: {
    icon: '✅ ',
    name: 'Verfügungsschicht',
  },
  restDay: {
    icon: '😴',
    name: 'Ersatzruhetag',
  },
};

const PROFILE_IMAGE_BASE_PATH = `${ASSET_BASE_URL}/assets/jobs/shift-model`;

export const JOB_SHIFT_PERSONS: Record<
  JobShiftPersons,
  { name: string; image: string }
> = {
  anna: {
    image: `${PROFILE_IMAGE_BASE_PATH}/anna.jpeg`,
    name: 'Anna',
  },
  christina: {
    image: `${PROFILE_IMAGE_BASE_PATH}/christina.jpeg`,
    name: 'Christina',
  },
  jan: {
    image: `${PROFILE_IMAGE_BASE_PATH}/jan.jpeg`,
    name: 'Jan',
  },
  leon: {
    image: `${PROFILE_IMAGE_BASE_PATH}/noel.png`,
    name: 'Noel',
  },
  matthias: {
    image: `${PROFILE_IMAGE_BASE_PATH}/matthias.jpeg`,
    name: 'Matthias',
  },
  paul: {
    image: `${PROFILE_IMAGE_BASE_PATH}/marcel.png`,
    name: 'Marcel',
  },
};
