import React from 'react';
import type { DistrictHeatMapLegendProps } from './interfaces';
import styles from './DistrictHeatMapLegend.module.scss';

const DistrictHeatMapLegend = ({
  children,
}: DistrictHeatMapLegendProps) => {
  return <div className={styles.base}>{children}</div>;
};

export default DistrictHeatMapLegend;
