export const HEADLINE = 'Positive Energie Stories';
export const SUBTITLE =
  'Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.';
export const CARD_DATA = [
  {
    image: {
      name: 'Fernwärme wichtig',
      path: '/assets/cms/enercity-de/magazin/quaschning-notwendigkeit-fernwaermeausbau/header-prof-volker-quaschning.jpg',
    },
    link: '/magazin/deine-stadt/notwendigkeit-fernwaermeausbau',
    text: 'Fernwärme ist ein unerlässlicher Part der Energiewende',
  },
  {
    image: {
      name: 'So funktioniert Fernwärme',
      path: '/assets/cms/enercity-de/magazin/funktionsweise-fernwaerme/header-fernwaerme.jpg',
    },
    link: '/magazin/unsere-welt/funktionsweise-fernwaerme',
    text: 'So funktioniert grüne Fernwärme',
  },
  {
    image: {
      name: 'Doppelseite Solarmodule',
      path: '/assets/cms/enercity-de/magazin/bifaziale-solarmodule/header_bifaziale-pv-module.jpg',
    },
    link: '/magazin/unsere-welt/bifaziale-solaranlagen',
    text: 'Doppelseitige Solarmodule für die Energiewende',
  },
  {
    image: {
      name: 'Frischer Wind',
      path: '/assets/cms/enercity-de/magazin/infografik-onshore/header-infografik-wind.png',
    },
    link: '/magazin/unsere-welt/auf-einen-blick-onshore-windkraft',
    text: 'Noch mehr frischer Wind für die Erneuerbaren',
  },
  {
    image: {
      name: 'Smart Grids',
      path: '/assets/cms/enercity-de/magazin/bedeutung-von-smart-grids-fuer-die-energiewende/306_460751759_1944x822_header.jpg',
    },
    link: '/magazin/unsere-welt/bedeutung-von-smart-grids-fuer-die-energiewende',
    text: 'Wie lässt sich Energie smart verteilen?',
  },
  {
    image: {
      name: 'Vorreiter PV und Elektromobilität',
      path: '/assets/cms/enercity-de/magazin/Hausbesuch-Wendland/header-hausbesuch-wendland.jpg',
    },
    link: '/magazin/mein-leben/vorreiter-pv-und-elektromobilitaet',
    text: 'Vorreiter PV und Elektromobilität',
  },
];
