import Clickable from '@uikit/components/Clickable/Clickable';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import React from 'react';
import type { JobApplyStepHintProps } from './interfaces';
import styles from './JobApplyStepHint.module.scss';

const JobApplyStepHint = ({ children, link }: JobApplyStepHintProps) => {
  return (
    <p className={styles.base}>
      <strong>Tipp:</strong> {children}
      {link && (
        <Clickable
          actionType={ClickableAction.Linkintern}
          linkintern={link}
          className={styles.link}
        >
          {' '}
          Mehr erfahren
        </Clickable>
      )}
    </p>
  );
};

export default JobApplyStepHint;
