import clsx from 'clsx';
import { type SVGProps } from 'react';
import styles from './styles.module.scss';

const SvgFlagGb = ({ className, ...props }: SVGProps<SVGSVGElement>) =>  (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      className={clsx(styles.round, className)}
      {...props}
    >
      <g>
        <path fill="#012169" d="M-8.751-1.54v22.223h37.037V-1.54H-8.75Z" />
        <path
          fill="#000"
          d="m-8.751-1.54 37.037 22.223L-8.75-1.54Zm37.037 0L-8.75 20.683 28.286-1.54Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M5.448 9.571-9.895.366l2.287-3.811L9.767 6.98 27.142-3.445 29.43.365 14.087 9.572l15.342 9.206-2.287 3.811L9.767 12.163-7.608 22.588l-2.287-3.811L5.448 9.57Z"
          clipRule="evenodd"
        />
        <mask
          width={38}
          height={23}
          x={-9}
          y={-2}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill="#fff"
            d="M9.768 9.572h18.518v11.11L9.768 9.573Zm0 0v11.11H-8.751L9.768 9.573Zm0 0H-8.751V-1.54L9.768 9.571Zm0 0V-1.54h18.518L9.768 9.571Z"
          />
        </mask>
        <g>
          <path
            fill="#000"
            d="m-8.751-1.54 37.037 22.223L-8.75-1.54Zm37.037 0L-8.75 20.683 28.286-1.54Z"
          />
          <path
            fill="#C8102E"
            fillRule="evenodd"
            d="M6.888 9.571-9.513-.269l1.524-2.54L9.768 7.843 27.524-2.81l1.524 2.54-16.401 9.841 16.401 9.841-1.524 2.54L9.768 11.3-7.99 21.953l-1.524-2.54L6.888 9.57Z"
            clipRule="evenodd"
          />
        </g>
        <path
          fill="#C8102E"
          d="M-9.492 6.609H6.805v-8.89h5.925v8.89h16.297v5.926H12.73v8.888H6.805v-8.888H-9.492V6.609Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M6.064-3.021h7.407v8.889h16.297v7.407H13.47v8.889H6.064v-8.889h-16.296V5.868H6.064v-8.89ZM7.545-1.54v8.89H-8.75v4.444H7.545v8.888h4.445v-8.888h16.296V7.348H11.99V-1.54H7.545Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
export default SvgFlagGb;
