import useModal from '@/hooks/useModal/useModal';
import ProductOverview from '@/uikit/components/ProductOverview/ProductOverview';
import ProductOverviewModalContent from '@/uikit/components/ProductOverviewModalContent/ProductOverviewModalContent';
import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import toID from '@uikit/helpers/toID';
import ModalPortal from '../ModalPortal/ModalPortal';
import type { ProductModalWrapperProps } from './interfaces';

const ProductModalWrapper = ({
  imageComment,
  price,
  priceComment,
  buttonText,
  buttonSize,
  buttonWidth,
  buttonColor,
  details,
  detailInfoText,
  headlineFirst,
  headlineSecond,
  image,
  isWithBoxComponent,
  modalRightSide,
  ...props
}: ProductModalWrapperProps) => {
  const clickableProps = extractClickableProps(props);
  const { showModal, closeModal } = useModal();
  const modalId = `${toID(headlineFirst)}-details`;
  const showDetailsModal = () => showModal(modalId);

  return (
    <>
      <ProductOverview
        imageComment={imageComment}
        price={price}
        priceComment={priceComment}
        buttonText={buttonText}
        buttonSize={buttonSize}
        buttonWidth={buttonWidth}
        buttonColor={buttonColor}
        detailInfoText={detailInfoText}
        headlineFirst={headlineFirst}
        headlineSecond={headlineSecond}
        image={image}
        isWithBoxComponent={isWithBoxComponent}
        details={details}
        onClickDetails={showDetailsModal}
        {...clickableProps}
      />

      <ModalPortal id={modalId} onClose={closeModal}>
        <ProductOverviewModalContent
          imageComment={imageComment}
          price={price}
          priceComment={priceComment}
          buttonText={buttonText}
          buttonSize={buttonSize}
          buttonWidth={buttonWidth}
          buttonColor={buttonColor}
          detailInfoText={detailInfoText}
          headlineFirst={headlineFirst}
          headlineSecond={headlineSecond}
          details={details}
          image={image}
          modalRightSide={modalRightSide}
          {...clickableProps}
        />
      </ModalPortal>
    </>
  );
};

export default ProductModalWrapper;
