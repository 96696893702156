import clsx from 'clsx';
import styles from './ImageGridFilterItemList.module.scss';
import type { ImageGridFilterItemListProps } from './interfaces';

const ImageGridFilterItemList = ({
  activeItem,
  items,
  onChange,
  className,
}: ImageGridFilterItemListProps) => {
  const handleOnClickButton = (item: string) => () => {
    onChange(item);
  };

  return (
    <div className={clsx(styles.base, className)}>
      {items.map((item) => (
        <button
          key={item}
          type="button"
          className={clsx(styles.filterOption, {
            [styles.active]: activeItem === item,
          })}
          onClick={handleOnClickButton(item)}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default ImageGridFilterItemList;
