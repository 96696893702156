import type { ClickableProps } from '../Clickable/interfaces';

export enum InfoBoxColor {
  Simple = 'simple',
  Highlighted = 'highlighted',
}

export enum InfoBoxButtonStyle {
  Button = 'button',
  Link = 'link',
}

export enum InfoBoxStyle {
  Centered = 'centered',
  SideBySide = 'side-by-side',
}

export interface InfoBoxBaseProps {
  readonly color: InfoBoxColor;
  readonly boxStyle?: InfoBoxStyle;
  readonly headline?: string;
  readonly text: string;
  readonly buttonLabel?: string;
  readonly buttonStyle?: InfoBoxButtonStyle;
}

export type InfoBoxProps = InfoBoxBaseProps & ClickableProps;
