import { CalculationOptionsKey } from './types';

export const transformCalculationOptions = (
  options: Record<CalculationOptionsKey, string | undefined>
) => {
  return (Object.keys(options) as (keyof typeof options)[]).reduce(
    (result, key) => {
      const currentValue = options[key]?.toString().replace(',', '.');

      if (typeof currentValue !== 'undefined') {
        result[key] = parseFloat(currentValue);
      }

      return result;
    },
    {} as Record<CalculationOptionsKey, number>
  );
};
