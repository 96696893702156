import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import Background from '@uikit/components/Background/Background';
import Icon from '@uikit/components/Icon/Icon';
import Video from '@uikit/components/Video/Video';
import clsx from 'clsx';
import { useRef } from 'react';
import styles from './FullScreenStage.module.scss';
import type { FullScreenStageProps } from './types';

const FullScreenStage = ({
  scrollDownLabel,
  backgroundImage,
  mobileBackgroundImage,
  videoName,
  mobileVideoName,
  title,
  mobileOption,
}: FullScreenStageProps) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile(BreakPoint.SM);

  const options = {
    none: {
      currentBackgroundImage: backgroundImage,
      currentVideoName: videoName,
    },
    imageOnly: {
      currentBackgroundImage: isMobile
        ? mobileBackgroundImage
        : backgroundImage,
      currentVideoName: isMobile ? undefined : videoName,
    },
    videoWithImage: {
      currentBackgroundImage: isMobile
        ? mobileBackgroundImage
        : backgroundImage,
      currentVideoName: isMobile ? mobileVideoName : videoName,
    },
  } as const;

  const { currentBackgroundImage = backgroundImage, currentVideoName } =
    options[mobileOption || 'none'];

  const handleScrollDown = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.base}>
      <div className={styles.background}>
        {currentVideoName && (
          <Video.VideoPlayerWithData
            videoName={currentVideoName}
            className={styles.video}
            loop
            muted
            autoPlay
            playsInline
          />
        )}
        <Background.Image
          src={currentBackgroundImage.path}
          alt={currentBackgroundImage.name}
        />
      </div>

      <div className={clsx(styles.bar, title && styles.withTitle)}>
        {title && <h1 className={styles.title}>{title}</h1>}

        <div className={styles.scrollDown}>
          {scrollDownLabel && <div>{scrollDownLabel}</div>}
          <button className={styles.arrow} onClick={handleScrollDown}>
            <Icon variant="action/arrow-down" size="iconSize24" />
          </button>
        </div>
      </div>

      <div className={styles.bottom} ref={bottomRef} />
    </div>
  );
};

export default FullScreenStage;
