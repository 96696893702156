import { VideoWrapperProps } from './types';
import Video from './Video';

const VideoWrapper = ({
  thumbnail,
  title,
  url,
  videoName,
}: VideoWrapperProps) => (
  <Video src={url} thumbnail={thumbnail} title={title} videoName={videoName} />
);

export default VideoWrapper;
