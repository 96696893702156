import { isProductionMode } from '@/helpers/isProductionMode';
import { useCallback, useEffect, useState } from 'react';

const checkCmp = () =>
  typeof window !== 'undefined' && typeof window.__cmp === 'function';

/**
 * listens to changes of consent status for a specific vendor in the CMP (Consent Management Provider = "cookie banner")
 * @param vendorId the Vendor ID for which you want to check consent (ID can be found on app.consentmanager.net -> select your CMP -> "Anbieter"/"Vendors" -> "ID" column)
 * @returns tuple consisting of:
 *   - boolean with consent status; undefined if no initialized (or correctly working) CMP has been found yet
 *   - function for setting consent in CMP
 */
const useCmpConsent = (vendorId: string) => {
  const [consent, setConsent] = useState<boolean | undefined>(undefined);
  const hasCmp = checkCmp();

  const readConsent = useCallback(
    (_recursionDepth: number = 0) => {
      if (!isProductionMode || !process.env.CONSENT_MANAGER_CODE_ID) {
        setConsent(true);
        return;
      }

      const tryAgainLater = () => {
        if (_recursionDepth < 30) {
          setTimeout(() => readConsent(_recursionDepth + 1), 300);
        }
      };

      if (!checkCmp()) {
        tryAgainLater();
        return;
      }
      const cmpData = window.__cmp!('getCMPData');

      if (
        typeof cmpData !== 'object' ||
        !('vendorConsents' in cmpData) ||
        typeof cmpData.vendorConsents !== 'object'
      ) {
        tryAgainLater();
        return;
      }

      if (
        'gppdata' in cmpData &&
        'cmpStatus' in cmpData.gppdata &&
        cmpData.gppdata.cmpStatus === 'loading'
      ) {
        tryAgainLater();
        return;
      }

      setConsent(cmpData.vendorConsents[vendorId] === true);
    },
    [vendorId]
  );

  const writeConsent = useCallback(
    (consent: boolean) => {
      if (!checkCmp()) return;
      const userMadeChoice = window.__cmp!('consentStatus')?.userChoiceExists;
      window.__cmp!('setVendorConsent', [vendorId, consent ? 1 : 0]);
      if (userMadeChoice === false) {
        // banner would hide after calling `setVendorConsent` although the user hasn't made a choice for other vendors yet
        window.__cmp!('showScreen');
      }
    },
    [vendorId]
  );

  useEffect(() => {
    readConsent();

    if (!hasCmp) return;

    window.__cmp!('addEventListener', ['consent', readConsent, false], null);

    return () => {
      checkCmp() &&
        window.__cmp!(
          'removeEventListener',
          ['consent', readConsent, false],
          null
        );
    };
  }, [readConsent, hasCmp]);

  return [consent, writeConsent] as const;
};

export default useCmpConsent;
