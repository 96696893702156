import { formatDate } from '../../../helpers/date';
import { UseFormState } from '../../../hooks/useForm/interfaces';
import {
  registerChargingStationKeysOrder,
  registerChargingStationLabels,
} from './config';
import { RegisterChargingStationFormState } from './interfaces';

// eslint-disable-next-line
export const createRegisterChargingStationSubmitFormState = (
  state: UseFormState
) =>
  Object.keys(state).reduce<Partial<UseFormState>>((acc, key) => {
    let keyByLabel = key;

    if (key in registerChargingStationLabels) {
      keyByLabel =
        registerChargingStationLabels[
          key as keyof RegisterChargingStationFormState
        ];
    }
    const value = state[key];
    if (value !== '') {
      acc[keyByLabel] = value instanceof Date ? formatDate(value) : value;
    }

    return acc;
  }, {});

export const createRegisterChargingKeysOrder = (
  state: UseFormState
): string[] => {
  return registerChargingStationKeysOrder.filter((key) =>
    Object.keys(state).includes(key)
  );
};
