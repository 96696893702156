import clsx from 'clsx';
import Accent from '../Accent/Accent';
import CheckBox from '../CheckBox/CheckBox';
import MapExpandToggle from '../MapExpandToggle/MapExpandToggle';
import styles from './WedemarkMapMenu.module.scss';
import { FILTER_CRITERIA } from './consts';
import type { WedemarkMapMenuProps } from './types';

const WedemarkMapMenu = ({
  activeFilters = [],
  className,
  expanded,
  handleFilterToggle,
  handleSetExpanded,
}: WedemarkMapMenuProps) => {
  return (
    <div
      className={clsx(styles.base, className, { [styles.expanded]: expanded })}
    >
      <MapExpandToggle
        handleClick={handleSetExpanded}
        expanded={expanded}
        className={styles.expandToggle}
        small
      />
      {FILTER_CRITERIA.map((criteria) => {
        const isSelected = activeFilters.includes(criteria.value);

        return (
          <Accent color={criteria.color} key={criteria.value}>
            <label
              key={criteria.value}
              className={clsx(
                styles.label,
                criteria.value in styles &&
                  styles[criteria.value as keyof typeof styles],
                {
                  [styles.selected]: isSelected,
                }
              )}
            >
              {criteria.label}
              <CheckBox
                className={clsx(styles.checkbox)}
                onChange={() => handleFilterToggle(criteria.value)}
                checked={isSelected}
              />
            </label>
          </Accent>
        );
      })}
    </div>
  );
};

export default WedemarkMapMenu;
