import React from 'react';
import assetPath from '../../helpers/assetPath';
import type { JobKununuScoreProps } from './interfaces';
import styles from './JobKununuScore.module.scss';
import JobKununuScoreStarGroup from './JobKununuScoreStarGroup';

const formatScore = (score: number) => score.toFixed(1).replace('.', ',');

const JobKununuScore = ({ score }: JobKununuScoreProps) => {
  const logoPath = `${assetPath}/assets/logos/kununu.svg`;

  return (
    <div className={styles.base}>
      <p className={styles.heading}>Arbeitgeberin-Score</p>
      <p className={styles.score}>{formatScore(score)}</p>
      <JobKununuScoreStarGroup score={score} />
      <hr className={styles.hr} />
      <img className={styles.img} src={logoPath} alt="Kununu" width="140" />
    </div>
  );
};

export default JobKununuScore;
