import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import React from 'react';
import type { JobApplyStepDescriptionProps } from './interfaces';
import styles from './JobApplyStepDescription.module.scss';

const JobApplyStepDescription = ({
  children,
}: JobApplyStepDescriptionProps) => {
  return <p className={styles.base} {...getSafeInnerHTMLProp(children)} />;
};

export default JobApplyStepDescription;
