import clsx from 'clsx';
import React from 'react';
import type { StepHeadlineProps } from './interfaces';
import styles from './StepHeadline.module.scss';

const StepHeadline = ({
  children,
  label,
  isSub,
  isError,
}: StepHeadlineProps) => {
  return (
    <header
      className={clsx(styles.base, {
        [styles.sub]: isSub,
        [styles.error]: isError,
      })}
    >
      {label && <p className={styles.label}>{label}</p>}
      <h2 className={styles.headline}>{children}</h2>
    </header>
  );
};

export default StepHeadline;
