import clsx from 'clsx';
import { useId } from 'react';
import Section from '../../components/Section/Section';
import Background from '../Background/Background';
import LottieWrapper from '../LottieWrapper/LottieWrapper';
import sectionStyle from '../Section/Section.module.scss';
import styles from './SectionVisual.module.scss';
import type { SectionVisualProps } from './interfaces';

const SectionVisual = ({
  bgColor,
  children,
  image,
  sectionId,
  animationData,
}: SectionVisualProps) => {
  const animationId = useId();

  return (
    <Section bgColor={bgColor} hasPadding={false} sectionId={sectionId}>
      {(image || animationData) && (
        <div className={styles.wrapper}>
          {image && (
            <div
              className={clsx(styles.image, sectionStyle.keyVisual, {
                [styles.withLottie]: !!animationData,
              })}
            >
              <Background.Picture asset={image} />
            </div>
          )}

          {animationData && (
            <LottieWrapper
              animationName={animationId}
              name={animationId}
              animationData={animationData}
            />
          )}
        </div>
      )}
      {children && <div className={styles.stagebox}>{children}</div>}
    </Section>
  );
};

export default SectionVisual;
