import React, { ReactElement } from 'react';
import hasItemProps from '../../helpers/hasItemProps';
import isEven from '../../utils/isEven';
import { ShowcaseElementProps } from '../ShowcaseElement/interfaces';
import ShowcaseElement from '../ShowcaseElement/ShowcaseElement';
import type { ShowcaseListProps } from '../ShowcaseList/interfaces';
import styles from './ShowcaseList.module.scss';

const ShowcaseList = ({
  children,
}: ShowcaseListProps): JSX.Element => {
  let index = 0;
  const _children = React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      return child;
    }

    let props: ShowcaseElementProps;

    if (hasItemProps(child)) {
      props = child.props.item.props;
    } else {
      props = (child as ReactElement<ShowcaseElementProps>).props;
    }

    return (
      <ShowcaseElement {...props} align={isEven(index++) ? 'left' : 'right'} />
    );
  });

  return <div className={styles.base}>{_children}</div>;
};

export default ShowcaseList;
