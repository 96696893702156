import { useRive } from '@rive-app/react-canvas';
import type { RiveWrapperProps } from './types';

const RiveWrapper = (props: RiveWrapperProps) => {
  const src = 'src' in props ? props.src : props.asset.path;

  const { RiveComponent } = useRive(
    {
      autoplay: true,
      src,
      stateMachines: props.stateMachines,
    },
    {
      fitCanvasToArtboardHeight: true,
    }
  );

  return <RiveComponent />;
};

export default RiveWrapper;
