import convertQueryStatusToButtonSubmitState from '@/helpers/convertQueryStatusToButtonSubmitState';
import type { UseSuGRTariffsProps } from '@/hooks/useSuGRTariffs/types';
import useSuGRTariffs from '@/hooks/useSuGRTariffs/useSuGRTariffs';
import Headline from '@uikit/components/Headline/Headline';
import TariffComparison from '@uikit/components/TariffComparison/TariffComparison';
import ZipCodeFormBox from '@uikit/components/ZipCodeFormBox/ZipCodeFormBox';
import { ZipCodeFormBoxState } from '@uikit/components/ZipCodeFormBox/types';
import productTypeDictionary from '@uikit/helpers/productTypeDictionary';
import { capitalize } from '@uikit/utils/convertCase';
import { useState } from 'react';
import { ProductComparisonProps } from './types';

const initialState: UseSuGRTariffsProps = {
  city: '',
  streetName: '',
  streetNumber: '',
  zipCode: '',
  productType: 'power',
};

const ProductComparison = ({
  productType,
  configuratorPath = '/privatkunden/produkte/strom/tarife',
  consumption = 3000,
}: ProductComparisonProps) => {
  const [state, setState] = useState(initialState);

  const { data, status, isSuccess, isError } = useSuGRTariffs(state, {
    retry: 0,
    staleTime: Infinity,
  });

  const hasTariffs = data?.[productType]?.tariffs?.length;

  const headlineText = hasTariffs
    ? `Unsere Stromtarife für ${state.city}`
    : `${capitalize(
        productTypeDictionary[productType]
      )}tarif direkt vergleichen`;

  const handleZipCodeSubmit = (data: ZipCodeFormBoxState) => {
    setState((state) => ({
      ...state,
      ...data,
      productType,
      [productType]: consumption,
    }));
  };

  const handleZipCodeChange = () => {
    if (isError) {
      setState(initialState);
    }
  };

  return (
    <>
      <Headline>{headlineText}</Headline>

      {!isSuccess && (
        <ZipCodeFormBox
          onSubmit={handleZipCodeSubmit}
          onZipCodeChange={handleZipCodeChange}
          submitButtonState={convertQueryStatusToButtonSubmitState(status)}
        />
      )}

      {
        <TariffComparison
          tariffResponseData={data}
          configuratorUrl={configuratorPath}
          zipCode={state.zipCode}
          productType={productType}
          consumption={consumption}
        />
      }
    </>
  );
};

export default ProductComparison;
