import Icon from '../Icon/Icon';
import type { NoSearchResultProps } from './interfaces';
import styles from './NoSearchResult.module.scss';

const NoSearchResult = ({
  headline,
  subtext,
  children,
  iconVariant = 'user/search',
}: NoSearchResultProps) => {
  return (
    <div className={styles.base}>
      <Icon
        className={styles.icon}
        variant={iconVariant}
        size="iconSize64"
        isOutlined
      />
      <p className={styles.headline}>{headline}</p>
      <p className={styles.subtext}>{subtext}</p>

      {children && <p className={styles.childrenWrapper}>{children}</p>}
    </div>
  );
};

export default NoSearchResult;
