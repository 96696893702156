import JobKununuScore from '../../uikit/components/JobKununuScore/JobKununuScore';
import { useKununuScore } from './hooks';

const KununuScoreMagnoliaWrapper = () => {
  const { data: kununuScore } = useKununuScore();

  if (!kununuScore) {
    return null;
  }

  return <JobKununuScore score={kununuScore} />;
};

export default KununuScoreMagnoliaWrapper;
