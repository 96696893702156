import { UseScriptConfigVariant } from '@/hooks/useScript/scriptVariants';
import useScript from '@/hooks/useScript/useScript';
import styles from './CMPVendorList.module.scss';

const CMPVendorList = () => {
  useScript(UseScriptConfigVariant.CMPVendorList, { removeOnUnmount: true });

  return <div className={styles.base} id="cmpvendorlist" />;
};

export default CMPVendorList;
