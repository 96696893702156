import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import parsePhoneNumber from '../../helpers/parsePhoneNumber';
import toID from '../../helpers/toID';
import Avatar from '../Avatar/Avatar';
import styles from './ContactBlock.module.scss';
import type { ContactBlockProps } from './types';

const ContactBlock = ({
  title,
  description,
  pronouns,
  phone,
  email,
  image,
}: ContactBlockProps) => {
  return (
    <div className={styles.contactBlock}>
      {image && (
        <Avatar
          className={styles.avatar}
          image={image}
          alt={title}
          title={image.name}
          breakpointAspectRatioConfigs={[
            {
              breakpoint: BreakPoint.XSS,
              aspectRatio: '1 / 1',
              width: 124,
            },
          ]}
        />
      )}
      <div className={styles.contactData}>
        <div>
          <span className={styles.name}>
            {title}{pronouns && <em> ({pronouns})</em>}
          </span>
          <span className={styles.jobTitle}>{description}</span>
        </div>
        <div className={styles.links}>
          {email && (
            <a href={`mailto:${email}`} className={styles.linkMail}>
              E-Mail senden
            </a>
          )}

          {phone && (
            <a
              id={`contact-tel-${toID(phone)}`}
              href={`tel:${parsePhoneNumber(phone)}`}
              className={styles.link}
            >
              {phone}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactBlock;
