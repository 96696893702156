import useForm from '@/hooks/useForm/useForm';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import CheckBoxBlock from '@uikit/components/CheckBoxBlock/CheckBoxBlock';
import Container from '@uikit/components/Container/Container';
import FormRow from '@uikit/components/FormRow/FormRow';
import FormRowHeadline from '@uikit/components/FormRow/FormRowHeadline';
import Headline from '@uikit/components/Headline/Headline';
import {
  HeadlineAlign,
  HeadlineLevel,
} from '@uikit/components/Headline/consts';
import { HeadlineProps } from '@uikit/components/Headline/interfaces';
import RichText from '@uikit/components/RichText/RichText';
import { GVHFormStep, GVHFormStep2State } from '../interfaces';
import schema from './GVHFormStep2.schema';

export const gvhInitialFormStateStep2: GVHFormStep2State = {
  confirmation: false,
  agreement: false,
  cancellationPolicy: false,
  phoneConsulting: false,
  emailConsulting: false,
};

const GVHFormHeadline = ({ children, ...props }: HeadlineProps) => (
  <Headline
    level={HeadlineLevel.Four}
    tag="h3"
    align={HeadlineAlign.Left}
    style={{ marginBottom: '0.5rem', display: 'block' }}
    {...props}
  >
    {children}
  </Headline>
);

const GVHFormStep2 = ({
  onSubmit,
  submissionState = ButtonSubmitState.Initial,
}: GVHFormStep) => {
  const { getErrorByFieldName, handleChange, handleSubmit, isFormValid } =
    useForm(onSubmit, schema, gvhInitialFormStateStep2, 'gvh-form-step-2');

  return (
    <form onSubmit={handleSubmit}>
      <FormRowHeadline>Datenverarbeitung</FormRowHeadline>
      <FormRow>
        <RichText>
          <p>
            Damit Ihre MobilCard erstellt werden kann, stimmen Sie bitte der
            Weitergabe der personenbezogenen Daten zu:
          </p>
        </RichText>
      </FormRow>
      <FormRow>
        <CheckBoxBlock
          onChange={handleChange}
          name={'agreement'}
          errorMessage={getErrorByFieldName('agreement')}
        >
          Ich stimme der Weitergabe der personenbezogenen Daten an die ÜSTRA
          Hannoversche Verkehrsbetriebe AG zu. Personenbezogene Daten sind der
          Name, die Anschrift, das Geburtsdatum sowie Angaben zur gewünschten
          MobilCard.
        </CheckBoxBlock>
      </FormRow>
      <br />
      <FormRowHeadline>
        Ihre wesentlichen Vertragsinhalte im Überblick:
      </FormRowHeadline>
      <br />
      <GVHFormHeadline>Vertragspartner</GVHFormHeadline>
      <RichText>
        <p>
          enercity AG <br />
          Ihmeplatz 2 <br />
          30449 Hannover <br />
          0511.430.0 <br />
        </p>
      </RichText>
      <br />
      <GVHFormHeadline>Umfang der Leistung</GVHFormHeadline>
      <RichText>
        <p>
          enercity bestellt als Sammelbesteller für den Kunden oder dem von ihm
          benannten Nutzer gemäß den Bestimmungen dieses Vertrages persönliche,
          nicht übertragbare MobilCards der Großraum-Verkehr Hannover GmbH (GVH)
          im Jahresabonnement (Jobticket S). Ein Anspruch auf Beförderung
          entsteht damit nicht, der Beförderungsvertrag kommt ausschließlich
          zwischen dem GVH und dem Fahrgast zu den Beförderungsbedingungen des
          GVH zustande.
        </p>
      </RichText>
      <br />
      <GVHFormHeadline>Fahrgeld und Zahlungsweise</GVHFormHeadline>
      <RichText>
        <p>
          Die Preise entsprechen immer den gültigen veröffentlichten Preisen des
          GVH zum Jobticket S für das Fahrgeld in der ersten oder zweiten
          Wagenklasse und können unter www.gvh.de eingesehen werden. Das
          Fahrgeld ist monatlich im Voraus jeweils zum 1. des Monats fällig. Der
          Kunde ist verpflichtet, den monatlichen Betrag ab Monatsbeginn bis zur
          Abbuchung auf dem im SEPA-Lastschriftmandat genannten Konto
          bereitzuhalten. Dies gilt entsprechend für sonstige fällige
          Forderungen aus dem Vertrag enercity GVH MobilCard.
        </p>
      </RichText>
      <br />
      <GVHFormHeadline>Preisanpassung</GVHFormHeadline>
      <RichText>
        <p>
          Änderungen der im{' '}
          <a
            href="/assets/cms/enercity-de/Privatkunden/Service/Vorteilswelt/GVH/preisblatt_enercity_gvh_mobilcard.pdf"
            target="_blank"
            rel="noreferrer"
          >
            &quot;Preisblatt enercity GVH MobilCard&quot;
          </a>{' '}
          genannten Preise für das Fahrgeld werden zum 01.01. eines Jahres
          wirksam und entsprechen den Änderungen der veröffentlichten Preisen
          des GVH zum Jobticket S. Diese können unter www.gvh.de eingesehen
          werden. Bei einer Erhöhung des tariflichen Fahrpreises kann der Kunde
          den Vertrag ohne Einhaltung einer Kündigungsfrist mit Wirkung zum Tage
          des Inkrafttretens der Preisänderung kündigen. Hierzu müssen die
          vollständigen bereits ausgegebenen gültigen MobilCards spätestens am
          3. Kalendertag nach Inkrafttreten der Preisänderung bei enercity
          vorliegen.
        </p>
      </RichText>
      <br />
      <GVHFormHeadline>Vertragsdauer und Kündigung</GVHFormHeadline>
      <FormRow>
        <RichText>
          <p>
            Der Vertrag läuft zunächst bis zum 31.12. eines Jahres ab
            Inkrafttreten. Er verlängert sich jeweils um ein weiteres Jahr,
            sofern er nicht von einem der Vertragspartner mit einer Frist von 6
            Wochen zum 30.06. oder zum 31.12. eines Kalenderjahres in Textform
            gekündigt wird. Bei einem Umzug des Kunden in Gebiete, die außerhalb
            des Geltungsbereichs der zur Verfügung gestellten MobilCards liegen,
            endet der Vertrag. Dies gilt auch, wenn der abweichende Kartennutzer
            aus dem Haushalt des Kunden auszieht. Der Vertrag endet auch ohne
            Kündigung automatisch, wenn der Kunde den dazugehörigen Strom- oder
            Gasvertrag kündigt oder dieser aus anderen Gründen endet. Nach
            Beendigung des Vertrages müssen die vollständigen bereits
            ausgegebenen gültigen MobilCards spätestens am 3. Kalendertag nach
            Beendigung des Vertrages bei enercity vorliegen.
          </p>
        </RichText>
      </FormRow>
      <FormRow>
        <CheckBoxBlock
          name={'confirmation'}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('confirmation')}
        >
          Ich habe{' '}
          <a
            href="/assets/cms/enercity-de/Privatkunden/Service/Vorteilswelt/GVH/2020-01-01_Vertragsbedingungen_enercity_GVH_MobilCard.pdf"
            target="_blank"
            rel="noreferrer"
          >
            die Vertragsbedingungen enercity GVH MobilCard
          </a>{' '}
          sowie{' '}
          <a
            href="/assets/cms/enercity-de/Privatkunden/Service/Vorteilswelt/GVH/preisblatt_enercity_gvh_mobilcard.pdf"
            target="_blank"
            rel="noreferrer"
          >
            das Preisblatt enercity GVH MobilCard
          </a>{' '}
          sowie{' '}
          <a
            href="/assets/cms/Datenschutz/datenschutzinformation_enercity_AG.pdf"
            target="_blank"
            rel="noreferrer"
          >
            die Datenschutzinformation
          </a>{' '}
          zur Kenntnis genommen und erkläre mich damit einverstanden.
        </CheckBoxBlock>
      </FormRow>

      <FormRowHeadline>Auftrag</FormRowHeadline>
      <FormRow>
        <RichText>
          <p>
            Sie haben die enercity GVH MobilCard ausgewählt. Vielen Dank für Ihr
            Vertrauen! Wenn Sie den verbindlichen kostenpflichtigen Auftrag
            senden, haben Sie uns mitgeteilt, dass Sie einen Vertrag abschließen
            wollen. Kurz danach bestätigen wir Ihnen den Eingang und die
            Bearbeitung des Auftrags. Erst wenn wir Ihnen die
            Vertragsbestätigung durch die enercity AG zugesendet haben, ist der
            Vertrag zustande gekommen. Mit der Vertragsbestätigung erhalten Sie
            nochmals alle wesentlichen Vertragsinhalte. Unsere Angaben in diesem
            Internetauftritt stellen noch kein verbindliches Angebot dar. Die
            Daten aus Ihrem Auftrag werden von uns gespeichert.
          </p>
        </RichText>
      </FormRow>

      <FormRowHeadline>Widerrufsbelehrung</FormRowHeadline>
      <FormRow>
        <CheckBoxBlock
          name={'cancellationPolicy'}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('cancellationPolicy')}
        >
          Ich habe{' '}
          <a
            href="/assets/cms/enercity-de/Privatkunden/Service/Vorteilswelt/GVH/widerrufsbelehrung-enercity-gvh-mobilcard.pdf"
            target="_blank"
            rel="noreferrer"
          >
            die Widerrufsbelehrung
          </a>{' '}
          zur Kenntnis genommen.
        </CheckBoxBlock>
      </FormRow>
      <FormRowHeadline>Beratung und Information</FormRowHeadline>
      <FormRow>
        <RichText>
          <p>
            Ich möchte über Angebote zu Energielieferungen und -dienstleistungen
            im Zusammenhang mit Strom, Gas, Wärme und Wasser der enercity AG
            informiert und beraten werden.
          </p>
        </RichText>
      </FormRow>
      <FormRow>
        <CheckBoxBlock
          name={'emailConsulting'}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('emailConsulting')}
        >
          Ich möchte Angebote zu Energielieferungen und -dienstleistungen per{' '}
          <strong>E-Mail</strong> erhalten.
        </CheckBoxBlock>{' '}
      </FormRow>
      <FormRow>
        <CheckBoxBlock
          name={'phoneConsulting'}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('phoneConsulting')}
        >
          Ich möchte Angebote zu Energielieferungen und -dienstleistungen per{' '}
          <strong>Telefon</strong> erhalten.
        </CheckBoxBlock>
      </FormRow>
      <FormRow
        description={
          <>
            Die Einwilligung kann ich jederzeit mit Wirkung für die Zukunft
            durch Mitteilung an die enercity AG, Ihmeplatz 2, 30449 Hannover
            oder{' '}
            <a href="mailto:kundenservice@enercity.de">
              kundenservice@enercity.de
            </a>{' '}
            widerrufen. Anschließend werde ich nicht mehr über neue Angebote
            informiert.
          </>
        }
      />
      <br />
      <Container>
        <ButtonSubmit state={submissionState} inactive={!isFormValid}>
          Bestellung abschicken
        </ButtonSubmit>
      </Container>
    </form>
  );
};

export default GVHFormStep2;
