import { createContext, Ref, useContext } from 'react';

interface Context {
  readonly canGoNext: boolean;
  readonly canGoPrev: boolean;
  readonly activeIndex: number;
  readonly prev: () => void;
  readonly next: () => void;
  readonly numberOfItems: number;
  readonly id: number;
  readonly goTo: (index: number) => void;
  readonly carouselItemsRef: Ref<HTMLDivElement>;
  readonly itemsPerPage: number;
}

export const CarouselContext = createContext({} as Context);

export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error(`invalid MultiSelect Context`);
  }
  return context;
};
