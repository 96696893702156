import { CalculateSavingsAmountOptions } from './types';

export const calculateSavingsAmount = (
  options: CalculateSavingsAmountOptions
) => {
  const {
    electricityBasicPrice,
    electricityPriceHT,
    electricityPriceNT,
    fossilBasicPrice,
    fossilConsumption,
    fossilEfficiency,
    fossilPrice,
    heatPumpEfficiency,
    type,
  } = options;

  const fossilEfficiencyFraction = fossilEfficiency / 100;
  const heatpumpEfficiencyFraction = heatPumpEfficiency / 100;
  const fossilBasicPriceCt =
    fossilBasicPrice === undefined || type === 'oil'
      ? 0
      : fossilBasicPrice * 100;
  const electricityBasicPriceCt = electricityBasicPrice * 100;
  const oilMultiplier = type === 'oil' ? 10 : 1;

  const fossilConsumptionCosts =
    fossilConsumption * fossilPrice + fossilBasicPriceCt;
  const electricityConsumption =
    (oilMultiplier * (fossilConsumption * fossilEfficiencyFraction)) /
    heatpumpEfficiencyFraction;

  const electricityConsumptionCostsHT =
    electricityConsumption * electricityPriceHT;
  const electricityConsumptionCostsNT =
    electricityConsumption * electricityPriceNT;

  const electricityConsumptionCosts =
    0.7 * electricityConsumptionCostsHT +
    0.3 * electricityConsumptionCostsNT +
    electricityBasicPriceCt;

  const savingsCt = fossilConsumptionCosts - electricityConsumptionCosts;
  const savingsEur = savingsCt / 100;

  return savingsEur;
};
