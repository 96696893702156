import React from 'react';
import Avatar from '../Avatar/Avatar';
import RichText from '../RichText/RichText';
import type { QuoteProps } from './interfaces';
import styles from './Quote.module.scss';
import clsx from 'clsx';

const Quote = ({
  image,
  quote,
  author,
  title,
  className,
  hasAvatarShadow = true,
  hasAvatarBorder = true,
  classNameAvatarWrapper,
  classNameAvatar,
  classNameCite,
  classNameQuote,
  ...additionalProps
}: QuoteProps): JSX.Element => {
  return (
    <div>
      <blockquote className={clsx(styles.base, className)} {...additionalProps}>
        {!!image && (
          <div className={clsx(styles.avatar, classNameAvatarWrapper)}>
            <Avatar
              className={classNameAvatar}
              image={image}
              title={image.name || author}
              hasShadow={hasAvatarShadow}
              hasBorder={hasAvatarBorder}
            />
          </div>
        )}
        <RichText className={clsx(styles.quote, classNameQuote)}>
          {quote}
        </RichText>
        <cite className={clsx(styles.cite, classNameCite)}>
          {author && <strong>{author}</strong>}
          {title && <span>{title}</span>}
        </cite>
      </blockquote>
    </div>
  );
};

export default Quote;
