import ModalPortal from '@/components/ModalPortal/ModalPortal';
import useShowMore from '@/hooks/useShowMore/useShowMore';
import Button from '@uikit/components/Button/Button';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import { SHOW_ALL_FILTER_NAME } from '@uikit/components/ImageGrid/consts';
import generateImageGridItems from '@uikit/components/ImageGrid/generateImageGridItems';
import ImageGridWrapper from '@uikit/components/ImageGrid/ImageGridWrapper';
import ImageGridHeader from '@uikit/components/ImageGridHeader/ImageGridHeader';
import ImageGridItem from '@uikit/components/ImageGridItem/ImageGridItem';
import ImageInfoModalContent from '@uikit/components/ImageInfoModalContent/ImageInfoModalContent';
import { ImageInfoModalContentProps } from '@uikit/components/ImageInfoModalContent/interfaces';
import ModalContentFull from '@uikit/components/Modal/ModalContentFull';
import React from 'react';
import ImageGridFilter from '../ImageGridFilter/ImageGridFilter';
import ImageGridFilterItemList from '../ImageGridFilterItemList/ImageGridFilterItemList';
import styles from './ImageGrid.module.scss';
import type { ImageGridProps } from './interfaces';

const ImageGrid = ({ title, groups, columnAmount = 3 }: ImageGridProps) => {
  const [currentFilter, setCurrentFilter] =
    React.useState(SHOW_ALL_FILTER_NAME);

  const allItems = React.useMemo(
    () => generateImageGridItems(groups, currentFilter),
    [currentFilter, groups]
  );

  const groupNames = React.useMemo(
    () => [SHOW_ALL_FILTER_NAME, ...groups.map(({ name }) => name)],
    [groups]
  );

  const { currentPageItems, next, canGoNext } = useShowMore(allItems, {
    itemsPerPage: 6,
  });

  const [currentItem, setCurrentItem] = React.useState<Omit<
    ImageInfoModalContentProps,
    'onClickShareButton'
  > | null>(null);

  const handleCloseModal = () => {
    setCurrentItem(null);
  };

  const handleClickInfoButton = (imagePath: string) => () => {
    const item = currentPageItems.find((item) => item.image.path === imagePath);
    if (item) {
      setCurrentItem(item);
    }
  };

  return (
    <>
      <div className={styles.base}>
        <ImageGridHeader title={title}>
          <ImageGridFilter>
            <ImageGridFilterItemList
              items={groupNames}
              onChange={setCurrentFilter}
              activeItem={currentFilter}
            />
          </ImageGridFilter>
        </ImageGridHeader>

        <ImageGridWrapper columnAmount={columnAmount}>
          {currentPageItems.map(({ image, imageNumber, title, id }) => {
            return (
              <ImageGridItem
                key={id}
                image={image}
                imageNumber={imageNumber}
                title={title}
                onClickInfoButton={handleClickInfoButton(image.path)}
              />
            );
          })}
        </ImageGridWrapper>

        {canGoNext && (
          <Button
            actionType={ClickableAction.Custom}
            onClick={next}
            className={styles.loadMoreButton}
          >
            Mehr anzeigen
          </Button>
        )}
      </div>

      <ModalPortal
        id={'image-grid'}
        onClose={handleCloseModal}
        show={!!currentItem}
        variant="shade"
      >
        <ModalContentFull>
          {currentItem && <ImageInfoModalContent {...currentItem} />}
        </ModalContentFull>
      </ModalPortal>
    </>
  );
};

export default ImageGrid;
