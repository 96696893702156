import useDistrictHeatData from '@/hooks/useDistrictHeatData/useDistrictHeatData';
import DistrictHeatMapLegend from '@uikit/components/DistrictHeatMapLegend/DistrictHeatMapLegend';
import DistrictHeatMapLegendItem from '@uikit/components/DistrictHeatMapLegend/DistrictHeatMapLegendItem/DistrictHeatMapLegendItem';
import DistrictHeatMapDetails from '../DistrictHeatMapDetails/DistrictHeatMapDetails';
import GoogleMap from '@uikit/components/GoogleMap/GoogleMap';
import { AdressComponent } from '@uikit/composites/Map/interfaces';
import SearchBox from '@uikit/composites/SearchBox/SearchBox';
import clsx from 'clsx';
import { Point } from 'points-cluster';
import React, { useEffect, useState } from 'react';
import MapMarker from '../MapMarker/MapMarker';
import {
  BOOTSTRAP_URL_KEYS,
  BOUNDS_GERMANY,
  CENTER_DEFAULT,
  INIT_MAP_STATE,
  OPTIONS,
  ZOOM_DEFAULT,
} from './consts';
import styles from './DistrictHeatMap.module.scss';
import {
  DistrictHeatMapProps,
  GeneralMapProps,
  GoogleMapState,
  Location,
} from './interfaces';

const DistrictHeatMap = ({
  districtHeatMapData,
  fullScreen = false,
}: DistrictHeatMapProps) => {
  const { data } = useDistrictHeatData(districtHeatMapData.path);
  const [mapProps, setMapProps] = useState<GeneralMapProps | undefined>();
  const [googleMapState, setGoogleMapState] =
    useState<GoogleMapState>(INIT_MAP_STATE);
  const [selectedDetail, setSelectedDetail] = useState<null | Location>(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [point, setPoint] = useState<Point | null>(null);
  const { mapApiLoaded, mapInstance, mapApi } = googleMapState;

  const apiHasLoaded = (mapData: any): void => {
    if (mapData && 'map' in mapData) {
      setGoogleMapState({
        mapApiLoaded: true,
        mapInstance: mapData.map,
        mapApi: mapData.maps,
      });
    }
  };

  const stylePolygons = React.useCallback(() => {
    mapInstance.data.setStyle(() => ({
      strokeWeight: '3',
      fillColor: '#c266c2',
      strokeColor: '#990099',
    }));
  }, [mapInstance]);

  const parseGoogleAddressComponents = (
    addressComponents: AdressComponent[]
  ) => {
    let components: Record<string, string> = {};
    addressComponents.forEach((addressComponent: AdressComponent) => {
      addressComponent.types.forEach((type: string) => {
        components[type] = addressComponent.long_name;
      });
    });

    return components;
  };

  const isWithinPolygon = (place: GoogleMapPlace) => {
    const { lat, lng } = place.geometry.location;
    const placeLatLng = new google.maps.LatLng(lat(), lng());
    let isWithin = false;

    mapInstance.data.forEach((feature: any) => {
      if (feature.getGeometry().getType() === 'Polygon') {
        const polygon = new google.maps.Polygon({
          paths: feature.getGeometry().getAt(0).getArray(),
        });

        if (google.maps.geometry.poly.containsLocation(placeLatLng, polygon)) {
          isWithin = true;
        }
      }
    });

    return isWithin;
  };

  const handleAddPlace = (place: GoogleMapPlace | null): void => {
    if (!place) {
      setPoint(null);
      setSelectedDetail(null);
      return;
    }

    if ('address_components' in place) {
      if (place.geometry) {
        if (place.geometry.viewport) {
          googleMapState.mapInstance.fitBounds(place.geometry.viewport);
        } else {
          googleMapState.mapInstance.setCenter(place.geometry.location);
          googleMapState.mapInstance.setZoom(15);
        }
      }
    }

    const { lat, lng } = place.geometry.location;
    setPoint({ lat: lat(), lng: lng() });

    const adressDetails: Record<string, string> = parseGoogleAddressComponents(
      place.address_components
    );

    setSelectedDetail({
      available: isWithinPolygon(place),
      zipCode: adressDetails.postal_code ?? null,
      city: adressDetails.locality ?? null,
      street: adressDetails.route ?? null,
      streetNumber: adressDetails.street_number ?? null,
    });
    setDetailsVisible(true);
  };

  const handleDetailsVisibility = (isVisible: boolean) => () => {
    setDetailsVisible(isVisible);
  };

  useEffect(() => {
    if (!mapApiLoaded || !data) {
      return;
    }

    mapInstance.data.addGeoJson(data);
    stylePolygons();
  }, [mapApiLoaded, mapInstance, stylePolygons, data]);

  useEffect(() => {
    if (!mapApiLoaded) {
      return;
    }
    stylePolygons();
  }, [mapApiLoaded, selectedDetail, stylePolygons]);

  return (
    <div className={styles.base}>
      {mapApiLoaded && (
        <div className={styles.searchBox}>
          <SearchBox
            map={mapInstance}
            mapApi={mapApi}
            bounds={BOUNDS_GERMANY}
            addPlace={handleAddPlace}
            placeholder="Nach Adresse suchen..."
          />
        </div>
      )}
      <div
        className={clsx(styles.mapContainer, {
          [styles.fullScreen]: fullScreen,
        })}
      >
        <GoogleMap
          defaultZoom={ZOOM_DEFAULT}
          zoom={mapProps?.zoom ?? ZOOM_DEFAULT}
          defaultCenter={CENTER_DEFAULT}
          center={mapProps?.center ?? CENTER_DEFAULT}
          bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
          onChange={setMapProps}
          onGoogleApiLoaded={apiHasLoaded}
          onDrag={handleDetailsVisibility(false)}
          options={OPTIONS}
          fullHeight
          yesIWantToUseGoogleMapApiInternals
        >
          {point && (
            <MapMarker
              lat={point.lat}
              lng={point.lng}
              color="violet"
              onClick={handleDetailsVisibility(true)}
            />
          )}
        </GoogleMap>
        <div className={styles.districtHeatMapLegend}>
          <DistrictHeatMapLegend>
            <DistrictHeatMapLegendItem>
              FW-Satzungsgebiet
            </DistrictHeatMapLegendItem>
          </DistrictHeatMapLegend>
        </div>
        {selectedDetail && detailsVisible && (
          <div className={styles.districtHeatMapDetails}>
            <DistrictHeatMapDetails address={selectedDetail} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DistrictHeatMap;
