import React, { useState } from 'react';
import styles from './JobApplySteps.module.scss';
import { steps } from './consts';
import JobApplyStep from '../JobApplyStep/JobApplyStep';
import JobApplyStepsNavigation from '@uikit/components/JobApplyStepsNavigation/JobApplyStepsNavigation';
import JobApplyStepTitle from '@uikit/components/JobApplyStep/JobApplyStepTitle/JobApplyStepTitle';
import JobApplyStepDescription from '@uikit/components/JobApplyStep/JobApplyStepDescription/JobApplyStepDescription';
import JobApplyStepHint from '@uikit/components/JobApplyStep/JobApplyStepHint/JobApplyStepHint';
import JobApplyStepBackground from '@uikit/components/JobApplyStep/JobApplyStepBackground/JobApplyStepBackground';
import { useDrag } from 'react-use-gesture';
import JobApplyStepsButton from './JobApplyStepsButton/JobApplyStepsButton';
import JobApplyStepsContent from './JobApplyStepsContent/JobApplyStepsContent';

const JobApplySteps = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleChangeStep = (index: number) => {
    if (index < 0 || index > steps.length - 1) {
      return;
    }

    setCurrentIndex(index);
  };

  const bind = useDrag(({ down, direction: [xDir] }) => {
    if (xDir > 0 && !down) {
      handleChangeStep(currentIndex - 1);
    } else if (xDir < 0 && !down) {
      handleChangeStep(currentIndex + 1);
    }
  });

  return (
    <div className={styles.base} {...bind()}>
      <JobApplyStepsButton
        direction="prev"
        stepAmount={steps.length}
        onClick={handleChangeStep}
        currentIndex={currentIndex}
      />
      <div className={styles.contentWrapper}>
        <JobApplyStepsContent offset={currentIndex * -100}>
          {steps.map((step) => (
            <JobApplyStep key={step.id} className={styles.step}>
              <JobApplyStepBackground variant={step.backgroundVariant} />
              <JobApplyStepTitle icon={step.icon}>
                {step.title}
              </JobApplyStepTitle>
              <JobApplyStepDescription>
                {step.description}
              </JobApplyStepDescription>
              {step.hint && (
                <JobApplyStepHint link={step.link}>
                  {step.hint}
                </JobApplyStepHint>
              )}
            </JobApplyStep>
          ))}
        </JobApplyStepsContent>
      </div>
      <JobApplyStepsButton
        direction="next"
        stepAmount={steps.length}
        onClick={handleChangeStep}
        currentIndex={currentIndex}
      />
      <JobApplyStepsNavigation
        activeIndex={currentIndex}
        stepAmount={steps.length}
        onStepClick={handleChangeStep}
      />
    </div>
  );
};

export default JobApplySteps;
