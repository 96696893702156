import clsx from 'clsx';
import Icon from '../../components/Icon/Icon';
import MapPin from '../MapPin/MapPin';
import styles from './MapMarker.module.scss';
import { MapMarkerProps } from './interfaces';

const MapMarker = ({
  hasAnimation,
  startAnimation,
  isCurrentMarker,
  size,
  color = 'surrogate',
  icon,
  onClick,
  className,
  pinSlot = <MapPin />,
}: MapMarkerProps) => {
  return (
    <div
      className={clsx(styles.base, className, styles[color], {
        [styles.active]: isCurrentMarker,
        [styles.small]: size === 'small',
        [styles.withAnimation]: startAnimation,
      })}
      onClick={onClick}
    >
      {icon ? (
        <Icon variant={icon} size="iconSize20" className={styles.icon} />
      ) : (
        <span className={styles.dot} />
      )}
      {pinSlot}
      {hasAnimation && <div className={styles.pulse} />}
    </div>
  );
};

export default MapMarker;
