import { useScroll } from '@react-spring/web';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import styles from './UmbrellaCampaignStoriesSection.module.scss';
import type { UmbrellaCampaignStoriesSectionProps } from './types';

const UmbrellaCampaignStoriesSection = ({
  children,
}: UmbrellaCampaignStoriesSectionProps) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null!);
  useScroll({
    container: wrapperRef,
    onChange: ({ value: { scrollXProgress } }) => {
      setScrollProgress(scrollXProgress);
    },
  });

  return (
    <div className={styles.base}>
      <div className={styles.wrapper}>
        <div
          className={clsx(styles.leftIndicator, {
            [styles.visible]: scrollProgress > 0.05,
          })}
        />
        <div
          className={clsx(styles.rightIndicator, {
            [styles.visible]: scrollProgress < 0.95,
          })}
        />
        <div className={styles.wrapperInner} ref={wrapperRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default UmbrellaCampaignStoriesSection;
