const convertObjectToSearchParams = (data: {
  [key: string]: string | number | boolean | undefined;
}): string => {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return '';
  }

  return (
    '?' +
    keys
      .map((key) => {
        const value = data[key];
        if (typeof value !== 'undefined') {
          return `${key}=${encodeURIComponent(value)}`;
        }
        return '';
      })
      .join('&')
  );
};

export default convertObjectToSearchParams;
