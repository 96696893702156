import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import React from 'react';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep1,
} from '../interfaces';
import schema from './RegisterChargingStationStep1.schema';

export const registerChargingStationInitalStateStep1: RegisterChargingStationStateStep1 =
  {
    locStreet: '',
    locStreetNumber: '',
    locZipCode: '',
    locCity: '',
  };

const RegisterChargingStationStep1 = ({
  onSubmit,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep1);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>

      <StepHeadline>
        Geben Sie hier die Adresse Ihrer (geplanten) Ladeeinrichtung ein.
      </StepHeadline>

      <FormRow>
        <FormField
          label={'PLZ'}
          name={'locZipCode'}
          value={formState['locZipCode']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('locZipCode')}
          twoFifthsWidth
        />
        <FormField
          label={'Ort'}
          name={'locCity'}
          value={formState['locCity']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('locCity')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Straße'}
          name={'locStreet'}
          value={formState['locStreet']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('locStreet')}
        />
        <FormField
          label={'Hausnummer'}
          name={'locStreetNumber'}
          value={formState['locStreetNumber']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('locStreetNumber')}
          twoFifthsWidth
        />
      </FormRow>
      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default RegisterChargingStationStep1;
