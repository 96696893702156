import clsx from 'clsx';
import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { FormFieldType } from '../FormField/consts';
import FormField from '../FormField/FormField';
import styles from './GuideBoxSearchBox.module.scss';
import type { GuideBoxSearchBoxProps } from './interfaces';

const GuideBoxSearchBox = ({
  onChange,
  label,
  resultsAmount,
}: GuideBoxSearchBoxProps) => {
  const props = useSpring({ value: resultsAmount, from: { value: 0 } });
  return (
    <>
      <FormField
        className={styles.base}
        type={FormFieldType.Text}
        onChange={({ target: { value } }) => onChange(value)}
        label={label}
        icon={'user/search'}
        iconSize="iconSize24"
        id="guides-search-box"
        autoComplete="off"
        data-test-component="guides-search-box"
      />
      <p
        className={clsx(styles.amount, {
          [styles.visible]: resultsAmount !== 0,
        })}
        data-test-component="guides-search-result-amount"
      >
        <animated.span>
          {/* @ts-ignore */}
          {props.value.to((v) => v.toFixed())}
        </animated.span>
        {resultsAmount !== 1 ? ' Ergebnisse' : ' Ergebnis'}
      </p>
    </>
  );
};

export default GuideBoxSearchBox;
