import React from 'react';
import type { DistrictHeatMapLegendItemProps } from './interfaces';
import styles from './DistrictHeatMapLegendItem.module.scss';

const DistrictHeatMapLegendItem = ({
  children,
}: DistrictHeatMapLegendItemProps) => {
  return <div className={styles.base}>{children}</div>;
};

export default DistrictHeatMapLegendItem;
