import ImageComposition from '../ImageComposition/ImageComposition';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import styles from './ImageCompositionMagnoliaWrapper.module.scss';
import type { ImageCompositionMagnoliaWrapperProps } from './interfaces';

const ImageCompositionMagnoliaWrapper = ({
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  showInfo = false,
}: ImageCompositionMagnoliaWrapperProps) => {
  return (
    <ImageComposition
      item1={<LegacyPicture asset={image1} className={styles.image} />}
      item2={<LegacyPicture asset={image2} className={styles.image} />}
      item3={<LegacyPicture asset={image3} className={styles.image} />}
      item4={<LegacyPicture asset={image4} className={styles.image} />}
      item5={<LegacyPicture asset={image5} className={styles.image} />}
      item6={<LegacyPicture asset={image6} className={styles.image} />}
      showInfo={showInfo}
    />
  );
};

export default ImageCompositionMagnoliaWrapper;
