import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import clsx from 'clsx';
import Clickable from '../Clickable/Clickable';
import Icon from '../Icon/Icon';
import styles from './HintBox.module.scss';
import type { HintBoxProps } from './interfaces';

const HintBox = ({
  titleText,
  hintText,
  linkText,
  variant = 'reduced',
  className,
  ...props
}: HintBoxProps) => {
  const clickableProps = extractClickableProps(props);

  return (
    <div
      className={clsx(styles.base, className, {
        [styles.warning]: variant === 'warning',
      })}
    >
      {variant === 'warning' && (
        <Icon
          variant="user/attention"
          isOutlined
          size="iconSize32"
          className={styles.icon}
        />
      )}
      {titleText && <div className={styles.title}>{titleText}</div>}
      <div>
        {hintText}
        {linkText && (
          <Clickable className={styles.link} {...clickableProps}>
            <u>{linkText}</u>
          </Clickable>
        )}
      </div>
    </div>
  );
};

export default HintBox;
