import React, { useEffect, useState } from 'react';
import { placeToCurrentLocation } from '../../composites/Map/placeToCurrentLocation';
import { FormFieldElement } from '../FormField/interfaces';
import FormFieldSuggestion from '../FormFieldSuggestion/FormFieldSuggestion';
import { SuggestionOption } from '../FormFieldSuggestion/interfaces';
import getLocationString from './getLocationString';
import { GoogleStreetSuggestionsProps, Suggest } from './interfaces';

/**
 * Old Component Name: SearchBoxInput but uses Google Street Suggestions
 */
const GoogleStreetSuggestions = ({
  initialValue = '',
  placeholder,
  map,
  mapApi,
  addPlace,
  handleBlur,
  formFieldId = 'map-search-box',
  formFieldIcon,
  formFieldPrefixIcon,
  errorMessage,
}: GoogleStreetSuggestionsProps) => {
  const [autocompleteInstance, setAutocompleteInstance] = useState<any>(null);
  const [placeInstance, setPlaceInstance] = useState<any>(null);

  const [suggestion, setSuggestion] = useState<SuggestionOption[]>([]);
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    if (mapApi && 'places' in mapApi) {
      const autocompleteRequest = new mapApi.places.AutocompleteService();
      const placeInstanceRequest = new mapApi.places.PlacesService(map);

      setAutocompleteInstance(autocompleteRequest);
      setPlaceInstance(placeInstanceRequest);
    }
  }, [mapApi, map]);

  const formatSuggestion = (suggest: Suggest[]): SuggestionOption[] => {
    if (!suggest) {
      return [];
    }

    return suggest.map((suggestionItem: Suggest) => {
      return {
        value: suggestionItem.id,
        label: suggestionItem.description,
        ...suggestionItem,
      };
    });
  };

  const handleOnChangeInput = ($event: React.ChangeEvent<FormFieldElement>) => {
    if ($event.target.value.length === 0) {
      addPlace(null);
    }

    setInputValue($event.target.value);

    // set to radius from 150km center is hannover
    autocompleteInstance?.getPlacePredictions(
      {
        input: $event.target.value,
        location: new mapApi.LatLng({ lat: 52.37052, lng: 9.73322 }),
        radius: 150000,
        types: ['geocode'],
        componentRestrictions: { country: 'de' },
      },
      (suggest: Suggest[]) => setSuggestion(formatSuggestion(suggest))
    );
  };

  const handleOnClickoption = ({ ...props }: { [key: string]: any }) => {
    placeInstance?.getDetails(
      {
        placeId: props['place_id'],
      },
      (place: GoogleMapPlace) => {
        setInputValue(getLocationString(placeToCurrentLocation(place)));
        addPlace(place);
        setSuggestion([]);
      }
    );
  };

  return (
    <FormFieldSuggestion
      name="search"
      inputId={formFieldId}
      label={placeholder}
      iconSize="iconSize24"
      prefixBoxIcon={formFieldPrefixIcon}
      icon={formFieldIcon}
      autoComplete="off"
      options={suggestion}
      value={inputValue}
      onSelectOption={handleOnClickoption}
      onChange={handleOnChangeInput}
      onBlur={handleBlur}
      nativeOptions={[]}
      disableNativeSelect
      errorMessage={errorMessage}
    />
  );
};

export default GoogleStreetSuggestions;
