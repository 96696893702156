import FormFieldRadioGroup from '../FormFieldRadioGroup/FormFieldRadioGroup';
import styles from './StageBoxCalculatorFormFieldRadioGroupWrapper.module.scss';
import type { StageBoxCalculatorFormFieldRadioGroupWrapperProps } from './interfaces';

const CUSTOMER_GROUP_ITEMS = [
  { value: 'private', label: 'Privat' },
  { value: 'business', label: 'Gewerblich' },
];

const StageBoxCalculatorFormFieldRadioGroupWrapper = ({
  value,
  color,
  onChange,
}: StageBoxCalculatorFormFieldRadioGroupWrapperProps) => {
  return (
    <div className={styles.base}>
      <FormFieldRadioGroup
        name="customerType"
        items={CUSTOMER_GROUP_ITEMS}
        color={color}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default StageBoxCalculatorFormFieldRadioGroupWrapper;
