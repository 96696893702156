import * as Yup from 'yup';
import { phone } from '../../../../schemas';

const kdKey = Yup.string()
  .min(10, 'Der AnmeldeCode muss aus 10 Ziffern bestehen.')
  .max(10, 'Der AnmeldeCode muss aus 10 Ziffern bestehen.')
  .required('Bitte geben Sie Ihren AnmeldeCode an.');

const schema = Yup.object({
  kdKey: kdKey,
  phone,
});

export default schema;
