import * as Yup from 'yup';

const heatPumpCalculatorSchema = Yup.object({
  gas: Yup.number()
    .required('Bitte einen Wert eingeben')
    .positive('Bitte eine gültige Zahl eingeben')
    .min(1000, 'Der Wert muss größer als 1000 kWh sein')
    .max(200000, 'Der Wert darf nicht größer als 200.000 kWh sein'),
  oil: Yup.number()
    .required('Bitte einen Wert eingeben')
    .positive('Bitte eine gültige Zahl eingeben')
    .min(100, 'Der Wert muss größer als 100 Liter sein')
    .max(20000, 'Der Wert darf nicht größer als 20.000 Liter sein'),
  heatingType: Yup.string()
    .required()
    .matches(/^(oil|gas)$/),
});

export default heatPumpCalculatorSchema;
