import { ClickableAction } from '../Clickable/consts';
import TeaserTileLarge from '../TeaserTileLarge/TeaserTileLarge';
import UmbrellaCampaignTeaserTileSection from '../UmbrellaCampaignTeaserTileSection/UmbrellaCampaignTeaserTileSection';
import { TEASER_TILES_DATA } from './consts';

const UmbrellaCampaignTeaserTileSectionWrapper = () => {
  return (
    <UmbrellaCampaignTeaserTileSection>
      {TEASER_TILES_DATA.map((teaser) => (
        <TeaserTileLarge
          key={teaser.id}
          actionType={ClickableAction.Modal}
          modallink={teaser.url}
          title={teaser.headline}
          icon={teaser.icon}
          buttonLabel="Mehr lesen"
          teaserAction="button"
        >
          {teaser.subtitle}
        </TeaserTileLarge>
      ))}
    </UmbrellaCampaignTeaserTileSection>
  );
};

export default UmbrellaCampaignTeaserTileSectionWrapper;
