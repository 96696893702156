import useAnimatedIcon from '@/hooks/useAnimatedIcon/useAnimatedIcon';
import clsx from 'clsx';
import AnimatedIcon from '../AnimatedIcon/AnimatedIcon';
import Icon from '../Icon/Icon';
import styles from './IconCard.module.scss';
import type { IconCardProps } from './interfaces';

const IconCard = ({
  icon,
  animatedIcon,
  label,
  onChange,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  className,
  ...radioProps
}: IconCardProps) => {
  const { handlerProps, isIconPlaying } = useAnimatedIcon();

  return (
    <label className={styles.wrapper}>
      <input
        className={styles.input}
        type="radio"
        onChange={onChange}
        {...handlerProps}
        {...radioProps}
      />
      <div className={clsx(styles.base, className)}>
        <Icon
          variant={icon}
          size="iconSize32"
          className={styles.icon}
          isOutlined
        />
        <AnimatedIcon
          variant={animatedIcon}
          playing={isIconPlaying}
          className={styles.animatedIcon}
          pauseMode="finishAndPause"
        />
        <span className={styles.label}>{label}</span>
      </div>
    </label>
  );
};

export default IconCard;
