import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import { FormFieldType } from '@uikit/components/FormField/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import { FormFieldDropdownOption } from '@uikit/components/FormFieldDropdown/interfaces';
import FormInfoBox from '@uikit/components/FormInfoBox/FormInfoBox';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import React from 'react';
import { connectionTypeOptions, meteringOptions } from '../config';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep3,
} from '../interfaces';
import schema from './RegisterChargingStationStep3.schema';

export const registerChargingStationInitalStateStep3: RegisterChargingStationStateStep3 =
  {
    acPorts11: '0',
    acPorts3: '0',
    acPortsGE22: '0',
    chargingStations: '0',
    connectionType: '',
    dcPortsGT100: '0',
    dcPortsLE100: '0',
    manufacturer: '',
    meterNumber: '',
    metering: '',
    netWithdrawalPower: '0',
  };

const RegisterChargingStationStep3 = ({
  onSubmit,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleChangeValue,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep3);

  const handleChangeOption =
    (fieldName: keyof typeof formState & string) =>
    (option: FormFieldDropdownOption | null) =>
      handleChangeValue(fieldName)(option?.value);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>
      <StepHeadline>Technische Daten</StepHeadline>
      <FormRow description="Nun benötigen wir von Ihnen noch paar technische Details zu Ihrer Ladeeinrichtung und Ihrem Messkonzept.">
        <FormField
          label={'Netzentnahmeleistung der Ladeeinrichtungen'}
          name={'netWithdrawalPower'}
          type={FormFieldType.Number}
          value={formState['netwithdrawalPower']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('netWithdrawalPower')}
          suffixBoxText="kW"
        />
      </FormRow>
      <FormInfoBox>
        Die Netzentnahmeleistung ist inklusive gegebenenfalls vorhandener
        Ladeeinrichtungen anzugeben.
      </FormInfoBox>
      <FormRow>
        <FormField
          label={'Anzahl aller Ladeeinrichtungen / Ladesäulen'}
          name={'chargingStations'}
          type={FormFieldType.Number}
          value={formState['chargingStations']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('chargingStations')}
        />
      </FormRow>
      <FormInfoBox>
        Bei Ladeeinrichtungen größer 11 kW senden Sie uns bitte die
        Konformitätserklärung zu:{' '}
        <a href="mailto:messwesen-ladeeinrichtung@enercity-netz.de">
          messwesen-ladeeinrichtung@enercity-netz.de
        </a>
      </FormInfoBox>
      <FormRow title="Ladepunkte Wechselstrom (AC)">
        <FormField
          label={'Anzahl Ladepunkte AC 3,7 kW'}
          name={'acPorts3'}
          type={FormFieldType.Number}
          value={formState['acPorts3']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('acPorts3')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Anzahl Ladepunkte AC 11 kW'}
          name={'acPorts11'}
          value={formState['acPorts11']}
          type={FormFieldType.Number}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('acPorts11')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Anzahl Ladepunkte AC >= 22 kW'}
          name={'acPortsGE22'}
          value={formState['acPortsGE22']}
          type={FormFieldType.Number}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('acPortsGE22')}
        />
      </FormRow>
      <FormRow title="Ladepunkte Gleichstrom (DC)">
        <FormField
          label={'Anzahl Ladepunkte DC <= 100 kW'}
          name={'dcPortsLE100'}
          value={formState['dcPortsLE100']}
          type={FormFieldType.Number}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('dcPortsLE100')}
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Anzahl Ladepunkte DC > 100 kW'}
          name={'dcPortsGT100'}
          value={formState['dcPortsGT100']}
          type={FormFieldType.Number}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('dcPortsGT100')}
        />
      </FormRow>
      <FormRow>
        <FormFieldDropdown
          label={'Anschluss der Ladeeinrichtung'}
          name={'connectionType'}
          value={formState['connectionType']}
          options={connectionTypeOptions}
          nativeOptions={connectionTypeOptions}
          onBlur={handleBlur}
          onChange={handleChangeOption('connectionType')}
          errorMessage={getErrorByFieldName('connectionType')}
          errorMessageHasRelativePosition
        />
      </FormRow>
      <FormRow>
        <FormField
          label={'Hersteller'}
          name={'manufacturer'}
          value={formState['manufacturer']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('manufacturer')}
        />
      </FormRow>
      <FormRow>
        <FormFieldDropdown
          label={'Messung'}
          name={'metering'}
          value={formState['metering']}
          options={meteringOptions}
          nativeOptions={meteringOptions}
          onBlur={handleBlur}
          onChange={(option) => handleChangeValue('metering')(option?.value)}
          errorMessage={getErrorByFieldName('metering')}
          errorMessageHasRelativePosition
        />
      </FormRow>
      {formState['metering'] === 'Neuer Zähler' && (
        <FormInfoBox>
          Die Messung der Ladeeinrichtung soll über einen separaten Zähler
          erfolgen. Bitte beachten Sie, dass der Einbau eines neuen Zählers
          durch einen Elektroinstallateur angefordert werden muss, wobei auch
          ein Lageplan mit eingezeichnetem Zählerplatz und Ladepunkt benötigt
          wird.
        </FormInfoBox>
      )}
      {formState['metering'] === 'Vorhandener Zähler' && (
        <FormInfoBox>
          Die Messung der Ladeeinrichtung soll über einen bereits vorhandenen
          Zähler erfolgen.
        </FormInfoBox>
      )}
      <FormRow>
        <FormField
          label={'Zählernummer bei vorhandener Messung'}
          name={'meterNumber'}
          value={formState['meterNumber']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('meterNumber')}
        />
      </FormRow>
      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default RegisterChargingStationStep3;
