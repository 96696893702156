import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import BoxWithArrow from '@uikit/components/BoxWithArrow/BoxWithArrow';
import { BoxArrowPosition } from '@uikit/components/BoxWithArrow/interfaces';
import ButtonLink from '@uikit/components/ButtonLink/ButtonLink';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import TrafficLight from '@uikit/components/TrafficLight/TrafficLight';
import clsx from 'clsx';
import styles from './GasConsumptionTrafficLight.module.scss';
import type {
  ContentMapper,
  GasConsumptionTrafficLightProps,
} from './interfaces';

const GasConsumptionTrafficLight = ({
  saving,
  status,
  linkText = 'Mehr über den Gas-Stand',
  linkintern,
  greenHeadline,
  greenDescription,
  yellowHeadline,
  yellowDescription,
  redHeadline,
  redDescription,
  showTextBox = true,
}: GasConsumptionTrafficLightProps) => {
  const isMobile = useIsMobile(BreakPoint.SM);
  const contentMapper: ContentMapper = {
    green: {
      headline: greenHeadline,
      description: greenDescription,
    },
    yellow: {
      headline: yellowHeadline,
      description: yellowDescription,
    },
    red: {
      headline: redHeadline,
      description: redDescription,
    },
  };
  const shouldShowLink = linkintern && linkText;
  const savingToNumber = Number(saving);
  const savingSubtext = savingToNumber > 0 ? 'Einsparung' : 'Mehrverbrauch';
  const savingAbsolute = Math.abs(savingToNumber);
  const [firstNumber, lastNumber] = savingAbsolute.toString().split('.');

  return (
    <div className={clsx(styles.base, { [styles.withSaving]: saving })}>
      <div className={styles.trafficLightOuterWrapper}>
        <div className={styles.trafficLightInnerWrapper}>
          <TrafficLight status={status} className={styles.trafficLight} />
          {saving && (
            <div className={clsx(styles.saving, styles[status])}>
              <span className={styles.savingAmount}>
                {firstNumber}
                {lastNumber && <span>,{lastNumber}</span>}
              </span>
              %<span className={styles.savingSubtext}>{savingSubtext}</span>
              <div className={styles.savingDivider} />
            </div>
          )}
        </div>
      </div>
      {showTextBox && (
        <BoxWithArrow
          ArrowPosition={
            isMobile ? BoxArrowPosition.topCenter : BoxArrowPosition.left
          }
          className={styles.infobox}
          reducedShadow
        >
          <div className={styles.headline}>
            {contentMapper[status].headline}
          </div>
          <p className={styles.description}>
            {contentMapper[status].description}
          </p>
          {shouldShowLink && (
            <ButtonLink
              linkintern={linkintern}
              actionType={ClickableAction.Linkintern}
              icon="action/arrow-right"
              iconPosition="left"
              disabled={false}
              className={styles.link}
            >
              {linkText}
            </ButtonLink>
          )}
        </BoxWithArrow>
      )}
    </div>
  );
};

export default GasConsumptionTrafficLight;
