import {
  email,
  firstName,
  lastName,
  message,
  phoneNotRequired,
} from '../../../schemas';

// eslint-disable-next-line
export default {
  firstName,
  lastName,
  mail: email,
  phone: phoneNotRequired,
  message: message(0, 2000),
};
