import styles from './ImageGridHeader.module.scss';
import type { ImageGridHeaderProps } from './interfaces';

const ImageGridHeader = ({ children, title }: ImageGridHeaderProps) => (
  <header className={styles.header}>
    {title && <div className={styles.title}>{title}</div>}

    {children}
  </header>
);

export default ImageGridHeader;
