import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import { animated, config, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Icon from '../Icon/Icon';
import styles from './Tabs.module.scss';
import type { TabsProps } from './interfaces';

const ANIMATION_DURATION = 250;

const Tabs = ({
  items,
  initialItem,
  alignNavItems = 'left',
  noShadow = false,
}: TabsProps) => {
  const navItems = useMemo(() => items.map((item) => item.navItem), [items]);
  const contents = useMemo(() => items.map((item) => item.content), [items]);
  const navItemRefs = useMemo(
    () => navItems.map(() => React.createRef<HTMLDivElement>()),
    [navItems]
  );
  const navRef = useRef<HTMLDivElement>(null);

  const initialTabIndex = useMemo(
    () =>
      initialItem && items.includes(initialItem)
        ? items.indexOf(initialItem)
        : 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const prevTabIndex = useRef(tabIndex);
  const [barWidth, setBarWidth] = useState(0);
  const isMobile = useIsMobile();
  const currentContent = contents.find(
    (content, index) => index === tabIndex && content
  );

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);

  const handleOnClickTabNavItem =
    (index: number) => ($event: React.MouseEvent<HTMLDivElement>) => {
      $event.preventDefault();
      $event.stopPropagation();
      prevTabIndex.current = tabIndex;
      setTabIndex(index);
    };

  useEffect(() => {
    // change bar width on tab change
    setBarWidth(navItemRefs[tabIndex].current?.offsetWidth ?? 0);
  }, [navItemRefs, tabIndex]);

  const getOffsetLeft = useCallback(
    (index?: number) => {
      if (index) return navItemRefs[index].current?.offsetLeft ?? 0;
      return navItemRefs[tabIndex].current?.offsetLeft ?? 0;
    },
    [navItemRefs, tabIndex]
  );

  useEffect(() => {
    // scroll to active tab nav item
    if (navRef.current && (!isMobile || initialItem)) {
      if (tabIndex === 0) {
        navRef.current.scrollLeft = 0;
      } else if (navItems.length - 1 === tabIndex) {
        navRef.current.scrollLeft = 999999999;
      } else {
        const currentRefWidth = navItemRefs[tabIndex].current?.offsetWidth ?? 0;
        navRef.current.scrollLeft = isMobile
          ? getOffsetLeft(tabIndex)
          : getOffsetLeft(tabIndex) - currentRefWidth * 0.25;
      }
    }
  }, [
    getOffsetLeft,
    isMobile,
    navItemRefs,
    navItems.length,
    tabIndex,
    initialItem,
  ]);

  const updateDisabledStateOfScrollButtons = useCallback(() => {
    setIsNextDisabled(
      navRef.current
        ? navRef.current?.scrollWidth <=
            Math.ceil(
              navRef.current?.scrollLeft +
                navRef.current?.getBoundingClientRect().width
            )
        : true
    );
    setIsPrevDisabled(navRef.current?.scrollLeft === 0);
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const listener = (_: Event) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // user scrolling ended
        updateDisabledStateOfScrollButtons();
        if (!isMobile) {
          return;
        }
        navItemRefs.forEach((el, index) => {
          if (navRef.current && el.current) {
            const { x: elX, width: elWidth } =
              el.current.getBoundingClientRect();
            const { x: containerX, width: containerWidth } =
              navRef.current.getBoundingClientRect();
            const containerXCenter = containerX + containerWidth / 2;
            if (
              // element is positioned crossing center of container
              elX <= containerXCenter &&
              elX + elWidth >= containerXCenter
            ) {
              prevTabIndex.current = tabIndex;
              setTabIndex(index);
            }
          }
        });
      }, 100);
    };

    const element = navRef.current;
    if (element) {
      element.addEventListener('scroll', listener);
      updateDisabledStateOfScrollButtons();
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', listener);
      }
    };
  }, [updateDisabledStateOfScrollButtons, navItemRefs, isMobile, tabIndex]);

  const transitions = useTransition(currentContent, {
    from: {
      transform: `translateX(${prevTabIndex.current < tabIndex ? '' : '-'}${
        isMobile ? 18 : 36
      }px)`,
      opacity: 0,
      position: 'relative',
    },
    enter: { transform: `translateX(0px)`, opacity: 1 },
    leave: {
      transform: `translateX(${prevTabIndex.current < tabIndex ? '-' : ''}${
        isMobile ? 18 : 36
      }px)`,
      opacity: 0,
      position: 'absolute',
    },
    config: {
      duration: ANIMATION_DURATION,
      ...config.gentle,
    },
    trail: ANIMATION_DURATION,
    order: ['leave', 'enter', 'update'],
  });

  const handlePrev = () => {
    if (isMobile) {
      prevTabIndex.current = tabIndex;
      setTabIndex((index) => (index <= 0 ? navItems.length - 1 : index - 1));
    }

    navRef.current?.scrollBy({
      left: Number(`-${navItemRefs[tabIndex - 1].current?.offsetWidth ?? 0}`),
      top: 0,
    });
  };

  const handleNext = () => {
    if (isMobile) {
      prevTabIndex.current = tabIndex;
      setTabIndex((index) => (index + 1) % navItems.length);
    }

    navRef.current?.scrollBy({
      left: navItemRefs[tabIndex + 1].current?.offsetWidth ?? 0,
      top: 0,
    });
  };

  const NavItems = navItems.map((props, index) => {
    return (
      <div
        ref={navItemRefs[index]}
        key={index}
        className={clsx(styles.tabNavItem, {
          [styles.tabNavItemSelected]: index === tabIndex,
        })}
        onClick={handleOnClickTabNavItem(index)}
      >
        {props.icon && (
          <div className={styles.tabNavItemIcon}>
            <Icon variant={props.icon} size="iconSize24" isOutlined={false} />
          </div>
        )}
        <div className={styles.tabNavItemHeadline}>{props.headline}</div>
      </div>
    );
  });

  return (
    <div className={clsx(styles.tabs, { [styles.tabsNoShadow]: noShadow })}>
      <div className={styles.tabNavWrapper}>
        <div
          className={clsx(styles.tabNav, {
            [styles.tabNavAlignRight]: alignNavItems === 'right',
          })}
          ref={navRef}
        >
          {NavItems}
          <div
            className={styles.tabBar}
            style={{
              left: `${getOffsetLeft()}px`,
              width: barWidth,
            }}
            role="none"
          />
        </div>
        <button
          type="button"
          aria-label="Zeige vorherigeren Tab"
          className={clsx(styles.navButton, styles.prev)}
          onClick={handlePrev}
          disabled={isPrevDisabled}
        >
          <Icon variant="action/chevron-left" size="iconSize24" isOutlined />
        </button>
        <button
          type="button"
          aria-label="Zeige nächsten Tab"
          className={clsx(styles.navButton, styles.next)}
          onClick={handleNext}
          disabled={isNextDisabled}
        >
          <Icon variant="action/chevron-right" size="iconSize24" isOutlined />
        </button>
      </div>
      <div className={styles.tabContent}>
        {transitions(({ position, ...props }, getCurrentContent) => (
          <animated.div
            style={{
              position: position as React.CSSProperties['position'] | any,
              ...props,
            }}
            className={styles.tabContentAnimationWrapper}
          >
            {getCurrentContent}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
