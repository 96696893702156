import IconButton from '@uikit/components/IconButton/IconButton';
import clsx from 'clsx';
import type { JobApplyStepsButtonProps } from './interfaces';
import styles from './JobApplyStepsButton.module.scss';

const JobApplyStepsButton = ({
  currentIndex,
  direction,
  stepAmount,
  onClick,
}: JobApplyStepsButtonProps) => {
  const handleClick = () => {
    const index = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;

    onClick(index);
  };

  const isActive =
    direction === 'prev' ? currentIndex !== 0 : currentIndex < stepAmount - 1;

  return (
    <div
      className={clsx(styles.base, styles[direction], {
        [styles.active]: isActive,
      })}
    >
      <IconButton
        variant={
          direction === 'prev' ? 'action/arrow-left' : 'action/arrow-right'
        }
        aria-label={direction === 'prev' ? 'Zurück' : 'Weiter'}
        onClick={handleClick}
      />
    </div>
  );
};

export default JobApplyStepsButton;
