import clsx from 'clsx';
import React from 'react';
import Box from '../Box/Box';
import type { ModalContentFullProps } from './interfaces';
import styles from './ModalContentFull.module.scss';

const ModalContentFull = ({
  className = '',
  children,
  background = 'white',
}: ModalContentFullProps) => {
  return (
    <div className={styles.wrapper}>
      <Box className={clsx(styles.content, styles[background], className)}>
        {children}
      </Box>
    </div>
  );
};

export default ModalContentFull;
