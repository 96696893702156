import useForm from '@/hooks/useForm/useForm';
import useSuGRZipCodeAddress from '@/hooks/useZipCodeAddress/useSuGRZipCodeAddress';
import { ChangeEvent, useEffect, useRef } from 'react';
import Box from '../Box/Box';
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '../ButtonSubmit/consts';
import FormField from '../FormField/FormField';
import { FormFieldType } from '../FormField/consts';
import { FormFieldElement } from '../FormField/interfaces';
import FormRow from '../FormRow/FormRow';
import styles from './ZipCodeFormBox.module.scss';
import { schema } from './schema';
import type { ZipCodeFormBoxProps, ZipCodeFormBoxState } from './types';

const CITY_PLACEHOLDER = 'Ort';

const initialState: ZipCodeFormBoxState = {
  zipCode: '',
  city: undefined,
  streetName: undefined,
  streetNumber: undefined,
};

const ZipCodeFormBox = ({
  onSubmit,
  onZipCodeChange,
  submitButtonState,
}: ZipCodeFormBoxProps) => {
  const {
    formState,
    handleChange,
    handleSubmit,
    getErrorByFieldName,
    handleBlur,
    isFormValid,
    isFieldValid,
    setFormState,
  } = useForm(
    ({ zipCode, city, streetName, streetNumber }) => {
      onSubmit({ zipCode, city, streetName, streetNumber });
    },
    schema,
    initialState
  );

  const { data, isSuccess, isError, error } = useSuGRZipCodeAddress(
    formState.zipCode
  );

  const isZipCodeValid = isFieldValid('zipCode', true);

  const useFormFnsRef = useRef({ setFormState, isFieldValid });
  useFormFnsRef.current = { setFormState, isFieldValid };

  const handleZipCodeChange = ($event: ChangeEvent<FormFieldElement>) => {
    if (
      submitButtonState === ButtonSubmitState.Fail &&
      isFieldValid('zipCode', true)
    ) {
      onZipCodeChange?.($event.currentTarget.value);
    }

    handleChange($event);
  };

  useEffect(() => {
    if (!isZipCodeValid || isError) {
      useFormFnsRef.current.setFormState(() => ({
        ...initialState,
        zipCode: formState.zipCode,
      }));
      return;
    }

    if (isZipCodeValid && data?.city) {
      useFormFnsRef.current.setFormState(() => ({
        zipCode: data?.zipCode,
        city: data?.city,
        streetName: data?.streetName,
        streetNumber: data?.streetNumber,
      }));
    }
  }, [data, formState.zipCode, isError, isSuccess, isZipCodeValid]);

  const zipCodeErrorMessage = error
    ? 'Keinen Ort gefunden'
    : getErrorByFieldName('zipCode');

  return (
    <form onSubmit={handleSubmit} className={styles.base}>
      <Box className={styles.box}>
        <FormRow bottomMarginSize={{ default: 1.5, sm: 2 }}>
          <h3 className={styles.headline}>Wie lautet Ihre Postleitzahl?</h3>
        </FormRow>
        <FormRow bottomMarginSize={{ default: 1.5, sm: 1.5 }}>
          <FormField
            name="zipCode"
            value={formState.zipCode ?? ''}
            type={FormFieldType.Number}
            label="Ihre Postleitzahl"
            autoComplete="postal-code"
            inputMode="numeric"
            maxLength={5}
            onChange={handleZipCodeChange}
            errorMessage={zipCodeErrorMessage}
            onBlur={handleBlur}
            dontAllowMoreThenMaxLength
            errorMessageHasRelativePosition
            suffixBoxText={formState.city || CITY_PLACEHOLDER}
            className={styles.zipCodeField}
          />
        </FormRow>

        <ButtonSubmit
          state={submitButtonState}
          inactive={!isFormValid}
          animate={isFormValid}
          stateMessages={{
            pending: 'Tarife laden…',
            fail: 'Keine Tarife gefunden',
            success: 'Erfolgreich versandt!',
          }}
        >
          Jetzt vergleichen
        </ButtonSubmit>
      </Box>
    </form>
  );
};

export default ZipCodeFormBox;
