export const ZOOM_DEFAULT = 12;

export const CENTER_DEFAULT = {
  lat: 52.37052,
  lng: 9.73322,
};

export const BOOTSTRAP_URL_KEYS = {
  libraries: ['places', 'geometry'],
  language: 'ge',
};

export const INIT_MAP_STATE = {
  mapApiLoaded: false,
  mapInstance: null,
  mapApi: null,
};

export const BOUNDS_GERMANY = {
  sw: { lat: 47.3024876979, lng: 5.98865807458 },
  ne: { lat: 54.983104153, lng: 15.0169958839 },
};

export const OPTIONS = {
  fullscreenControl: false,
  gestureHandling: 'cooperative',
  mapTypeControl: true,
  mapTypeControlOptions: { position: 3 },
  zoomControlOptions: { position: 7 },
  scrollwheel: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
  ],
};

export const FIBER_OPTIC_MAP_COLORS = ['green', 'orange', 'turquoise'] as const;

export const LOCATION_TYPES = [
  'Geplant',
  'Verfügbar',
  'Vorbereitet',
  'Aktiv',
] as const;

type LocationType = (typeof LOCATION_TYPES)[number];

export const LOCATION_LINK_TITLES: Record<LocationType, string> = {
  Verfügbar: 'htp-Tarif auswählen',
  Vorbereitet: 'htp-Tarif auswählen',
  Geplant: 'htp-Tarif auswählen',
  Aktiv: 'htp-Tarif auswählen',
};

export const LOCATION_LINKS: Record<LocationType, string> = {
  Verfügbar: 'https://enercity.online-auftrag.htp.net/?VPID=P2005459W',
  Vorbereitet: 'https://enercity.online-auftrag.htp.net/?VPID=P2005459W',
  Geplant: 'https://enercity.online-auftrag.htp.net/?VPID=P2005459W',
  Aktiv: 'https://enercity.online-auftrag.htp.net/?VPID=P2005459W',
};

type PolygonStyle = {
  fillColor: string;
  strokeColor: string;
  zIndex?: string;
};

export const POLYGON_STYLES: Record<LocationType, PolygonStyle> = {
  Verfügbar: {
    fillColor: '#E0F0D5',
    strokeColor: '#64B32C',
  },
  Geplant: {
    fillColor: '#CCEBED',
    strokeColor: '#009AA3',
  },
  Vorbereitet: {
    fillColor: '#FCE2CC',
    strokeColor: '#EE7100',
  },
  Aktiv: {
    fillColor: '#F4D0DF',
    strokeColor: '#C7125C',
    zIndex: '1',
  },
};

export const statusToColor: Record<
  LocationType,
  (typeof FIBER_OPTIC_MAP_COLORS)[number]
> = {
  Verfügbar: 'green',
  Vorbereitet: 'orange',
  Geplant: 'turquoise',
  Aktiv: 'green',
};
