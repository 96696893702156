import {
  toMediaQueries,
  toVariables,
} from '@uikit/helpers/responsiveProperties';
import styles from './Skeleton.module.scss';
import type { SkeletonProps } from './types';

const Skeleton = ({ width, height, borderRadius = 0.5 }: SkeletonProps) => {
  const vars = { width, height, borderRadius };

  return (
    <div
      className={styles.base}
      data-responsive-breakpoints={toMediaQueries(vars)}
      style={{
        ...toVariables(vars),
      }}
    />
  );
};

export default Skeleton;
