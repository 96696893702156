import { ReactElement } from 'react';
import { ItemToComponentProps } from '../../components/ItemToComponent/interfaces';

// eslint-disable-next-line
export default (
  // eslint-disable-next-line
  component: any
): component is ReactElement<ItemToComponentProps> => {
  return 'item' in component.props;
};
