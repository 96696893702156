import * as Yup from 'yup';
import {
  zipcode,
  streetNumber,
  city,
  firstName,
  lastName,
  email,
  phone,
  phoneNotRequired,
} from '../../../../schemas';

const schema = Yup.object({
  customerSalutation: Yup.string().required('Wir benötigen Ihre Anrede.'),
  customerTitle: Yup.string(),
  customerSurname: lastName,
  customerFirstName: firstName,
  customerZipCode: zipcode,
  customerCity: city,
  customerStreet: Yup.string().required('Wir benötigen Ihre Straße.'),
  customerStreetNumber: streetNumber,
  customerEmail: email,
  customerPhone: phone,
  customerMobile: phoneNotRequired,
});

export default schema;
