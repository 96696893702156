import {
  CarouselContext,
  useCarouselContext,
} from '@/contexts/CarouselContext';
import useCarousel from '@/hooks/useCarousel/useCarousel';
import clsx from 'clsx';
import React from 'react';
import Icon from '../Icon/Icon';
import type {
  CarouselButtonProps,
  CarouselItemProps,
  CarouselProps,
} from './Carousel.interfaces';
import styles from './Carousel.module.scss';

export const CarouselItem = ({
  children,
  index,
  className,
  ...props
}: CarouselItemProps) => {
  const { id, numberOfItems } = useCarouselContext();
  return (
    <div
      className={clsx(styles.carouselItem, className)}
      role="group"
      aria-label={`${index + 1} von ${numberOfItems}`}
      id={`carousel-${id}-item-${index + 1}`}
      {...props}
    >
      {children}
    </div>
  );
};

export const CarouselIndicators = () => {
  const {
    numberOfItems,
    activeIndex,
    id,
    goTo,
    canGoPrev,
    prev,
    canGoNext,
    next,
    itemsPerPage,
  } = useCarouselContext();
  const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);
  return (
    <div className={styles.indicatorsWrapper}>
      <div role="tablist" aria-label="Zitate" className={styles.indicators}>
        {[...Array(numberOfPages)].map((_item, index) => (
          <button
            type="button"
            className={styles.indicator}
            role="tab"
            aria-label={`Zitat ${index + 1}`}
            aria-selected={index === activeIndex}
            aria-controls={`carousel-${id}-item-${index + 1}`}
            tabIndex={index === activeIndex ? undefined : -1}
            onClick={() => goTo(index)}
            key={index}
            onKeyDown={(event) => {
              switch (event.key) {
                case 'ArrowLeft':
                  if (canGoPrev) {
                    prev();
                    (
                      (event.target as HTMLElement)
                        .previousElementSibling as HTMLElement
                    ).focus();
                  }
                  break;
                case 'ArrowRight':
                  if (canGoNext) {
                    next();
                    (
                      (event.target as HTMLElement)
                        .nextElementSibling as HTMLElement
                    ).focus();
                  }
                  break;
                default:
                  break;
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const CarouselButtonLeft = ({
  accessible,
  ...props
}: CarouselButtonProps) => {
  const { prev, canGoPrev, id } = useCarouselContext();
  const ariaProps = accessible
    ? {
        'aria-controls': `carousel-${id}-items`,
        'aria-label': 'Zurück',
      }
    : {
        'aria-hidden': true,
        tabIndex: -1,
      };
  return (
    <div
      {...props}
      className={clsx(styles.carouselButtonWrapper, props.className)}
    >
      <button
        {...ariaProps}
        onClick={prev}
        disabled={!canGoPrev}
        className={styles.carouselButton}
      >
        <Icon size="iconSize24" variant={'action/chevron-left'} />
      </button>
    </div>
  );
};

export const CarouselButtonRight = ({
  accessible,
  ...props
}: CarouselButtonProps) => {
  const { next, id } = useCarouselContext();
  const ariaProps = accessible
    ? {
        'aria-controls': `carousel-${id}-items`,
        'aria-label': 'Weiter',
      }
    : {
        'aria-hidden': true,
        tabIndex: -1,
      };

  return (
    <div
      {...props}
      className={clsx(styles.carouselButtonWrapper, props.className)}
    >
      <button {...ariaProps} onClick={next} className={styles.carouselButton}>
        <Icon size="iconSize24" variant={'action/chevron-right'} />
      </button>
    </div>
  );
};

export const CarouselItems = ({
  children,
  ...props
}: {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  const { carouselItemsRef, id, itemsPerPage } = useCarouselContext();
  return (
    <div
      {...props}
      id={`carousel-${id}-items`}
      aria-live="polite"
      className={clsx(styles.carouselItems, props.className)}
      ref={carouselItemsRef}
      data-items-per-page={itemsPerPage}
    >
      {children}
    </div>
  );
};

export const Carousel = ({
  children,
  itemsPerPage = 1,
  className,
  ...props
}: CarouselProps) => {
  const {
    activeIndex,
    canGoNext,
    canGoPrev,
    prev,
    next,
    carouselItemsRef,
    numberOfItems,
    id,
    goTo,
  } = useCarousel({ itemsPerPage });
  return (
    <section
      id={`carousel-${id}`}
      aria-roledescription="carousel"
      className={className}
      {...props}
    >
      <CarouselContext.Provider
        value={{
          activeIndex,
          canGoNext,
          canGoPrev,
          prev,
          next,
          carouselItemsRef,
          numberOfItems,
          id,
          goTo,
          itemsPerPage,
        }}
      >
        {children}
      </CarouselContext.Provider>
    </section>
  );
};
