import React from 'react';
import type { JobApplyStepsNavigationProps } from './interfaces';
import styles from './JobApplyStepsNavigation.module.scss';
import clsx from 'clsx';
import Icon from '../Icon/Icon';
import JobApplyStepsNavigationItem from './JobApplyStepsNavigationItem/JobApplyStepsNavigationItem';

const JobApplyStepsNavigation = ({
  stepAmount,
  activeIndex,
  onStepClick,
}: JobApplyStepsNavigationProps) => {
  const steps = [...Array(stepAmount)].map((_, idx) => `step-${idx}`);

  const handleClick = (index: number) => () => {
    onStepClick(index);
  };

  return (
    <div className={styles.base}>
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarFill}
          style={{
            transform: `scaleX(${
              (activeIndex * (100 / (stepAmount - 1))) / 100
            }`,
          }}
        />
      </div>
      <div
        className={clsx(styles.chevron, {
          [styles.active]: activeIndex !== 0,
        })}
        onClick={handleClick(activeIndex - 1)}
      >
        <Icon size="iconSize24" variant="action/chevron-left" />
      </div>
      {steps.map((step, idx) => (
        <JobApplyStepsNavigationItem
          key={step}
          index={idx}
          activeIndex={activeIndex}
          onClick={handleClick(idx)}
        />
      ))}
      <div
        className={clsx(styles.chevron, {
          [styles.active]: activeIndex !== stepAmount - 1,
        })}
        onClick={handleClick(activeIndex + 1)}
      >
        <Icon size="iconSize24" variant="action/chevron-right" />
      </div>
    </div>
  );
};

export default JobApplyStepsNavigation;
