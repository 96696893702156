import clsx from 'clsx';
import { statusComponent } from './consts';
import type { TrafficLightProps } from './interfaces';
import styles from './TrafficLight.module.scss';

const TrafficLight = ({ status, className }: TrafficLightProps) => {
  const Image = statusComponent[status];

  return (
    <div className={clsx(styles.base, className)}>
      <Image />
    </div>
  );
};

export default TrafficLight;
