import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import clsx from 'clsx';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Badge from '../Badge/Badge';
import { BadgeColor, BadgeTextType } from '../Badge/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import styles from './TeaserWithImage.module.scss';
import type { TeaserWithImageProps } from './interfaces';

const breakpointAspectRatioConfigs = [
  {
    breakpoint: BreakPoint.XSS,
    aspectRatio: '592:387',
    width: 343,
  },
  {
    breakpoint: BreakPoint.XS,
    aspectRatio: '592:387',
    width: 512,
  },
  {
    breakpoint: BreakPoint.SM,
    aspectRatio: '592:387',
    width: 542,
  },
  {
    breakpoint: BreakPoint.MD,
    aspectRatio: '592:387',
    width: 432,
  },
  {
    breakpoint: BreakPoint.LG,
    aspectRatio: '592:387',
    width: 516,
  },
  {
    breakpoint: BreakPoint.XL,
    aspectRatio: '592:387',
    width: 592,
  },
];

const TeaserWithImage = ({
  headline,
  description,
  buttonText,
  image,
  displayBadge = false,
  badgetext,
  badgeColor = BadgeColor.Blue,
  badgeTextType = BadgeTextType.Default,
  ...props
}: TeaserWithImageProps): JSX.Element => {
  const clickableProps = extractClickableProps(props);

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <LegacyPicture
          asset={image}
          fitCover
          breakpointAspectRatioConfigs={breakpointAspectRatioConfigs}
        />
      </div>
      <div className={clsx(styles.box, { [styles.withBadge]: displayBadge })}>
        {headline && <h3 className={styles.headline}>{headline}</h3>}
        {description && <p className={styles.description}>{description}</p>}
        {buttonText && (
          <ButtonLink {...clickableProps}>{buttonText}</ButtonLink>
        )}
        {displayBadge && (
          <div className={styles.badgeContainer}>
            <Badge badgeTextType={badgeTextType} color={badgeColor}>
              {badgetext}
            </Badge>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeaserWithImage;
