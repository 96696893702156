import React from 'react';
import styles from './MapLoadingOverlayProgress.module.scss';
import { animated, easings, useSpring } from '@react-spring/web';

const MapLoadingOverlayProgress = () => {
  const progressBarStyles = useSpring({
    from: {
      transform: 'translateX(calc(0vw - 100%))',
    },
    to: {
      transform: 'translateX(calc(100vw + 0%))',
    },
    config: { duration: 1750, easing: easings.easeInOutQuart },
    loop: true,
  });

  return (
    <div className={styles.base}>
      <animated.div style={progressBarStyles} className={styles.progressBar} />
    </div>
  );
};

export default MapLoadingOverlayProgress;
