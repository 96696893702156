import styles from './WaermewendeMapIframe.module.scss';

const WaermewendeMapIframe = () => {
  const mapkitUrl = process.env.MAPKIT_URL || 'https://karten.enercity.de';

  return (
    <iframe
      className={styles.base}
      src={`${mapkitUrl}/waermewende/embed`}
      title="Wärmewende karte"
      allow="accelerometer; geolocation; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

export default WaermewendeMapIframe;
