import useFrcCaptcha from '@/hooks/useFrcCaptcha/useFrcCaptcha';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import FormInfoBox from '@uikit/components/FormInfoBox/FormInfoBox';
import FormRow from '@uikit/components/FormRow/FormRow';
import { createContext, useMemo, useState } from 'react';
import submitContactForm from '../../../helpers/submitContactForm';
import { UseFormState } from '../../../hooks/useForm/interfaces';
import useForm from '../../../hooks/useForm/useForm';
import createInitialFormState, {
  InitialFormConfig,
} from './createInitialFormState';
import type { FormBuilderProps } from './interfaces';

export const FormBuilderContext = createContext<any>({});

const FormBuilder = ({
  onSuccess,
  mailTemplateId,
  recipientId,
  formName,
  children,
  buttonLabel,
  dataProcessing,
  doubleOptInActive,
  formApiTarget,
  formID,
}: FormBuilderProps) => {
  const [buttonState, setButtonState] = useState(ButtonSubmitState.Initial);

  const { initialState, validations, keysOrder } = useMemo<InitialFormConfig>(
    () => createInitialFormState(children),
    [children]
  );
  const { getSolution, FrcCaptchaComponent } = useFrcCaptcha();

  const handleSubmit = async (submittedFormState: UseFormState) => {
    setButtonState(ButtonSubmitState.Pending);
    try {
      const frcCaptchaSolution = await getSolution();
      const response = await submitContactForm(submittedFormState, {
        mailTemplateId,
        recipientId,
        formName,
        keysOrder,
        doubleOptInActive,
        formApiTarget,
        frcCaptchaSolution,
      });

      const json = await response.json();

      if (json.message === 'success') {
        setButtonState(ButtonSubmitState.Success);
        if (onSuccess) setTimeout(onSuccess, 1500);
      } else {
        throw Error;
      }
    } catch (error) {
      setButtonState(ButtonSubmitState.Fail);
      setTimeout(() => {
        setButtonState(ButtonSubmitState.Initial);
      }, 2000);
    }
  };

  const useFormProps = useForm(
    handleSubmit,
    validations,
    initialState,
    `${formID}-${formName}`
  );

  return (
    <FormBuilderContext.Provider value={useFormProps}>
      <form onSubmit={useFormProps.handleSubmit}>
        {children}
        <FormRow>
          <FormInfoBox>{dataProcessing}</FormInfoBox>
        </FormRow>
        <FormRow
          fullWidthChildren={false}
          bottomMarginSize={{ default: 1.125 }}
        >
          <ButtonSubmit
            inactive={!useFormProps.isFormValid}
            state={buttonState}
            id={'submit-contact-form'}
          >
            {buttonLabel}
          </ButtonSubmit>
        </FormRow>

        <FrcCaptchaComponent />
      </form>
    </FormBuilderContext.Provider>
  );
};

export default FormBuilder;
