import React from 'react';
import type { JobApplyStepsContentProps } from './interfaces';
import styles from './JobApplyStepsContent.module.scss';

const JobApplyStepsContent = ({
  children,
  offset,
}: JobApplyStepsContentProps) => {
  return (
    <div
      className={styles.base}
      style={{ transform: `translateX(${offset}%)` }}
    >
      {children}
    </div>
  );
};

export default JobApplyStepsContent;
