import clsx from 'clsx';
import React from 'react';
import Icon from '../Icon/Icon';
import { UnorderedListItemProps, FontSizeOptions } from './interfaces';
import styles from './UnorderedList.module.scss';

const UnorderedListItem = ({
  icon,
  children,
  inactive,
  fontSize,
  className,
}: UnorderedListItemProps) => {
  return (
    <li
      className={clsx(styles.item, className, {
        [styles.itemCheckmark]: icon === 'action/checkmark',
        [styles.itemClose]: icon === 'action/close',
        [styles.itemInactive]: inactive,
        [styles.fontSizeRegular]: fontSize === FontSizeOptions.Regular,
      })}
    >
      <Icon variant={icon} size="iconSize24" />
      {children}
    </li>
  );
};

export default UnorderedListItem;
