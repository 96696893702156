import clsx from 'clsx';
import React from 'react';
import Icon from '../Icon/Icon';
import styles from './JobToggleFavorite.module.scss';

const JobToggleFavorite = ({
  isFavorite,
  toggleFavorite,
  asNavItem,
}: {
  readonly isFavorite: boolean;
  readonly toggleFavorite: () => void;
  readonly asNavItem?: boolean;
}) => (
  <button
    aria-label={
      isFavorite ? 'Aus Favoriten entfernen' : 'Zu Favoriten hinzufügen'
    }
    onClick={toggleFavorite}
    aria-pressed={isFavorite}
    className={clsx({
      [styles.button]: !asNavItem,
      [styles.navItem]: asNavItem,
    })}
  >
    {isFavorite ? (
      <Icon
        variant={'action/heart-filled'}
        size="iconSize32"
        className={styles.icon}
      />
    ) : (
      <Icon
        variant={'action/heart'}
        size="iconSize32"
        className={styles.icon}
      />
    )}
    {asNavItem && (
      <span
        className={clsx(styles.label, {
          [styles.isFavorite]: isFavorite,
        })}
      >
        {isFavorite ? 'Gemerkt' : 'Merken'}
      </span>
    )}
  </button>
);
export default JobToggleFavorite;
