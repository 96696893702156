import {
  Feature,
  ValidFeatureProperties,
  WedemarkMapData,
} from '../hooks/types';

const extractMarkerData = (data: WedemarkMapData | null | undefined) => {
  if (!data) {
    return data;
  }

  const { features } = data;

  return features
    .filter((feature): feature is Feature<ValidFeatureProperties> =>
      Boolean(
        feature.properties['Lat-Y'] &&
          feature.properties['Log-X'] &&
          feature.properties['WEA_Anzahl'] !== null
      )
    )
    .map(({ properties }) => {
      return {
        lat: properties['Lat-Y'],
        lng: properties['Log-X'],
        numberOfPlants: properties['WEA_Anzahl'],
        category: properties['Kategorie'],
      };
    });
};

export default extractMarkerData;
