import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { useQuery } from 'react-query';

interface DistrictHeatData {
  type: string;
  name: string;
  features: google.maps.Data.Feature;
}

const getDistrictHeatData =
  (path: string) => async (): Promise<DistrictHeatData | null> => {
    try {
      const data = (await ajax.getJsonCached<DistrictHeatData>(path)) ?? null;

      return data;
    } catch (error) {
      logger.log('getDistrictHeatData', error);
      return null;
    }
  };

export default function useDistrictHeatData(mapDataPath: string) {
  return useQuery(
    ['districtHeatData', mapDataPath],
    getDistrictHeatData(mapDataPath),
    {
      placeholderData: null,
    }
  );
}
