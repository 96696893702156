import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useMeasure from '@/hooks/useMeasure/useMeasure';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import ImageMapBubble from '../ImageMapBubble/ImageMapBubble';
import ImageMapTooltip from '../ImageMapTooltip/ImageMapTooltip';
import { createImageURL } from '../LegacyPicture/image-transformer/helpers';
import styles from './ImageMap.module.scss';
import { percentage } from './helpers';
import type { ImageMapProps } from './interfaces';

const ImageMap = ({ bubbles, image }: ImageMapProps) => {
  //state
  const [base, setBase] = useState<HTMLElement>();
  const [baseWidth, setBaseWidth] = useState(0);
  const [sliderOffset, setSliderOffset] = useState(0);
  const [imageOffset, setImageOffset] = useState(0);

  // breakpoints
  const tooltipBreakpoint = useIsMobile(370);
  const isSm = useIsMobile(768);
  const isMd = useIsMobile(992);

  // refs
  const baseRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setBase(node);
    }
  }, []);
  const imageRef = useRef<HTMLImageElement>();
  const { width } = useMeasure(imageRef);

  // other variables
  const imageHeight = image.dimensions?.height ?? 1;
  const imageWidth = image.dimensions?.width ?? 1;
  const bubbleWidth = isMd ? 48 : 64;

  const bubbleCenter = bubbleWidth / 2;

  const visibleWidthPercentage = (imageHeight * 100) / imageWidth;
  const coveredWidthPercentage = 100 - visibleWidthPercentage;

  const offsetCorrection = (bubbleWidth * 100) / imageWidth;

  const offsetLimit = coveredWidthPercentage;

  // math
  const sliderIncreaseOffset = () => {
    if (sliderOffset < bubbles.length) {
      setSliderOffset(sliderOffset + 1);
    }
  };
  const sliderDecreaseOffset = () => {
    if (sliderOffset > 1) {
      setSliderOffset(sliderOffset - 1);
    }
  };
  const sliderOffsetByIndex = (index: number) => {
    setSliderOffset(index + 1);
  };

  // styles
  const tooltipSliderClasses = clsx(styles.tooltipSlider, {
    [styles.smallMobile]: tooltipBreakpoint,
  });

  const tooltipSliderAdditionalOffset =
    sliderOffset === 0
      ? '0px'
      : sliderOffset === 1
      ? '298px'
      : sliderOffset === bubbles.length
      ? '100vw - 16px'
      : '50vw + 141px';

  const tooltipSliderStyle: React.CSSProperties = {
    transform:
      'translateX(calc(-' +
      sliderOffset * 298 +
      'px + ' +
      tooltipSliderAdditionalOffset +
      '))',
    transition: 'transform 0.6s',
  };

  const tooltipSliderSmallMobileAdditionalOffset =
    sliderOffset === 0
      ? '0px'
      : sliderOffset === 1
      ? '266px'
      : sliderOffset === bubbles.length
      ? '100vw - 16px'
      : '50vw + 125px';

  const tooltipSliderSmallMobileStyle: React.CSSProperties = {
    transform:
      'translateX(calc(-' +
      sliderOffset * 266 +
      'px + ' +
      tooltipSliderSmallMobileAdditionalOffset +
      '))',
    transition: 'transform 0.6s',
  };

  const imageAreaStyle: React.CSSProperties = isSm
    ? {
        width: width,
        height: baseWidth,
        transform: 'translateX(-' + imageOffset + '%)',
        transition: 'transform 0.8s',
      }
    : { maxWidth: '100%' };

  // event handlers
  const handleOnMouseOver = (index: number) => {
    !isMd && sliderOffsetByIndex(index);
  };
  const handleOnMouseLeave = () => {
    !isMd && setSliderOffset(0);
  };

  const handleResize = useCallback(() => {
    setBaseWidth(base ? base.getBoundingClientRect().width : 0);
    if (
      base &&
      base.getBoundingClientRect().width < 957 &&
      sliderOffset === 0
    ) {
      setSliderOffset(1);
    }
  }, [base, sliderOffset]);

  const handleClickTile = (index: number) => {
    handleActiveImageArea(percentage(bubbles[index].x, imageWidth));
    sliderOffsetByIndex(index);
  };

  const bind = useDrag(({ down, direction: [xDir] }) => {
    if (xDir > 0 && !down) {
      sliderDecreaseOffset();
    } else if (xDir < 0 && !down) {
      sliderIncreaseOffset();
    }
  });

  const handleActiveImageArea = (x: number) => {
    if (x - visibleWidthPercentage / 2 < 0) {
      setImageOffset(0);
    } else if (x - visibleWidthPercentage / 2 > offsetLimit) {
      setImageOffset(offsetLimit);
    } else {
      setImageOffset(x - visibleWidthPercentage / 2 + offsetCorrection);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleResize();
      window.addEventListener('resize', handleResize, { passive: true });
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [handleResize]);

  useEffect(() => {
    if (sliderOffset - 1 >= 0 && sliderOffset <= bubbles.length - 1) {
      handleActiveImageArea(
        percentage(bubbles[sliderOffset - 1].x, imageWidth)
      );
    }
    // eslint-disable-next-line
  }, [handleActiveImageArea]);

  return (
    <div className={styles.base} ref={baseRef as React.Ref<HTMLDivElement>}>
      <div className={styles.imageArea} style={imageAreaStyle}>
        <img
          src={createImageURL(image.path)}
          className={styles.image}
          ref={imageRef as React.MutableRefObject<HTMLImageElement>}
          alt={image.name}
        />
        {bubbles.map((el) => (
          <div
            key={`${el.x}x${el.y}`}
            onMouseEnter={() => handleOnMouseOver(bubbles.indexOf(el))}
            onMouseLeave={handleOnMouseLeave}
            onClick={() => handleClickTile(bubbles.indexOf(el))}
          >
            <ImageMapBubble
              {...el}
              isInverted={false}
              isActive={sliderOffset - 1 === bubbles.indexOf(el)}
              x={percentage(el.x - bubbleCenter, imageWidth)}
              y={percentage(el.y - bubbleCenter, imageHeight)}
            />
          </div>
        ))}
      </div>
      {/* Tooltips for mobile: */}
      {isMd && (
        <div
          className={tooltipSliderClasses}
          {...bind()}
          style={
            tooltipBreakpoint
              ? tooltipSliderSmallMobileStyle
              : tooltipSliderStyle
          }
        >
          {bubbles.map((el) => (
            // eslint-disable-next-line
            <a
              key={bubbles.indexOf(el)}
              onClick={() => handleClickTile(bubbles.indexOf(el))}
            >
              <ImageMapTooltip
                {...el}
                isInverted={false}
                isActive={sliderOffset - 1 === bubbles.indexOf(el)}
                title={el.title}
                text={el.text}
              />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageMap;
