import React, { useCallback, useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside/useOnClickOutside';
import FormFieldSuggestion from '../FormFieldSuggestion/FormFieldSuggestion';
import { SuggestionOption } from '../FormFieldSuggestion/interfaces';
import type { FormFieldSelectProps } from './interfaces';

const toggle = (state: boolean) => !state;

const FormFieldSelect = ({
  onClickIcon,
  onSelectOption,
  options,
  onBlur,
  ...props
}: FormFieldSelectProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(!!onClickIcon);

  const handleOnClickOutside = () => {
    if (onBlur && isOpen && props.name) {
      onBlur(props.name);
    }

    setIsOpen(() => false);
  };

  const handleOnClickIcon = useCallback(() => {
    if (onClickIcon) {
      onClickIcon();
    } else {
      setIsOpen(toggle);
    }
  }, [onClickIcon, setIsOpen]);

  const handleOnSelectOption = useCallback(
    (option: SuggestionOption) => {
      if (onSelectOption) {
        onSelectOption(option);
      }

      setIsOpen(() => false);
    },
    [onSelectOption, setIsOpen]
  );

  const _options: SuggestionOption[] = onClickIcon
    ? options
    : isOpen
    ? options
    : [];

  useOnClickOutside(ref, handleOnClickOutside);

  return (
    <div ref={ref}>
      <FormFieldSuggestion
        {...props}
        onSelectOption={handleOnSelectOption}
        options={_options}
        selectOnly
        icon={_options.length ? 'action/chevron-up' : 'action/chevron-down'}
        onClickIcon={handleOnClickIcon}
      />
    </div>
  );
};

export default FormFieldSelect;
