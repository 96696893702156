export const ZOOM_DEFAULT = 12;

export const CENTER_DEFAULT = {
  lat: 52.37052,
  lng: 9.73322,
};

export const BOOTSTRAP_URL_KEYS = {
  libraries: ['places', 'geometry'],
  language: 'ge',
};

export const INIT_MAP_STATE = {
  mapApiLoaded: false,
  mapInstance: null,
  mapApi: null,
};

export const BOUNDS_GERMANY = {
  sw: { lat: 47.3024876979, lng: 5.98865807458 },
  ne: { lat: 54.983104153, lng: 15.0169958839 },
};

export const OPTIONS = {
  fullscreenControl: false,
  gestureHandling: 'cooperative',
  mapTypeControl: true,
  mapTypeControlOptions: { position: 3 },
  zoomControlOptions: { position: 7 },
  scrollwheel: false,
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
  ],
};
