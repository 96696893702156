import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormRow from '@uikit/components/FormRow/FormRow';
import { useContext } from 'react';
import { FormBuilderContext } from '../../FormBuilder';
import type { DesignationProps } from './interfaces';

const Designation = ({ fieldName }: DesignationProps) => {
  const context = useContext(FormBuilderContext);
  const name = `${fieldName} - Anrede`;

  return (
    <FormRow>
      <FormFieldRadioGroup
        items={[
          { label: 'Herr', value: 'Herr' },
          { label: 'Frau', value: 'Frau' },
          { label: 'Divers', value: 'Divers' },
        ]}
        name={name}
        onChange={context.handleChange}
        value={context.formState[name]}
        errorMessage={context.getErrorByFieldName(name)}
      />
    </FormRow>
  );
};

export default Designation;
