export const DEFAULT_PROPS = {
  title: 'Lassen Sie sich von uns beraten!',
  text: (
    <>
      Sie möchten unkompliziert eine erste Einschätzung der Kosten für den Kauf
      oder die Miete Ihrer Solar-Anlage einholen? In einem digitalen
      Beratungsgespräch helfen wir Ihnen gerne weiter.
      <br />
      Aktuell ist leider kein:e Berater:in verfügbar. Versuchen Sie es später
      noch mal oder teilen Sie uns Ihre Daten vorab schriftlich mit.
    </>
  ),
  buttonLabel: 'Beratung aktuell nicht verfügbar',
  bubbleMessage: (
    <>
      <strong>Hallo, ich bin Jan</strong> 👋 Ich freue mich, dass Sie Interesse
      an einer Solaranlage von enercity haben!
    </>
  ),
};

export const ACTIVE_OPERATOR_PROPS = {
  title: 'Lassen Sie sich jetzt von uns beraten!',
  text: (
    <>
      Sie haben Glück - Gerade ist ein:e Berater:in verfügbar! Sie möchten
      unkompliziert eine erste Einschätzung der Kosten für den Kauf oder die
      Miete Ihrer Solar-Anlage einholen?
      <br />
      In einem digitalen Beratungsgespräch helfen wir Ihnen gerne weiter.
    </>
  ),
  buttonLabel: 'Zum Beratungsgespräch',
  bubbleMessage: (
    <>
      <strong>Hallo, ich bin Jan</strong> 👋 Ich freue mich, dass Sie Interesse
      an einer Solaranlage von enercity haben! Wir können direkt mit der
      Konfiguration beginnen.
    </>
  ),
};
