import React from 'react';
import type { IframeSectionProps } from './interfaces';
import styles from './IframeSection.module.scss';

const IframeSection = ({ title, url }: IframeSectionProps) => {
  return (
    <div className={styles.base}>
      <iframe className={styles.iframe} title={title} src={url} />
    </div>
  );
};

export default IframeSection;
