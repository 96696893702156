const MapPin = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
    <defs>
      <linearGradient
        x1="-0.938042535%"
        y1="52.9833362%"
        x2="100%"
        y2="52.9833362%"
        id="linearGradient-enercity"
      >
        <stop stopColor="#A1028F" offset="0%" />
        <stop stopColor="#FD0004" offset="100%" />
      </linearGradient>

      <linearGradient
        id="linearGradient-netz"
        x1="-0.356456"
        y1="51.7262"
        x2="38"
        y2="51.7262"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#990099" />
        <stop offset="0.802305" stopColor="#FF0000" />
        <stop offset="1" stopColor="#EE7100" />
      </linearGradient>
    </defs>
    <g
      id="ICONS/Special/Vorfahren/pin-gradient"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M5.90132657,18.4945533 L5.90833082,18.5115728 C2.67935226,13.4874483 3.62996392,6.85285699 8.13962561,2.93872669 C12.6492873,-0.975403596 19.3481025,-0.980080478 23.8632194,2.92774903 C28.3785764,6.84930244 29.32217,13.4972773 26.0765616,18.5215843 L26.0235295,18.6016759 L25.780382,18.9350572 L17.1081228,31.4193359 C16.8539687,31.7837527 16.4377163,32 15.9944474,32 C15.5511786,32 15.1339256,31.7827515 14.8807721,31.4193359 L5.90132657,18.4945533 Z"
        id="Path"
        fill={'currentColor'}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default MapPin;
