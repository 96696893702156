import useDataLayer from '@/hooks/useDataLayer/useDataLayer';
import React, { useState } from 'react';
import AccordionItem from '../AccordionItem/AccordionItem';
import RichText from '../RichText/RichText';
import styles from './Accordion.module.scss';
import { AccordionListItemComponentProps, AccordionProps } from './interfaces';

export const AccordionListItem = ({
  description,
  headline,
  isActive,
  onClick,
  color = 'default',
}: AccordionListItemComponentProps) => {
  return (
    <AccordionItem
      onClick={onClick}
      headline={headline}
      isActive={isActive}
      color={color}
    >
      <RichText className={styles.description}>{description}</RichText>
    </AccordionItem>
  );
};

const Accordion = ({ items, color = 'default' }: AccordionProps) => {
  const [activeItem, setActiveItem] = useState<null | number>(null);
  const { pushDataLayerEvent } = useDataLayer();

  const handleOnClick = (idx: number, headline: string) => {
    if (idx !== activeItem) {
      setActiveItem(idx);
      pushDataLayerEvent({
        event: 'customClick3',
        customClickText: headline,
      });
    } else {
      setActiveItem(null);
    }
  };
  const itemList = items.map((item, idx: number) => {
    return (
      <AccordionListItem
        isActive={idx === activeItem}
        headline={item.headline}
        description={item.description}
        key={idx}
        onClick={() => handleOnClick(idx, item.headline)}
        color={color}
      />
    );
  });
  return <div>{itemList}</div>;
};

export const AccordionMagnolia = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div>{children}</div>;
};

export default Accordion;
