import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import FormRow from '@uikit/components/FormRow/FormRow';
import { useContext } from 'react';
import { FormBuilderContext } from '../../FormBuilder';
import type { CustomSelectProps } from './interfaces';

const CustomSelect = ({ options, label, isOptional }: CustomSelectProps) => {
  const { formState, getErrorByFieldName, setFieldValue, handleBlur } =
    useContext(FormBuilderContext);

  const suggestionOptions = options.map((val) => ({ value: val, label: val }));

  return (
    <FormRow>
      <FormFieldDropdown
        options={suggestionOptions}
        nativeOptions={suggestionOptions}
        onChange={(option) => setFieldValue(label, option?.value)}
        onBlur={handleBlur}
        name={label}
        value={formState[label]}
        label={isOptional ? `${label} (optional)` : label}
        errorMessage={getErrorByFieldName(label)}
      />
    </FormRow>
  );
};

export default CustomSelect;
