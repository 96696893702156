import * as Yup from 'yup';

import { zipcode, streetNumber, city } from '../../../../schemas';

const schema = Yup.object({
  locZipCode: zipcode,
  locStreetNumber: streetNumber,
  locStreet: Yup.string().required('Wir benötigen Ihre Straße'),
  locCity: city,
});

export default schema;
