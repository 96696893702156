export const PRODUCTS_FORM_INFOS = {
  power: {
    radioLabel: 'Strom',
    title: 'Person im Haushalt',
    titlePlural: 'Personen im Haushalt',
    label: 'Jahresverbrauch',
    editorTitle: 'Wie hoch ist Ihr jährlicher Stromverbrauch?',
    buttonText: 'Stromtarife berechnen',
    options: [
      { label: '1.590 kWh/Jahr (1 Person)', value: '1590' },
      { label: '2.275 kWh/Jahr (2 Personen)', value: '2275' },
      { label: '3.000 kWh/Jahr (3 Personen)', value: '3000' },
      { label: '3.940 kWh/Jahr (4 Personen)', value: '3940' },
    ],
    defaultValue: '2275',
    min: 1000,
    max: 200000,
    configuratorPath: '/sugr/strom',
  },
  gas: {
    radioLabel: 'Gas',
    buttonText: 'Gastarife berechnen',
    title: 'Wohnfläche im Haushalt',
    titlePlural: 'Wohnfläche im Haushalt',
    label: 'Jahresverbrauch',
    editorTitle: 'Wie hoch ist Ihr jährlicher Gasverbrauch?',
    options: [
      { label: '50㎡', value: '6250' },
      { label: '80㎡', value: '10000' },
      { label: '100㎡', value: '12500' },
      { label: '120㎡', value: '15000' },
    ],
    defaultValue: '10000',
    min: 1,
    max: 30000,
    configuratorPath: '/sugr/gas',
  },
  combined: {
    radioLabel: 'Strom & Gas',
    buttonText: 'Tarife berechnen',
    title: '',
    titlePlural: '',
    label: '',
    editorTitle: '',
    options: [],
    configuratorPath: '/sugr/strom-gas',
  },
};
