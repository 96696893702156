import useForm from '@/hooks/useForm/useForm';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import Container from '@uikit/components/Container/Container';
import FormField from '@uikit/components/FormField/FormField';
import { FormFieldType } from '@uikit/components/FormField/consts';
import FormRow from '@uikit/components/FormRow/FormRow';
import Headline from '@uikit/components/Headline/Headline';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep7,
} from '../interfaces';
import schema from './RegisterChargingStationStep7.schema';

const registerChargingStationInitalStateStep7: RegisterChargingStationStateStep7 =
  {
    comment: '',
  };

const RegisterChargingStationStep7 = ({
  onSubmit,
  submissionState = ButtonSubmitState.Initial,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep7);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>
      <StepHeadline>Abschluss</StepHeadline>
      <FormRow>
        <FormField
          type={FormFieldType.Textarea}
          label="Möchten Sie uns noch was mitteilen?"
          name="comment"
          value={formState['comment']}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('customerSurname')}
        />
      </FormRow>
      <br />
      <Container>
        <ButtonSubmit state={submissionState} inactive={!isFormValid}>
          Anmeldung abschicken
        </ButtonSubmit>
      </Container>
    </form>
  );
};

export default RegisterChargingStationStep7;
