import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import Icon from '../Icon/Icon';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import { BreakpointAspectRatioConfigs } from '../LegacyPicture/image-transformer/types';
import NewCarousel from '../NewCarousel/NewCarousel';
import styles from './ImageGallery.module.scss';
import type { ImageGalleryProps } from './interfaces';

const breakpointAspectRatioConfigs: BreakpointAspectRatioConfigs = [
  {
    breakpoint: BreakPoint.XSS,
    width: 375,
  },
  {
    breakpoint: BreakPoint.XS,
    width: 576,
  },
  {
    breakpoint: BreakPoint.SM,
    width: 720,
  },
  {
    breakpoint: BreakPoint.MD,
    width: 960,
  },
];

const ImageGallery = ({ contents }: ImageGalleryProps) => {
  const items =
    contents?.[0]?.contents?.map(({ props }) => ({
      original: props.image.path,
      props,
    })) ?? [];

  return (
    <NewCarousel>
      <NewCarousel.Controls />
      <NewCarousel.Slides>
        {items.map((item, index) => (
          <NewCarousel.Slide
            key={index}
            captionSlot={
              item.props.caption && (
                <div className={styles.caption}>
                  {item.props.caption}

                  {item.props.assetLink && (
                    <a
                      href={item.props.assetLink}
                      rel="noreferrer"
                      className={styles.buttonLink}
                      aria-label="Bild herunterladen"
                    >
                      <Icon variant={'action/download'} size="iconSize24" />
                    </a>
                  )}
                </div>
              )
            }
          >
            <LegacyPicture
              asset={item.props.image}
              className={styles.image}
              scaleToFit
              breakpointAspectRatioConfigs={breakpointAspectRatioConfigs}
            />
          </NewCarousel.Slide>
        ))}
      </NewCarousel.Slides>
      <NewCarousel.Indicators />
    </NewCarousel>
  );
};

export default ImageGallery;
