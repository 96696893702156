import React from 'react';
import type { IframeProps } from './interfaces';
import styles from './Iframe.module.scss';

const Iframe = ({
  title,
  loading = 'lazy',
  ...props
}: IframeProps) => {
  return (
    <div className={styles.base}>
      <iframe className={styles.iframe} title={title} {...props} />
    </div>
  );
};

export default Iframe;
