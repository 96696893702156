import { formatDate, shiftDate } from '../../../helpers/date';
import { UseFormState } from '../../../hooks/useForm/interfaces';
import { gvhFormKeysOrder, gvhFormRequestLabels } from './config';
import { GVHFormState } from './interfaces';

// eslint-disable-next-line
export const createGVHSubmitFormState = (state: UseFormState) =>
  Object.keys(state).reduce<Partial<UseFormState>>((acc, key) => {
    let keyByLabel = key;

    if (key in gvhFormRequestLabels) {
      keyByLabel = gvhFormRequestLabels[key as keyof GVHFormState];
    }
    const value = state[key];
    if (value !== '') {
      acc[keyByLabel] = value instanceof Date ? formatDate(value) : value;
    }

    return acc;
  }, {});

export const createGVHFormKeysOrder = (state: UseFormState): string[] => {
  return gvhFormKeysOrder.filter((key) => Object.keys(state).includes(key));
};

const shiftedDate = shiftDate(6 * 7);

export const minGVHStartDate = new Date(
  shiftedDate.getFullYear(),
  shiftedDate.getMonth() + Number(shiftedDate.getDay() > 1),
  1
);

export const maxGVHStartDate = new Date(
  shiftedDate.getFullYear() + 4,
  shiftedDate.getMonth(),
  1
);
