import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import React from 'react';
import { orderKfWConfirmationOptions } from '../config';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep6,
} from '../interfaces';
import schema from './RegisterChargingStationStep6.schema';

const registerChargingStationInitalStateStep6: RegisterChargingStationStateStep6 =
  {
    orderKfWConfirmation: '',
  };

const RegisterChargingStationStep6 = ({
  onSubmit,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep6);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>
      <StepHeadline>
        KfW-Förderprogramm 440 / 441 - Bestätigungsschreiben
      </StepHeadline>
      <FormRow description="Ich benötige ein Bestätigungsschreiben zur erfolgten Abstimmung, ob eine Vereinbarung zur Steuerung der Ladestation(en) im Sinne des §14a EnWG gefordert ist.">
        <FormFieldRadioGroup
          items={orderKfWConfirmationOptions}
          value={formState['orderKfWConfirmation'] || ''}
          name="orderKfWConfirmation"
          onChange={handleChange}
          errorMessage={getErrorByFieldName('orderKfWConfirmation')}
        />
      </FormRow>
      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default RegisterChargingStationStep6;
