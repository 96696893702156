import { useEffect, useMemo, useState } from 'react';
import GoogleMap from '../GoogleMap/GoogleMap';
import styles from './WedemarkMap.module.scss';
import {
  BOOTSTRAP_URL_KEYS,
  CATEGORY_TO_LOCATION_TYPE,
  CENTER_DEFAULT,
  INIT_MAP_STATE,
  LOCATION_TYPES,
  OPTIONS,
  POLYGON_STYLES,
  ZOOM_DEFAULT,
} from './consts';

import MapFilterMenuToggle from '../MapFilterMenuToggle/MapFilterMenuToggle';
import MapLoadingOverlay from '../MapLoadingOverlay/MapLoadingOverlay';
import WedemarkMapMarker from '../WedemarkMapMarker/WedemarkMapMarker';
import WedemarkMapMenu from '../WedemarkMapMenu/WedemarkMapMenu';
import extractMarkerData from './helpers/extractMarkerData';
import useWedemarkMapData from './hooks/useWedemarkMapData';
import type {
  GeneralMapProps,
  GoogleMapState,
  LocationCategory,
  LocationType,
} from './types';

const WedemarkMap = () => {
  const [mapProps, setMapProps] = useState<GeneralMapProps | undefined>();
  const [googleMapState, setGoogleMapState] =
    useState<GoogleMapState>(INIT_MAP_STATE);
  const { mapApiLoaded, mapInstance } = googleMapState;
  const { data, isLoading } = useWedemarkMapData();
  const [activeFilters, setActiveFilters] =
    useState<LocationType[]>(LOCATION_TYPES);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const markerData = useMemo(() => extractMarkerData(data), [data]);
  const filteredMarkerData = useMemo(
    () =>
      markerData?.filter((item) =>
        activeFilters.includes(CATEGORY_TO_LOCATION_TYPE[item.category])
      ),
    [activeFilters, markerData]
  );

  const apiHasLoaded = (mapData: any): void => {
    if (mapData?.map) {
      setGoogleMapState({
        mapApiLoaded: true,
        mapInstance: mapData.map,
        mapApi: mapData.maps,
      });
    }
  };

  const handleFilterToggle = (criteria: LocationType) => {
    if (activeFilters.includes(criteria)) {
      const arr = activeFilters.filter((item) => item !== criteria);
      setActiveFilters(arr);
    } else {
      setActiveFilters((prev) => [...prev, criteria]);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (!mapApiLoaded || !data) {
      return;
    }

    mapInstance.data.addGeoJson(data);
  }, [mapApiLoaded, mapInstance, data]);

  useEffect(() => {
    if (!mapApiLoaded || !data) {
      return;
    }

    mapInstance.data.setStyle((feature: any) => {
      const category: LocationCategory = feature.getProperty('Kategorie');
      const locationType = CATEGORY_TO_LOCATION_TYPE[category];
      const isHidden = !activeFilters.includes(locationType);

      return {
        strokeWeight: '3',
        ...(isHidden ? POLYGON_STYLES['hidden'] : POLYGON_STYLES[locationType]),
      };
    });
  }, [mapInstance, activeFilters, mapApiLoaded, data]);

  return (
    <div className={styles.base}>
      <MapLoadingOverlay isVisible={isLoading} />
      <MapFilterMenuToggle
        activeFiltersAmount={activeFilters.length}
        onClick={handleMenuToggle}
        active={isMenuExpanded}
        className={styles.filterToggle}
      />
      <WedemarkMapMenu
        activeFilters={activeFilters}
        handleFilterToggle={handleFilterToggle}
        expanded={isMenuExpanded}
        handleSetExpanded={handleMenuToggle}
      />
      <div className={styles.mapContainer}>
        <GoogleMap
          defaultZoom={ZOOM_DEFAULT}
          zoom={mapProps?.zoom ?? ZOOM_DEFAULT}
          defaultCenter={CENTER_DEFAULT}
          center={mapProps?.center ?? CENTER_DEFAULT}
          bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
          onChange={setMapProps}
          onGoogleApiLoaded={apiHasLoaded}
          options={OPTIONS}
          fullHeight
          yesIWantToUseGoogleMapApiInternals
        >
          {filteredMarkerData?.map(({ lat, lng, numberOfPlants, category }) => (
            <WedemarkMapMarker
              key={`${lat}-${lng}`}
              lat={lat}
              lng={lng}
              variant={category === 'Bestandspark' ? 'existing' : 'planned'}
              numberOfPlants={numberOfPlants}
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default WedemarkMap;
