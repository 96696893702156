import React from 'react';
import { UseShowMore } from './interfaces';

const useShowMore: UseShowMore = (items, { itemsPerPage, initialPage = 1 }) => {
  const itemsRef = React.useRef(items);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(initialPage);
  const maxPages = Math.ceil(items.length / itemsPerPage);
  const canGoNext = currentPageNumber < maxPages;
  const currentPageItems = items.slice(0, currentPageNumber * itemsPerPage);

  const next = () => {
    if (canGoNext) {
      setCurrentPageNumber((oldCurrentPage) => oldCurrentPage + 1);
    }
  };

  React.useEffect(() => {
    /* Reset page number if items has changed or initialPage */
    if (JSON.stringify(itemsRef.current) !== JSON.stringify(items)) {
      setCurrentPageNumber(() => initialPage);
      itemsRef.current = items;
    }
  }, [initialPage, items]);

  return {
    canGoNext,
    currentPageNumber,
    currentPageItems,
    next,
  };
};

export default useShowMore;
