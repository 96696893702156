import React, { useContext, useEffect } from 'react';
import FormField from '@uikit/components/FormField/FormField';
import FormRow from '@uikit/components/FormRow/FormRow';
import type { CustomTextProps } from './interfaces';
import { FormBuilderContext } from '../../FormBuilder';
import { FormFieldType } from '@uikit/components/FormField/consts';

const CustomText = ({
  isOptional,
  label,
  type = FormFieldType.Text,
  placeholder,
  maxLength,
  defaultValue,
}: CustomTextProps) => {
  const {
    handleChange,
    handleBlur,
    formState,
    getErrorByFieldName,
    setFieldValue,
  } = useContext(FormBuilderContext);

  useEffect(() => {
    setFieldValue(label, defaultValue ?? formState[label] ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, label]);

  if (type === FormFieldType.Date) {
    placeholder = 'TT.MM.JJJJ';
    type = FormFieldType.Text;
  }

  return (
    <FormRow>
      <FormField
        maxLength={maxLength}
        type={type}
        placeholder={placeholder}
        label={isOptional ? `${label} (optional)` : label}
        name={label}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMessage={getErrorByFieldName(label)}
        value={formState[label]}
      />
    </FormRow>
  );
};

export default CustomText;
