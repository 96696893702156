import { logger } from '@/helpers/logger';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import React from 'react';
import { animated, useTransition } from '@react-spring/web';
import submitContactForm from '../../../helpers/submitContactForm';
import { UseFormState } from '../../../hooks/useForm/interfaces';
import useScrollToRef from '../../../hooks/useScrollToRef/useScrollToRef';
import { FormComponentProps } from '../interfaces';
import { createGVHFormKeysOrder, createGVHSubmitFormState } from './helpers';
import GVHFormStep1 from './steps/GVHFormStep1';
import GVHFormStep2 from './steps/GVHFormStep2';

const GVHFormSteps = [GVHFormStep1, GVHFormStep2];

const GVHForm = ({
  onSuccess,
  buttonLabel = 'Bestellung abschicken',
  ...options
}: FormComponentProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [formState, setFormState] = React.useState<UseFormState>({});
  const [submissionState, setSubmissionState] = React.useState(
    ButtonSubmitState.Initial
  );
  const scrollTo = useScrollToRef(ref);

  const callbackOnSubmit = React.useCallback(
    async (formState: UseFormState) => {
      const state = createGVHSubmitFormState(formState);
      setSubmissionState(ButtonSubmitState.Pending);

      try {
        const response = await submitContactForm(state, {
          ...options,
          keysOrder: createGVHFormKeysOrder(state),
        });
        const json = await response.json();

        if (json.message === 'success') {
          setSubmissionState(ButtonSubmitState.Success);
          onSuccess && setTimeout(onSuccess, 1500);
        } else {
          throw Error(json);
        }
      } catch (error) {
        setSubmissionState(ButtonSubmitState.Fail);
        logger.error(error);
        setTimeout(() => {
          setSubmissionState(ButtonSubmitState.Initial);
        }, 2000);
      }
    },
    [onSuccess, options]
  );

  const isLastStep = React.useMemo(
    () => GVHFormSteps.length - 1 === currentStepIndex,
    [currentStepIndex]
  );

  const handleOnSubmit = async (stepState: UseFormState) => {
    const newFormState = { ...formState, ...stepState };
    if (isLastStep) {
      await callbackOnSubmit(newFormState);
    } else {
      setCurrentStepIndex((prevState) => prevState + 1);
      setFormState(() => newFormState);
      scrollTo();
    }
  };

  const step = React.useMemo(
    () => GVHFormSteps[currentStepIndex],
    [currentStepIndex]
  );

  const transitions = useTransition(step, {
    from: {
      position: 'absolute',
      opacity: 0,
    },
    enter: { position: 'relative', opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  return (
    <div ref={ref}>
      {transitions(({ opacity, position }, Step) => (
        <animated.div
          style={{
            position: position as React.CSSProperties['position'] | any,
            opacity,
          }}
        >
          <Step
            onSubmit={handleOnSubmit}
            submissionState={submissionState}
            buttonLabel={buttonLabel}
          />
        </animated.div>
      ))}
    </div>
  );
};

export default GVHForm;
