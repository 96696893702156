import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import clsx from 'clsx';
import Clickable from '../Clickable/Clickable';
import Icon from '../Icon/Icon';
import styles from './ButtonCard.module.scss';
import type { ButtonCardProps } from './interfaces';

const ButtonCard = ({
  text,
  subText,
  icon,
  inactive = false,
  onClick,
  ...props
}: ButtonCardProps) => {
  const clickableProps = extractClickableProps(props);

  return (
    <Clickable
      className={clsx(styles.base, {
        [styles.inactive]: inactive || props.disabled,
      })}
      asButton
      {...clickableProps}
    >
      <div className={styles.textWrapper}>
        <div className={styles.baseText}>{text}&nbsp;</div>
        {subText && <div className={styles.subText}>{subText}</div>}
      </div>
      <Icon
        variant={icon}
        size="iconSize24"
        isOutlined
        className={styles.icon}
      />
    </Clickable>
  );
};

export default ButtonCard;
