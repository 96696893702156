import { FormFieldType } from '@uikit/components/FormField/consts';
import React from 'react';
import * as Yup from 'yup';
import {
  FormValidations,
  UseFormState,
} from '../../../hooks/useForm/interfaces';
import type { PageContentItem } from '../../../models/page';
import {
  agreement,
  city,
  designation,
  email,
  emailNotRequired,
  firstName,
  lastName,
  phone,
  phoneNotRequired,
  street,
  streetNumber,
  zipcode,
} from '../../../schemas';
import { ContactFieldVisibility } from './parts/Contact/consts';

export interface FieldConfig {
  fields: { [key: string]: string };
  validations?: FormValidations;
}

const componentNameFieldsConfigFactoryMapper: {
  [key: string]: (item: PageContentItem) => FieldConfig;
} = {
  designation: (item) => ({
    fields: { [`${item.props?.fieldName} - Anrede`]: '' },
    validations: {
      ...(item.props?.isOptional
        ? undefined
        : {
            [`${item.props?.fieldName} - Anrede`]: designation,
          }),
    },
  }),
  title: (item) => ({
    fields: { [`${item.props?.fieldName} - Titel`]: '' },
    validations: undefined,
  }),
  'full-name': (item) => ({
    fields: {
      [`${item.props?.fieldName} - Vorname`]: '',
      [`${item.props?.fieldName} - Nachname`]: '',
    },
    validations: {
      ...(item.props?.isOptional
        ? undefined
        : {
            [`${item.props?.fieldName} - Vorname`]: firstName,
            [`${item.props?.fieldName} - Nachname`]: lastName,
          }),
    },
  }),
  address: (item) => ({
    fields: {
      [`${item.props?.fieldName} - Ort`]: '',
      [`${item.props?.fieldName} - PLZ`]: '',
      [`${item.props?.fieldName} - Straße`]: '',
      [`${item.props?.fieldName} - Hausnummer`]: '',
    },
    validations: {
      ...(item.props?.isOptional
        ? undefined
        : {
            [`${item.props?.fieldName} - Ort`]: city,
            [`${item.props?.fieldName} - PLZ`]: zipcode,
            [`${item.props?.fieldName} - Straße`]: street,
            [`${item.props?.fieldName} - Hausnummer`]: streetNumber,
          }),
    },
  }),
  contact: (item) => ({
    fields: {
      ...(item.props?.fieldVisibility !== ContactFieldVisibility.PhoneOnly
        ? { [`${item.props?.fieldName} - E-Mail`]: '' }
        : undefined),
      ...(item.props?.fieldVisibility !== ContactFieldVisibility.EmailOnly
        ? { [`${item.props?.fieldName} - Telefon`]: '' }
        : undefined),
    },
    validations: {
      ...(item.props?.fieldVisibility !== ContactFieldVisibility.PhoneOnly
        ? item.props?.isMailOptional
          ? { [`${item.props?.fieldName} - E-Mail`]: emailNotRequired }
          : { [`${item.props?.fieldName} - E-Mail`]: email }
        : undefined),
      ...(item.props?.fieldVisibility !== ContactFieldVisibility.EmailOnly
        ? {
            [`${item.props?.fieldName} - Telefon`]: item.props?.isPhoneOptional
              ? phoneNotRequired
              : phone,
          }
        : undefined),
    },
  }),
  'custom-text': (item) => {
    let customTextFieldValidation: Yup.AnySchema = Yup.string();

    if (!item.props?.isOptional) {
      customTextFieldValidation = Yup.string().required(
        'Bitte füllen Sie dieses Feld aus.'
      );
    } else {
      customTextFieldValidation = Yup.string().notRequired();
    }

    if (item.props?.type === FormFieldType.Number) {
      const onlyNumbersSchema = (
        item.props?.isOptional
          ? Yup.string().notRequired()
          : Yup.string().required('Bitte füllen Sie dieses Feld aus.')
      ).matches(/^\d{1,}$/, {
        message: 'Bitte geben Sie nur Zahlen ein.',
        excludeEmptyString: true,
      });

      customTextFieldValidation = onlyNumbersSchema;
    }

    if (item.props?.type === FormFieldType.Date) {
      const dateSchema = Yup.string().matches(
        /^[0-3][0-9]\.[01][0-9]\.[12][0-9][0-9][0-9]$/,
        {
          message: 'Das Datum muss im Format TT.MM.JJJJ angegeben werden.',
          excludeEmptyString: true,
        }
      );

      customTextFieldValidation = customTextFieldValidation.concat(dateSchema);
    }

    if (item.props?.maxLength) {
      const maxLengthSchema = Yup.string().max(
        item.props?.maxLength,
        'Sie haben die maximale Länge überschritten.'
      );

      customTextFieldValidation =
        customTextFieldValidation.concat(maxLengthSchema);
    }

    return {
      fields: { [item.props?.label]: item.props?.defaultValue ?? '' },
      validations: {
        [item.props?.label]: customTextFieldValidation,
      },
    };
  },
  'custom-checkbox': (item) => ({
    fields: { [item.props?.label]: item.props?.defaultValue ?? false },
    validations: {
      ...(item.props?.isOptional
        ? undefined
        : {
            [item.props?.label]: agreement,
          }),
    },
  }),
  'custom-select': (item) => ({
    fields: { [item.props?.label]: '' },
    validations: {
      ...(item.props?.isOptional
        ? undefined
        : {
            [item.props?.label]: Yup.string().required(
              'Bitte wählen Sie eine Option.'
            ),
          }),
    },
  }),
  'custom-radio': (item) => ({
    fields: { [item.props?.label]: '' },
    validations: {
      ...(item.props?.isOptional
        ? undefined
        : {
            [item.props?.label]: Yup.string().required(
              'Bitte wählen Sie eine Option.'
            ),
          }),
    },
  }),
  'info-title': (item) => ({
    fields: { [`__info-title-${item.id}`]: item.props.text },
  }),
  'info-text': (item) => ({
    fields: { [`__info-text-${item.id}`]: item.props.text },
  }),
};

export interface InitialFormConfig {
  initialState: UseFormState;
  validations: FormValidations;
  keysOrder: string[];
}

const createInitialFormState = (
  children: React.ReactNode
): InitialFormConfig => {
  if (typeof window === 'undefined' || window.USE_MAGNOLIA_RENDERER) {
    return { initialState: {}, validations: {}, keysOrder: [] };
  }

  const items = (
    React.Children.toArray(children).filter((child) => {
      return (
        // filter out magnolia comments
        typeof child !== 'string'
      );
    }) as { props: { item: PageContentItem } }[]
  ).map((child) => child.props.item);

  const fields = items.map((item) =>
    componentNameFieldsConfigFactoryMapper[item.componentName](item)
  );

  const acc = fields.reduce<InitialFormConfig>(
    (pv, cv) => {
      return {
        initialState: { ...pv.initialState, ...cv.fields },
        validations: { ...pv.validations, ...cv.validations },
        keysOrder: [...pv.keysOrder, ...Object.keys(cv.fields)],
      };
    },
    { initialState: {}, validations: {}, keysOrder: [] }
  );

  return {
    initialState: { ...acc.initialState },
    validations: { ...acc.validations },
    keysOrder: [...acc.keysOrder],
  };
};

export default createInitialFormState;
