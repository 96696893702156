import { JobApplyStep } from './interfaces';

export const steps: JobApplyStep[] = [
  {
    id: 1,
    title: 'Traumjob gefunden?',
    icon: 'user/heart',
    description:
      'Dann kannst du deine Bewerbung ganz einfach über unser Bewerbungsportal einreichen. Du erhältst daraufhin eine Bestätigungsmail.',
    hint: 'Manchmal verirren sich automatisch generierte Mails im Spam-Ordner',
    backgroundVariant: 'rocket',
  },
  {
    id: 2,
    title: 'Wann höre ich von euch?',
    icon: 'user/contact-person',
    description:
      'Wir wollen dich nicht zappeln lassen - der Fach- und Personalbereich prüfen deine Bewerbungsunterlagen so schnell wie möglich. Bei vielen Interessent:innen kann das mal bis zu vier Wochen dauern.',
    backgroundVariant: 'calendar',
  },
  {
    id: 3,
    title: 'Wir wollen dich richtig kennenlernen!',
    icon: 'user/checklist',
    description:
      'Bei dualen Student:innen und Azubis führen wir einen Einstellungstest durch. Bei Trainees sowie bei Fach- und Führungskräften aus bestimmten Bereichen legen wir Wert auf einen Persönlichkeitstest. Wir geben dir rechtzeitig Bescheid!',
    backgroundVariant: 'search',
  },
  {
    id: 4,
    title: 'Wie erfahre ich, ob ich zum Gespräch eingeladen bin?',
    icon: 'user/speechbubble',
    description:
      'Wenn wir uns telefonisch bei dir melden, hast du auf ganzer Linie überzeugt! Du erhältst zusätzlich eine automatisierte Einladung zum Gespräch per Mail mit den Gesprächsteilnehmer:innen, wie beispielsweise jemandem aus der Fachabteilung, dem Personalbereich, dem Betriebsrat und der <a href="mailto:schwerbehindertenvertretung@enercity.de">Schwerbehindertenvertretung</a>. Führungskräfte laden wir gerne noch zu einem zweiten Kennenlernen ein.',
    backgroundVariant: 'thumbs-up',
  },
  {
    id: 5,
    title: 'Und dann?',
    icon: 'user/high5',
    description:
      'Je nach Position dauert es etwa ein bis zwei Wochen, bis sich der jeweilige Fach- und Personalbereich abgestimmt haben und sich bei dir melden. Den Arbeitsvertrag senden wir dir per Post zu und erklären, wie es weiter geht.',
    hint: 'Berufsstarter:innen können sich schon vorab informieren, wie die ersten Tage bei uns ablaufen.',
    link: '/karriere/berufsstarter',
    backgroundVariant: 'contract-amendents',
  },
];
