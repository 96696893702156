import clsx from 'clsx';
import React from 'react';
import Icon from '@uikit/components/Icon/Icon';
import type { MapFilterMenuToggleProps } from './interfaces';
import styles from './MapFilterMenuToggle.module.scss';

const MapFilterMenuToggle = ({
  active,
  activeFiltersAmount,
  className,
  onClick,
}: MapFilterMenuToggleProps) => {
  const buttonText = active ? 'Filter ausblenden' : 'Filter anzeigen';

  return (
    <div className={clsx(styles.base, className)} onClick={onClick}>
      <Icon
        variant={'user/slider'}
        size="iconSize18"
        className={styles.toggleIcon}
      />
      {activeFiltersAmount > 0 && !active ? (
        <div className={clsx(styles.animatedText, styles.toggleText)}>
          <div className={styles.animatedLabel}>
            <div
              className={styles.animatedLabelItem}
            >{`Aktive Filter (${activeFiltersAmount})`}</div>
            <div className={styles.animatedLabelItem}>{buttonText}</div>
          </div>
        </div>
      ) : (
        <div className={styles.toggleText}>{buttonText}</div>
      )}
    </div>
  );
};

export default MapFilterMenuToggle;
