import * as Yup from 'yup';

const isNumber = Yup.string().matches(
  /^\d+$/,
  'Der Wert muss nummerisch sein.'
);

const schema = Yup.object({
  netWithdrawalPower: Yup.string().matches(
    /^[1-9]\d*$/,
    'Die Leistung muss größer als 0 kW sein.'
  ),
  chargingStations: Yup.string().matches(
    /^[1-9]\d*$/,
    'Es muss mindestens eine Ladeeinrichtung gemeldet werden.'
  ),
  acPorts3: isNumber,
  acPorts11: isNumber,
  acPortsGE22: isNumber,
  dcPortsLE100: isNumber,
  dcPortsGT100: isNumber,
  connectionType: Yup.string().required(
    'Bitte geben Sie an, wie die Ladeeinrichtung angeschlossen wird.'
  ),
  manufacturer: Yup.string().required('Bitte geben Sie einen Hersteller an.'),
  metering: Yup.string().required('Bitte geben Sie die Art der Messung an.'),
  meterNumber: Yup.string().when('metering', {
    is: (metering?: string) => metering === 'Vorhandener Zähler',
    then: () => Yup.string().required('Bitte geben Sie eine Zählernummer an.'),
  }),
});

export default schema;
