import useForm from '@/hooks/useForm/useForm';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import {
  formatCurrency,
  formatNumberWithFraction,
} from '@uikit/helpers/formatNumber';
import { AnimatedIconVariant } from '../AnimatedIcon/animatedIconVariants';
import FormField from '../FormField/FormField';
import { FormFieldType } from '../FormField/consts';
import Headline from '../Headline/Headline';
import { HeadlineColor, HeadlineLevel } from '../Headline/consts';
import Icon from '../Icon/Icon';
import IconCard from '../IconCard/IconCard';
import IconCardGroup from '../IconCardGroup/IconCardGroup';
import InputListbox from '../InputListbox/InputListbox';
import { InputListboxOption } from '../InputListbox/types';
import styles from './HeatPumpSavingsCalculator.module.scss';
import { GAS_CONSUMPTION_OPTIONS } from './consts';
import { calculateSavingsAmount } from './helpers/calculateSavingsAmount/calculateSavingsAmount';
import { transformCalculationOptions } from './helpers/transformCalculationOptions/transformCalculationOptions';
import heatPumpCalculatorSchema from './schema';
import type {
  HeatPumpHeatingType,
  HeatPumpSavingsCalculatorProps,
} from './types';

const initialFormState: {
  oil: string;
  gas: string;
  heatingType: HeatPumpHeatingType;
} = {
  gas: '10000',
  oil: '1000',
  heatingType: 'gas',
};

const HeatPumpSavingsCalculator = ({
  headline,
  electricityBasicPrice,
  electricityPriceHT,
  electricityPriceNT,
  gasBasicPrice,
  gasEfficiency,
  gasPrice,
  oilBasicPrice,
  oilEfficiency,
  oilPrice,
  heatPumpEfficiency,
}: HeatPumpSavingsCalculatorProps) => {
  const isTablet = useIsMobile(992);
  const iconSize = isTablet ? 'iconSize40' : 'iconSize64';
  const {
    formState,
    getErrorByFieldName,
    handleChange,
    handleBlur,
    setFieldValue,
    isFormValid,
  } = useForm(() => void 0, heatPumpCalculatorSchema, initialFormState);
  const yearlyConsumption = formState[formState.heatingType];
  const calculationOptions = {
    electricityBasicPrice,
    electricityPriceHT,
    electricityPriceNT,
    fossilBasicPrice:
      formState.heatingType === 'gas' ? gasBasicPrice : oilBasicPrice,
    fossilConsumption: yearlyConsumption,
    fossilEfficiency:
      formState.heatingType === 'gas' ? gasEfficiency : oilEfficiency,
    fossilPrice: formState.heatingType === 'gas' ? gasPrice : oilPrice,
    heatPumpEfficiency,
  };

  const handleYearlyGasConsumptionChange = (option: InputListboxOption) => {
    setFieldValue('gas', option.value);
  };

  const handleYearlyGasConsumptionChangeCustom = (value: string) => {
    setFieldValue('gas', value);
  };

  const transformedCalculationOptions =
    transformCalculationOptions(calculationOptions);

  const yearlySavings = calculateSavingsAmount({
    type: formState.heatingType,
    ...transformedCalculationOptions,
  });

  const hasYearlySavings = yearlySavings && yearlySavings > 0 && isFormValid;

  const consumptionInputComponent =
    formState.heatingType === 'gas' ? (
      <InputListbox
        color="surrogate"
        label="Jährlicher Verbrauch"
        icon="user/energy-fire"
        customSelectLabel={`Verbrauch in kWh`}
        placeholder="Jährlicher Verbrauch"
        customFormatter={formatNumberWithFraction}
        customFormFieldType={FormFieldType.Number}
        onChange={handleYearlyGasConsumptionChange}
        onCustomChange={handleYearlyGasConsumptionChangeCustom}
        options={GAS_CONSUMPTION_OPTIONS}
        suffix="kWh"
        selected={GAS_CONSUMPTION_OPTIONS.find(
          (option) => option.value === formState.gas
        )}
        customSelected={formState.gas}
        errorMessage={getErrorByFieldName('gas')}
        errorMessageHasRelativePosition
      />
    ) : (
      <FormField
        label="Jährlicher Verbrauch"
        suffixBoxText="Liter"
        value={formState.oil}
        onChange={handleChange}
        type={FormFieldType.Number}
        name="oil"
        errorMessage={getErrorByFieldName('oil')}
        errorMessageHasRelativePosition
        onBlur={handleBlur}
      />
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.base}>
        <div className={styles.body}>
          <div className={styles.badge}>
            <Icon variant="user/calculator" size={iconSize} isOutlined />
          </div>
          <Headline
            headlineColor={HeadlineColor.Gradient}
            level={HeadlineLevel.Three}
            className={styles.headline}
          >
            {headline}
          </Headline>
          <div className={styles.row}>
            <div className={styles.column}>
              <span className={styles.label}>Heizungsart</span>
            </div>
            <div className={styles.column}>
              <IconCardGroup className={styles.iconCardGroup}>
                <IconCard
                  icon="user/energy-fire"
                  animatedIcon={AnimatedIconVariant.Fire}
                  label="Gas"
                  value="gas"
                  checked={formState.heatingType === 'gas'}
                  onChange={handleChange}
                  className={styles.iconCard}
                  name="heatingType"
                />
                <IconCard
                  icon="user/oil-heating"
                  animatedIcon={AnimatedIconVariant.OilHeating}
                  label="Öl"
                  value="oil"
                  checked={formState.heatingType === 'oil'}
                  onChange={handleChange}
                  className={styles.iconCard}
                  name="heatingType"
                />
              </IconCardGroup>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.label}>Ihr jährlicher Verbrauch</label>
            </div>
            <div className={styles.column}>{consumptionInputComponent}</div>
          </div>
        </div>
        <div className={styles.footer}>
          <span className={styles.label}>Ersparnis pro Jahr</span>
          <span className={styles.savingsAmount}>
            {hasYearlySavings ? formatCurrency(yearlySavings) : '--- €'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeatPumpSavingsCalculator;
