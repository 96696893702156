import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { useQuery } from 'react-query';
import { WEDEMARK_MAP_DATA_PATH } from '../consts';
import { WedemarkMapData } from './types';

const fetchWedemarkMapData =
  (path: string) => async (): Promise<WedemarkMapData | null> => {
    try {
      const data = (await ajax.getJsonCached<WedemarkMapData>(path)) ?? null;

      return data;
    } catch (error) {
      logger.log('getWedemarkMapData', error);
      return null;
    }
  };

export default function useWedemarkMapData() {
  return useQuery(
    'wedemarkMapData',
    fetchWedemarkMapData(WEDEMARK_MAP_DATA_PATH)
  );
}
