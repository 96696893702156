import React from 'react';
import Button from '../Button/Button';
import { ButtonAlign } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import Icon from '../Icon/Icon';
import type { NotAvailableInfoProps } from './interfaces';
import styles from './NotAvailableInfo.module.scss';

const NotAvailableInfo = ({
  icon,
  title,
  text,
  buttonLabel,
  buttonLink,
}: NotAvailableInfoProps) => (
  <div className={styles.base}>
    {icon && (
      <Icon
        variant={icon}
        size="iconSize32"
        className={styles.icon}
        isOutlined
      />
    )}
    <h3 className={styles.title}>{title} </h3>
    {text && <p className={styles.text}>{text}</p>}
    {buttonLabel && buttonLink && (
      <>
        <br />
        <Button
          actionType={ClickableAction.Linkextern}
          linkextern={buttonLink}
          align={ButtonAlign.Center}
        >
          {buttonLabel}
        </Button>
      </>
    )}
  </div>
);

export default NotAvailableInfo;
