import config from '@/config';
import { base64 } from '@uikit/utils/base64';

type Operation = 'S' | 'E' | 'K';
type RegiocomFn<Data> = (data: Readonly<Data>) => Promise<Response>;
interface CreateCheckData {
  kdKey: string;
  operation: Operation;
  value: string;
  frcCaptchaSolution: string;
}

interface CheckPINData {
  kdKey: string;
  operation: Operation;
  pin: string;
  frcCaptchaSolution: string;
}

interface DeleteContactData {
  cancelCode: string;
}

export const meinHGasRegiocomCheckPin: RegiocomFn<CheckPINData> = ({
  kdKey,
  operation,
  pin,
  frcCaptchaSolution,
}) => {
  return fetch(
    `${
      config.api.baseUrl
    }regiocomCheckPin?kdKey=${kdKey}&operation=${operation}&pin=${pin}&frcCaptchaSolution=${base64.encode(
      frcCaptchaSolution
    )}`
  );
};

export const meinHGasRegiocomCreateCheck: RegiocomFn<CreateCheckData> = ({
  kdKey,
  operation,
  value,
  frcCaptchaSolution,
}) => {
  return fetch(
    `${
      config.api.baseUrl
    }regiocomCreateCheck?kdKey=${kdKey}&operation=${operation}&value=${value}&frcCaptchaSolution=${base64.encode(
      frcCaptchaSolution
    )}`
  );
};

export const meinHGasRegiocomDeleteContact: RegiocomFn<DeleteContactData> = ({
  cancelCode,
}) => {
  return fetch(
    `${config.api.baseUrl}regiocomDeleteContact?cancelCode=${cancelCode}`
  );
};
