import Icon from '@uikit/components/Icon/Icon';
import clsx from 'clsx';
import React from 'react';
import type { JobApplyStepBackgroundProps } from './interfaces';
import styles from './JobApplyStepBackground.module.scss';
import { toCamelCase } from '@/uikit/utils/convertCase';

const JobApplyStepBackground = ({ variant }: JobApplyStepBackgroundProps) => {
  const styleVariant = toCamelCase(variant);
  return (
    <div className={clsx(styles.base, styles[styleVariant])}>
      <Icon
        size="iconSize64"
        variant={`user/${variant}`}
        isOutlined
        className={styles.icon}
      />
    </div>
  );
};

export default JobApplyStepBackground;
