import React from 'react';
import styles from './FormRow.module.scss';

type FormRowHeadlineProps = {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  children: React.ReactNode;
};

const FormRowHeadline = ({
  tag: Tag = 'h3',
  children,
}: FormRowHeadlineProps) => {
  return <Tag className={styles.headline}>{children}</Tag>;
};

export default FormRowHeadline;
