import { getSafeInnerHTML } from '../../helpers/getSafeInnerHTML';
import FlipCard from '../../uikit/components/FlipCard/FlipCard';
import type { FlipCardWrapperProps } from './types';

const FlipCardWrapper = ({
  title,
  subTitle,
  description,
  icon,
}: FlipCardWrapperProps) => {
  return (
    <FlipCard>
      <FlipCard.Front>
        {icon && <FlipCard.Icon icon={icon} />}
        {title && <FlipCard.Headline>{title}</FlipCard.Headline>}
        {subTitle && <p>{subTitle}</p>}
      </FlipCard.Front>

      <FlipCard.Back>
        {typeof description === 'string' ? (
          <div
            dangerouslySetInnerHTML={{ __html: getSafeInnerHTML(description) }}
          />
        ) : (
          description
        )}
      </FlipCard.Back>
    </FlipCard>
  );
};

export default FlipCardWrapper;
