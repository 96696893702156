import clsx from 'clsx';
import React from 'react';
import styles from './ImageGrid.module.scss';
import { ImageGridGroupProps } from './interfaces';

const ImageGridWrapper = ({
  children,
  columnAmount = 3,
}: Pick<ImageGridGroupProps, 'columnAmount'> & {
  children: React.ReactNode;
}) => {
  return (
    <div
      className={clsx(styles.grid, {
        // eslint-disable-next-line
        [styles.threeCols]: columnAmount == 3,
        // eslint-disable-next-line
        [styles.fourCols]: columnAmount == 4,
      })}
    >
      {children}
    </div>
  );
};

export default ImageGridWrapper;
