import type { RefObject } from 'react';

export const updateCarouselHeight = (
  carouselRef: RefObject<HTMLElement> | null,
  slideRef: RefObject<HTMLElement> | null
) => {
  if (!carouselRef?.current || !slideRef?.current) {
    return;
  }

  const height =
    slideRef.current?.firstElementChild?.getBoundingClientRect().height ?? 0;

  if (height > 0) {
    carouselRef.current.style.maxHeight = height + 'px';
  }
};
