import Button from '../Button/Button';
import { ButtonAlign } from '../Button/consts';
import { ButtonProps } from '../Button/interfaces';
import { HeadlineLevel } from '../Headline/consts';
import Headline from '../Headline/Headline';
import RichText from '../RichText/RichText';
import styles from './StageBoxWhite.module.scss';
import type { StageBoxWhiteProps } from './types';

const StageBoxWhite = ({
  headline,
  text,
  buttonLabel,
  ...props
}: StageBoxWhiteProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.base}>
        <Headline tag="h3" level={HeadlineLevel.Three} className={styles.title}>
          {headline}
        </Headline>

        {text && <RichText className={styles.text}>{text}</RichText>}

        {buttonLabel && (
          <Button
            {...(props as ButtonProps)}
            align={ButtonAlign.Center}
            className={styles.button}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StageBoxWhite;
