import clsx from 'clsx';
import React from 'react';
import { JOB_SHIFTS, JOB_SHIFT_PERSONS } from './consts';
import { JobShiftPersons, JobShiftPillType } from './interfaces';
import styles from './JobShiftModel.module.scss';

const JobShiftPill = ({
  className,
  type,
  showBottomLine = true,
}: {
  type: JobShiftPillType;
  className?: string;
  showBottomLine?: boolean;
}) => {
  return (
    <div
      className={clsx(
        styles.pillWrapper,
        styles.surrogate,
        styles[type],
        className,
        { [styles.bottomLine]: showBottomLine }
      )}
    >
      <div className={clsx(styles.white, styles.pill)}>
        <span className={styles.pillIcon}>{JOB_SHIFTS[type].icon}</span>

        {JOB_SHIFTS[type].name}
      </div>
    </div>
  );
};
const JobShiftDay = ({
  children,
  className,
  showBottomLine = true,
}: {
  children: React.ReactChild;
  className?: string;
  showBottomLine?: boolean;
}) => {
  return (
    <div
      className={clsx(styles.day, styles.surrogate, className, {
        [styles.bottomLine]: showBottomLine,
      })}
    >
      {children}
    </div>
  );
};

const JobShiftPerson = ({
  person,
  showBottomLine = true,
}: {
  person: JobShiftPersons;
  showBottomLine?: boolean;
}) => {
  const { image, name } = JOB_SHIFT_PERSONS[person];
  return (
    <div
      className={clsx(styles.white, styles[person], styles.person, {
        [styles.bottomLine]: showBottomLine,
      })}
    >
      <span className={styles.personImageWrapper}>
        <img
          src={image}
          alt={`Bild von ${name}`}
          className={styles.personImage}
        />
      </span>
      {name}
    </div>
  );
};

const JobShiftFree = ({
  className,
  showBottomLine = true,
}: {
  className?: string;
  showBottomLine?: boolean;
}) => {
  return (
    <div
      className={clsx(styles.surrogate, className, {
        [styles.bottomLine]: showBottomLine,
      })}
    />
  );
};

const JobShiftModel = () => {
  return (
    <div className={styles.baseWrapper}>
      <div className={styles.base}>
        <div className={clsx(styles.white, styles.bottomLine)} />
        <div className={styles.verticalLines}>
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <JobShiftDay className={clsx(styles.mo, styles.borderTopLeft)}>
          MO
        </JobShiftDay>
        <JobShiftDay className={styles.di}>DI</JobShiftDay>
        <JobShiftDay className={styles.mi}>MI</JobShiftDay>
        <JobShiftDay className={styles.do}>DO</JobShiftDay>
        <JobShiftDay className={styles.fr}>FR</JobShiftDay>
        <JobShiftDay className={styles.sa}>SA</JobShiftDay>
        <JobShiftDay className={clsx(styles.so, styles.borderTopRight)}>
          SO
        </JobShiftDay>
        <JobShiftPerson person="paul" />
        <JobShiftPill className={styles.paulShiftFirst} type="backShift" />
        <JobShiftPill className={styles.paulShiftSecond} type="nightShift" />
        <JobShiftFree />
        <JobShiftPill className={styles.paulShiftThird} type="restDay" />
        <JobShiftFree />
        <JobShiftPerson person="anna" />
        <JobShiftPill className={styles.annaShiftFirst} type="earlyShift" />
        <JobShiftPill className={styles.annaShiftSecond} type="backShift" />
        <JobShiftPill className={styles.annaShiftThird} type="nightShift" />

        <JobShiftPerson person="jan" />
        <JobShiftPill className={styles.janShiftFirst} type="nightShift" />
        <JobShiftFree />
        <JobShiftPill className={styles.janShiftSecond} type="restDay" />
        <JobShiftFree />
        <JobShiftFree />
        <JobShiftFree />

        <JobShiftPerson person="leon" />
        <JobShiftFree />
        <JobShiftPill className={styles.leonShiftFirst} type="restDay" />
        <JobShiftFree />
        <JobShiftFree />
        <JobShiftPill className={styles.leonShiftSecond} type="earlyShift" />

        <JobShiftPerson person="matthias" />
        <JobShiftPill
          className={styles.matthiasShiftFirst}
          type="disposalShift"
        />

        <JobShiftPerson person="christina" showBottomLine={false} />
        <JobShiftFree
          className={styles.borderBottomLeft}
          showBottomLine={false}
        />
        <JobShiftPill
          className={styles.christinaShiftFirst}
          type="restDay"
          showBottomLine={false}
        />
        <JobShiftPill
          className={styles.christinaShiftSecond}
          type="earlyShift"
          showBottomLine={false}
        />
        <JobShiftPill
          className={clsx(styles.christinaShiftThird, styles.borderBottomRight)}
          type="backShift"
          showBottomLine={false}
        />
      </div>
    </div>
  );
};

export default JobShiftModel;
