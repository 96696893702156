import clsx from 'clsx';
import React from 'react';
import useOnClickOutside from '@/hooks/useOnClickOutside/useOnClickOutside';
import FormField from '../FormField/FormField';
import { FormFieldElement } from '../FormField/interfaces';
import Icon from '../Icon/Icon';
import styles from './InputListbox.module.scss';
import type { InputListboxOption, InputListboxProps } from './types';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { animated, useSpring, config } from '@react-spring/web';
import useDimensions from '@/hooks/useDimensions/useDimensions';
import useKeyPress from '@/hooks/useKeyPress/useKeyPress';
import nextId from '@uikit/helpers/nextId';

const hasEventTargetWithName = ($event: KeyboardEvent, name: string) =>
  $event.target && ($event.target as HTMLElement).id === name;

const InputListbox = ({
  color = 'surrogate',
  label,
  customSelectLabel,
  suffix,
  icon,
  options,
  hasRelativeOptionsDropDown = false,
  selected,
  customSelected,
  customSelectedSuffix,
  customFormatter,
  customFormFieldType,
  placeholder,
  onChange,
  onCustomChange,
  onBlur,
  inputMode,
  errorMessage,
  errorMessageHasRelativePosition = false,
  isBoxOnGradient = false,
  className,
}: InputListboxProps): JSX.Element => {
  const uniqueId = React.useMemo(() => nextId(), []);
  const listBoxId = `listbox-${uniqueId}`;
  const [isOptionOpen, setIsOptionOpen] = React.useState(false);
  const listboxRef = React.useRef<HTMLDivElement>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(-1);

  const [customValue, setCustomValue] = React.useState(customSelected || '');
  const [isCustomValueChanged, setIsCustomValueChanged] = React.useState(false);

  const handleButtonClick = ($event: React.MouseEvent<HTMLButtonElement>) => {
    $event.preventDefault();
    if (options[selectedOptionIndex]) {
      handleOptionClick(options[selectedOptionIndex]);
    }

    setIsOptionOpen(!isOptionOpen);
  };

  const handleCustomChange = ($event: React.ChangeEvent<FormFieldElement>) => {
    setCustomValue($event.target.value);
    setIsCustomValueChanged(true);
  };

  const handleCustomSubmitClick = (
    $event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    $event.preventDefault();
    setIsOptionOpen(!isOptionOpen);
    onCustomChange(customValue);
    setIsCustomValueChanged(false);
  };

  const handleOptionClick = (option: InputListboxOption) => {
    onChange(option);
    setIsOptionOpen(!isOptionOpen);
    setSelectedOptionIndex(-1);
    setCustomValue('');
  };

  const handleKeyDown = ($event: React.KeyboardEvent<FormFieldElement>) => {
    if ($event.key === 'Enter') {
      $event.preventDefault();

      onCustomChange(customValue);
      setIsCustomValueChanged(false);

      setIsOptionOpen(false);
    }
  };

  useOnClickOutside(listboxRef, () => {
    if (isOptionOpen) {
      setIsOptionOpen(false);
    }
  });

  const [dropDownRef, { height }] = useDimensions();

  const props = useSpring({
    height: isOptionOpen ? height + 16 : 0,
    opacity: isOptionOpen ? 1 : 0,
    config: {
      duration: 200,
      ...config.gentle,
    },
  });

  const onPressArrow =
    (direction: 'up' | 'down') => ($event: KeyboardEvent) => {
      if (hasEventTargetWithName($event, label)) {
        $event.preventDefault();

        if (direction === 'up') {
          if (selectedOptionIndex === 0) {
            setSelectedOptionIndex(options.length - 1);
          }
          if (selectedOptionIndex > 0) {
            setSelectedOptionIndex(selectedOptionIndex - 1);
          }
          return;
        }

        if (direction === 'down') {
          if (!isOptionOpen) {
            setIsOptionOpen(() => true);
          } else if (selectedOptionIndex !== options.length - 1) {
            setSelectedOptionIndex(selectedOptionIndex + 1);
          } else {
            setSelectedOptionIndex(0);
          }

          return;
        }
      }
    };
  const onPressHome = ($event: KeyboardEvent) => {
    $event.preventDefault();
    setSelectedOptionIndex(0);
  };

  const onPressEnd = ($event: KeyboardEvent) => {
    $event.preventDefault();
    setSelectedOptionIndex(options.length - 1);
  };

  useKeyPress('ArrowUp', { onKeyDown: onPressArrow('up') });
  useKeyPress('ArrowDown', { onKeyDown: onPressArrow('down') });
  useKeyPress('Home', { onKeyDown: onPressHome });
  useKeyPress('End', { onKeyDown: onPressEnd });

  return (
    <div ref={listboxRef} className={clsx(styles.base, className)}>
      <button
        type="button"
        className={clsx(styles.button, styles[color], {
          [styles.invalid]: errorMessage,
        })}
        onClick={handleButtonClick}
        onBlur={onBlur}
        id={label}
      >
        <div className={clsx(styles.icon, styles[color])}>
          <Icon variant={icon} size="iconSize24" />
        </div>
        <div className={styles.content}>
          <span
            className={clsx(styles.label, {
              [styles.active]: selected || customSelected || isOptionOpen,
            })}
          >
            {label}
          </span>

          <span
            className={clsx(styles.text, {
              [styles[color]]: selected || customSelected,
              [styles.active]: selected || customSelected || isOptionOpen,
            })}
          >
            {selected || customSelected
              ? selected
                ? selected.label
                : customSelected && (
                    <>
                      {customFormatter(+customSelected)}
                      {customSelectedSuffix && ` ${customSelectedSuffix}`}
                    </>
                  )
              : placeholder}
          </span>
        </div>
        <span
          className={clsx(styles.chevron, {
            [styles.active]: isOptionOpen,
          })}
        >
          <Icon variant={'action/chevron-down'} size="iconSize16" />
        </span>
      </button>

      <animated.div
        className={clsx(styles.optionsWrapper, {
          [styles.optionsWrapperOpen]: isOptionOpen,
          [styles.relative]: hasRelativeOptionsDropDown,
        })}
        style={props}
        aria-expanded={isOptionOpen}
      >
        <ul ref={dropDownRef} className={styles.options}>
          <li className={clsx(styles.option, styles.inputGroup)}>
            <FormField
              label={customSelectLabel}
              type={customFormFieldType}
              value={customValue}
              onChange={handleCustomChange}
              onKeyDown={handleKeyDown}
              onSuffixBoxClick={handleCustomSubmitClick}
              suffixBoxText={suffix}
              inputMode={inputMode}
              fullWidth
              color={color}
              tabIndex={isOptionOpen ? 0 : -1}
            />
            <button
              className={clsx(styles.submitButton, styles[color], {
                [styles.active]: isCustomValueChanged,
              })}
              onClick={handleCustomSubmitClick}
              type="button"
              tabIndex={isOptionOpen ? 0 : -1}
            >
              <Icon variant={'action/enter'} size="iconSize24" />
            </button>
          </li>

          <hr className={styles.optionDivider} />

          {options.map((option, idx) => {
            return (
              <li
                key={option.value}
                className={clsx(styles.option, styles[color], {
                  [styles.disabled]: option.isDisabled,
                  [styles.selected]: idx === selectedOptionIndex,
                })}
                onClick={() => {
                  if (option.isDisabled) {
                    return;
                  }

                  handleOptionClick(option);
                }}
                aria-label={option.label}
                role="option"
                aria-selected={idx === selectedOptionIndex}
                id={`${listBoxId}-${idx}`}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </animated.div>

      {errorMessage && (
        <ErrorMessage
          hasRelativePosition={errorMessageHasRelativePosition}
          withWhiteColor={isBoxOnGradient}
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
};

export default InputListbox;
