import { useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { UseDimensions, UseDimensionsHook } from './interface';

function getDimensionObject(node: HTMLElement): UseDimensions {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    right: rect.right,
    bottom: rect.bottom,
  };
}

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

function useDimensions(): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });
  const [node, setNode] = useState();

  const ref = useCallback((node: HTMLElement | null) => {
    // @ts-ignore
    if (node) setNode(node);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          // @ts-ignore
          setDimensions(getDimensionObject(node))
        );

      measure();

      window.addEventListener('resize', measure, { passive: true });
      window.addEventListener('scroll', measure, { passive: true });

      return () => {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      };
    }
  }, [node]);

  // @ts-ignore
  return [ref, dimensions, node];
}

export default useDimensions;
