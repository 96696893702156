import Button from '../Button/Button';
import { ButtonSize } from '../Button/consts';
import type { ClickableProps } from '../Clickable/interfaces';
import BoxTeaser from './BoxTeaser';
import type { BoxTeaserProps } from './types';

const BoxTeaserWrapper = ({
  image,
  title,
  description,
  buttonLabel,
  ...rest
}: Omit<BoxTeaserProps, 'children'> &
  ClickableProps & { buttonLabel?: string }) => {
  return (
    <BoxTeaser image={image} title={title} description={description}>
      {buttonLabel && (
        <Button size={ButtonSize.Large} {...rest}>
          {buttonLabel}
        </Button>
      )}
    </BoxTeaser>
  );
};

export default BoxTeaserWrapper;
