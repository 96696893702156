export const GAS_CONSUMPTION_OPTIONS = [
  {
    value: '6250',
    label: '6.250 kWh',
  },
  {
    value: '10000',
    label: '10.000 kWh',
  },
  {
    value: '12500',
    label: '12.500 kWh',
  },
  {
    value: '15000',
    label: '15.000 kWh',
  },
  {
    value: '18750',
    label: '18.750 kWh',
  },
  {
    value: '21000',
    label: '21.000 kWh',
  },
  {
    value: '27000',
    label: '27.000 kWh',
  },
  {
    value: '33000',
    label: '33.000 kWh',
  },
];
