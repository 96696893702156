import { useLayoutEffect, useState } from 'react';
import type { MapLoadingOverlayLottieProps } from './interfaces';
import styles from './MapLoadingOverlayLottie.module.scss';
import { ANIMATION_FRAME_TIMES } from './consts';
import Lottie from '@uikit/components/Lottie/Lottie';
import { EnterFrameCallbackEvent } from '@uikit/components/Lottie/interfaces';
import { AnimationItem } from 'lottie-web';
import { currentProjectName } from '@/config';

const ANIMATION_DATA_BY_PROJECTNAME = {
  enercity: () => import('./marker-animation-enercity.json'),
  eng: () => import('./marker-animation-eng.json'),
};

const MapLoadingOverlayLottie = ({
  isPlaying,
  onAnimationEnd,
}: MapLoadingOverlayLottieProps) => {
  const [animationData, setAnimationData] = useState<{}>();
  const projectName = currentProjectName === 'eng' ? 'eng' : 'enercity';

  useLayoutEffect(() => {
    ANIMATION_DATA_BY_PROJECTNAME[projectName]().then((data) => {
      setAnimationData(data);
    });
  }, [projectName]);

  const handleEnterFrame = (
    $event: EnterFrameCallbackEvent,
    animationItem: AnimationItem
  ) => {
    if (
      Math.round($event.currentTime) >= ANIMATION_FRAME_TIMES.LOOP_END &&
      isPlaying
    ) {
      animationItem.goToAndPlay(ANIMATION_FRAME_TIMES.LOOP_START, true);
    }

    if (animationItem.currentFrame === ANIMATION_FRAME_TIMES.ANIMATION_END) {
      onAnimationEnd();
    }
  };

  return (
    <>
      {animationData && (
        <Lottie
          className={styles.base}
          animationData={animationData}
          onEnterFrame={handleEnterFrame}
          autoplay
          name="map-loading-animation"
        />
      )}
    </>
  );
};

export default MapLoadingOverlayLottie;
