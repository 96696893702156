import React from 'react';
import styles from './CMPCookieInfo.module.scss';
import { UseScriptConfigVariant } from '@/hooks/useScript/scriptVariants';
import useScript from '@/hooks/useScript/useScript';

const CMPCookieInfo = () => {
  useScript(UseScriptConfigVariant.CMPCookieInfo);

  return <div className={styles.base} id="cmpcookieinfo" />;
};

export default CMPCookieInfo;
