import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import { QueryStatus } from 'react-query';

const mapper = {
  idle: ButtonSubmitState.Initial,
  loading: ButtonSubmitState.Pending,
  success: ButtonSubmitState.Success,
  error: ButtonSubmitState.Fail,
};

const convertQueryStatusToButtonSubmitState = (status: QueryStatus) =>
  mapper[status];

export default convertQueryStatusToButtonSubmitState;
