import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormRow from '@uikit/components/FormRow/FormRow';
import React, { useContext } from 'react';
import { FormBuilderContext } from '../../FormBuilder';
import type { CustomRadioProps } from './interfaces';

const CustomRadio = ({ options, label, isLabelVisible }: CustomRadioProps) => {
  const { formState, getErrorByFieldName, handleChange } =
    useContext(FormBuilderContext);

  const suggestionOptions = options.map((val) => ({ value: val, label: val }));

  return (
    <FormRow title={isLabelVisible ? label : undefined}>
      <FormFieldRadioGroup
        items={suggestionOptions}
        name={label}
        onChange={handleChange}
        value={formState[label]}
        errorMessage={getErrorByFieldName(label)}
      />
    </FormRow>
  );
};

export default CustomRadio;
