import Fuse from 'fuse.js';
import React from 'react';
import type { UseSearchItems } from './interfaces';

export const DEFAULT_FUSE_OPTIONS = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 500,
  useExtendedSearch: true,
  minMatchCharLength: 1,
  fieldNormWeight: 1,
};

const useSearchItems: UseSearchItems = (
  items,
  keys,
  { initialState = 'empty' } = {}
) => {
  const propsRef = React.useRef({ items, keys });
  const fuse = React.useMemo(() => {
    return new Fuse(propsRef.current.items, {
      ...DEFAULT_FUSE_OPTIONS,
      keys: propsRef.current.keys,
    });
  }, [propsRef]);

  React.useEffect(() => {
    if (items) {
      fuse.setCollection(items);
    }
  }, [fuse, items]);

  return (term) => {
    if (!term) {
      switch (initialState) {
        case 'empty':
          return [];
        case 'full':
          return items;
        default:
          throw new Error('Value of initialState is not supported');
      }
    }

    return fuse.search(term).map(({ item }) => item);
  };
};

export default useSearchItems;
