import { logger } from '@/helpers/logger';
import useDataLayer from '@/hooks/useDataLayer/useDataLayer';
import useForm from '@/hooks/useForm/useForm';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useNavigate from '@/hooks/useNavigate/useNavigate';
import Button from '@uikit/components/Button/Button';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonType,
  ShowAddon,
  ShowIcon,
} from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import FormField from '@uikit/components/FormField/FormField';
import { FormFieldType } from '@uikit/components/FormField/consts';
import { UseFormState } from '../../../../hooks/useForm/interfaces';
import ProductTeaserHeadline from '../ProductTeaserHeadline/ProductTeaserHeadline';
import styles from './ProductTeaserTariffBox.module.scss';
import type { ProductTeaserTariffBoxProps } from './interfaces';
import schema from './schema';

const ProductTeaserTariffBox = ({
  page,
  productType,
  variant,
  headline,
}: ProductTeaserTariffBoxProps) => {
  const { pushDataLayerEvent } = useDataLayer();
  const navigate = useNavigate();

  const initialState = {
    zipcode: '',
  };

  const handleOnSubmit = (formState: UseFormState) => {
    const zipcode = formState['zipcode'];
    const pagePath = page;
    pushDataLayerEvent({ plz: zipcode });

    if (pagePath) {
      const searchParams = new URLSearchParams();
      searchParams.append('zipCode', zipcode);

      navigate(`${pagePath}?${searchParams}`);
    } else {
      logger.error(`'pagePath' for ${productType} in CMS is not defined!`);
    }
  };

  const { handleSubmit, handleChange, getErrorByFieldName, formState } =
    useForm(handleOnSubmit, schema, initialState);

  const isSm = useIsMobile(768);

  return (
    <>
      <ProductTeaserHeadline size="large" variant={variant}>
        {headline}
      </ProductTeaserHeadline>
      <form onSubmit={handleSubmit}>
        <FormField
          name={'zipcode'}
          value={formState['zipcode'] || ''}
          label={'Ihre Postleitzahl'}
          type={FormFieldType.Number}
          autoComplete="postal-code"
          inputMode="numeric"
          maxLength={5}
          onChange={handleChange}
          errorMessage={getErrorByFieldName(`zipcode`)}
          dontAllowMoreThenMaxLength
          isBoxOnGradient={variant === 'highlighted'}
          errorMessageHasRelativePosition
        />
        <Button
          className={styles.button}
          type={ButtonType.Submit}
          actionType={ClickableAction.None}
          align={ButtonAlign.Left}
          color={
            variant === 'simple' ? ButtonColor.Primary : ButtonColor.Secondary
          }
          showAddon={ShowAddon.Gradient}
          showIcon={ShowIcon.ShowIcon}
          icon={'user/calculator'}
          size={isSm ? ButtonSize.Medium : ButtonSize.Large}
          noClickable
        >
          Stromtarife berechnen
        </Button>
      </form>
    </>
  );
};

export default ProductTeaserTariffBox;
