import React from 'react';
import { ColorFilterProps } from './ColorFilter.interfaces';

const ColorFilter = ({
  color,
  children,
  className,
}: ColorFilterProps) => {
  return (
    <span data-color={color} className={className}>
      {children}
    </span>
  );
};

export default ColorFilter;
