import Portal from '@/components/Portal/Portal';
import { useEffect, useMemo, useState } from 'react';
import { ButtonAlign } from '../Button/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import { ClickableAction } from '../Clickable/consts';
import Icon from '../Icon/Icon';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import Lightbox from '../Lightbox/Lightbox';
import Video from '../Video/Video';
import { prepareSource } from '../Video/helpers';
import styles from './VideoTeaser.module.scss';
import type { VideoTeaserProps } from './interfaces';

const VideoTeaser = ({
  buttonText,
  thumbnail,
  videoUrl,
  videoName,
  canOpen = true,
}: VideoTeaserProps) => {
  const [lightboxIsActive, setLightboxIsActive] = useState(false);
  const [thumbnailAsset, setThumbnailAsset] = useState(thumbnail);
  const source = useMemo(() => prepareSource(videoUrl), [videoUrl]);

  useEffect(() => {
    if (!thumbnail && source?.getThumbnail) {
      source
        .getThumbnail()
        .then((asset) => setThumbnailAsset(asset))
        .catch(() => void 0);
    }
  }, [source, thumbnail]);

  const openLightbox = () => {
    if (canOpen) {
      setLightboxIsActive(true);
    }
  };

  const closeLightBox = () => {
    setLightboxIsActive(false);
  };

  return (
    <>
      <Portal id={`lightbox-${buttonText}`}>
        <Lightbox active={lightboxIsActive} onClose={closeLightBox}>
          <Video src={videoUrl} videoName={videoName} />
        </Lightbox>
      </Portal>

      <div className={styles.base} onClick={openLightbox}>
        <div className={styles.videoBackground}>
          {thumbnailAsset && (
            <LegacyPicture
              fitCover
              asset={thumbnailAsset}
              className={styles.thumbnailPicture}
            />
          )}
          <div className={styles.playButton}>
            <Icon variant={'user/play'} size="iconSize40" />
          </div>
        </div>

        <div className={styles.button}>
          <ButtonLink
            actionType={ClickableAction.None}
            align={ButtonAlign.Center}
          >
            {buttonText}
          </ButtonLink>
        </div>
      </div>
    </>
  );
};

export default VideoTeaser;
