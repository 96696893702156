import { UmbrellaCampaignTeaserTileData } from './types';

export const TEASER_TILES_DATA: UmbrellaCampaignTeaserTileData[] = [
  {
    icon: 'user/security',
    id: 1,
    headline: 'Versorgungssicherheit zu jeder Zeit.',
    subtitle:
      'Schon seit über 125 Jahren versorgen wir Sie in Hannover mit Energie. Damit Ihre Kaffeemaschine am Morgen läuft, die Wohnung kuschelig warm bleibt und Sie den Abend mit Ihren Liebsten vor dem Fernseher ausklingen lassen können.',
    url: '/',
  },
  {
    icon: 'user/plant-leaf',
    id: 2,
    headline: 'Positive Energie aus 100% klimaneutraler Erzeugung.',
    subtitle:
      'Bei uns bekommen Sie nur das Beste: reinen Ökostrom & klimaneutrales Erdgas. Wie wir das machen?',
    url: '/',
  },
  {
    icon: 'user/magnifier-and-euro',
    id: 3,
    headline: 'Faire Preise & volle Kostentransparenz.',
    subtitle:
      'Uns liegt besonders am Herzen, dass Sie sich bei uns gut aufgehoben fühlen. Deshalb gehen wir transparent, offen und ehrlich mit unseren Preisen um.',
    url: '/',
  },
  {
    icon: 'user/city',
    id: 4,
    headline: 'Für 0511 schlägt unser Herz.',
    subtitle:
      'Hannover ist unsere Heimat. Wir setzen alles daran, dass sie so schön grün bleibt, wie sie ist und machen uns stark für die Region.',
    url: '/',
  },
];
