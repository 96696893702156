import clsx from 'clsx';
import type { SVGProps } from 'react';
import styles from './styles.module.scss';

const SvgFlagIt = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    className={clsx(styles.round, className)}
    {...props}
  >
    <g>
      <path fill="#BB3533" d="M0 20V0h6.667v20z" />
      <path fill="#fff" d="M6.666 20V0h6.668v20z" />
      <path fill="#3D8A4D" d="M13.333 20V0h6.668v20z" />
    </g>
  </svg>
);
export default SvgFlagIt;
