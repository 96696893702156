import React from 'react';
import type { JobApplyStepProps } from './interfaces';
import styles from './JobApplyStep.module.scss';
import clsx from 'clsx';

const JobApplyStep = ({ children, className }: JobApplyStepProps) => {
  return <div className={clsx(styles.base, className)}>{children}</div>;
};

export default JobApplyStep;
