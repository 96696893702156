import React from 'react';

import QuoteCarousel from './QuoteCarousel';
import type { QuoteProps } from '../Quote/interfaces';
import type { QuoteCarouselWrapperProps } from './QuoteCarousel.interfaces';

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const QuoteCarouselWrapper = ({
  children,
}: QuoteCarouselWrapperProps) => {
  const quotes: QuoteProps[] = children
    .map((child) => child.props.item.props)
    .filter(notEmpty);

  return <QuoteCarousel quotes={quotes} />;
};

export default QuoteCarouselWrapper;
