import clsx from 'clsx';
import React from 'react';
import type { JobListProps } from './JobList.interfaces';
import styles from './JobList.module.scss';
import JobListItem from './JobListItem';

const JobList = ({
  jobs,
  toggleFavorite,
  isFavorite,
  styleVariant,
  hasItemPadding,
}: JobListProps) => (
  <ul className={clsx(styles.list, styles[styleVariant])}>
    {jobs.map(
      ({ title, categories: [mainCategory] = [], location, id, path }) => (
        <JobListItem
          title={title || 'KEINE ANGABE'}
          mainCategory={mainCategory || 'KEINE ANGABE'}
          city={location || 'KEINE ANGABE'}
          key={id}
          toggleFavorite={() => toggleFavorite(id)}
          isFavorite={isFavorite(id)}
          link={path}
          hasPadding={hasItemPadding}
        />
      )
    )}
  </ul>
);

export default JobList;
