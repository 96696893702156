import React from 'react';
import styles from './ProductTeaserHeadline.module.scss';
import { ProductTeaserHeadlineProps } from './interfaces';
import clsx from 'clsx';

const ProductTeaserHeadline = ({
  variant,
  children,
  size = 'default',
}: ProductTeaserHeadlineProps) => {
  return (
    <h3 className={clsx(styles.base, styles[variant], styles[size])}>
      {children}
    </h3>
  );
};

export default ProductTeaserHeadline;
