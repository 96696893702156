import { UsePagination } from './interfaces';
import { usePaginationSearchParamsState } from './usePaginationSearchParamsState';

const usePagination: UsePagination = (
  data,
  { itemsPerPage, initialPage = 1 }
) => {
  const maxPage = Math.ceil(data.length / itemsPerPage);

  const [currentPage, setCurrentPage] = usePaginationSearchParamsState(
    initialPage,
    maxPage
  );

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = data.slice(start, end);
  const canGoPrev = currentPage > 1;
  const canGoNext = currentPage < maxPage;
  const next = () => setCurrentPage(currentPage + 1);
  const prev = () => setCurrentPage(currentPage - 1);
  const jumpToPage = (pageNumber: number) => setCurrentPage(pageNumber);

  return {
    next,
    prev,
    jumpToPage,
    currentData,
    currentPage,
    maxPage,
    canGoPrev,
    canGoNext,
  };
};

export default usePagination;
