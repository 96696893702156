interface Location {
  street?: string;
  zipcode: string;
  city: string;
}

const getLocationString = (location: Location): string => {
  return `${location.street ? `${location.street}, ` : ''}${location.zipcode} ${
    location.city
  }`;
};

export default getLocationString;
