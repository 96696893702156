import * as Yup from 'yup';
import { agreement } from '../../../../schemas';

export default Yup.object({
  confirmation: agreement,
  agreement: agreement,
  cancellationPolicy: agreement,
  phoneConsulting: Yup.boolean(),
  emailConsulting: Yup.boolean(),
});
