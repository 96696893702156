'use client';

import useResizeObserver from '@/hooks/useResizeObserver/useResizeObserver';
import * as RadixSlider from '@radix-ui/react-slider';
import { formatNumber } from '@uikit/helpers/formatNumber';
import clsx from 'clsx';
import { useMemo, useRef, useState } from 'react';
import styles from './InvestmentSlider.module.scss';
import { convertValueToPercentage, getThumbInBoundsOffset } from './helpers';
import type { InvestmentSliderProps } from './types';

const LABEL_STEP = 2;

const InvestmentSlider = ({
  max,
  min,
  step = 1,
  value,
  onChange,
  showLegend = true,
  className,
}: InvestmentSliderProps) => {
  const thumbRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const percentage = convertValueToPercentage(value, min, max);

  const cssVars = useMemo(() => {
    const tooltipOffset =
      (percentage / 100) *
      (wrapperWidth - (tooltipRef.current?.clientWidth || 0));

    return {
      '--thumb-offset': `${getThumbInBoundsOffset(32, percentage, 1)}px`,
      '--tooltip-offset': `${tooltipOffset}px`,
      '--tail-position': `calc(${percentage}%)`,
      '--track-width': `calc(${percentage}% + ${getThumbInBoundsOffset(32, percentage, 1)}px)`,
      '--tooltip-current-rotation': `${percentage < 50 ? -10 : 10}deg`,
    } as React.CSSProperties;
  }, [percentage, wrapperWidth]);

  const handleOnValueChange = (values: number[]) => {
    onChange(values[0]);
  };

  useResizeObserver(wrapperRef, () => {
    setWrapperWidth(wrapperRef.current?.clientWidth || 0);
  });

  return (
    <div className={clsx(styles.wrapper, className)} ref={wrapperRef}>
      <div className={styles.base} style={cssVars}>
        <div className={styles.tooltipWrapper}>
          <div className={styles.tooltip} ref={tooltipRef}>
            <span className={styles.label}>Investitionssumme</span>
            <span className={styles.value}>{formatNumber(value)} €</span>
          </div>
          <div className={styles.tail} />
        </div>
        <RadixSlider.Root
          min={min}
          max={max}
          step={step}
          className={styles.slider}
          value={[value]}
          onValueChange={handleOnValueChange}
        >
          <RadixSlider.Track className={styles.track}>
            <RadixSlider.Range className={styles.range} />
          </RadixSlider.Track>
          <RadixSlider.Thumb className={styles.thumb} ref={thumbRef} />
        </RadixSlider.Root>
      </div>
      {showLegend && (
        <div className={styles.legend}>
          <div className={styles.marks}>
            {Array.from({ length: 100 }).map((_, index) => {
              const isActive =
                index >= percentage - LABEL_STEP && index <= percentage;

              return (
                index % LABEL_STEP === 0 && (
                  <div
                    key={index}
                    className={clsx(styles.mark, isActive && styles.active)}
                  />
                )
              );
            })}
          </div>
          <div className={styles.labels}>
            <div
              className={clsx(
                styles.label,
                value === min && styles.active,
                styles.label
              )}
            >
              {formatNumber(min)} €
            </div>
            <div
              className={clsx(
                styles.label,
                value === max && styles.active,
                styles.label
              )}
            >
              {formatNumber(max)} €
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentSlider;
