import clsx from 'clsx';
import React from 'react';
import type { ProductTeaserProps } from './interfaces';
import styles from './ProductTeaser.module.scss';
import ProductTeaserText from './ProductTeaserText/ProductTeaserText';
import ProductTeaserTariffBox from './ProductTeaserTariffBox/ProductTeaserTariffBox';
import ProductTeaserImage from './ProductTeaserImage/ProductTeaserImage';
import { productTeaserConfig } from './consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import assetPath from '@uikit/helpers/assetPath';

const productAssetPath = `${assetPath}/assets/images/`;

const ProductTeaser = ({ productType, variant, page }: ProductTeaserProps) => {
  const {
    headline,
    productText,
    highlightColor = 'surrogate',
    hasTariffBox,
    imageDescription,
    imagePaths,
    imagePathsMobile,
  } = productTeaserConfig[productType];

  const isSm = useIsMobile(768);

  const imagePathMobile =
    imagePathsMobile?.[variant] ?? imagePathsMobile?.simple;
  const imagePath =
    (isSm ? imagePathMobile : imagePaths[variant]) ?? imagePaths.simple;
  const isMobileCover = !!imagePathMobile;

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.base, styles[variant], styles[highlightColor])}
      >
        <div className={styles.inner}>
          <div className={styles.content}>
            {hasTariffBox ? (
              <ProductTeaserTariffBox
                headline={headline}
                productType={productType}
                page={page}
                variant={variant}
              />
            ) : (
              <ProductTeaserText
                headline={headline}
                variant={variant}
                page={page}
              >
                {productText}
              </ProductTeaserText>
            )}
          </div>
          <ProductTeaserImage
            description={imageDescription}
            path={`${productAssetPath}${imagePath}`}
            isMobileCover={isMobileCover}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductTeaser;
