import { TrafficLightStatus } from './interfaces';
import TrafficLightGreen from './TrafficLightGreen';
import TrafficLightRed from './TrafficLightRed';
import TrafficLightYellow from './TrafficLightYellow';

export const statusComponent: Record<TrafficLightStatus, () => JSX.Element> = {
  red: TrafficLightRed,
  yellow: TrafficLightYellow,
  green: TrafficLightGreen,
};
