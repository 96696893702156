import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import clsx from 'clsx';
import Badge from '../Badge/Badge';
import { BadgeColor, BadgeTextType } from '../Badge/consts';
import BadgeRectangular from '../BadgeRectangular/BadgeRectangular';
import Button from '../Button/Button';
import {
  ButtonAlign,
  ButtonColor,
  ButtonSize,
  ButtonWidth,
} from '../Button/consts';
import ButtonLink from '../ButtonLink/ButtonLink';
import Clickable from '../Clickable/Clickable';
import { ClickableAction } from '../Clickable/consts';
import Collapsible from '../Collapsible/Collapsible';
import Icon from '../Icon/Icon';
import RichText from '../RichText/RichText';
import styles from './TeaserTileLarge.module.scss';
import type {
  TeaserTileLargeProps,
  TeaserTileLargeWrapperBaseProps,
} from './interfaces';

const TeaserTileLargeWrapper = ({
  asDiv,
  children,
  clickableProps,
}: TeaserTileLargeWrapperBaseProps) => {
  if (asDiv) {
    return <>{children}</>;
  }

  return (
    <Clickable {...clickableProps} className={styles.clickable}>
      {children}
    </Clickable>
  );
};

const TeaserTileLarge = ({
  children,
  color = 'simple',
  icon,
  teaserAction = 'none',
  buttonLabel,
  collapsibleText,
  title,
  buttonLink = '',
  displayBadge = false,
  badgetext,
  badgeColor = BadgeColor.Blue,
  badgeTextType = BadgeTextType.Default,
  tileSize = 'default',
  className,
  ...props
}: TeaserTileLargeProps) => {
  const clickableProps = extractClickableProps(props);

  const colorStyle = styles[color];
  const sizeStyle = styles[tileSize];
  const classname = clsx(
    colorStyle,
    sizeStyle,
    styles.base,
    {
      [styles.withButton]: buttonLabel,
    },
    className
  );
  const displayCollapsible =
    teaserAction === 'collapsible' && collapsibleText && color === 'simple';

  const hasHighlightedButton =
    teaserAction === 'button' && color === 'highlighted';
  const hasSimpleButton = teaserAction === 'button' && color === 'simple';
  const hasCollapsible = teaserAction === 'collapsible';
  const hasModal = clickableProps.actionType === ClickableAction.Modal;

  return (
    <TeaserTileLargeWrapper
      clickableProps={clickableProps}
      asDiv={!!displayCollapsible}
    >
      <div className={classname}>
        {displayBadge && color === 'simple' && badgetext && (
          <>
            <div className={styles.badgeSm}>
              <Badge color={badgeColor} badgeTextType={badgeTextType}>
                {badgetext}
              </Badge>
            </div>
            {badgetext && (
              <div className={styles.badgeXxs}>
                <BadgeRectangular
                  additionalClassName={styles.badgeXxsInner}
                  color={badgeColor}
                  text={badgetext}
                />
              </div>
            )}
          </>
        )}
        <div className={styles.topContainer}>
          {icon && color === 'simple' && (
            <Icon
              variant={icon}
              className={styles.icon}
              size="iconSize64"
              isOutlined
            />
          )}
          {title && color === 'simple' && (
            <div className={styles.title}>{title}</div>
          )}
          {children && (
            <RichText className={styles.description}>{children}</RichText>
          )}
        </div>

        {hasHighlightedButton && (
          <div className={clsx(styles.buttonSimple, styles.button)}>
            <Button
              actionType={ClickableAction.None}
              size={ButtonSize.Medium}
              color={ButtonColor.Secondary}
              width={ButtonWidth.Auto}
              align={ButtonAlign.Center}
              clickContext={title}
              noClickable
            >
              {buttonLabel}
            </Button>
          </div>
        )}

        {hasCollapsible && (
          <Collapsible>
            <RichText className={styles.description}>
              {collapsibleText}
            </RichText>
          </Collapsible>
        )}

        {hasSimpleButton && (
          <ButtonLink
            className={styles.button}
            actionType={ClickableAction.None}
            clickContext={title}
            noClickable
            asATag={hasModal}
          >
            {buttonLabel}
          </ButtonLink>
        )}
      </div>
    </TeaserTileLargeWrapper>
  );
};

export default TeaserTileLarge;
