import { formatCurrency, formatNumber } from '@uikit/helpers/formatNumber';
import clsx from 'clsx';
import { useState } from 'react';
import Button from '../Button/Button';
import { ButtonSize } from '../Button/consts';
import { ClickableAction } from '../Clickable/consts';
import InvestmentSlider from '../InvestmentSlider/InvestmentSlider';
import ToggleIcon from '../ToggleIcon/ToggleIcon';
import styles from './ACWindPowerInvestCalc.module.scss';
import { getNumberValue } from './helpers';
import type { ACWindPowerInvestCalcProps } from './types';

const ACWindPowerInvestCalc = ({
  headline,
  baseInterestRatePercentage = 2,
  bonusPercentage = 2,
  minimumInvestment = 100,
  maximumInvestment = 10_000,
  sliderStep = 100,
  link,
}: ACWindPowerInvestCalcProps) => {
  const [hasBonus, setHasBonus] = useState(false);
  const [investmentValue, setInvestmentValue] = useState(() => {
    {
      const middle = (minimumInvestment + maximumInvestment) / 2;
      return Math.floor(middle / sliderStep) * sliderStep;
    }
  });

  const interestRate = getNumberValue(baseInterestRatePercentage) / 100;
  const bonus = getNumberValue(bonusPercentage) / 100;
  const sum = (interestRate + (hasBonus ? bonus : 0)) * investmentValue;

  return (
    <div className={styles.base}>
      <h3 className={styles.headline}>{headline}</h3>

      <InvestmentSlider
        className={styles.slider}
        max={maximumInvestment}
        min={minimumInvestment}
        step={sliderStep}
        value={investmentValue}
        onChange={setInvestmentValue}
      />

      <div className={styles.dataItems}>
        <dl className={styles.dataItem}>
          <dt className={styles.dataItemLabel}>Grundzins</dt>
          <span className={styles.dots} />
          <dd className={styles.dataItemDetail}>
            {formatNumber(getNumberValue(baseInterestRatePercentage), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            })}{' '}
            % p.a.
          </dd>
        </dl>
      </div>

      <div className={styles.bonusBox}>
        <div>
          {formatNumber(getNumberValue(bonusPercentage), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          })}{' '}
          % p.a. Bonus für Strom-Kund:innen
        </div>
        <div>
          <ToggleIcon
            checked={hasBonus}
            className={styles.switch}
            onChange={() => setHasBonus((value) => !value)}
          />
        </div>
      </div>

      <div className={styles.dataItems}>
        <dl className={styles.dataItem}>
          <dt className={styles.dataItemLabel}>Jährliche Rendite</dt>
          <span className={styles.dots} />
          <dd className={styles.dataItemDetail}>{formatCurrency(sum)}</dd>
        </dl>

        <dl className={styles.dataItem}>
          <dt className={styles.dataItemLabel}>Rendite nach 5 Jahren</dt>
          <span className={styles.dots} />
          <dd className={styles.dataItemDetail}>{formatCurrency(sum * 5)}</dd>
        </dl>

        <dl className={clsx(styles.dataItem, styles.highlighted)}>
          <dt className={styles.dataItemLabel}>Auszahlung nach 5 Jahren</dt>
          <span className={styles.dots} />
          <dd className={styles.dataItemDetail}>
            {formatCurrency(sum * 5 + investmentValue)}
          </dd>
        </dl>
      </div>

      <Button
        actionType={ClickableAction.Linkextern}
        size={ButtonSize.Medium}
        linkextern={link}
        className={styles.button}
      >
        Jetzt investieren
      </Button>
    </div>
  );
};

export default ACWindPowerInvestCalc;
