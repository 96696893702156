import { animated, easings, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

const EegJobSearchAnimatedIcon = ({
  children,
  className,
  withBlur = true,
  withOpacity = true,
}: PropsWithChildren<{
  className?: string;
  withBlur?: boolean;
  withOpacity?: boolean;
}>) => {
  const animationDuration = (1 + Math.random()) * 2000;

  const [animatedStyles] = useSpring(() => ({
    from: { filter: 'blur(0px)', transform: 'translateZ(0px)', opacity: 1 },
    to: {
      filter: withBlur ? 'blur(3px)' : 'blur(0px)',
      transform: 'translateZ(-100px)',
      opacity: withOpacity ? 0.3 : 1,
    },
    loop: {
      reverse: true,
      reset: false,
      config: { easing: easings.easeInOutSine },
    },
    config: {
      duration: animationDuration,
    },
  }));

  return (
    <div className={clsx(className)}>
      <animated.div style={{ willChange: 'transform', ...animatedStyles }}>
        {children}
      </animated.div>
    </div>
  );
};

export default EegJobSearchAnimatedIcon;
