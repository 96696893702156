import ModalPortal from '@/components/ModalPortal/ModalPortal';
import getProductDetailsString from '@uikit/deprecated-helpers/getProductDetailsString';
import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import React from 'react';
import { BadgeColor } from '../Badge/consts';
import { ClickableAction } from '../Clickable/consts';
import { ProductOverviewType, StepColor } from '../ProductOverview/consts';
import ProductOverview from '../ProductOverview/ProductOverview';
import ProductOverviewModalContent from '../ProductOverviewModalContent/ProductOverviewModalContent';
import type { TariffCardProps } from './interfaces';

const TariffCard = ({
  id,
  productName,
  subtitle,
  badge,
  shortDescription: _shortDescription,
  icon,
  buttonText,
  moreInfo,
  attachments,
  ...props
}: TariffCardProps) => {
  const shortDescription = _shortDescription
    ? getProductDetailsString(_shortDescription)
    : undefined;

  const [isTariffDetailsModalVisible, setIsTariffDetailsModalVisible] =
    React.useState(false);
  const clickableProps = extractClickableProps(props);
  const handleClickDetails = () => {
    setIsTariffDetailsModalVisible(true);
  };

  return (
    <>
      <ProductOverview
        productOverviewType={ProductOverviewType.PowerAndGas}
        headlineFirst={productName}
        headlineSecond={subtitle}
        badgeColor={BadgeColor.Yellow}
        tariffId={id}
        badge={badge}
        icon={icon}
        stepColor={StepColor.Surrogate}
        detailInfoText="Tarifdetails anzeigen"
        onClickDetails={() => handleClickDetails()}
        customDataLayer={{
          productId: id,
        }}
        details={shortDescription}
        {...clickableProps}
        buttonText={buttonText}
      />

      <ModalPortal
        id={'product-overview-modal'}
        onClose={() => setIsTariffDetailsModalVisible(false)}
        show={isTariffDetailsModalVisible}
        variant="shade"
        disableCloseIcon
        contentCentered
      >
        <ProductOverviewModalContent
          onClose={() => setIsTariffDetailsModalVisible(false)}
          productOverviewType={ProductOverviewType.PowerAndGas}
          actionType={ClickableAction.None}
          stepColor={StepColor.Surrogate}
          icon={icon}
          headlineFirst={productName}
          headlineSecond={subtitle}
          details={shortDescription}
          modalRightSide={moreInfo}
          attachments={attachments ?? []}
        />
      </ModalPortal>
    </>
  );
};

export default TariffCard;
