import { PRODUCTS_FORM_INFOS } from '@/consts/products';
import { logger } from '@/helpers/logger';
import useDataLayer from '@/hooks/useDataLayer/useDataLayer';
import useForm from '@/hooks/useForm/useForm';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import { FormFieldDropdownOption } from '@uikit/components/FormFieldDropdown/interfaces';
import StageBoxCalculatorFormFieldRadioGroupWrapper from '@uikit/components/StageBoxCalculatorFormFieldRadioGroupWrapper/StageBoxCalculatorFormFieldRadioGroupWrapper';
import StageBoxForm from '@uikit/components/StageBoxForm/StageBoxForm';
import React, { useEffect, useState } from 'react';
import { PRODUCT_BUTTON_ICONS, PRODUCT_OPTIONS_ORDER } from './consts';
import type {
  CustomerTypeOption,
  ProductFormType,
  ProductOption,
  StageBoxCalculatorNewProps,
} from './types';

const sortProductOptions = (options: ProductOption[]) =>
  options.sort(
    (a, b) =>
      PRODUCT_OPTIONS_ORDER.indexOf(a) - PRODUCT_OPTIONS_ORDER.indexOf(b)
  );

const productOptionFromProductFormType = (productFormType: ProductFormType) =>
  (productFormType.split('-') as ProductOption[]) || [];

const backgroundTypeToRadioGroupColor = {
  gradient: 'white',
  white: 'default',
} as const;

const StageBoxCalculatorNew = ({
  title,
  titleTag,
  productFormType,
  productCustomerType,
  powerPagePath,
  gasPagePath,
  powerAndGasPagePath,
  powerPageGwKPath,
  gasPageGwKPath,
  powerAndGasPageGwKPath,
  powerGasCombinedDefault,
  backgroundType = 'gradient',
}: StageBoxCalculatorNewProps) => {
  const { pushDataLayerEvent } = useDataLayer();

  const productOptions: ProductOption[] = sortProductOptions(
    productOptionFromProductFormType(productFormType)
  );

  const productPageLinkMap: Record<
    ProductOption,
    Record<CustomerTypeOption, string | undefined>
  > = {
    power: {
      private: powerPagePath,
      business: powerPageGwKPath,
    },
    gas: {
      private: gasPagePath,
      business: gasPageGwKPath,
    },
    combined: {
      private: powerAndGasPagePath,
      business: powerAndGasPageGwKPath,
    },
  };

  const [productOption, setProductOption] = useState<ProductOption>(
    powerGasCombinedDefault ?? productOptions.find(Boolean) ?? 'power'
  );

  const [customerType, setCustomerType] = useState<CustomerTypeOption>(
    productCustomerType === 'business-customer' ? 'business' : 'private'
  );

  const handleOnSubmit = () => {
    const pagePath = productPageLinkMap[productOption][customerType];

    pushDataLayerEvent({
      event: 'customClick2',
      isLeadingTo: 'S&G-Konfigurator',
      isSgkModulePresent: 'true',
      sgkProductSelection: PRODUCTS_FORM_INFOS[productOption].radioLabel,
      sgkCustomerTypeSelection:
        customerType === 'private' ? 'Privat' : 'Gewerbe',
    });

    if (pagePath) {
      if (typeof window !== undefined) {
        window.location.href = `${pagePath}`;
      }
    } else {
      logger.error(
        `'pagePath' for ${productOption} ${customerType} in CMS is not defined!`
      );
    }
  };

  const { handleSubmit, handleBlur } = useForm(handleOnSubmit, {}, {});

  const productDropdownOptions: FormFieldDropdownOption[] = productOptions.map(
    (value) => {
      return {
        label: PRODUCTS_FORM_INFOS[value].radioLabel,
        value,
      };
    }
  );

  const showDropdown = productDropdownOptions.length > 1;
  const showCustomerTypeRadio =
    productCustomerType === 'private-business-combined';

  const handleOnDropdownChange = (option: FormFieldDropdownOption | null) => {
    const value = (option?.value || '') as ProductOption;
    setProductOption(value);
  };

  const handleOnCustomerTypeChange = (
    $event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = $event.target;
    setCustomerType(value as CustomerTypeOption);
  };

  useEffect(() => {
    pushDataLayerEvent({
      event: 'sgkModuleUpdate',
      isSgkModulePresent: 'true',
      sgkProductSelection: PRODUCTS_FORM_INFOS[productOption].radioLabel,
      sgkCustomerTypeSelection:
        customerType === 'private' ? 'Privat' : 'Gewerbe',
    });
  }, [customerType, productOption, pushDataLayerEvent]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <StageBoxForm
          title={title}
          titleTag={titleTag}
          buttonText={PRODUCTS_FORM_INFOS[productOption].buttonText}
          buttonIcon={PRODUCT_BUTTON_ICONS[productOption]}
          backgroundType={backgroundType}
        >
          {showCustomerTypeRadio && (
            <StageBoxCalculatorFormFieldRadioGroupWrapper
              onChange={handleOnCustomerTypeChange}
              value={customerType}
              color={
                backgroundTypeToRadioGroupColor[backgroundType || 'gradient']
              }
            />
          )}

          {showDropdown && (
            <FormFieldDropdown
              options={productDropdownOptions}
              nativeSelectOnly
              label="Tarife anzeigen für"
              name="productOption"
              value={productOption}
              isBoxOnGradient={backgroundType === 'gradient'}
              onBlur={handleBlur}
              onChange={handleOnDropdownChange}
            />
          )}
        </StageBoxForm>
      </form>
    </>
  );
};
export default StageBoxCalculatorNew;
