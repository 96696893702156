import clsx from 'clsx';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import styles from './IconCardGroup.module.scss';
import type { IconCardGroupProps } from './interfaces';

const IconCardGroup = ({
  errorMessage,
  children,
  className,
}: IconCardGroupProps) => {
  return (
    <div className={clsx(styles.base, className)}>
      <div className={styles.wrapper}>{children}</div>

      {errorMessage && (
        <div>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </div>
  );
};

export default IconCardGroup;
