import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import clsx from 'clsx';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Badge from '../Badge/Badge';
import ButtonLink from '../ButtonLink/ButtonLink';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import styles from './ShowcaseElement.module.scss';
import type { ShowcaseElementProps } from './interfaces';

const ShowcaseElement = ({
  title,
  description,
  image,
  displayLink = 'none',
  // eslint-disable-next-line
  link,
  linktext,
  align = 'left',
  displayBadge,
  badgetext,
  badgeTextType,
  badgeimage,
  badgeColor,
  ...props
}: ShowcaseElementProps) => {
  const clickableProps = extractClickableProps(props);

  const showBadge = displayBadge && (badgetext || badgeimage);
  const showLink = displayLink === 'showLink' && props.actionType && linktext;
  const boxStyles = clsx(styles.box, {
    [styles.boxWithLink]: showLink,
  });
  const classBase = clsx(styles.base, styles[align]);
  const badgeDesktopStyles = clsx(styles.badge, styles.badgeDesktop);
  const badgeMobileStyles = clsx(styles.badge, styles.badgeMobile);

  return (
    <div className={styles.wrapper}>
      <div className={classBase}>
        <div className={styles.imageWrapper}>
          <LegacyPicture
            fitCover
            className={styles.image}
            asset={image}
            breakpointAspectRatioConfigs={[
              {
                breakpoint: BreakPoint.XSS,
                width: 576,
                aspectRatio: '625:448',
              },
              {
                breakpoint: BreakPoint.SM,
                aspectRatio: '625:448',
                width: 736,
              },
              {
                breakpoint: BreakPoint.MD,
                width: 960,
                aspectRatio: '625:448',
              },
              {
                breakpoint: BreakPoint.LG,
                width: 625,
                aspectRatio: '625:448',
              },
            ]}
          />
          {showBadge && (
            <div className={badgeMobileStyles}>
              <Badge
                image={badgeimage}
                color={badgeColor}
                badgeTextType={badgeTextType}
              >
                {badgetext}
              </Badge>
            </div>
          )}
        </div>
        <aside className={styles.contentWrapper}>
          <div className={styles.badgeTextElement}>
            {showBadge && (
              <div className={badgeDesktopStyles}>
                <Badge
                  image={badgeimage}
                  color={badgeColor}
                  badgeTextType={badgeTextType}
                >
                  {badgetext}
                </Badge>
              </div>
            )}
            <div className={boxStyles}>
              <h4 className={styles.title}>{title}</h4>
              <p className={styles.description}>{description}</p>
              {showLink && (
                <div className={styles.link}>
                  <ButtonLink {...clickableProps} clickContext={title}>
                    {linktext}
                  </ButtonLink>
                </div>
              )}
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default ShowcaseElement;
