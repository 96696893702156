import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import clsx from 'clsx';
import React from 'react';
import ButtonLink from '../ButtonLink/ButtonLink';
import Icon from '../Icon/Icon';
import styles from './ImageMapBubble.module.scss';
import type { ImageMapBubbleProps } from './interfaces';

const ImageMapBubble = ({
  icon,
  number,
  x,
  y,
  title,
  text,
  isActive,
  isInverted,
  linkTitle,
  ...props
}: ImageMapBubbleProps) => {
  const clickableProps = extractClickableProps(props);
  const isMobile = useIsMobile(992);

  const positionStyle: React.CSSProperties = {
    left: x + '%',
    top: y + '%',
  };
  const bubbleClasses = clsx(styles.base, {
    [styles.isActive]: isActive,
    [styles.isInverted]: isInverted,
  });

  const tooltipClasses = clsx(styles.tooltip, {
    [styles.tooltipFarLeft]: x < 12,
    [styles.tooltipFarRight]: x > 85,
    [styles.tooltipFarDown]: x > 11 && x < 86 && y > 70,
  });

  const pulseClasses = clsx(styles.pulse, {
    [styles.isActive]: isActive,
  });

  const innerPulseClasses = clsx(styles.innerPulse, {
    [styles.isActive]: isActive,
  });

  return (
    <div className={bubbleClasses} style={positionStyle}>
      <div className={pulseClasses} />
      <div className={innerPulseClasses} />
      <div className={styles.bubbleContent}>
        {number ? (
          <div className={styles.number}>{number}</div>
        ) : (
          <Icon
            variant={icon}
            size={isMobile ? 'iconSize24' : 'iconSize32'}
            isOutlined
          />
        )}
      </div>

      <div className={tooltipClasses}>
        <div className={styles.tooltipArrow} />
        <p className={styles.tooltipTitle}>{title}</p>
        <p className={styles.tooltipText}>{text}</p>
        {clickableProps && linkTitle && (
          <ButtonLink
            {...clickableProps}
            className={styles.buttonLink}
            sizeVariant="small"
          >
            {linkTitle}
          </ButtonLink>
        )}
      </div>
    </div>
  );
};

export default ImageMapBubble;
