import clsx from 'clsx';
import styles from './Group.module.scss';
import type { GroupProps } from './types';

const Group = ({ className, styleVariant, children }: GroupProps) => {
  return (
    <div
      className={clsx(
        styles.base,
        !!styleVariant && styles[styleVariant],
        className
      )}
    >
      {children}
    </div>
  );
};

export default Group;
