const TrafficLightGreen = () => {
  return (
    <svg viewBox="0 0 403 976" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_8411_40641)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268.992 12.1213C262.64 8.54187 255.256 7.23919 248.062 8.4289L35.7787 43.5368C20.3305 46.0917 9 59.4501 9 75.1081L9.00001 877.209C9.00001 888.761 15.2265 899.416 25.291 905.087L129.627 963.878C135.98 967.458 143.364 968.76 150.558 967.571L362.841 932.463C378.289 929.908 389.62 916.55 389.62 900.892L389.62 98.7912C389.62 87.2389 383.393 76.5835 373.329 70.9124L268.992 12.1213Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268.992 12.1213C262.64 8.54187 255.256 7.23919 248.062 8.4289L35.7787 43.5368C20.3305 46.0917 9 59.4501 9 75.1081L9.00001 877.209C9.00001 888.761 15.2265 899.416 25.291 905.087L129.627 963.878C135.98 967.458 143.364 968.76 150.558 967.571L362.841 932.463C378.289 929.908 389.62 916.55 389.62 900.892L389.62 98.7912C389.62 87.2389 383.393 76.5835 373.329 70.9124L268.992 12.1213Z"
          fill="black"
          fillOpacity={0.01}
        />
        <mask
          id="mask0_8411_40641"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={9}
          y={8}
          width={381}
          height={960}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M268.992 12.1213C262.64 8.54187 255.256 7.23919 248.062 8.4289L35.7787 43.5368C20.3305 46.0917 9 59.4501 9 75.1081L9.00001 877.209C9.00001 888.761 15.2265 899.416 25.291 905.087L129.627 963.878C135.98 967.458 143.364 968.76 150.558 967.571L362.841 932.463C378.289 929.908 389.62 916.55 389.62 900.892L389.62 98.7912C389.62 87.2389 383.393 76.5835 373.329 70.9124L268.992 12.1213Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_8411_40641)">
          <g filter="url(#filter1_f_8411_40641)">
            <path
              d="M139.442 142.797C139.442 131.054 147.94 121.035 159.526 119.119L367.543 84.7098C382.163 82.2915 395.46 93.5694 395.46 108.388V927.635C395.46 939.378 386.963 949.397 375.377 951.313L167.359 985.722C152.739 988.141 139.442 976.863 139.442 962.044V142.797Z"
              fill="white"
            />
          </g>
        </g>
        <path
          d="M389.62 648.632L150.558 688.169C143.364 689.358 135.98 688.056 129.627 684.476L9 616.506"
          stroke="#F2F2F2"
          strokeWidth={3}
        />
        <path
          d="M389.62 355.6L150.558 395.137C143.364 396.327 135.98 395.024 129.627 391.445L9 323.474"
          stroke="#F2F2F2"
          strokeWidth={3}
        />
        <g filter="url(#filter2_di_8411_40641)">
          <path
            d="M265.018 724.08C306.415 724.08 338.027 748.419 338.027 800.502C338.027 841.902 305.416 892.014 250.416 892.014C208.071 892.014 183.735 861.373 183.735 819.973C181.788 774.217 222.673 727.488 265.018 724.08Z"
            fill="white"
          />
          <path
            d="M265.018 724.08C306.415 724.08 338.027 748.419 338.027 800.502C338.027 841.902 305.416 892.014 250.416 892.014C208.071 892.014 183.735 861.373 183.735 819.973C181.788 774.217 222.673 727.488 265.018 724.08Z"
            fill="url(#paint0_radial_8411_40641)"
          />
        </g>
        <g filter="url(#filter3_i_8411_40641)">
          <path
            d="M263.624 435.43C305.021 435.43 336.633 459.768 336.633 511.851C336.633 553.252 304.023 603.363 249.023 603.363C206.678 603.363 182.341 572.722 182.341 531.322C180.394 485.566 221.279 438.837 263.624 435.43Z"
            fill="white"
          />
          <path
            d="M263.624 435.43C305.021 435.43 336.633 459.768 336.633 511.851C336.633 553.252 304.023 603.363 249.023 603.363C206.678 603.363 182.341 572.722 182.341 531.322C180.394 485.566 221.279 438.837 263.624 435.43Z"
            fill="url(#paint1_radial_8411_40641)"
          />
        </g>
        <g filter="url(#filter4_i_8411_40641)">
          <path
            d="M261.677 145.318C303.074 145.318 334.686 169.657 334.686 221.74C334.686 263.141 302.076 313.252 247.076 313.252C204.731 313.252 180.394 282.611 180.394 241.211C178.447 195.455 219.332 148.726 261.677 145.318Z"
            fill="white"
          />
          <path
            d="M261.677 145.318C303.074 145.318 334.686 169.657 334.686 221.74C334.686 263.141 302.076 313.252 247.076 313.252C204.731 313.252 180.394 282.611 180.394 241.211C178.447 195.455 219.332 148.726 261.677 145.318Z"
            fill="url(#paint2_radial_8411_40641)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_8411_40641"
          x={0.263672}
          y={0}
          width={397.601}
          height={976}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8411_40641"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8411_40641"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_8411_40641"
          x={131.442}
          y={76.3823}
          width={272.018}
          height={917.667}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation={4}
            result="effect1_foregroundBlur_8411_40641"
          />
        </filter>
        <filter
          id="filter2_di_8411_40641"
          x={119.668}
          y={660.08}
          width={282.359}
          height={295.934}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={32} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.392157 0 0 0 0 0.701961 0 0 0 0 0.172549 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8411_40641"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8411_40641"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={-12} />
          <feGaussianBlur stdDeviation={12} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_8411_40641"
          />
        </filter>
        <filter
          id="filter3_i_8411_40641"
          x={182.274}
          y={423.429}
          width={154.359}
          height={179.934}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={-12} />
          <feGaussianBlur stdDeviation={12} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8411_40641"
          />
        </filter>
        <filter
          id="filter4_i_8411_40641"
          x={180.327}
          y={133.318}
          width={154.359}
          height={179.934}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={-12} />
          <feGaussianBlur stdDeviation={12} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_8411_40641"
          />
        </filter>
        <radialGradient
          id="paint0_radial_8411_40641"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(251.389 790.767) rotate(4.35139) scale(89.8164 89.8234)"
        >
          <stop stopColor="#64B32C" stopOpacity={0.63} />
          <stop offset={1} stopColor="#64B32C" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_8411_40641"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(249.996 502.116) rotate(4.35139) scale(89.8164 89.8234)"
        >
          <stop stopColor="#FFF5CC" stopOpacity={0.63} />
          <stop offset={1} stopColor="#FFF5CC" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_8411_40641"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(248.049 212.005) rotate(4.35139) scale(89.8164 89.8234)"
        >
          <stop stopColor="#FFE6E6" stopOpacity={0.63} />
          <stop offset={1} stopColor="#FFE6E6" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default TrafficLightGreen;
