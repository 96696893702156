import React, { useContext } from 'react';
import FormField from '@uikit/components/FormField/FormField';
import FormRow from '@uikit/components/FormRow/FormRow';
import type { FullNameProps } from './interfaces';
import { FormBuilderContext } from '../../FormBuilder';
import { FormFieldElement } from '@uikit/components/FormField/interfaces';

const FullName = ({
  fieldName,
  isOptional,
  useForMailTemplate,
}: FullNameProps) => {
  const context = useContext(FormBuilderContext);

  const handleChange = (event: React.ChangeEvent<FormFieldElement>) => {
    context.handleChange(event);

    if (useForMailTemplate) {
      if (event.target.name.endsWith('Vorname')) {
        context.setFieldValue('firstName', event.target.value);
      }

      if (event.target.name.endsWith('Nachname')) {
        context.setFieldValue('lastName', event.target.value);
      }
    }
  };

  return (
    <FormRow>
      <FormField
        label={isOptional ? 'Vorname (optional)' : 'Vorname'}
        name={`${fieldName} - Vorname`}
        onChange={handleChange}
        onBlur={context.handleBlur}
        errorMessage={context.getErrorByFieldName(`${fieldName} - Vorname`)}
        value={context.formState[`${fieldName} - Vorname`]}
        autoComplete="given-name"
      />
      <FormField
        label={isOptional ? 'Nachname (optional)' : 'Nachname'}
        name={`${fieldName} - Nachname`}
        onChange={handleChange}
        onBlur={context.handleBlur}
        errorMessage={context.getErrorByFieldName(`${fieldName} - Nachname`)}
        value={context.formState[`${fieldName} - Nachname`]}
        autoComplete="family-name"
      />
    </FormRow>
  );
};

export default FullName;
