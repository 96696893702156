import { logger } from '@/helpers/logger';
import useIsMounted from '@/hooks/useIsMounted/useIsMounted';
import RichText from '@uikit/components/RichText/RichText';
import Table from '@uikit/components/Table/Table';
import { Fragment } from 'react';
import { parseTable } from './parseTable';
import type { TableWrapperProps } from './types';

const TableWrapper = ({ table, ...props }: TableWrapperProps) => {
  const isMounted = useIsMounted();

  try {
    if (!isMounted && typeof table === 'string') {
      return null;
    }

    const parsedTable = typeof table === 'string' ? parseTable(table) : table;

    return parsedTable?.map(({ htmlBefore, htmlAfter, tableData }) => {
      if (!tableData) {
        return null;
      }

      const key =
        tableData.head?.join('') + tableData.body.join('').substring(0, 20);

      return (
        <Fragment key={key}>
          {htmlBefore && <RichText>{htmlBefore}</RichText>}
          <Table tableData={tableData} {...props} />
          {htmlAfter && <RichText>{htmlAfter}</RichText>}
        </Fragment>
      );
    });
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export default TableWrapper;
