import { HeaderLink } from '@/hooks/useHeader/types';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Button from '../Button/Button';
import { ButtonSize } from '../Button/consts';
import { LegacyPageHeaderContent } from '../LegacyHeader/types';
import NewHeader from '../NewHeader/NewHeader';
import PageHeader from '../NewHeader/PageHeader/PageHeader';
import { NewHeaderProps } from '../NewHeader/types';
import { LegacyJobHeaderProps } from './types';

const LegacyJobHeader = ({
  contents,
  ctaText,
  favorites = [],
  headerTitle,
  headerTitleLink,
  icon,
  invert,
  isFavorite,
  navItems,
  noSticky,
  pageNavItemMain,
  parentPagePath,
  showIcon,
  siteVersion = 2,
  ...props
}: LegacyJobHeaderProps) => {
  const clickableProps = extractClickableProps(props);

  const pageHeaderButton = !ctaText ? undefined : (
    <Button
      {...clickableProps}
      clickContext="Page Header"
      icon={icon}
      showIcon={showIcon}
      size={ButtonSize.Small}
    >
      {ctaText}
    </Button>
  );

  const pageHeaderLinks = !contents?.length
    ? []
    : (contents as unknown as LegacyPageHeaderContent[])
        .filter((content) => content.componentName.startsWith('navItems'))
        .map<HeaderLink>(({ props }) => {
          return {
            isInternalUrl: props.link === 'page',
            title: props.title,
            url: props.link === 'page' ? props.page : props.url,
          };
        });

  const convertedProps: NewHeaderProps = {
    hasPageHeader: pageHeaderLinks.length > 0 || !!headerTitle,
    hasPreHeader: false,
    isFullWidth: invert,
    isInverted: invert,
    isSticky: !noSticky,
    renderPageHeader: (props) => (
      <PageHeader
        {...props}
        addonsSlot={
          <>
            <PageHeader.Action
              badgeValue={favorites.length}
              icon="action/heart"
              isInternalUrl
              title="Merkliste"
              url="/karriere/jobsuche/favoriten"
            />
            {pageHeaderButton}
          </>
        }
        links={pageHeaderLinks}
        overviewUrl={headerTitleLink ?? parentPagePath}
        title={headerTitle}
      />
    ),
    variant: 'career',
  };

  return <NewHeader {...convertedProps} />;
};

export default LegacyJobHeader;
