import React, { useCallback } from 'react';

// eslint-disable-next-line
const useScrollToRef = (
  ref?: React.RefObject<HTMLElement>,
  behavior: ScrollBehavior = 'smooth'
) =>
  useCallback(
    ($event?: React.SyntheticEvent): void => {
      if ($event) $event.preventDefault();
      if (ref && ref.current) ref.current.scrollIntoView({ behavior });
    },
    [ref, behavior]
  );

export default useScrollToRef;
