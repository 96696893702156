import React, { useState } from 'react';
import { animated, useTransition } from '@react-spring/web';
import type { MapLoadingOverlayProps } from './interfaces';
import styles from './MapLoadingOverlay.module.scss';
import MapLoadingOverlayLottie from './MapLoadingOverlayLottie/MapLoadingOverlayLottie';
import MapLoadingOverlayProgress from './MapLoadingOverlayProgress/MapLoadingOverlayProgress';

const MapLoadingOverlay = ({ isVisible }: MapLoadingOverlayProps) => {
  const [isLottieAnimationRunning, setIsLottieAnimationRunning] =
    useState(true);

  const handleLottieAnimationFinished = () => {
    setIsLottieAnimationRunning(false);
  };

  const baseTransition = useTransition(isLottieAnimationRunning, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  });

  return (
    <>
      {baseTransition(
        (animatedStyles, item) =>
          item && (
            <animated.div style={animatedStyles} className={styles.base}>
              <MapLoadingOverlayLottie
                isPlaying={isVisible}
                onAnimationEnd={handleLottieAnimationFinished}
              />
              <MapLoadingOverlayProgress />
            </animated.div>
          )
      )}
    </>
  );
};

export default MapLoadingOverlay;
