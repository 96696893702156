import { BoxWidth } from '@uikit/components/Box/consts';
import clsx from 'clsx';
import Box from '../../components/Box/Box';
import GoogleStreetSuggestions from '../../components/GoogleStreetSuggestions/GoogleStreetSuggestions';
import styles from './SearchBox.module.scss';
import type { SearchBoxProps } from './interfaces';

const SearchBox = ({ boxClassname, ...props }: SearchBoxProps) => {
  return (
    <Box className={clsx(styles.box, boxClassname)} width={BoxWidth.Auto}>
      <GoogleStreetSuggestions {...props} />
    </Box>
  );
};

export default SearchBox;
