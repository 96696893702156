import useFrcCaptcha from '@/hooks/useFrcCaptcha/useFrcCaptcha';
import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormInfoBox from '@uikit/components/FormInfoBox/FormInfoBox';
import FormRow from '@uikit/components/FormRow/FormRow';
import { useState } from 'react';
import submitContactForm from '../../../helpers/submitContactForm';
import { UseFormState } from '../../../hooks/useForm/interfaces';
import useForm from '../../../hooks/useForm/useForm';
import { FormComponentProps } from '../interfaces';
import { EMobilityAdviceFormValues } from './interfaces';
import schema from './schema';

const initialFormValues: EMobilityAdviceFormValues = {
  firstName: '',
  lastName: '',
  mail: '',
  phone: '',
  street: '',
  streetNumber: '',
  city: '',
  zipcode: '',
};

const EMobilityAdviceForm = ({
  onSuccess,
  buttonLabel,
  dataProcessing,
  ...options
}: FormComponentProps) => {
  const [buttonState, setButtonState] = useState(ButtonSubmitState.Initial);
  const { getSolution, FrcCaptchaComponent } = useFrcCaptcha();

  const callbackOnSubmit = async (formState: UseFormState) => {
    setButtonState(ButtonSubmitState.Pending);
    try {
      const frcCaptchaSolution = await getSolution();
      const response = await submitContactForm(formState, {
        ...options,
        frcCaptchaSolution,
      });
      const json = await response.json();

      if (json.message === 'success') {
        setButtonState(ButtonSubmitState.Success);
        onSuccess && setTimeout(onSuccess, 1500);
      } else {
        throw Error;
      }
    } catch (error) {
      setButtonState(ButtonSubmitState.Fail);
      setTimeout(() => {
        setButtonState(ButtonSubmitState.Initial);
      }, 2000);
    }
  };
  const {
    formState,
    getErrorByFieldName,
    isFormValid,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useForm(callbackOnSubmit, schema, initialFormValues);

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="firstName"
          value={formState['firstName']}
          errorMessage={getErrorByFieldName('firstName')}
          label="Ihr Vorname"
          autoComplete="given-name"
          id="first-name"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="lastName"
          value={formState['lastName']}
          errorMessage={getErrorByFieldName('lastName')}
          label="Ihr Nachname"
          autoComplete="family-name"
          id="last-name"
        />
      </FormRow>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="street"
          value={formState['street']}
          errorMessage={getErrorByFieldName('street')}
          label="Straße"
          autoComplete="address-line1"
          id="street"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="streetNumber"
          value={formState['streetNumber']}
          errorMessage={getErrorByFieldName('streetNumber')}
          label="Haus-Nr."
          autoComplete="address-line2"
          halfWidth
          id="street-number"
        />
      </FormRow>
      <FormRow>
        <FormField
          maxLength={5}
          onBlur={handleBlur}
          onChange={handleChange}
          name="zipcode"
          value={formState['zipcode']}
          errorMessage={getErrorByFieldName('zipcode')}
          label="PLZ"
          autoComplete="postal-code"
          halfWidth
          id="zipcode"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="city"
          value={formState['city']}
          errorMessage={getErrorByFieldName('city')}
          label="Ort"
          autoComplete="address-level2"
          id="city"
        />
      </FormRow>
      <FormRow>
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="mail"
          value={formState['mail']}
          errorMessage={getErrorByFieldName('mail')}
          label="E-Mail-Adresse"
          autoComplete="email"
          id="email"
        />
        <FormField
          onBlur={handleBlur}
          onChange={handleChange}
          name="phone"
          value={formState['phone']}
          errorMessage={getErrorByFieldName('phone')}
          label="Telefonnummer (optional)"
          autoComplete="tel"
          id="phone"
        />
      </FormRow>
      <FormRow>
        <FormInfoBox>{dataProcessing}</FormInfoBox>
      </FormRow>
      <FormRow fullWidthChildren={false} bottomMarginSize={{ default: 1.125 }}>
        <ButtonSubmit
          inactive={!isFormValid}
          state={buttonState}
          id={'submit-request-advice-form'}
        >
          {buttonLabel}
        </ButtonSubmit>
      </FormRow>

      <FrcCaptchaComponent />
    </form>
  );
};

export default EMobilityAdviceForm;
