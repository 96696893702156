import { extractClickableProps } from '@uikit/helpers/extractClickableProps';
import clsx from 'clsx';
import ButtonLink from '../ButtonLink/ButtonLink';
import Icon from '../Icon/Icon';
import styles from './ImageMapTooltip.module.scss';
import type { ImageMapTooltipProps } from './interfaces';

const ImageMapTooltip = ({
  text,
  title,
  isFirst,
  isActive,
  isInverted,
  linkTitle,
  ...props
}: ImageMapTooltipProps) => {
  const clickableProps = extractClickableProps(props);

  const tooltipClasses = clsx(styles.base, {
    [styles.isFirst]: isFirst,
    [styles.isActive]: isActive,
    [styles.isInverted]: isInverted,
  });

  return (
    <div className={tooltipClasses}>
      <p className={styles.title}>{title}</p>
      <p className={styles.text}>{text}</p>
      {isFirst && (
        <Icon
          className={styles.icon}
          variant={'action/arrow-right'}
          size="iconSize24"
        />
      )}
      {clickableProps && linkTitle && (
        <ButtonLink
          {...clickableProps}
          className={styles.buttonLink}
          sizeVariant="small"
        >
          {linkTitle}
        </ButtonLink>
      )}
    </div>
  );
};

export default ImageMapTooltip;
