import Section from '@uikit/components/Section/Section';
import {
  SectionBackgroundColor,
  SectionVariant,
} from '@uikit/components/Section/consts';
import React from 'react';
import styles from './AdditionalContents.module.scss';

const AdditionalContents = ({ children }: { children: React.ReactNode }) => {
  return (
    <Section
      variant={SectionVariant.LargePadding}
      bgColor={SectionBackgroundColor.Violet}
      className={styles.section}
      withShadow
    >
      {children}
    </Section>
  );
};

export default AdditionalContents;
