import * as ajax from './ajax';
import convertObjectToSearchParams from './convertObjectToSearchParams';
import { logger } from './logger';

type StreetSuggestionsResponse = {
  streets: string[];
};

type GetStreetSuggestionOptions = {
  term: string;
  cityKey?: string;
  city: string;
  zipCode: string;
  limit?: number;
};

const DEFAULT_LIMIT = 3;
const SERVICEKIT_URL = process.env.SERVICEKIT_URL;

const fetchSuGRStreetSuggestion = async ({
  limit = DEFAULT_LIMIT,
  term,
  ...options
}: GetStreetSuggestionOptions): Promise<string[]> => {
  const endpoint = new URL(`/api/sugr/streets/suggestions`, SERVICEKIT_URL);

  const params = convertObjectToSearchParams({
    limit,
    query: term,
    ...options,
  });

  try {
    const data = await ajax.getJson<StreetSuggestionsResponse>(
      endpoint + params
    );
    if (!data.streets) {
      logger.warn(data);
      return [];
    }

    return data.streets;
  } catch (error) {
    logger.error(error);
  }

  return [];
};

export default fetchSuGRStreetSuggestion;
