import RichText from '@uikit/components/RichText/RichText';
import React from 'react';
import styles from './ProductTeaserText.module.scss';
import clsx from 'clsx';
import { ProductTeaserTextProps } from './interfaces';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import Button from '@uikit/components/Button/Button';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import {
  ButtonColor,
  ButtonSize,
  ShowIcon,
} from '@uikit/components/Button/consts';
import ProductTeaserHeadline from '../ProductTeaserHeadline/ProductTeaserHeadline';

const ProductTeaserText = ({
  variant,
  children,
  page,
  headline,
}: ProductTeaserTextProps) => {
  const isSm = useIsMobile(768);

  return (
    <>
      <ProductTeaserHeadline size="default" variant={variant}>
        {headline}
      </ProductTeaserHeadline>
      <RichText className={clsx(styles.base, styles[variant])}>
        {children}
      </RichText>

      <Button
        actionType={ClickableAction.Linkintern}
        linkintern={page}
        color={
          variant === 'simple' ? ButtonColor.Primary : ButtonColor.Secondary
        }
        size={isSm ? ButtonSize.Medium : ButtonSize.Large}
        showIcon={ShowIcon.ShowIcon}
        icon={'action/arrow-right'}
      >
        Jetzt konfigurieren
      </Button>
    </>
  );
};

export default ProductTeaserText;
