import { SHOW_ALL_FILTER_NAME } from '@uikit/components/ImageGrid/consts';
import {
  GroupItem,
  ImageGridGroup,
} from '@uikit/components/ImageGrid/interfaces';

const generateImageGridItems = (
  groups: ImageGridGroup[],
  currentFilter: string
): GroupItem[] => {
  const filteredItems = groups.filter(
    ({ name }) =>
      currentFilter === SHOW_ALL_FILTER_NAME || name === currentFilter
  );

  return filteredItems.flatMap((group) =>
    group.items.map((item) => ({ ...item, category: group.name }))
  );
};

export default generateImageGridItems;
