import useLocalStorage from '../useLocalStorage/useLocalStorage';

const useJobFavorites = (
  knownJobIds: Set<string>
): {
  readonly isFavoriteJob: (id: string) => boolean;
  readonly favoriteJobs: readonly string[];
  readonly toggleFavoriteJob: (id: string) => void;
} => {
  const [storedFavoriteJobs, setStoredFavoriteJobs] = useLocalStorage<string[]>(
    'enercity@job-favorites',
    []
  );

  const isFavoriteJob = (id: string) => storedFavoriteJobs.includes(id);
  const favoriteJobs = storedFavoriteJobs.filter((jobId) =>
    knownJobIds.has(jobId)
  );
  const toggleFavoriteJob = (id: string) => {
    const isFavorite = isFavoriteJob(id);

    if (isFavorite) {
      setStoredFavoriteJobs((oldState) =>
        oldState.filter((jobId) => jobId !== id)
      );
    } else {
      setStoredFavoriteJobs((oldState) => [...oldState, id]);
    }
  };

  return {
    toggleFavoriteJob,
    isFavoriteJob,
    favoriteJobs,
  };
};

export default useJobFavorites;
