import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import UnorderedList from '@uikit/components/UnorderedList/UnorderedList';
import UnorderedListItem from '@uikit/components/UnorderedList/UnorderedListItem';
import React from 'react';
import { reducedNetFeeOptions } from '../config';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep5,
} from '../interfaces';
import schema from './RegisterChargingStationStep5.schema';

const registerChargingStationInitalStateStep5: RegisterChargingStationStateStep5 =
  {
    reducedNetFee: '',
  };

const RegisterChargingStationStep5 = ({
  onSubmit,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep5);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>
      <StepHeadline>Steuerung der Ladeeinrichtung</StepHeadline>
      <p>
        Wer seine Ladeeinrichtung für eine netzdienliche Steuerung seitens des
        Netzbetreibers zur Verfügung stellt, kann derzeit verminderte
        Netzentgelte in Anspruch nehmen. Dazu ist vorab eine Vereinbarung
        zwischen dem Lieferanten und dem Netzbetreiber erforderlich. Außerdem
        muss die Ladesäule vom Ladesäulenbetreiber für eine netzdienliche
        Steuerung technisch ausgerüstet werden.
      </p>
      <p> Folgende technischen Voraussetzungen sind dabei zu erfüllen: </p>
      <UnorderedList padding={false}>
        <UnorderedListItem icon="action/checkmark">
          Der Abrechnungszähler dient ausschließlich der Versorgung von zur
          Ladeeinrichtung gehörenden Verbrauchsmitteln.
        </UnorderedListItem>
        <UnorderedListItem icon="action/checkmark">
          Im direkten Nahbereich der Zählung (ggf. im anlagenseitigen
          Anschlussraum bzw. im Raum für Zusatzanwendungen) wird ein
          Hutschienenplatz (mit 8 Teilungseinheiten) für ein Kommunikations- und
          Steuergerät („Steuerbox“) dauerhaft freigehalten, so dass die
          Möglichkeit des Nachrüstens der Steuerbarkeit gewährleistet ist.
        </UnorderedListItem>
        <UnorderedListItem icon="action/checkmark">
          Die Ladeeinrichtung muss entweder über potenzialfreie Kontakte oder
          über ein Steuergerät in Stufen steuerbar sein.
        </UnorderedListItem>
      </UnorderedList>
      <p>
        Des Weiteren müssen Sie Ihren Stromlieferanten informieren, dass der
        Abrechnungszähler der Ladesäule mit dem Hinweis “vermindertes
        Netzentgelt“ beim Netzbetreiber angemeldet werden muss.
      </p>
      <p>
        Die Höhe der Netzentgelte sowie der verminderten Netzentgelte
        (steuerbare Last) finden Sie in unserem Preisblatt Strom unter
        Preisblatt 2.
      </p>
      <FormRow description="Ich möchte verminderte Netzentgelte in Anspruch nehmen und stelle meine Ladeeinrichtung für die netzdienliche Steuerung zur Verfügung. Die  vorstehend genannten technischen Voraussetzungen werde ich sicherstellen und meinen Lieferanten unverzüglich informieren.">
        <FormFieldRadioGroup
          items={reducedNetFeeOptions}
          value={formState['reducedNetFee'] || ''}
          name="reducedNetFee"
          onChange={handleChange}
          errorMessage={getErrorByFieldName('reducedNetFee')}
        />
      </FormRow>
      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default RegisterChargingStationStep5;
