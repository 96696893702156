import useForm from '@/hooks/useForm/useForm';
import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import CheckBoxBlock from '@uikit/components/CheckBoxBlock/CheckBoxBlock';
import {
  ClickableAction,
  ClickableTarget,
} from '@uikit/components/Clickable/consts';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import { FormFieldDropdownOption } from '@uikit/components/FormFieldDropdown/interfaces';
import FormRow from '@uikit/components/FormRow/FormRow';
import { HeadlineColor } from '@uikit/components/Headline/consts';
import Headline from '@uikit/components/Headline/Headline';
import StepHeadline from '@uikit/components/StepHeadline/StepHeadline';
import Clickable from '@uikit/components/Clickable/Clickable';

import React from 'react';
import {
  buildingTypeOptions,
  locationOptions,
  publicStationOptions,
  usageTypeOptions,
} from '../config';
import {
  RegisterChargingStationFormStep,
  RegisterChargingStationStateStep2,
} from '../interfaces';
import schema from './RegisterChargingStationStep2.schema';

export const registerChargingStationInitalStateStep2: RegisterChargingStationStateStep2 =
  {
    buildingType: '',
    feedbackPlanned: false,
    location: '',
    locationPlanAvailable: false,
    publicStation: '',
    usageType: '',
  };

const RegisterChargingStationStep2 = ({
  onSubmit,
  usageVariantsFile,
}: RegisterChargingStationFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleChange,
    handleChangeValue,
    handleBlur,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, registerChargingStationInitalStateStep2);

  const handleChangeOption =
    (fieldName: keyof typeof formState & string) =>
    (option: FormFieldDropdownOption | null) =>
      handleChangeValue(fieldName)(option?.value);

  return (
    <form onSubmit={handleSubmit}>
      <Headline headlineColor={HeadlineColor.Gradient}>
        Anmeldung für eine Ladeeinrichtung für Elektrofahrzeuge
      </Headline>
      <StepHeadline>Ihre Anschluss-Situation</StepHeadline>

      <p>
        Bitte beschreiben Sie uns die Situation vor Ort und Ihre (geplante)
        Ladeeinrichtung so gut wie möglich, damit wir ein umfassendes Bild von
        Ihrem Vorhaben bekommen.
      </p>
      <FormRow title="Standort">
        <FormFieldDropdown
          label={'Wo soll Ihre Ladeeinrichtung platziert werden?'}
          name={'publicStation'}
          value={formState['publicStation']}
          options={publicStationOptions}
          nativeOptions={publicStationOptions}
          onBlur={handleBlur}
          onChange={handleChangeOption('publicStation')}
          errorMessage={getErrorByFieldName('publicStation')}
        />
      </FormRow>
      <FormRow>
        <FormFieldDropdown
          label={'Um welchen Gebäudetyp handelt es sich?'}
          name={'buildingType'}
          value={formState['buildingType']}
          options={buildingTypeOptions}
          nativeOptions={buildingTypeOptions}
          onBlur={handleBlur}
          onChange={handleChangeOption('buildingType')}
          errorMessage={getErrorByFieldName('buildingType')}
        />
      </FormRow>
      <FormRow>
        <FormFieldDropdown
          label={'Wo soll geladen werden?'}
          name={'location'}
          value={formState['location']}
          options={locationOptions}
          nativeOptions={locationOptions}
          onBlur={handleBlur}
          onChange={handleChangeOption('location')}
          errorMessage={getErrorByFieldName('location')}
          errorMessageHasRelativePosition
        />
      </FormRow>
      <FormRow
        title="Nutzungsvariante"
        description={
          <>
            Eine Erläuterung der Varianten finden Sie in dieser{' '}
            <Clickable
              actionType={ClickableAction.Asset}
              target={ClickableTarget.Blank}
              asset={usageVariantsFile}
              download
            >
              Skizze
            </Clickable>
            .
          </>
        }
      >
        <FormFieldDropdown
          label={'Typauswahl?'}
          name={'usageType'}
          value={formState['usageType']}
          options={usageTypeOptions}
          nativeOptions={usageTypeOptions}
          onBlur={handleBlur}
          onChange={handleChangeOption('usageType')}
          errorMessage={getErrorByFieldName('usageType')}
        />
      </FormRow>
      <FormRow
        title="Soll Ihre Ladeeinrichtung Energie ins Netz zurückspeisen?"
        description={
          <>
            Falls ja, sind weitere technische Maßnahmen in Ihrer Installation
            erforderlich (Zweirichtungszähler, etc.). Bitte sprechen Sie in
            diesem Fall ihren Elektroinstallateur an.
          </>
        }
      >
        <CheckBoxBlock
          onChange={handleChange}
          name={'feedbackPlanned'}
          errorMessage={getErrorByFieldName('feedbackPlanned')}
        >
          Rückspeisung vorgesehen
        </CheckBoxBlock>
      </FormRow>
      <FormRow
        title="Lageplan"
        description={
          <>
            Bitte schicken Sie uns den Lageplan und den Übersichtsplan an{' '}
            <a href="mailto:messwesen-ladeeinrichtung@enercity-netz.de">
              messwesen-ladeeinrichtung@enercity-netz.de
            </a>
            <br />
            Bei der Erstellung der Pläne kann Ihr Elektroinstallateur Sie
            unterstützen. Bitte senden Sie uns ebenso die Konformitätserklärung
            für die Ladeeinrichtung an die o.g. E-Mail-Adresse. Wir benötigen
            diese als Nachweis der Erfüllung der technischen Anforderungen.
          </>
        }
      >
        <CheckBoxBlock
          onChange={handleChange}
          name={'locationPlanAvailable'}
          errorMessage={getErrorByFieldName('locationPlanAvailable')}
        >
          Lageplan vorhanden
        </CheckBoxBlock>
      </FormRow>
      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default RegisterChargingStationStep2;
