import React from 'react';
import { HeroSize } from '../HeroArea/consts';
import HeroArea from '../HeroArea/HeroArea';
import { HeroAreaProps } from '../HeroArea/interfaces';

const SmallHeroArea = (props: HeroAreaProps) => {
  // @ts-ignore
  return <HeroArea size={HeroSize.Small} {...props} />;
};
export default SmallHeroArea;
