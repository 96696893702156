import React from 'react';
import type { UmbrellaCampaignTeaserTileSectionProps } from './types';
import styles from './UmbrellaCampaignTeaserTileSection.module.scss';

const UmbrellaCampaignTeaserTileSection = ({
  children,
}: UmbrellaCampaignTeaserTileSectionProps) => {
  return (
    <div className={styles.base}>
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          {children &&
            React.Children.map(children, (child) => (
              <div className={styles.item}>{child}</div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default UmbrellaCampaignTeaserTileSection;
