import FlipCardWrapper from '@/components/FlipCardWrapper/FlipCardWrapper';
import ImageMapWrapper from '@/components/ImageMapWrapper/ImageMapWrapper';
import KununuScoreMagnoliaWrapper from '@/components/KununuScoreMagnoliaWrapper/KununuScoreMagnoliaWrapper';
import ProductComparisonWrapper from '@/components/ProductComparisonWrapper/ProductComparisonWrapper';
import SolarVideoChatTeaserSection from '@/components/SolarVideoChatTeaserSection/SolarVideoChatTeaserSection';
import StageBoxCalculatorNew from '@/components/StageBoxCalculatorNew/StageBoxCalculatorNew';
import TableWrapper from '@/components/TableWrapper/TableWrapper';
import FiberOpticMap from '@/uikit/components/FiberOpticMap/FiberOpticMap';
import Accordion from '@uikit/components/Accordion/Accordion';
import ACWindPowerInvestCalc from '@uikit/components/ACWindPowerInvestCalc/ACWindPowerInvestCalc';
import AdditionalContents from '@uikit/components/AdditionalContents/AdditionalContents';
import BoxTeaserWrapper from '@uikit/components/BoxTeaser/BoxTeaserWrapper';
import ButtonLink from '@uikit/components/ButtonLink/ButtonLink';
import CMPCookieInfo from '@uikit/components/CMPCookieInfo/CMPCookieInfo';
import CMPVendorList from '@uikit/components/CMPVendorList/CMPVendorList';
import CommunityHeatPlannerMap from '@uikit/components/CommunityHeatPlannerMap/CommunityHeatPlannerMap';
import ComponentList from '@uikit/components/ComponentList/ComponentList';
import ContactBlock from '@uikit/components/ContactBlock/ContactBlock';
import ContactTelephone from '@uikit/components/ContactTelephone/ContactTelephone';
import Container from '@uikit/components/Container/Container';
import DistrictHeatMap from '@uikit/components/DistrictHeatMap/DistrictHeatMap';
import DisturbanceMapIframe from '@uikit/components/DisturbanceMapIframe/DisturbanceMapIframe';
import DisturbanceMapIframeEng from '@uikit/components/DisturbanceMapIframeEng/DisturbanceMapIframeEng';
import Download from '@uikit/components/Download/Download';
import EegJobSearch from '@uikit/components/EegJobSearch/EegJobSearch';
import EnercityFooterWrapper from '@uikit/components/EnercityFooter/EnercityFooter.wrapper';
import FullScreenStage from '@uikit/components/FullScreenStage/FullScreenStage';
import Group from '@uikit/components/Group/Group';
import GuideBox from '@uikit/components/GuideBox/GuideBox';
import GuideBoxList from '@uikit/components/GuideBoxList/GuideBoxList';
import Headline from '@uikit/components/Headline/Headline';
import HeatProductTeasers from '@uikit/components/HeatProductTeasers/HeatProductTeasers';
import HeatPumpSavingsCalculator from '@uikit/components/HeatPumpSavingsCalculator/HeatPumpSavingsCalculator';
import HintBox from '@uikit/components/HintBox/HintBox';
import Icon from '@uikit/components/Icon/Icon';
import Iframe from '@uikit/components/Iframe/Iframe';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import Image from '@uikit/components/Image/Image';
import ImageCompositionMagnoliaWrapper from '@uikit/components/ImageCompositionGridMagnoliaWrapper/ImageCompositionMagnoliaWrapper';
import ImageGallery from '@uikit/components/ImageGallery/ImageGallery';
import ImageGrid from '@uikit/components/ImageGrid/ImageGrid';
import InfoBox from '@uikit/components/InfoBox/InfoBox';
import JobApplySteps from '@uikit/components/JobApplySteps/JobApplySteps';
import JobBenefitTeaser from '@uikit/components/JobBenefitTeaser/JobBenefitTeaser';
import JobCallout from '@uikit/components/JobCallout/JobCallout';
import JobListWrapper from '@uikit/components/JobListWrapper/JobListWrapper';
import JobShiftModel from '@uikit/components/JobShiftModel/JobShiftModel';
import LegacyHeader from '@uikit/components/LegacyHeader/LegacyHeader.wrapper';
import LegacyJobHeader from '@uikit/components/LegacyJobHeader/LegacyJobHeader.wrapper';
import LottieWrapper from '@uikit/components/LottieWrapper/LottieWrapper';
import NavItem from '@uikit/components/NavItem/NavItem';
import NoticeBar from '@uikit/components/NoticeBar/NoticeBar';
import ProductTeaser from '@uikit/components/ProductTeaser/ProductTeaser';
import Quote from '@uikit/components/Quote/Quote';
import QuoteCarouselWrapper from '@uikit/components/QuoteCarousel/QuoteCarouselWrapper';
import RichText from '@uikit/components/RichText/RichText';
import RiveWrapper from '@uikit/components/RiveWrapper/RiveWrapper';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import ShowcaseElement from '@uikit/components/ShowcaseElement/ShowcaseElement';
import ShowcaseList from '@uikit/components/ShowcaseList/ShowcaseList';
import StageBoxText from '@uikit/components/StageBoxText/StageBoxText';
import StageBoxWhite from '@uikit/components/StageBoxWhite/StageBoxWhite';
import TariffCard from '@uikit/components/TariffCard/TariffCard';
import Teaser from '@uikit/components/Teaser/Teaser';
import TeaserImageCardBigWrapper from '@uikit/components/TeaserImageCard/TeaserImageCardBig.wrapper';
import TeaserImageCardSmallWrapper from '@uikit/components/TeaserImageCard/TeaserImageCardSmall.wrapper';
import TeaserTile from '@uikit/components/TeaserTile/TeaserTile';
import TeaserTileGroup from '@uikit/components/TeaserTileGroup/TeaserTileGroup';
import TeaserTileLarge from '@uikit/components/TeaserTileLarge/TeaserTileLarge';
import TeaserWithImage from '@uikit/components/TeaserWithImage/TeaserWithImage';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import UmbrellaCampaignStoriesSectionWrapper from '@uikit/components/UmbrellaCampaignStoriesSectionWrapper/UmbrellaCampaignStoriesSectionWrapper';
import UmbrellaCampaignTeaserTileSectionWrapper from '@uikit/components/UmbrellaCampaignTeaserTileSectionWrapper/UmbrellaCampaignTeaserTileSectionWrapper';
import VideoWrapper from '@uikit/components/Video/Video.wrapper';
import VideoTeaser from '@uikit/components/VideoTeaser/VideoTeaser';
import WaermewendeMapIframe from '@uikit/components/WaermewendeMapIframe/WaermewendeMapIframe';
import WedemarkMap from '@uikit/components/WedemarkMap/WedemarkMap';
import GasConsumptionTrafficLight from '@uikit/composites/GasConsumptionTrafficLight/GasConsumptionTrafficLight';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import HeroAreaContent from '@uikit/composites/HeroArea/HeroAreaContent';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import SplitContainer from '@uikit/composites/SplitContainer/SplitContainer';
import TabsCardsItem from '@uikit/composites/TabsCardsItem/TabsCardsItem';
import TabsContainer from '@uikit/composites/TabsContainer/TabsContainer';
import React from 'react';
import Address from '../components/Forms/FormBuilder/parts/Address/Address';
import Contact from '../components/Forms/FormBuilder/parts/Contact/Contact';
import CustomCheckBox from '../components/Forms/FormBuilder/parts/CustomCheckBox/CustomCheckBox';
import CustomRadio from '../components/Forms/FormBuilder/parts/CustomRadio/CustomRadio';
import CustomSelect from '../components/Forms/FormBuilder/parts/CustomSelect/CustomSelect';
import CustomText from '../components/Forms/FormBuilder/parts/CustomText/CustomText';
import Designation from '../components/Forms/FormBuilder/parts/Designation/Designation';
import FullName from '../components/Forms/FormBuilder/parts/FullName/FullName';
import InfoText from '../components/Forms/FormBuilder/parts/InfoText/InfoText';
import InfoTitle from '../components/Forms/FormBuilder/parts/InfoTitle/InfoTitle';
import Title from '../components/Forms/FormBuilder/parts/Title/Title';
import Forms from '../components/Forms/Forms';
import ProductModalWrapper from '../components/ProductModalWrapper/ProductModalWrapper';
import StageBoxCalculator from '../components/StageBoxCalculator/StageBoxCalculator';

export const CmsComponentsBase = {
  'contact-block': ContactBlock,
  'contact-telephone': ContactTelephone,
  'download-bar': Download,
  'rich-text': RichText,
  'section-visual': SectionVisual,
  'iframe-section': IframeSection,
  'showcase-list': ShowcaseList,
  'showcase-element': ShowcaseElement,
  'stage-box-text': StageBoxText,
  'stage-box-white': StageBoxWhite,
  'stage-box-calculator': StageBoxCalculator,
  'stage-box-calculator-new': StageBoxCalculatorNew,
  'teaser-with-image': TeaserWithImage,
  'teaser-tile': TeaserTile,
  'teaser-tile-group': TeaserTileGroup,
  'teaser-tile-large': TeaserTileLarge,
  button: ButtonLink,
  'product-overview': ProductModalWrapper,
  container: SplitContainer,
  'product-teaser': ProductTeaser,
  'tabs-container': SplitContainer,
  headline: Headline,
  icon: Icon,
  image: Image,
  quote: Quote,
  section: Section,
  teaser: Teaser,
  'teaser-image-card-small': TeaserImageCardSmallWrapper,
  'teaser-image-card-big': TeaserImageCardBigWrapper,
  video: VideoWrapper,
  header: LegacyHeader,
  'hint-box': HintBox,
  'nav-item': NavItem,
  'guide-box': GuideBox,
  'two-sided-box-alternative': TwoColumnContainer,
  'component-list': ComponentList,
  table: TableWrapper,
  forms: Forms,
  'eng-forms': Forms,
  'stage-area': React.Fragment,
  stage: HeroArea,
  'stage-small': SmallHeroArea,
  'guide-box-list': GuideBoxList,
  tabs: TabsContainer,
  'tabs-item': TabsCardsItem,
  'lottie-magnolia': LottieWrapper,
  footer: EnercityFooterWrapper,
  group: Group,
  'hero-area-content': HeroAreaContent,
  'info-box': InfoBox,
  'image-gallery': ImageGallery,
  'image-map-custom': ImageMapWrapper,
  designation: Designation,
  title: Title,
  'full-name': FullName,
  address: Address,
  contact: Contact,
  'custom-text': CustomText,
  'custom-checkbox': CustomCheckBox,
  'custom-select': CustomSelect,
  'custom-radio': CustomRadio,
  'info-text': InfoText,
  'info-title': InfoTitle,
  'notice-bar-area': React.Fragment,
  'notice-bar': NoticeBar,
  'additional-contents': AdditionalContents,
  'image-grid': ImageGrid,
  'video-teaser': VideoTeaser,
  'job-additional-content-container': Container,
  'image-composition': ImageCompositionMagnoliaWrapper,
  'job-image-grid': ImageCompositionMagnoliaWrapper,
  'job-header': LegacyJobHeader,
  'job-callout': JobCallout,
  'job-apply-steps': JobApplySteps,
  'job-shift-model': JobShiftModel,
  'kununu-score': KununuScoreMagnoliaWrapper,
  'faq-accordion': Accordion,
  'button-link': ButtonLink,
  'job-list-wrapper': JobListWrapper,
  'quote-carousel': QuoteCarouselWrapper,
  iframe: Iframe,
  'fiber-optic-map': FiberOpticMap,
  'district-heat-map': DistrictHeatMap,
  'cmp-cookie-info': CMPCookieInfo,
  'cmp-vendor-list': CMPVendorList,
  'tariff-card': TariffCard,
  'job-benefit-teaser': JobBenefitTeaser,
  'disturbance-map': DisturbanceMapIframe,
  'disturbance-map-eng': DisturbanceMapIframeEng,
  'gas-consumption-traffic-light': GasConsumptionTrafficLight,
  'umbrella-campaign-teaser-tile-section':
    UmbrellaCampaignTeaserTileSectionWrapper,
  'umbrella-campaign-stories-section': UmbrellaCampaignStoriesSectionWrapper,
  'heat-pump-savings-calculator': HeatPumpSavingsCalculator,
  'wedemark-map': WedemarkMap,
  'product-comparison': ProductComparisonWrapper,
  'solar-video-chat-teaser-section': SolarVideoChatTeaserSection,
  'eeg-job-search': EegJobSearch,
  'rive-wrapper': RiveWrapper,
  'flip-card': FlipCardWrapper,
  'community-heat-planner-map': CommunityHeatPlannerMap,
  'ac-wind-power-invest-calc': ACWindPowerInvestCalc,
  'box-teaser': BoxTeaserWrapper,
  'fullscreen-stage': FullScreenStage,
  'heat-product-teasers': HeatProductTeasers,
  'waermewende-map-iframe': WaermewendeMapIframe,
};
