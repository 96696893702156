import useEegJobSearch from '@/hooks/useEegJobSearch/useEegJobSearch';
import usePagination from '@/hooks/usePagination/usePagination';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import NoSearchResult from '../NoSearchResult/NoSearchResult';
import Skeleton from '../Skeleton/Skeleton';
import Stack from '../Stack/Stack';
import styles from './EegJobSearch.module.scss';
import EegJobSearchAnimatedIcon from './EegJobSearchAnimatedIcon';
import PlantLeafIcon from './PlantLeafIcon';
import RocketIcon from './RocketIcon';
import type { EegJobSearchProps } from './types';

const generateAmountString = (amount: number) => {
  if (amount === 1) {
    return 'eine freie Stelle verfügbar';
  }

  return `${amount} freie Stellen verfügbar`;
};

const EegJobSearch = ({}: EegJobSearchProps) => {
  const [workingHours, setWorkingHours] = useState('');
  const [location, setLocation] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const jobAmountRef = useRef<HTMLHeadingElement>(null);

  const { isLoading, error, jobs, filteredJobs } = useEegJobSearch(
    searchTerm,
    workingHours,
    location
  );

  const {
    currentData,
    maxPage,
    currentPage,
    canGoNext,
    canGoPrev,
    next,
    prev,
  } = usePagination(filteredJobs, {
    itemsPerPage: 5,
  });

  const jobEmploymentTypes = [
    ...new Set(jobs.map((job) => job.jobEmploymentType)),
  ];

  const jobWorkplaces = [...new Set(jobs.map((job) => job.jobWorkplace))];

  const scrollToAnchor = (callback?: () => void) => {
    if (jobAmountRef.current) {
      jobAmountRef.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });

      setTimeout(() => {
        callback?.();
      }, 500);
    }
  };

  return (
    <Stack className={styles.base} gap={3}>
      <Stack
        gap={3}
        className={styles.banner}
        justifyContent="center"
        alignItems="center"
      >
        <div className={styles.backgroundIcons}>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.carreer, styles.backgroundIcon)}
          >
            <Icon variant="user/carreer" size="iconSize48" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.battery, styles.backgroundIcon)}
          >
            <Icon variant="user/battery" size="iconSize48" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.lightning, styles.backgroundIcon)}
          >
            <Icon variant="user/lightning" size="iconSize48" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.sun, styles.backgroundIcon)}
          >
            <Icon variant="user/sun" size="iconSize48" isOutlined />
          </EegJobSearchAnimatedIcon>

          <EegJobSearchAnimatedIcon
            className={clsx(styles.solarPlant, styles.backgroundIcon)}
          >
            <Icon variant="user/solar-plant" size="iconSize64" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.windPower, styles.backgroundIcon)}
          >
            <Icon variant="user/wind-power" size="iconSize64" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.emobility, styles.backgroundIcon)}
          >
            <Icon variant="user/emobility" size="iconSize64" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={clsx(styles.heart, styles.backgroundIcon)}
          >
            <Icon variant="user/heart" size="iconSize64" isOutlined />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={styles.plantLeafIcon}
            withBlur={false}
            withOpacity={false}
          >
            <PlantLeafIcon />
          </EegJobSearchAnimatedIcon>
          <EegJobSearchAnimatedIcon
            className={styles.rocketIcon}
            withBlur={false}
            withOpacity={false}
          >
            <RocketIcon />
          </EegJobSearchAnimatedIcon>
        </div>
        <h2 className={styles.headline}>
          Finden Sie den Job, der zu Ihnen passt!
        </h2>

        <div className={styles.inputs}>
          <div className={clsx(styles.input, styles.searchInput)}>
            <Icon
              variant="action/search"
              size="iconSize24"
              className={clsx(styles.icon, styles.inputIcon)}
            />
            <input
              type="text"
              placeholder="Jobtitel, Stichwort"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={clsx(styles.input)}>
            <select
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option value="">Standort</option>
              {jobWorkplaces.map((place) => (
                <option key={place} value={place}>
                  {place.charAt(0).toUpperCase() + place.slice(1)}
                </option>
              ))}
            </select>
            <Icon
              variant="action/chevron-down"
              size="iconSize24"
              className={clsx(styles.icon, styles.selectIcon)}
            />
          </div>

          <div className={clsx(styles.input)}>
            <select
              value={workingHours}
              onChange={(e) => setWorkingHours(e.target.value)}
            >
              <option value="">Art der Beschäftigung</option>
              {jobEmploymentTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <Icon
              variant="action/chevron-down"
              size="iconSize24"
              className={clsx(styles.icon, styles.selectIcon)}
            />
          </div>
        </div>
      </Stack>

      <Stack gap={1} className={styles.jobs}>
        {!error ? (
          isLoading ? (
            <>
              <Skeleton height={1.5} width={14.5} />
              {new Array(5).fill(0).map((_, index) => (
                <Skeleton height={6.75} width={80} key={index} />
              ))}
            </>
          ) : filteredJobs.length !== 0 ? (
            <>
              <h5 className={styles.amount} ref={jobAmountRef}>
                {generateAmountString(filteredJobs.length)}
              </h5>
              {currentData.map((job) => (
                <a
                  key={job.jobId}
                  className={styles.job}
                  href={job.jobOffer}
                  target="_blank"
                >
                  <div className={styles.title}>{job.jobTitle}</div>
                  <div className={styles.infos}>
                    <div className={styles.info}>
                      <Icon
                        variant="user/pin"
                        size="iconSize20"
                        isOutlined
                        className={styles.icon}
                      />
                      {job.jobWorkplace}
                    </div>

                    <div className={styles.info}>
                      <Icon
                        variant="user/carreer"
                        size="iconSize20"
                        isOutlined
                        className={styles.icon}
                      />
                      {job.jobEmploymentType}
                    </div>
                  </div>
                </a>
              ))}
            </>
          ) : (
            <NoSearchResult
              headline="Wir haben leider keine passenden Jobs gefunden."
              iconVariant="user/monkey-blind"
              subtext="Probieren Sie es doch mal mit einem anderen Suchbegriff oder anderen Filtern."
            />
          )
        ) : (
          <NoSearchResult
            headline="Ups! Es gab einen Fehler"
            iconVariant="user/error"
            subtext="Probieren Sie es später erneut."
          />
        )}
      </Stack>

      {filteredJobs.length !== 0 && (
        <div className={styles.pagination}>
          <button
            disabled={!canGoPrev}
            onClick={() => scrollToAnchor(prev)}
            className={styles.paddle}
            type="button"
          >
            <Icon variant="action/chevron-left" size="iconSize24" />
          </button>
          <div className={styles.text}>
            <span>{currentPage}</span> von <span>{maxPage}</span>
          </div>
          <button
            disabled={!canGoNext}
            onClick={() => scrollToAnchor(next)}
            className={styles.paddle}
            type="button"
          >
            <Icon variant="action/chevron-right" size="iconSize24" />
          </button>
        </div>
      )}
    </Stack>
  );
};

export default EegJobSearch;
