import * as ajax from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import { useQuery } from 'react-query';

interface FiberOpticData {
  type: string;
  name: string;
  features: google.maps.Data.Feature;
}

const getFiberOpticData =
  (path: string) => async (): Promise<FiberOpticData | null> => {
    try {
      const data = (await ajax.getJsonCached<FiberOpticData>(path)) ?? null;

      return data;
    } catch (error) {
      logger.log('getFiberOpticData', error);
      return null;
    }
  };

export default function useFiberOpticData(mapDataPath: string) {
  return useQuery(
    ['fiberOpticData', mapDataPath],
    getFiberOpticData(mapDataPath),
    {
      placeholderData: null,
    }
  );
}
