import React from 'react';
import CheckBox from '../CheckBox/CheckBox';
import type { CheckBoxBlockProps } from './interfaces';
import FormBox from '../FormBox/FormBox';

const CheckBoxBlock = ({
  children,
  errorMessage,
  className,
  size,
  checked,
  badgeLabel,
  ...props
}: CheckBoxBlockProps): JSX.Element => {
  return (
    <FormBox
      label={children}
      {...{ errorMessage, className, size, badgeLabel, checked }}
    >
      <CheckBox checked={checked} {...props} />
    </FormBox>
  );
};

export default CheckBoxBlock;
