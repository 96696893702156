import { getJson } from './ajax';

type City = {
  city: string;
  platform: string;
  requiresFullAddress: boolean;
  zipCode: string;
};

type GetCitiesResponse = {
  cities: City[];
};

const SERVICEKIT_URL = process.env.SERVICEKIT_URL;

const fetchSuGRCity = async (zipcode: string): Promise<City[]> => {
  const endpoint = new URL(
    `/api/sugr/cities?zipCode=${zipcode}`,
    SERVICEKIT_URL
  );

  const data = await getJson<GetCitiesResponse>(endpoint);

  if (!data || !data.cities || data.cities.length === 0) {
    throw new Error('Keine Stadt gefunden');
  }

  return data.cities;
};

export default fetchSuGRCity;
