import { getJson } from '@/helpers/ajax';
import { logger } from '@/helpers/logger';
import assetPath from '@uikit/helpers/assetPath';
import { useQuery } from 'react-query';

const KUNUNU_URL = `/json-data/kununuScore.json`;

type KununuScoreData = {
  all?: {
    points?: number;
  };
};

export async function getKununuScore(): Promise<number> {
  try {
    const data = await getJson<KununuScoreData>(`${assetPath}${KUNUNU_URL}`);

    if (!data?.all?.points) {
      logger.error('no score for kununu');
      return 0;
    }

    return data.all.points;
  } catch (error) {
    logger.error(error);
    return 0;
  }
}

export const useKununuScore = () => useQuery('kununuScore', getKununuScore);
