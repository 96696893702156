import FormRow from '@uikit/components/FormRow/FormRow';
import RichText from '@uikit/components/RichText/RichText';
import React from 'react';
import { InfoTextProps, InfoTextType } from './interfaces';

const InfoText = ({ text, type = InfoTextType.Default }: InfoTextProps) => {
  return (
    <FormRow>
      {type === InfoTextType.Default && <RichText>{text}</RichText>}
      {type === InfoTextType.Footnote && (
        <RichText type="typeFootnote">{text}</RichText>
      )}
    </FormRow>
  );
};

export default InfoText;
