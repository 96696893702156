import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormInfoBox from '@uikit/components/FormInfoBox/FormInfoBox';
import FormRow from '@uikit/components/FormRow/FormRow';
import Headline from '@uikit/components/Headline/Headline';
import { HeadlineAlign } from '@uikit/components/Headline/consts';
import RichText from '@uikit/components/RichText/RichText';
import useForm from '../../../../hooks/useForm/useForm';
import {
  AppointmentReminderSMSStep,
  AppointmentReminderSMSStep1State,
} from '../interfaces';
import schema from './AppointmentReminderSMSStep1.schema';

export const initialState: AppointmentReminderSMSStep1State = {
  kdKey: '',
  phone: '',
};

const AppointmentReminderSMSFormStep1 = ({
  onSubmit,
  initalState,
  submissionState = ButtonSubmitState.Initial,
}: AppointmentReminderSMSStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, initalState || initialState);

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <Headline
          subText="Einfach mit dem AnmeldeCode aus Ihrem Informationsanschreiben unseren Handyservice anfordern"
          tag="h1"
          level={3}
          align={HeadlineAlign.Left}
        >
          Mit dem Handy sicher erreichbar!
        </Headline>
      </FormRow>
      <FormRow>
        <FormField
          name={'kdKey'}
          value={formState['kdKey']}
          label={'10-stelliger-AnmeldeCode'}
          maxLength={10}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('kdKey')}
        />
      </FormRow>
      <FormRow>
        <FormField
          name={'phone'}
          value={formState['phone']}
          label={'Mobilnummer'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('phone')}
          autoComplete={'tel'}
        />
      </FormRow>
      <FormRow>
        <RichText size="small" type="typeFootnote">
          Sollten Sie noch keinen AnmeldeCode erhalten haben, warten Sie gerne
          auf Ihr Infoschreiben oder fordern Sie einen AnmeldeCode an unter{' '}
          <a href="mailto:info@mein-h-gas.de">info@mein-h-gas.de</a> oder der
          kostenlosen ServiceNummer{' '}
          <a href="tel:+4980036372489">0800.36372489</a>
        </RichText>
      </FormRow>
      <FormRow>
        <FormInfoBox>
          Informationen gemäß Art. 13 DSGVO über die Verarbeitung Ihrer
          personenbezogenen Daten finden Sie in unseren{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.enercity-netz.de/unternehmen/fakten/datenschutz/index.html"
          >
            Datenschutzinformationen
          </a>
          .
        </FormInfoBox>
      </FormRow>

      <FormRow fullWidthChildren={false} bottomMarginSize={{ default: 1.125 }}>
        <ButtonSubmit
          state={submissionState}
          inactive={!isFormValid}
          stateMessages={{
            pending: 'Versende SMS',
            success: 'SMS erfolgreich versendet!',
            fail: 'Es ist ein Fehler aufgetreten',
          }}
        >
          PIN per SMS erhalten
        </ButtonSubmit>
      </FormRow>
    </form>
  );
};

export default AppointmentReminderSMSFormStep1;
