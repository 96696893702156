import { IconVariants } from '@uikit/components/Icon/interfaces';
import { ProductOption } from './types';

export const PRODUCT_BUTTON_ICONS: Record<ProductOption, IconVariants> = {
  power: 'user/lightning',
  gas: 'user/energy-fire',
  combined: 'user/calculator',
};

export const PRODUCT_OPTIONS_ORDER: ProductOption[] = [
  'combined',
  'power',
  'gas',
];
