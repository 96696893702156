type Point = {
  lat: number;
  lng: number;
};

export const calculateCenter = (points: Point[]) => {
  const { totalLat, totalLng } = points.reduce(
    ({ totalLat, totalLng }, point) => ({
      totalLat: totalLat + point.lat,
      totalLng: totalLng + point.lng,
    }),
    { totalLat: 0, totalLng: 0 }
  );

  const center = {
    lat: totalLat / points.length,
    lng: totalLng / points.length,
  };

  return center;
};
