import useJobFavorites from '@/hooks/useJobFavorites/useJobFavorites';
import type { JobPosting } from '@/page-templates/JobDetailsPage/interfaces';
import React from 'react';
import JobList from '../JobList/JobList';

const JobListWrapper = ({ jobs }: { readonly jobs: JobPosting[] }) => {
  const knownJobIds = jobs.map((job) => job.id);
  const { isFavoriteJob: isFavorite, toggleFavoriteJob: toggleFavorite } =
    useJobFavorites(new Set(knownJobIds));

  return (
    <JobList
      isFavorite={isFavorite}
      toggleFavorite={toggleFavorite}
      styleVariant="borderBetween"
      jobs={jobs}
      hasItemPadding
    />
  );
};

export default JobListWrapper;
