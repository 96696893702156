import styles from './BoxTeaser.module.scss';
import type { BoxTeaserProps } from './types';

const BoxTeaser = ({ image, title, description, children }: BoxTeaserProps) => {
  return (
    <div className={styles.base}>
      <div className={styles.thumbnail}>
        <img src={image.path} alt="image" />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};

export default BoxTeaser;
