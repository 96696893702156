import clsx from 'clsx';
import type { SVGProps } from 'react';
import styles from './styles.module.scss';

const SvgFlagTr = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    className={clsx(styles.round, className)}
    {...props}
  >
    <g>
      <path fill="#E30A17" d="M0 0h20v20H0V0Z" />
      <path
        fill="#fff"
        d="m9.688 10 3.645-1.225-2.253 3.206V8.02l2.253 3.205L9.688 10Zm.248 2.228a4.062 4.062 0 0 1-1.977 1.668 3.906 3.906 0 0 1-2.55.106 4.031 4.031 0 0 1-2.1-1.5A4.26 4.26 0 0 1 2.5 10c0-.903.284-1.781.808-2.503a4.031 4.031 0 0 1 2.1-1.5 3.906 3.906 0 0 1 2.55.107 4.06 4.06 0 0 1 1.978 1.668 3.206 3.206 0 0 0-1.643-1.013 3.125 3.125 0 0 0-1.91.128 3.244 3.244 0 0 0-1.502 1.224A3.413 3.413 0 0 0 4.314 10c0 .674.198 1.333.567 1.889.37.555.894.982 1.503 1.223a3.12 3.12 0 0 0 1.909.129 3.206 3.206 0 0 0 1.643-1.013Z"
      />
    </g>
  </svg>
);
export default SvgFlagTr;
