import clsx from 'clsx';
import React from 'react';
import type { JobApplyStepsNavigationItemProps } from './interfaces';
import styles from './JobApplyStepsNavigationItem.module.scss';

const JobApplyStepsNavigationItem = ({
  onClick,
  index,
  activeIndex,
}: JobApplyStepsNavigationItemProps) => {
  const isActive = index === activeIndex;

  return (
    <div
      className={clsx(styles.base, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    />
  );
};

export default JobApplyStepsNavigationItem;
