import React from 'react';
import type { JobKununuStarGroupProps } from './JobKununuStarGroup.interfaces';

const NUMBER_OF_STARS = 5;

const JobKununuScoreStarGroup = ({
  score,
}: JobKununuStarGroupProps) => {
  const gradientPercent = Math.round((score % 1) * 100);
  return (
    <svg
      width="192px"
      height="33px"
      viewBox="0 0 192 33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient id="percentGradient">
          <stop
            stopOpacity="1"
            offset={`${gradientPercent}%`}
            stopColor="#76B72A"
          />
          <stop stopOpacity="0" offset={`${gradientPercent}%`} />
        </linearGradient>
      </defs>
      {[...Array(NUMBER_OF_STARS)].map((_, index) => (
        <path
          d="M16,23.90625 L8.38826201,27.8923457 C7.89900421,28.148559 7.29468049,27.9596391 7.0384672,27.4703813 C6.93659373,27.2758466 6.9015863,27.0531876 6.93884914,26.8367772 L8.39154787,18.3999792 L8.39154787,18.3999792 L2.24142182,12.4285055 C1.84518488,12.0437786 1.83585409,11.4106824 2.22058092,11.0144455 C2.37438042,10.8560447 2.57627726,10.752995 2.79478321,10.7213682 L11.297718,9.49064583 L11.297718,9.49064583 L15.1039533,1.80849686 C15.3491454,1.31362391 15.9490868,1.11121766 16.4439597,1.35640984 C16.6400666,1.45357392 16.7988827,1.61238999 16.8960467,1.80849686 L20.702282,9.49064583 L20.702282,9.49064583 L29.2052168,10.7213682 C29.7518057,10.800482 30.1307692,11.3077142 30.0516554,11.8543032 C30.0200287,12.0728091 29.916979,12.274706 29.7585782,12.4285055 L23.6084521,18.3999792 L23.6084521,18.3999792 L25.0611509,26.8367772 C25.1548675,27.3810525 24.7896175,27.8982472 24.2453421,27.9919638 C24.0289317,28.0292266 23.8062727,27.9942192 23.611738,27.8923457 L16,23.90625 L16,23.90625 Z"
          fill={
            score < index
              ? 'transparent'
              : score < index + 1
              ? 'url(#percentGradient)'
              : '#76B72A'
          }
          stroke="#76B72A"
          strokeWidth="2"
          style={{ transform: `translateX(${index * 40}px)` }}
          key={index}
        />
      ))}
    </svg>
  );
};

export default JobKununuScoreStarGroup;
