import type { StrictBoxProps } from './StrictBox.interfaces';
import clsx from 'clsx';
import React from 'react';
import styles from './StrictBox.module.scss';

/**
 * Same as `<div>` but with `contain: strict`
 */
const StrictBox = ({ ...props }: StrictBoxProps) => {
  return <div {...props} className={clsx(styles.box, props.className)} />;
};

export default StrictBox;
