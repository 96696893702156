export function placeToCurrentLocation(place: GoogleMapPlace): any {
  const getDetail = (key: string): string => {
    const detail = place.address_components.find((addressComponent) =>
      addressComponent.types.includes(key)
    );
    return detail ? detail.long_name : '';
  };

  const streetNumber = getDetail('street_number')
    ? ' ' + getDetail('street_number')
    : '';
  return {
    city: getDetail('locality'),
    district: getDetail('sublocality'),
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    street: getDetail('route') + streetNumber,
    supporters: 0,
    type: 'proposal',
    zipcode: getDetail('postal_code'),
  };
}
