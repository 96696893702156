import useMeasure from '@/hooks/useMeasure/useMeasure';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './ImageGridFilter.module.scss';
import { ImageGridFilterProps } from './interfaces';

const ImageGridFilter = ({ children }: ImageGridFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const optionsRef = useRef<HTMLDivElement>(null);

  const { height } = useMeasure(optionsRef, true);

  const { ...springProps } = useSpring({
    opacity: isOpen ? 1 : 0,
    maxHeight: isOpen ? `${height}px` : '0px',
    marginBottom: isOpen ? '1.5rem' : '0rem',
    config: { duration: 300 },
  });

  return (
    <>
      <button
        className={clsx(styles.collapseButton, {
          [styles.collapsibleOpen]: isOpen,
        })}
        onClick={toggleIsOpen}
      >
        Filter
        <Icon
          className={styles.chevron}
          size="iconSize24"
          variant="action/chevron-down"
        />
      </button>
      <animated.div
        style={{
          ...springProps,
        }}
        className={styles.filterOptionsContainer}
      >
        <div ref={optionsRef}>{children}</div>
      </animated.div>
    </>
  );
};

export default ImageGridFilter;
