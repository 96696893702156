import { logger } from '@/helpers/logger';
import {
  meinHGasRegiocomCheckPin,
  meinHGasRegiocomCreateCheck,
} from '@/helpers/meinHGasRegiocom';
import { UseFormState } from '@/hooks/useForm/interfaces';
import useFrcCaptcha from '@/hooks/useFrcCaptcha/useFrcCaptcha';
import useScrollToRef from '@/hooks/useScrollToRef/useScrollToRef';
import useSessionStorage from '@/hooks/useSessionStorage/useSessionStorage';
import { animated, useTransition } from '@react-spring/web';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import React, { useEffect } from 'react';
import { FormComponentProps } from '../interfaces';
import MailInsteadLetterFormStep1 from './steps/MailInsteadLetterFormStep1';
import MailInsteadLetterFormStep2 from './steps/MailInsteadLetterFormStep2';

const mailInsteadLetterFormSteps = [
  MailInsteadLetterFormStep1,
  MailInsteadLetterFormStep2,
];

const MailInsteadLetter = ({
  onSuccess,
  buttonLabel = 'Bestellung abschicken',
  dataProcessing,
}: FormComponentProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [currentStepIndex, setCurrentStepIndex] = React.useState(0);
  const [storedValue, setValue] = useSessionStorage(
    `mail-instead-letter`,
    {} as UseFormState
  );
  const [formState, setFormState] = React.useState<UseFormState>(storedValue);
  const { getSolution, restartSolving, FrcCaptchaComponent } = useFrcCaptcha();

  const [submissionState, setSubmissionState] = React.useState(
    ButtonSubmitState.Initial
  );
  const [submissionStatePIN, setSubmissionStatePIN] = React.useState(
    ButtonSubmitState.Initial
  );

  const scrollTo = useScrollToRef(ref);

  const callbackOnSubmit = React.useCallback(
    async (formState: UseFormState) => {
      setSubmissionStatePIN(ButtonSubmitState.Pending);

      try {
        const frcCaptchaSolution = await getSolution();
        const apiResponse = await meinHGasRegiocomCheckPin({
          kdKey: formState['kdKey'],
          operation: 'E',
          pin: formState['pin'],
          frcCaptchaSolution,
        });
        const { response } = await apiResponse.json();

        if (response === 'PIN OK') {
          setSubmissionStatePIN(ButtonSubmitState.Success);
          setValue({});
          onSuccess && setTimeout(onSuccess, 1500);
        } else {
          throw Error(response);
        }
      } catch (error) {
        setSubmissionStatePIN(ButtonSubmitState.Fail);
        logger.error(error);
        setTimeout(() => {
          setSubmissionStatePIN(ButtonSubmitState.Initial);
        }, 2000);
      }
    },
    [onSuccess, setValue, getSolution]
  );

  const callbackOnSubmitKdKey = React.useCallback(
    async (formState: UseFormState) => {
      setSubmissionState(ButtonSubmitState.Pending);

      try {
        const frcCaptchaSolution = await getSolution();
        const apiResponse = await meinHGasRegiocomCreateCheck({
          kdKey: formState['kdKey'],
          operation: 'E',
          value: formState['email'],
          frcCaptchaSolution,
        });
        const { response } = await apiResponse.json();

        if (response === 'OK') {
          setSubmissionState(ButtonSubmitState.Success);
          setCurrentStepIndex((prevState) => prevState + 1);
          setFormState(() => formState);
          setValue(formState);
          scrollTo();
        } else {
          throw Error('error');
        }
      } catch (error) {
        setSubmissionState(ButtonSubmitState.Fail);
        logger.error(error);
        setTimeout(() => {
          setSubmissionState(ButtonSubmitState.Initial);
        }, 2000);
      }
    },
    [getSolution, scrollTo, setValue]
  );

  const requestNewPin = async () => {
    setFormState(() => storedValue);
    const frcCaptchaSolution = await getSolution();

    await meinHGasRegiocomCreateCheck({
      kdKey: formState['kdKey'],
      operation: 'E',
      value: formState['email'],
      frcCaptchaSolution,
    });
  };

  const isLastStep = React.useMemo(
    () => mailInsteadLetterFormSteps.length - 1 === currentStepIndex,
    [currentStepIndex]
  );

  const handleOnSubmit = async (stepState: UseFormState) => {
    const newFormState = {
      ...formState,
      ...stepState,
    };
    if (isLastStep) {
      await callbackOnSubmit(newFormState);
    } else {
      await callbackOnSubmitKdKey(newFormState);
      restartSolving();
    }
  };

  useEffect(() => {
    if (storedValue.kdKey && !isLastStep) {
      setCurrentStepIndex((prevState) => prevState + 1);
    }
  }, [isLastStep, storedValue.kdKey]);

  const step = React.useMemo(
    () => mailInsteadLetterFormSteps[currentStepIndex],
    [currentStepIndex]
  );

  const transitions = useTransition(step, {
    from: {
      position: 'absolute',
      opacity: 0,
    },
    enter: { position: 'relative', opacity: 1 },
    leave: { position: 'absolute', opacity: 0 },
  });

  return (
    <div ref={ref}>
      {transitions(({ opacity, position }, Step) => (
        <animated.div
          style={{
            position: position as React.CSSProperties['position'] | any,
            opacity,
          }}
        >
          <Step
            onSubmit={handleOnSubmit}
            submissionState={submissionState}
            submissionStatePIN={submissionStatePIN}
            buttonLabel={buttonLabel}
            dataProcessing={dataProcessing}
            requestNewPin={requestNewPin}
            initalState={formState}
          />
        </animated.div>
      ))}
      <FrcCaptchaComponent />
    </div>
  );
};

export default MailInsteadLetter;
