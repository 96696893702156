import { FormFieldElement } from '@uikit/components/FormField/interfaces';
import FormFieldSelect from '@uikit/components/FormFieldSelect/FormFieldSelect';
import React, { useContext, useState } from 'react';
import { FormBuilderContext } from '../../FormBuilder';
import { titleFormFieldOptions } from './consts';
import type { TitleProps } from './interfaces';
import FormRow from '@uikit/components/FormRow/FormRow';
import { SuggestionOption } from '@/uikit/components/FormFieldSuggestion/interfaces';

const Title = ({ isOptional, fieldName, useForMailTemplate }: TitleProps) => {
  const { formState, getErrorByFieldName, setFieldValue, handleChange } =
    useContext(FormBuilderContext);

  const [isOpen, setIsOpen] = useState(false);

  const onChange = ($event: React.ChangeEvent<FormFieldElement>) => {
    handleChange($event);

    if (useForMailTemplate) {
      setFieldValue('title', $event.target.value);
    }
  };

  const name = `${fieldName} - Titel`;

  const onSelectOption = (option: SuggestionOption) => {
    setFieldValue(name, option.value);
    if (useForMailTemplate) {
      setFieldValue('title', option.value);
    }

    setIsOpen(false);
  };

  return (
    <FormRow>
      <FormFieldSelect
        options={isOpen ? titleFormFieldOptions : []}
        nativeOptions={titleFormFieldOptions}
        onChange={onChange}
        value={formState[name]}
        inputId={name}
        label={isOptional ? 'Titel (optional)' : 'Titel'}
        onSelectOption={onSelectOption}
        errorMessage={getErrorByFieldName(name)}
        onClickIcon={() => setIsOpen(!isOpen)}
      />
    </FormRow>
  );
};

export default Title;
