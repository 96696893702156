import React from 'react';
import { ClickableAction } from '../Clickable/consts';
import Teaser from '../Teaser/Teaser';
import type { JobBenefitTeaserProps } from './interfaces';

const JobBenefitTeaser = ({
  icon,
  headline,
  description,
}: JobBenefitTeaserProps) => {
  return (
    <Teaser
      isButtonVisible={false}
      icon={icon}
      headline={headline}
      actionType={ClickableAction.None}
      isIcon
    >
      {description}
    </Teaser>
  );
};

export default JobBenefitTeaser;
