import clsx from 'clsx';
import { useRef } from 'react';
import Lottie from '../Lottie/Lottie';
import styles from './LottieWrapper.module.scss';
import type { LottieWrapperProps } from './interfaces';

const LottieWrapper = ({
  animationName,
  animationData,
  startInView = false,
  loop = true,
  align = 'left',
  noScale,
  className,
}: LottieWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  if (!animationData) {
    return null;
  }

  return (
    <div
      ref={wrapperRef}
      className={clsx(styles.main, className, styles[align], {
        [styles.noScale]: noScale,
      })}
    >
      <Lottie
        autoplay={!startInView}
        loop={loop}
        playing={!startInView}
        startInView={startInView}
        name={animationName}
        animationData={JSON.parse(animationData)}
        parentRef={wrapperRef}
      />
    </div>
  );
};

export default LottieWrapper;
