import fetchSuGRCity from '@/helpers/fetchSuGRCities';
import fetchSuGRStreetSuggestion from '@/helpers/fetchSuGRStreetSuggestion';
import { QueryObserverOptions, useQuery } from 'react-query';
import { AddressData } from './types';

const fetchSuGRZipCodeAddress = (zipCode?: string) => async () => {
  if (!zipCode) {
    throw new Error('Missing zipCode');
  }

  const [cityData] =
    (await fetchSuGRCity(zipCode).catch(() => undefined)) ?? [];

  if (!cityData) {
    throw new Error(`City not found for zipCode ${zipCode}`);
  }

  const [streetName] =
    (await fetchSuGRStreetSuggestion({
      zipCode,
      city: cityData.city,
      limit: 1,
      term: JSON.stringify(''),
    }).catch(() => undefined)) ?? [];

  if (!streetName) {
    throw new Error(
      `City not found for zipCode: ${zipCode} and city: ${cityData.city}`
    );
  }

  return {
    zipCode,
    city: cityData.city,
    streetName: streetName,
    streetNumber: '1',
  };
};

const useSuGRZipCodeAddress = (
  zipCode?: string,
  queryConfig: QueryObserverOptions<AddressData> = {}
) => {
  return useQuery<AddressData>(
    ['zipCodeAddress', zipCode],
    fetchSuGRZipCodeAddress(zipCode),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity,
      retry: 1,
      enabled: zipCode?.length === 5,
      ...queryConfig,
    }
  );
};

export default useSuGRZipCodeAddress;
