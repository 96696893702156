import {
  city,
  email,
  firstName,
  lastName,
  street,
  streetNumber,
  zipcode,
  phoneNotRequired,
} from '../../../schemas';

// eslint-disable-next-line
export default {
  firstName,
  lastName,
  street,
  streetNumber,
  city,
  zipcode,
  mail: email,
  phone: phoneNotRequired,
};
