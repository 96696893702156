import styles from './DisturbanceMapIframeEng.module.scss';

const DisturbanceMapIframeEng = () => {
  const mapkitUrl = 'https://karten.enercity.de'; // process.env.MAPKIT_URL || use ist when domain is reachable!;

  return (
    <iframe
      className={styles.base}
      src={`${mapkitUrl}/stoerung/embed?theme=netz`}
      title="Störungskarte"
      allow="accelerometer; geolocation; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

export default DisturbanceMapIframeEng;
