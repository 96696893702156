import React from 'react';
import ButtonCard from '../ButtonCard/ButtonCard';
import { ClickableAction } from '../Clickable/consts';
import type { DownloadProps } from './interfaces';

const Download = ({ text, subtext, asset }: DownloadProps) => {
  const subText = subtext && `/ ${subtext}`;

  return (
    <ButtonCard
      actionType={ClickableAction.Asset}
      asset={asset}
      text={text}
      subText={subText}
      icon="action/download"
    />
  );
};

export default Download;
