import clsx from 'clsx';
import Icon from '../Icon/Icon';
import styles from './WedemarkMapMarker.module.scss';
import type { WedemarkMapMarkerProps } from './types';

const WedemarkMapMarker = ({
  numberOfPlants,
  variant,
}: WedemarkMapMarkerProps) => {
  return (
    <div className={clsx(styles.base, styles[variant])}>
      <Icon variant="user/wind-power" size="iconSize24" />
      <span className={styles.text}>{numberOfPlants}</span>
    </div>
  );
};

export default WedemarkMapMarker;
