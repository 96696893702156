import ButtonSubmit from '@uikit/components/ButtonSubmit/ButtonSubmit';
import { ButtonSubmitState } from '@uikit/components/ButtonSubmit/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormInfoBox from '@uikit/components/FormInfoBox/FormInfoBox';
import FormRow from '@uikit/components/FormRow/FormRow';
import Headline from '@uikit/components/Headline/Headline';
import { HeadlineAlign } from '@uikit/components/Headline/consts';
import RichText from '@uikit/components/RichText/RichText';
import useForm from '../../../../hooks/useForm/useForm';
import {
  MailInsteadLetterStep,
  MailInsteadLetterStep1State,
} from '../interfaces';
import schema from './MailInsteadLetterFormStep1.schema';

const mailInsteadLetterInitialFormState: MailInsteadLetterStep1State = {
  kdKey: '',
  email: '',
};

const MailInsteadLetterFormStep1 = ({
  onSubmit,
  submissionState = ButtonSubmitState.Initial,
  initalState,
}: MailInsteadLetterStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleSubmit,
    isFormValid,
  } = useForm(
    onSubmit,
    schema,
    initalState || mailInsteadLetterInitialFormState
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <Headline
          subText="Kein Problem. Mit dem AnmeldeCode aus Ihrem
          Infoschreiben können Sie sich für den digitalen
          Versand Ihrer persönlichen Schreiben anmelden."
          tag="h1"
          level={3}
          align={HeadlineAlign.Left}
        >
          Sie lieben es papierlos und digital?
        </Headline>
      </FormRow>
      <FormRow>
        <FormField
          name={'kdKey'}
          value={formState['kdKey']}
          label={'10-stelliger-AnmeldeCode'}
          maxLength={10}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('kdKey')}
        />
      </FormRow>
      <FormRow>
        <FormField
          name={'email'}
          value={formState['email']}
          label={'E-Mail'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('email')}
        />
      </FormRow>
      <FormRow>
        <RichText size="small" type="typeFootnote">
          Durch die Angabe Ihrer E-Mail-Adresse erhalten Sie ab sofort keine
          weiteren Informationen per Brief. Sollten Sie noch keinen AnmeldeCode
          erhalten haben, warten Sie gerne auf Ihr Infoschreiben oder fordern
          Sie einen AnmeldeCode an unter{' '}
          <a href="mailto:info@mein-h-gas.de">info@mein-h-gas.de</a> oder der
          kostenlosen ServiceNummer{' '}
          <a href="tel:+4980036372489">0800.36372489</a>
        </RichText>
      </FormRow>
      <FormRow>
        <FormInfoBox>
          Informationen gemäß Art. 13 DSGVO über die Verarbeitung Ihrer
          personenbezogenen Daten finden Sie in unseren{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.enercity-netz.de/unternehmen/fakten/datenschutz/index.html"
          >
            Datenschutzinformationen
          </a>
          .
        </FormInfoBox>
      </FormRow>
      <FormRow fullWidthChildren={false} bottomMarginSize={{ default: 1.125 }}>
        <ButtonSubmit
          state={submissionState}
          inactive={!isFormValid}
          stateMessages={{
            pending: 'Versende E-Mail',
            success: 'E-Mail erfolgreich versendet!',
            fail: 'Es ist ein Fehler aufgetreten',
          }}
        >
          PIN per E-Mail erhalten
        </ButtonSubmit>
      </FormRow>
    </form>
  );
};

export default MailInsteadLetterFormStep1;
