import React from 'react';
import Container from '../../components/Container/Container';
import {
  HeadlineAlign,
  HeadlineColor,
  HeadlineLevel,
} from '../../components/Headline/consts';
import Headline from '../../components/Headline/Headline';
import SplitSection from '../../components/SplitSection/SplitSection';
import SplitSectionItem from '../../components/SplitSection/SplitSectionItem';
import toID from '../../helpers/toID';
import type { SplitContainerProps } from './interfaces';

const SplitContainer = ({
  children,
  size,
  headline,
  subtext,
  flexCenter,
  ...additionalProps
}: SplitContainerProps): JSX.Element => {
  return (
    <Container size={size} flexCenter={flexCenter} {...additionalProps}>
      {headline ? (
        <SplitSection>
          <SplitSectionItem>
            <div>
              <Headline
                headlineColor={HeadlineColor.Gradient}
                tag={'strong'}
                level={HeadlineLevel.Three}
                align={HeadlineAlign.Left}
                id={toID(headline)}
                subText={subtext}
              >
                {headline}
              </Headline>
            </div>
          </SplitSectionItem>
          <SplitSectionItem>{children}</SplitSectionItem>
        </SplitSection>
      ) : (
        children
      )}
    </Container>
  );
};

export default SplitContainer;
