import clsx from 'clsx';
import React from 'react';
import styles from './FiberOpticMapLegendItem.module.scss';
import type { FiberOpticMapLegendItemProps } from './interfaces';

const FiberOpticMapLegendItem = ({
  children,
  color,
}: FiberOpticMapLegendItemProps) => {
  return (
    <div className={clsx(styles.legendElement, styles[color])}>{children}</div>
  );
};

export default FiberOpticMapLegendItem;
