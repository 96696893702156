import Icon from '@uikit/components/Icon/Icon';
import React from 'react';
import type { JobApplyStepTitleProps } from './interfaces';
import styles from './JobApplyStepTitle.module.scss';

const JobApplyStepTitle = ({ children, icon }: JobApplyStepTitleProps) => {
  return (
    <div className={styles.base}>
      <div className={styles.icon}>
        <Icon size="iconSize64" variant={icon} isOutlined />
      </div>
      <h3 className={styles.title}>{children}</h3>
    </div>
  );
};

export default JobApplyStepTitle;
