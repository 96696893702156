import Tabs from '@uikit/components/Tabs/Tabs';
import { TabsItem } from '@uikit/components/Tabs/interfaces';
import React from 'react';
import ItemToComponent from '../../../components/ItemToComponent/ItemToComponent';
import type { TabsContainerProps } from './types';

const TabsContainer = ({ children, ...props }: TabsContainerProps) => {
  if (!children) {
    return null;
  }

  const items: TabsItem[] = React.Children.map(children, (child) => {
    const {
      props: { headline, icon },
      contents = [],
    } = child.props.item;

    const navItem = { headline, icon };

    const content = contents?.map((item: any, index: number) => (
      <ItemToComponent key={index} item={item} />
    ));

    return { navItem, content: <div>{content}</div> };
  });

  if (items.length < 1)  {
    return null;
  }

  return <Tabs items={items} {...props} />;
};

export default TabsContainer;
