import GVHForm from './GVHForm/GVHForm';
import MinimalContactForm from './MinimalContactForm/MinimalContactForm';
import ComfortRentForm from './ComfortRentForm/ComfortRentForm';
import { FormComponentProps } from './interfaces';
import AddressContactForm from './AddressContactForm/AddressContactForm';
import EMobilityAdviceForm from './EMobilityAdviceForm/EMobilityAdviceForm';
import FormBuilder from './FormBuilder/FormBuilder';
import MailInsteadLetter from './MailInsteadLetter/MailInsteadLetter';
import AppointmentReminderSMS from './AppointmentReminderSMS/AppointmentReminderSMS';
import RegisterChargingStation from './RegisterChargingStation/RegisterChargingStation';
import { FormBuilderProps } from './FormBuilder/interfaces';
import { RegisterChargingStationProps } from './RegisterChargingStation/interfaces';

const formComponentsMapper: {
  [key: string]:
    | React.ComponentType<FormComponentProps>
    | React.ComponentType<FormBuilderProps>
    | React.ComponentType<RegisterChargingStationProps>;
} = {
  ComfortRentForm,
  MinimalContactForm,
  AddressContactForm,
  EMobilityAdviceForm,
  FormBuilder,
  GVHForm,
  MailInsteadLetter,
  AppointmentReminderSMS,
  RegisterChargingStation,
};

export default formComponentsMapper;
