import IconButton from '../IconButton/IconButton';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import { WIDESCREEN_BREAKPOINT_ASPECT_RATIO_CONFIGS } from '../LegacyPicture/consts';
import styles from './ImageGridItem.module.scss';
import type { ImageGridItemProps } from './interfaces';

const ImageGridItem = ({
  image,
  title,
  imageNumber,
  onClickInfoButton,
}: ImageGridItemProps) => {
  const shouldShowText = title || imageNumber;

  return (
    <div className={styles.base}>
      <LegacyPicture
        className={styles.image}
        asset={image}
        breakpointAspectRatioConfigs={
          WIDESCREEN_BREAKPOINT_ASPECT_RATIO_CONFIGS
        }
        fitCover
      />

      <div className={styles.textAndDownloadContainer}>
        <div className={styles.downloadButtonWrapper}>
          {shouldShowText && <>&nbsp;</>}
          <a href={image.path} download>
            <IconButton
              variant={'action/download'}
              className={styles.downloadButton}
              aria-label="Herunterladen"
            />
          </a>
        </div>
        {shouldShowText && (
          <span className={styles.title}>
            {title}
            {imageNumber && (
              <span className={styles.number}>{imageNumber}</span>
            )}
          </span>
        )}
      </div>

      <button
        type="button"
        className={styles.infoButton}
        onClick={onClickInfoButton}
      >
        Details
      </button>
    </div>
  );
};

export default ImageGridItem;
