import { getSafeInnerHTMLProp } from '@/helpers/getSafeInnerHTML';
import usePathname from '@/hooks/usePathname/usePathname';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { animated, useSpring, useTransition } from '@react-spring/web';
import Portal from '../../../components/Portal/Portal';
import useKeyPress from '../../../hooks/useKeyPress/useKeyPress';
import ButtonLink from '../ButtonLink/ButtonLink';
import { ClickableAction } from '../Clickable/consts';
import Icon from '../Icon/Icon';
import LockBodyScroll from '../LockBodyScroll/LockBodyScroll';
import styles from './GuideBox.module.scss';
import type { GuideBoxProps } from './interfaces';

const GuideBox = ({
  guide: { name, teaserText, title, fullText },
  isOpenInitially = false,
  coloured = false,
}: GuideBoxProps) => {
  const pathname = usePathname();
  const [full, setFull] = useState(isOpenInitially);
  const [closing, setClosing] = useState(false);
  const [teaserBoxNode, setTeaserBoxNode] = useState<HTMLElement>();
  const [titleNode, setTitleNode] = useState<HTMLElement>();
  const [textNode, setTextNode] = useState<HTMLElement>();
  const [initOver, setInitOver] = useState(false);
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    bottom: 0,
    right: 0,
  });
  const [titleWidth, setTitleWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);

  const updatePositionToFullScreen = useCallback(() => {
    if (typeof window !== 'undefined') {
      setPosition({
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
  }, [setPosition]);

  const updatePositionToTeaserBoxSize = useCallback(() => {
    if (typeof window !== 'undefined' && teaserBoxNode) {
      setPosition(teaserBoxNode.getBoundingClientRect());
    }
  }, [teaserBoxNode]);

  const teaserBoxRef = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        setTeaserBoxNode(node);
        updatePositionToTeaserBoxSize();
      }
    },
    [updatePositionToTeaserBoxSize]
  );

  const titleRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setTitleNode(node);
    }
  }, []);

  const textRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setTextNode(node);
    }
  }, []);

  const handleReadMoreClick = (
    $event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    hash: string
  ) => {
    $event.preventDefault();

    if (teaserBoxNode) {
      // eslint-disable-next-line
      history.replaceState('', '', `#${hash}`);
      setFull(true);
    }
  };

  const updateNodesWidth = useCallback(() => {
    if (titleNode) {
      const { width } = titleNode.getBoundingClientRect();
      setTitleWidth(width);
    }
    if (textNode) {
      const { width } = textNode.getBoundingClientRect();
      setTextWidth(width);
    }
  }, [titleNode, textNode]);

  const updatePosition = useCallback(() => {
    if (full) {
      updatePositionToFullScreen();
    } else {
      updatePositionToTeaserBoxSize();
    }

    updateNodesWidth();
  }, [
    full,
    updatePositionToFullScreen,
    updatePositionToTeaserBoxSize,
    updateNodesWidth,
  ]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('scroll', updatePosition, {
        passive: true,
      });
      window.addEventListener('resize', updatePosition, {
        passive: true,
      });

      return () => {
        document.removeEventListener('scroll', updatePosition);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [updatePosition]);

  useEffect(() => {
    updatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [full]);

  useEffect(() => {
    if (closing) {
      updatePositionToTeaserBoxSize();
    }
  }, [closing, updatePositionToTeaserBoxSize]);

  const closedToFull = useSpring({
    config: {
      duration: 350,
    },
    height: position.height,
    width: position.width,
    top: position.top,
    left: position.left,

    borderRadius: full && !closing ? 0 : 5,
    // padding: full && !closing ? `10rem 3.5rem` : `4.5rem 3.5rem`,
    pointerEvents: full && !closing ? `all` : `none`,
    onRest: () => {
      if (closing) {
        setClosing(false);
        setFull(false);
      }
    },
  });

  const handleClose = () => {
    setClosing(true);
    // eslint-disable-next-line
    history.replaceState('', document.title, pathname + window.location.search);
  };

  useKeyPress('Escape', {
    onKeyDown: () => {
      if (full && !closing) {
        handleClose();
      }
    },
  });

  const closeIconTransition = useTransition(!closing && full, {
    from: { transform: `translate3D(200px, 0, 0)` },
    enter: { transform: `translate3D(0, 0, 0)` },
    leave: { transform: `translate3D(200px, 0, 0)` },
  });

  const teaserTransition = useTransition(!full || closing, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const fullTextTransition = useTransition(!closing && full, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
      position: `absolute`,
    },
    leave: { opacity: 0, position: `absolute` },
  });

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      position.width !== 0 &&
      position.height !== 0 &&
      !initOver
    ) {
      setInitOver(true);
      if (window.location.hash === `#${name}`) {
        (document.querySelector(`#${name}`) as HTMLElement).scrollIntoView();
        setTimeout(() => {
          setFull(true);
        }, 350);
      }
    }
  }, [name, position, initOver]);

  return (
    <>
      <article
        ref={teaserBoxRef as React.Ref<HTMLDivElement>}
        className={clsx(styles.base, coloured ? styles.coloured : styles.white)}
        id={name}
        key={name}
        data-test-component="guide-box"
      >
        <div className={styles.container}>
          <h3
            ref={titleRef as React.Ref<HTMLHeadingElement>}
            className={styles.title}
          >
            {title}
          </h3>
          <div
            className={styles.rightSide}
            ref={textRef as React.Ref<HTMLDivElement>}
          >
            <p className={styles.teaser}>{teaserText}</p>
            <ButtonLink
              actionType={ClickableAction.Custom}
              className={styles.link}
              // href={`#${name}`}
              onClick={($event) => handleReadMoreClick($event, name)}
              clickContext={title}
            >
              Antwort lesen
            </ButtonLink>
          </div>
        </div>
      </article>
      <Portal id={`guide-teaser-${name}`}>
        {full && (
          <>
            <LockBodyScroll />
            <animated.article
              className={clsx(
                styles.base,
                coloured ? styles.coloured : styles.white,
                { [styles.mobileFull]: full }
              )}
              style={{
                position: `fixed`,
                zIndex: full ? 20 : 0,
                ...closedToFull,
                pointerEvents: closedToFull.pointerEvents as
                  | React.CSSProperties['pointerEvents']
                  | any,
              }}
              key={name}
            >
              <div className={styles.container}>
                {closeIconTransition(
                  (props, item) =>
                    item && (
                      <animated.button
                        style={props}
                        className={styles.closeFull}
                        onClick={handleClose}
                      >
                        <Icon variant={'action/close'} size="iconSize32" />
                      </animated.button>
                    )
                )}

                <h3 className={styles.title} style={{ width: titleWidth }}>
                  {title}
                </h3>
                <div
                  style={{ width: textWidth }}
                  className={clsx(styles.rightSide, {
                    [styles.rightSideFull]: full && !closing,
                  })}
                >
                  <div className={styles.fullTextWrapper}>
                    <p className={styles.teaser}>{teaserText}</p>
                    {fullTextTransition(
                      (props, item) =>
                        item && (
                          <animated.div
                            style={props}
                            className={styles.teaser}
                            {...getSafeInnerHTMLProp(fullText)}
                          />
                        )
                    )}

                    {teaserTransition(
                      (props, item) =>
                        item && (
                          <animated.a style={props} className={styles.link}>
                            <Icon
                              variant={'action/arrow-right'}
                              size="iconSize32"
                            />
                            Antwort lesen
                          </animated.a>
                        )
                    )}
                  </div>
                </div>
              </div>
            </animated.article>
          </>
        )}
      </Portal>
    </>
  );
};

export default GuideBox;
