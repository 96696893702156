import FormRow from '@uikit/components/FormRow/FormRow';
import Headline from '@uikit/components/Headline/Headline';
import React from 'react';
import type { InfoTitleProps } from './interfaces';
import {
  HeadlineLevel,
  HeadlineAlign,
} from '@uikit/components/Headline/consts';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';

const InfoTitle = ({ text }: InfoTitleProps) => {
  const isMobile = useIsMobile(768);
  return (
    <FormRow>
      <Headline
        level={HeadlineLevel.Three}
        align={isMobile ? HeadlineAlign.Left : HeadlineAlign.Center}
      >
        {text}
      </Headline>
    </FormRow>
  );
};

export default InfoTitle;
