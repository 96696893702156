import useAnimatedHeight from '@/hooks/useAnimatedHeight/useAnimatedHeight';
import { animated, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Icon from '../Icon/Icon';
import NewCarouselContext from './NewCarousel.context';
import styles from './NewCarousel.module.scss';
import scrollToSlide from './helpers/scrollToSlide';
import { updateCarouselHeight } from './helpers/updateCarouselHeight';
import type {
  NewCarouselProps,
  NewCarouselSlideProps,
  NewCarouselSlidesProps,
} from './types';

const NewCarouselSlide = ({ children, captionSlot }: NewCarouselSlideProps) => {
  const ref = useRef<HTMLLIElement>(null);
  const { registerItem, unregisterItem, setCaptionComponents } =
    useContext(NewCarouselContext);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    registerItem(ref);

    return () => {
      unregisterItem(ref);
    };
  }, [registerItem, unregisterItem]);

  useEffect(() => {
    setCaptionComponents((components) => [...components, captionSlot]);
  }, [captionSlot, setCaptionComponents]);

  return (
    <li className={styles.slide} ref={ref}>
      <span className={styles.slideContent}>{children}</span>
    </li>
  );
};

const NewCarouselSlides = ({ children }: NewCarouselSlidesProps) => {
  const { setActiveIndex, activeIndex, slidesRef, slideRefs } =
    useContext(NewCarouselContext);

  const numberOfChildren = React.Children.toArray(children).length;

  useEffect(() => {
    if (!slidesRef.current || !slideRefs) {
      return;
    }
    updateCarouselHeight(slidesRef, slideRefs[activeIndex]);
  }, [activeIndex, slideRefs, slidesRef]);

  useEffect(() => {
    if (
      !slidesRef.current ||
      !slideRefs ||
      numberOfChildren !== slideRefs.length
    ) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }

          const currentIndex = slideRefs.findIndex(
            ({ current }) => current === entry.target
          );

          setActiveIndex(() => currentIndex);
        });
      },
      { root: slidesRef.current, rootMargin: '0px', threshold: 1 }
    );

    slideRefs.forEach((item) => item.current && observer.observe(item.current));

    return () => {
      observer.disconnect();
    };
  }, [numberOfChildren, setActiveIndex, slideRefs, slidesRef]);

  return (
    <ul className={styles.slides} ref={slidesRef}>
      {children}
    </ul>
  );
};

const NewCarouselControls = () => {
  const { handlePrevItem, handleNextItem, activeIndex, maxIndex } =
    useContext(NewCarouselContext);

  return (
    <>
      <button
        onClick={handlePrevItem}
        className={clsx(styles.controlButton, styles.left)}
        disabled={activeIndex === 0}
      >
        <Icon variant="action/arrow-left" size="iconSize20" />
      </button>
      <button
        onClick={handleNextItem}
        className={clsx(styles.controlButton, styles.right)}
        disabled={activeIndex === maxIndex}
      >
        <Icon variant="action/arrow-right" size="iconSize20" />
      </button>
    </>
  );
};

const NewCarouselIndicators = () => {
  const { activeIndex, slidesRef, numberOfItems } =
    useContext(NewCarouselContext);

  const handleIndicatorClick = (index: number) => {
    scrollToSlide(slidesRef, index);
  };

  return (
    <div className={styles.indicators}>
      {Array.from({ length: numberOfItems }).map((_, index) => (
        <button
          key={index}
          onClick={() => handleIndicatorClick(index)}
          className={clsx(styles.indicator, {
            [styles.active]: index === activeIndex,
          })}
          data-slide={index}
          data-active={index === activeIndex}
        />
      ))}
    </div>
  );
};

const NewCarousel = ({ children }: NewCarouselProps) => {
  const slidesRef = useRef<HTMLUListElement>(null);
  const shouldUpdateIndex = useRef(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideRefs, setSlideRefs] = useState<React.RefObject<HTMLElement>[]>(
    []
  );
  const [captionComponents, setCaptionComponents] = useState<
    Array<React.ReactNode | undefined>
  >([]);

  const [animatedHeightStyle, captionRef] = useAnimatedHeight();

  const baseTransition = useTransition(captionComponents[activeIndex], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
    exitBeforeEnter: true,
  });

  const numberOfItems = slideRefs.length;

  const registerItem = (itemRef: React.RefObject<HTMLElement>) => {
    if (slideRefs.includes(itemRef)) {
      return;
    }

    setSlideRefs((prevRefs) => [...prevRefs, itemRef]);
  };

  const unregisterItem = (itemRef: React.RefObject<HTMLElement>) => {
    if (!slideRefs.includes(itemRef)) {
      return;
    }

    setSlideRefs((prevRefs) => {
      return prevRefs.filter((ref) => ref !== itemRef);
    });
  };

  const handleNextItem = () => {
    if (!slidesRef.current) {
      return;
    }

    const nextIndex = Math.min(activeIndex + 1, numberOfItems - 1);

    scrollToSlide(slidesRef, nextIndex);
  };

  const handlePrevItem = () => {
    if (!slidesRef.current) {
      return;
    }

    const prevIndex = Math.max(activeIndex - 1, 0);

    scrollToSlide(slidesRef, prevIndex);
  };

  return (
    <NewCarouselContext.Provider
      value={{
        activeIndex,
        handleNextItem,
        handlePrevItem,
        maxIndex: numberOfItems - 1,
        numberOfItems,
        registerItem,
        setActiveIndex,
        slidesRef,
        unregisterItem,
        shouldUpdateIndex,
        captionComponents,
        setCaptionComponents,
        slideRefs,
      }}
    >
      <div>
        <div className={styles.base}>{children}</div>
        <animated.div style={animatedHeightStyle}>
          <div ref={captionRef}>
            {baseTransition((styleProps, captionComponent) => {
              return (
                <animated.div style={styleProps}>
                  {captionComponent}
                </animated.div>
              );
            })}
          </div>
        </animated.div>
      </div>
    </NewCarouselContext.Provider>
  );
};

export default Object.assign(NewCarousel, {
  Slides: NewCarouselSlides,
  Slide: NewCarouselSlide,
  Controls: NewCarouselControls,
  Indicators: NewCarouselIndicators,
});
