import { SVGProps } from 'react';
const PlantLeafIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={200}
    height={214}
    viewBox="0 0 200 214"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_1742_1851)">
      <path
        d="M120.282 127.345C59.3775 145.592 39.3489 101.418 28.8435 50.9272C27.4697 44.3243 34.7436 39.022 41.2071 40.9477C66.3852 48.4487 90.3838 41.4913 113.803 47.5528C139.577 54.2236 148.779 80.2313 140.555 101.234C158.278 121.22 152.354 137.726 142.36 140.384C120.282 146.256 120.282 127.345 120.282 127.345Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0082 56.6059C41.9055 55.8461 42.1962 55.088 42.7791 54.5917C43.3616 54.094 44.1565 53.9307 44.8893 54.1505C53.0745 56.63 66.2212 57.1853 80.9584 55.673C106.286 53.0733 122.774 58.757 128.638 72.1122C135.006 86.6091 129.422 99.7895 126.968 104.429C138.843 114.678 139.668 125.358 139.703 125.917C139.768 127.001 139.055 127.95 138.043 128.221C137.903 128.259 137.756 128.284 137.605 128.293C136.37 128.374 135.3 127.437 135.212 126.201C135.169 125.606 133.93 111.461 113.781 100.988C112.204 100.168 110.639 99.3763 109.073 98.5785L89.3 99.2069C88.057 99.245 87.0182 98.2716 86.9788 97.029C86.9393 95.7864 87.9177 94.7493 89.1552 94.7081L100.681 94.3421C100.455 94.2288 100.219 94.1087 99.993 93.9953C85.713 86.8456 73.3807 80.6711 66.992 74.6494C66.088 73.7968 66.0466 72.373 66.8973 71.468C67.7521 70.5665 69.1737 70.5226 70.0791 71.3748C74.386 75.4339 81.967 79.7447 91.1643 84.4889L87.9812 72.6095C87.6597 71.4098 88.3725 70.1753 89.5722 69.8538C90.7733 69.532 92.0064 70.2451 92.3279 71.4448L96.5595 87.2377C98.3367 88.1327 100.152 89.0438 102.007 89.971C106.508 92.2247 111.163 94.5555 115.856 96.9955C118.674 98.461 121.159 100 123.351 101.573C125.57 97.0122 129.742 85.8128 124.519 73.9225C118.198 59.5311 97.5918 58.49 81.4183 60.148C73.2769 60.9846 58.6793 61.888 47.1166 59.3678C48.7835 66.8581 54.1617 85.504 68.8105 101.709C89.07 124.121 114.859 112.54 115.942 112.039C117.067 111.514 118.409 112.001 118.934 113.127C119.459 114.252 118.973 115.592 117.847 116.117C117.768 116.154 115.651 117.114 112.162 118.049C102.434 120.655 82.0488 123.065 65.4727 104.726C45.7612 82.9222 42.1519 57.6699 42.0082 56.6059Z"
        fill="url(#paint0_linear_1742_1851)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_1742_1851"
        x={-19.3252}
        y={0.043457}
        width={219.233}
        height={213.576}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={16} />
        <feGaussianBlur stdDeviation={24} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1742_1851"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1742_1851"
          result="effect2_dropShadow_1742_1851"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1742_1851"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1742_1851"
        x1={145.277}
        y1={129.714}
        x2={66.8245}
        y2={150.735}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#990099" />
        <stop offset={1} stopColor="#FF0000" />
      </linearGradient>
    </defs>
  </svg>
);
export default PlantLeafIcon;
