import useMeasure from '@/hooks/useMeasure/useMeasure';
import { animated, useSpring } from '@react-spring/web';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import styles from './AccordionItem.module.scss';
import type { AccordionItemProps } from './interfaces';

const AccordionItem = ({
  headline,
  children,
  className,
  isActive = false,
  onClick,
  color = 'default',
}: AccordionItemProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const accordionOpen = isAccordionOpen || isActive;

  const childrenRef = useRef<HTMLDivElement>(null);

  const { height } = useMeasure(childrenRef, true);

  const { ...props } = useSpring({
    opacity: accordionOpen ? 1 : 0,
    maxHeight: accordionOpen ? `${height}px` : '0px',
    config: { duration: 300 },
  });

  const handleOnClick = () => {
    onClick ? onClick() : setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div
      className={clsx(
        styles.base,
        styles[color],
        {
          [styles.open]: accordionOpen,
        },
        className
      )}
      onClick={() => handleOnClick()}
    >
      <hr className={styles.hr} />

      <div className={styles.headlineContainer}>
        <h3 className={styles.headline}>{headline}</h3>
        <div className={clsx(styles.button)} />
      </div>

      <animated.div
        style={{
          overflow: 'hidden',
          ...props,
        }}
      >
        <div className={clsx(styles.contentContainer)} ref={childrenRef}>
          <div className={clsx(styles.description)}>{children}</div>
        </div>
      </animated.div>

      <hr className={styles.hr} />
    </div>
  );
};

export default AccordionItem;
