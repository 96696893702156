import React, { useContext, useEffect } from 'react';
import ModalContext from '../../contexts/Modal.context';
import useDataLayer from '../../hooks/useDataLayer/useDataLayer';
import DevErrorMessage from '../DevErrorMessage/DevErrorMessage';
import formComponentsMapper from './formComponentsMapper';
import type { FormsProps } from './interfaces';
import Container from '@uikit/components/Container/Container';
import { ContainerSize } from '@uikit/components/Container/consts';

const Forms = ({ form, ...formProps }: FormsProps): JSX.Element => {
  const { showRearLayer } = useContext(ModalContext);
  const { pushDataLayerEvent } = useDataLayer();
  const FormComponent = formComponentsMapper[form];
  const { formName } = formProps;

  const handleOnSuccess = () => {
    showRearLayer();
    pushDataLayerEvent({
      event: 'Formular abgeschickt',
      formName,
    });
  };

  useEffect(() => {
    pushDataLayerEvent({
      event: 'Formular geöffnet',
      formName,
    });
  }, [formName, pushDataLayerEvent]);

  return FormComponent ? (
    <Container size={ContainerSize.Small} spaceTop={4}>
      <FormComponent
        {...(formProps as any)}
        buttonLabel={formProps.buttonLabel ?? 'Kontaktformular abschicken'}
        formName={formName}
        onSuccess={handleOnSuccess}
      />
    </Container>
  ) : (
    <DevErrorMessage>no form component with name: ${form}</DevErrorMessage>
  );
};

export default Forms;
