import LinkButton from '@/uikit/components/LinkButton/LinkButton';
import clsx from 'clsx';
import styles from './FilterOpticMapDetails.module.scss';
import type { FilterOpticMapDetailsProps } from './interfaces';

const FilterOpticMapDetails = ({
  statusText,
  showLink,
  link,
  linkText,
  address,
  color,
}: FilterOpticMapDetailsProps) => {
  return (
    <div className={styles.base}>
      <div className={clsx(styles.status, styles[color])}>{statusText}</div>
      <div className={styles.address}>{address}</div>
      {showLink && (
        <>
          <div className={clsx(styles.link, styles.linkMobile)}>
            <LinkButton
              icon={'action/external-link'}
              size="small"
              iconPosition="left"
              variant="default"
              disabled={false}
              as="a"
              href={link}
              target="_blank"
              linkExtern
              disableTransition
            >
              {linkText}
            </LinkButton>
          </div>
          <div className={clsx(styles.link, styles.linkDesktop)}>
            <LinkButton
              icon={'action/external-link'}
              size="medium"
              iconPosition="left"
              variant="default"
              disabled={false}
              as="a"
              href={link}
              target="_blank"
              linkExtern
              disableTransition
            >
              {linkText}
            </LinkButton>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterOpticMapDetails;
