import listToOptions from '@/helpers/listToOptions';
import { RegisterChargingStationFormRequestLabels } from './interfaces';

export const registerChargingStationKeysOrder = [
  'Straße (Standort)',
  'Hausnummer (Standort)',
  'Postleitzahl (Standort)',
  'Stadt (Standort)',
  'Öffentliche Station',
  'Gebäudetyp',
  'Standort',
  'Nutzungstyp',
  'Rückspeisung vorgesehen',
  'Lageplan vorhanden',
  'Netzentnahmeleistung',
  'Anzahl aller Ladeeinrichtungen / Ladesäulen',
  'Anzahl Ladepunkte AC 3,7 kW',
  'Anzahl Ladepunkte AC 11 kW',
  'Anzahl Ladepunkte AC >= 22 kW',
  'Anzahl Ladepunkte DC <= 100 kW',
  'Anzahl Ladepunkte DC > 100 kW',
  'Anschluss der Ladeeinrichtung',
  'Hersteller',
  'Messung',
  'Zählernummer',
  'Anrede (Kunde)',
  'Titel (Kunde)',
  'Name (Kunde)',
  'Vorname (Kunde)',
  'Firma (Kunde)',
  'Straße (Kunde)',
  'Hausnummer (Kunde)',
  'PLZ (Kunde)',
  'Ort (Kunde)',
  'Telefon (Kunde)',
  'Telefon mobil (Kunde)',
  'E-Mail (Kunde)',
  'Reduziertes Netzentgelt',
  'Ich benötige das KfW Bestätigungsschreiben',
  'Anmerkung',
];

export const registerChargingStationLabels: RegisterChargingStationFormRequestLabels =
  {
    locStreet: 'Straße (Standort)',
    locStreetNumber: 'Hausnummer (Standort)',
    locZipCode: 'Postleitzahl (Standort)',
    locCity: 'Stadt (Standort)',
    publicStation: 'Öffentliche Station',
    buildingType: 'Gebäudetyp',
    location: 'Standort',
    usageType: 'Nutzungstyp',
    feedbackPlanned: 'Rückspeisung vorgesehen',
    locationPlanAvailable: 'Lageplan vorhanden',
    netWithdrawalPower: 'Netzentnahmeleistung',
    chargingStations: 'Anzahl aller Ladeeinrichtungen / Ladesäulen',
    acPorts3: 'Anzahl Ladepunkte AC 3,7 kW',
    acPorts11: 'Anzahl Ladepunkte AC 11 kW',
    acPortsGE22: 'Anzahl Ladepunkte AC >= 22 kW',
    dcPortsLE100: 'Anzahl Ladepunkte DC <= 100 kW',
    dcPortsGT100: 'Anzahl Ladepunkte DC > 100 kW',
    connectionType: 'Anschluss der Ladeeinrichtung',
    manufacturer: 'Hersteller',
    metering: 'Messung',
    meterNumber: 'Zählernummer',
    customerSalutation: 'Anrede (Kunde)',
    customerTitle: 'Titel (Kunde)',
    customerSurname: 'Name (Kunde)',
    customerFirstName: 'Vorname (Kunde)',
    customerCompany: 'Firma (Kunde)',
    customerStreet: 'Straße (Kunde)',
    customerStreetNumber: 'Hausnummer (Kunde)',
    customerZipCode: 'PLZ (Kunde)',
    customerCity: 'Ort (Kunde)',
    customerPhone: 'Telefon (Kunde)',
    customerMobile: 'Telefon mobil (Kunde)',
    customerEmail: 'E-Mail (Kunde)',
    reducedNetFee: 'Reduziertes Netzentgelt',
    orderKfWConfirmation: 'Ich bnötige das KfW Bestätigungsschreiben',
    comment: 'Anmerkung',
  };

export const publicStationOptions = listToOptions([
  'Privatgrundstück',
  'Öffentlich nutzbare Stelle',
]);
export const buildingTypeOptions = listToOptions([
  'Einfamilienhaus',
  'Reihen-/Doppelhaus',
  'Mehrfamilienhaus',
  'Gewerbe',
]);
export const locationOptions = listToOptions([
  'Stellplatz/Garage am Haus',
  'Stellplatz/Garage vom Haus entfernt',
  'Tiefgarage',
  'Gemeinschaftsgarage',
  'Andere Parksituation',
]);
export const usageTypeOptions = listToOptions([
  'Typ A',
  'Typ B',
  'Typ C',
  'Typ D',
  'Typ E',
  'Typ F',
  'Typ G',
]);
export const connectionTypeOptions = listToOptions([
  'L1',
  'L2',
  'L3',
  'Drehstrom',
]);
export const meteringOptions = listToOptions([
  'keine separate Messung für die Ladeeinrichtung notwendig',
  'Neuer Zähler',
  'Vorhandener Zähler', // keep schema validation for step 3 in mind!
]);

export const customerSalutationOptions = listToOptions([
  'Frau',
  'Herr',
  'Divers',
]);

export const customerTitleOptions = listToOptions(['Prof', 'Dr']);

export const reducedNetFeeOptions = listToOptions(['Ja', 'Nein']);

export const orderKfWConfirmationOptions = listToOptions(['Ja', 'Nein']);
