import { UseSpringProps, useSpring } from '@react-spring/web';
import { useRef } from 'react';
import useMeasure from '../useMeasure/useMeasure';

const useAnimatedHeight = <Props extends object>(
  options: Partial<UseSpringProps<Props>> = {}
) => {
  const childElementRef = useRef<HTMLDivElement>(null);
  const { height } = useMeasure(childElementRef);
  const animatedHeightStyle = useSpring({
    height,
    ...options,
  } as const);

  return [animatedHeightStyle, childElementRef] as const;
};

export default useAnimatedHeight;
