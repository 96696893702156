import React from 'react';
import styles from './SplitSection.module.scss';

const SplitSection = ({
  children,
  ...additionalProps
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <div className={styles.splitSection} {...additionalProps}>
    {children}
  </div>
);

export default SplitSection;
