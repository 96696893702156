import React from 'react';
import Icon from '../Icon/Icon';
import { JOB_CALLOUT_ICONS } from './JobCallout.consts';
import type { JobCalloutIcon, JobCalloutProps } from './JobCallout.interfaces';
import styles from './JobCallout.module.scss';
import assetPath from '../../helpers/assetPath';
import Clickable from '../Clickable/Clickable';
import ColorFilter from '../ColorFilter/ColorFilter';

const getIconUrl = (iconName: JobCalloutIcon) => {
  if (!JOB_CALLOUT_ICONS.includes(iconName)) {
    throw new Error(`unknown icon: ${iconName}`);
  }
  return `${assetPath}/assets/job-icons/${iconName}.svg`;
};

const JobCallout = ({
  headline,
  linkText,
  text,
  color,
  icon,
  ...props
}: JobCalloutProps) => {
  return (
    <div className={styles.base} data-color={color}>
      <div className={styles.icon}>
        <img src={getIconUrl(icon)} alt="" className={styles.img} />
      </div>
      <div className={styles.content}>
        <h4 className={styles.headline}>{headline}</h4>
        <p className={styles.text}>{text}</p>
        <Clickable className={styles.link} {...props}>
          <ColorFilter color={color} className={styles.arrow}>
            <Icon variant={'action/arrow-right'} size="iconSize20" />
          </ColorFilter>
          {linkText}
        </Clickable>
      </div>
    </div>
  );
};

export default JobCallout;
