import Button from '@uikit/components/Button/Button';
import { ButtonAlign, ButtonType } from '@uikit/components/Button/consts';
import CheckBoxBlock from '@uikit/components/CheckBoxBlock/CheckBoxBlock';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import FormField from '@uikit/components/FormField/FormField';
import FormFieldDateSelect from '@uikit/components/FormFieldDateSelect/FormFieldDateSelect';
import FormFieldDropdown from '@uikit/components/FormFieldDropdown/FormFieldDropdown';
import FormFieldRadioGroup from '@uikit/components/FormFieldRadioGroup/FormFieldRadioGroup';
import FormFieldSelect from '@uikit/components/FormFieldSelect/FormFieldSelect';
import FormRow from '@uikit/components/FormRow/FormRow';
import FormRowHeadline from '@uikit/components/FormRow/FormRowHeadline';
import React from 'react';
import listToOptions from '../../../../helpers/listToOptions';
import useForm from '../../../../hooks/useForm/useForm';
import { designationOptions, titleOptions, zoneOptions } from '../config';
import { maxGVHStartDate, minGVHStartDate } from '../helpers';
import { GVHFormStep, GVHFormStep1State } from '../interfaces';
import schema from './GVHFormStep1.schema';

const gvhInitialFormState: GVHFormStep1State = {
  designation: '',
  title: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  email: '',
  phone: '',
  enercityUserId: '',
  mobilCardUserType: '',
  mobilCardUserDesignation: '',
  mobilCardUserFirstName: '',
  mobilCardUserLastName: '',
  mobilCardUserBirthDate: '',
  mobilCardUserNr: '',
  startDate: '',
  fromZone: '',
  toZone: '',
  class: '',
  iban: '',
  accountOwner: '',
  SEPALastschrift: false,
};

const GVHFormStep1 = ({ onSubmit }: GVHFormStep) => {
  const {
    formState,
    getErrorByFieldName,
    handleBlur,
    handleChange,
    handleChangeValue,
    handleSubmit,
    isFormValid,
  } = useForm(onSubmit, schema, gvhInitialFormState, 'gvhFormStep1');

  return (
    <form onSubmit={handleSubmit}>
      <FormRowHeadline>Vertragspartner</FormRowHeadline>

      <FormRow>
        <FormFieldRadioGroup
          name={'designation'}
          items={designationOptions}
          value={formState['designation']}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('designation')}
        />
      </FormRow>

      <FormRow>
        <FormFieldSelect
          label={'Titel (optional)'}
          name={'title'}
          value={formState['title']}
          options={titleOptions}
          nativeOptions={titleOptions}
          onSelectOption={(option) => handleChangeValue('title')(option.value)}
          inputId={'title'}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('title')}
        />
      </FormRow>

      <FormRow>
        <FormField
          name={'firstName'}
          value={formState['firstName']}
          label={'Vorname'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('firstName')}
        />
        <FormField
          name={'lastName'}
          value={formState['lastName']}
          label={'Nachname'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('lastName')}
        />
      </FormRow>

      <FormRow>
        <FormField
          name={'birthDate'}
          value={formState['birthDate']}
          label={'Geburtsdatum'}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={'TT.MM.JJJJ'}
          errorMessage={getErrorByFieldName('birthDate')}
        />
      </FormRow>

      <FormRow>
        <FormField
          name={'email'}
          value={formState['email']}
          label={'E-Mail-Adresse'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('email')}
        />
        <FormField
          name={'phone'}
          value={formState['phone']}
          label={'Telefonnummer'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('phone')}
        />
      </FormRow>

      <FormRow>
        <FormField
          name={'enercityUserId'}
          value={formState['enercityUserId']}
          label={'enercity Kundennummer'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('enercityUserId')}
        />
      </FormRow>

      <FormRowHeadline>enercity GVH MobilCard-Nutzer</FormRowHeadline>

      <FormRow>
        <FormFieldRadioGroup
          label={'Der enercity GVH MobilCard-Nutzer'}
          items={[
            {
              label: 'ist Vertragspartner von enercity.',
              value: 'Vertragspartner',
            },
            {
              label: 'wohnt im Haushalt des Vertragspartners.',
              value: 'Selber Haushalt',
            },
          ]}
          name={'mobilCardUserType'}
          onChange={handleChange}
          value={formState['mobilCardUserType']}
          errorMessage={getErrorByFieldName('mobilCardUserType')}
        />
      </FormRow>
      {formState['mobilCardUserType'] === 'Selber Haushalt' && (
        <>
          <FormRow>
            <FormFieldRadioGroup
              name={'mobilCardUserDesignation'}
              items={designationOptions}
              value={formState['mobilCardUserDesignation']}
              onChange={handleChange}
              errorMessage={getErrorByFieldName('mobilCardUserDesignation')}
            />
          </FormRow>
          <FormRow>
            <FormField
              value={formState['mobilCardUserFirstName']}
              label={'Vorname'}
              name={'mobilCardUserFirstName'}
              onBlur={handleBlur}
              onChange={handleChange}
              errorMessage={getErrorByFieldName('mobilCardUserFirstName')}
            />
            <FormField
              name={'mobilCardUserLastName'}
              value={formState['mobilCardUserLastName']}
              label={'Nachname'}
              onBlur={handleBlur}
              onChange={handleChange}
              errorMessage={getErrorByFieldName('mobilCardUserLastName')}
            />
          </FormRow>
          <FormRow>
            <FormField
              name={'mobilCardUserBirthDate'}
              value={formState['mobilCardUserBirthDate']}
              label={'Geburtsdatum'}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={'TT.MM.JJJJ'}
              errorMessage={getErrorByFieldName('mobilCardUserBirthDate')}
            />
          </FormRow>
        </>
      )}
      <FormRow>
        <FormField
          name={'mobilCardUserNr'}
          value={formState['mobilCardUserNr']}
          label={'GVH Abonummer (optional)'}
          placeholder={'sofern vorhanden'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('mobilCardUserNr')}
        />
      </FormRow>

      <FormRowHeadline>
        Gewünschte enercity GVH MobilCard und Gültigkeitsbereich
      </FormRowHeadline>

      <FormRow
        title="Beginn der Laufzeit"
        description="Der Beginn der Laufzeit muss mind. 6 Wochen in der Zukunft liegen und
        ist immer der 01. des Monats."
      >
        <FormFieldDateSelect
          name={'startDate'}
          onChange={handleChangeValue('startDate')}
          errorMessage={getErrorByFieldName('startDate')}
          dateParts={['year', 'month']}
          minDate={minGVHStartDate}
          maxDate={maxGVHStartDate}
        />
      </FormRow>

      <FormRow
        title="Zonen"
        description={
          <>
            Bitte wählen Sie nachfolgend die Zonen aus, für die Ihre enercity
            GVH MobilCard gelten soll.{' '}
            <a
              href="https://www.gvh.de/fahrkarten/zonen/"
              target="_blank"
              rel="noreferrer"
            >
              Hier erfahren Sie mehr
            </a>
            . So erreichen Sie das ÜSTRA Kundenzentrum: 0511 1668-0.
          </>
        }
      >
        <FormFieldDropdown
          label={'Von Zone'}
          name={'fromZone'}
          value={formState['fromZone']}
          options={zoneOptions}
          nativeOptions={zoneOptions}
          onChange={(option) => handleChangeValue('fromZone')(option?.value)}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('fromZone')}
        />
        <FormFieldDropdown
          label={'Bis Zone'}
          name={'toZone'}
          value={formState['toZone']}
          options={zoneOptions}
          nativeOptions={zoneOptions}
          onChange={(option) => handleChangeValue('toZone')(option?.value)}
          onBlur={handleBlur}
          errorMessage={getErrorByFieldName('toZone')}
        />
      </FormRow>

      <FormRow>
        <FormFieldRadioGroup
          label={'Klasse'}
          items={listToOptions(['Erste Klasse', 'Zweite Klasse'])}
          name={'class'}
          value={formState['class']}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('class')}
        />
      </FormRow>

      <FormRowHeadline>SEPA-Lastschrift</FormRowHeadline>

      <FormRow>
        <FormField
          name={'iban'}
          value={formState['iban']}
          label={'IBAN'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('iban')}
        />
      </FormRow>

      <FormRow>
        <FormField
          name={'accountOwner'}
          value={formState['accountOwner']}
          label={'Kontoinhaber'}
          onBlur={handleBlur}
          onChange={handleChange}
          errorMessage={getErrorByFieldName('accountOwner')}
        />
      </FormRow>

      <FormRow
        description={
          <>
            Das SEPA-Mandant erhalten Sie nach Bestellung auf dem Postweg.
            <br />
            Gläubiger-Identifikationsnummer: DE49 ZZZ0 0000 1005 49
            <br />
            Mandatsreferenz: Die Mandatsreferenz teilen wir Ihnen zu einem
            späteren Zeitpunkt mit.
            <br />
            Kundennummer: Die Kundenummer für dieses Produkt teilen wir Ihnen
            später mit.
          </>
        }
      >
        <CheckBoxBlock
          onChange={handleChange}
          name={'SEPALastschrift'}
          errorMessage={getErrorByFieldName('SEPALastschrift')}
        >
          Ich ermächtige die enercity AG, Zahlungen von meinem Konto mittels
          Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an,
          die von der enercity AG auf mein Konto gezogenen Lastschriften
          einzulösen.
        </CheckBoxBlock>
      </FormRow>

      <FormRow>
        <Button
          type={ButtonType.Submit}
          actionType={ClickableAction.Custom}
          inactive={!isFormValid}
          align={ButtonAlign.Center}
          onClick={() => void 0}
        >
          Weiter
        </Button>
      </FormRow>
    </form>
  );
};

export default GVHFormStep1;
