import clsx from 'clsx';
import React from 'react';
import { ContainerChildrenSpace } from '../Container/consts';
import styles from './TwoColumnContainer.module.scss';
import { ContentAlign, ContentJustified, MobileColumnOrder } from './consts';
import type { TwoColumnContainerProps } from './interfaces';

const TwoColumnContainer = ({
  leftSideWidth = 50,
  contentAlign = ContentAlign.Top,
  withSeparator = false,
  'contents-left': contentsLeft,
  'contents-right': contentsRight,
  spaceBetweenChildrenLeft = ContainerChildrenSpace.Default,
  spaceBetweenChildrenRight = ContainerChildrenSpace.Default,
  justified,
  mobileColumnOrder,
  mobileColumnHidden,
  className,
  ...props
}: TwoColumnContainerProps) => {
  const leftSideStyles: React.CSSProperties = {
    width: `${leftSideWidth}%`,
  };
  const rightSideStyles: React.CSSProperties = {
    width: `${100 - leftSideWidth}%`,
  };

  return (
    <div
      className={clsx(className, styles.wrapper, {
        [styles.justifiedLeft]: justified === ContentJustified.Left,
        [styles.justifiedRight]: justified === ContentJustified.Right,
        [styles.mobileRightColumnFirst]:
          mobileColumnOrder === MobileColumnOrder.RightColumnFirst,
        [styles.hideLeftColumn]: mobileColumnHidden === 'left',
        [styles.hideRightColumn]: mobileColumnHidden === 'right',
      })}
      {...props}
      data-has-containers
    >
      <div
        className={clsx(
          styles.side,
          styles.left,
          // @ts-ignore
          styles[spaceBetweenChildrenLeft],
          {
            [styles.separator]: withSeparator,
            [styles.alignTop]: contentAlign === ContentAlign.Top,
            [styles.alignCenter]: contentAlign === ContentAlign.Center,
            [styles.alignBottom]: contentAlign === ContentAlign.Bottom,
          }
        )}
        style={leftSideStyles}
        data-is-container
        data-no-section-space={
          spaceBetweenChildrenLeft === ContainerChildrenSpace.None
        }
      >
        {contentsLeft}
      </div>
      <div
        className={clsx(
          styles.side,
          styles.right,
          // @ts-ignore
          styles[spaceBetweenChildrenRight],
          {
            [styles.alignTop]: contentAlign === ContentAlign.Top,
            [styles.alignCenter]: contentAlign === ContentAlign.Center,
            [styles.alignBottom]: contentAlign === ContentAlign.Bottom,
          }
        )}
        style={rightSideStyles}
        data-is-container
        data-no-section-space={
          spaceBetweenChildrenRight === ContainerChildrenSpace.None
        }
      >
        {contentsRight}
      </div>
    </div>
  );
};

export default TwoColumnContainer;
