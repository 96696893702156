import { getJson } from '@/helpers/ajax';
import { useQuery } from 'react-query';
import type { Response } from './types';

const URL = 'https://videoberatung-solar.enercity.de/api/v1/teaser/dashboard';

const getActiveOperators = async () => {
  const {
    data: { active_operators },
  } = await getJson<Response>(URL);

  return active_operators;
};

export const useSolarActiveOperators = () => {
  return useQuery('solar__active_operators', getActiveOperators, {
    retry: 3,
    refetchOnWindowFocus: false,
  });
};
