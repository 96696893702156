import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import clsx from 'clsx';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import styles from './Avatar.module.scss';
import type { AvatarProps } from './types';

const Avatar = ({
  image,
  className,
  alt = '',
  hasBorder = true,
  hasShadow = true,
  defaultWidth = 124,
  breakpointAspectRatioConfigs = [
    {
      breakpoint: BreakPoint.XSS,
      aspectRatio: '1 / 1',
      width: 124,
    },
  ],
  ...additionalProps
}: AvatarProps): JSX.Element => {
  const classes = clsx(
    styles.base,
    {
      [styles.border]: hasBorder,
      [styles.shadow]: hasShadow,
    },
    className
  );

  return (
    <LegacyPicture
      className={classes}
      asset={{ ...image, name: alt, calculatedAspectRatio: 100 }}
      defaultAspectRatio="1 / 1"
      defaultWidth={breakpointAspectRatioConfigs?.[0]?.width || 124}
      breakpointAspectRatioConfigs={breakpointAspectRatioConfigs}
      {...additionalProps}
      fitCover
    />
  );
};

export default Avatar;
